import { TextField, Typography } from "@mui/material";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import "./index.css";
import theme from "theme";

export default function AdminInput(props) {
  const {
    title,
    type,
    formError,
    value,
    onChange,
    endIcon,
    PasswordShowClick,
    sx,
    color,
    required,
    disabled,
    row,
    multiline,
    maxRows,
    minRows,
    min,
    onClick,
    maxLength,
    onBlur,
    placeholder,
    readOnly,
    autoFocus,
    startIcon,
    select
  } = props;
  return (
    <>
      <TextField
        autoFocus={autoFocus}
        select={select}
        margin="normal"
        // required={required}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            borderColor: "#C5C5C5",
          },

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#C5C5C5",
            },
            "&:hover fieldset": {
              borderColor: "#C5C5C5",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#C5C5C5",
            },
          },
          ...sx,
        }}
        fullWidth
        type={type}
        minRows={minRows}
        multiline={multiline}
        maxRows={maxRows}
        row={row}
        onChange={onChange}
        value={value}
        id={type}
        onClick={onClick}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        label={
          <span
            style={{ marginRight: title ? 8 : 0, marginLeft: title ? -1 : 0 }}
          >
            {title}
            {required ? <span style={{ marginLeft: 2 }}>*</span> : ""}
          </span>
        }
        name={type}
        autoComplete={type}
        inputProps={{
          min: min,
          maxLength: maxLength,
        }}
        InputProps={{
          readOnly: readOnly,
          style: {
            color: color,
          },
          startAdornment: (
            <InputAdornment
              onClick={PasswordShowClick}
              position="end"
              sx={{ color: theme.palette.secondary.main, cursor: "pointer" }}
            >
              {startIcon}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              onClick={PasswordShowClick}
              position="end"
              sx={{ color: theme.palette.secondary.main, cursor: "pointer" }}
            >
              {endIcon}
            </InputAdornment>
          ),
        }}
      />
      <Typography sx={{ color: theme.palette.error.main, fontSize: 12, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
