import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Stack,
  Typography,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInput from "components/adminInput";
import SVSignaturepad from "../../SVSignaturepad";
import "../index.css";
import { ESTATE_PLANNING_KEYS } from "utils/appConstant";
import { getSiteSetting } from "store/slice/settings";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { onlyNumber, validEmail, validateEmpty } from "utils/constantFun";
import { errorToast } from "components/showToast";
import { useDispatch, useSelector } from "react-redux";
import AdminButton from "components/adminButton";
import { api } from "services";
import { SILVERBACK, STATUS_CODES } from "services/apiConstant";
import { profileDetail, stateList } from "store/slice/profile";
import {
  addEstateDocument,
  getEstateDetail,
  getManagmentOptions,
  getManagmentRelationshipList,
  updateEstateDocument,
} from "store/slice/estate";
import Spinner from "components/spinner";
import SignatureImage from "../../signatureImage";
import GoogleAutoComplete from "components/googleAutoComplete";
import PreviewLivingWillDocument from "./previewLivingWill";
export default function LivingWillDocument() {
  const { id } = useParams();
  const { userMapperId } = useParams();
  const { actionType } = useParams();
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const witnessSign = useRef();
  const witnessTwoSign = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { stateListData, profileDetailData } = useSelector(
    (state) => state.profile
  );
  const { siteSettingData } = useSelector((state) => state.settings);

  const { estateDetailsData, managmentOptionsData, managmentRelationshipList } =
    useSelector((state) => state.estate);

  useEffect(() => {
    var requestParams = {
      id: id,
    };
    dispatch(stateList());
    dispatch(profileDetail(requestParams, true));
    dispatch(
      getManagmentOptions(ESTATE_PLANNING_KEYS?.LIVING_WILL_HEALTH_CARE_PROXY)
    );
    dispatch(getSiteSetting());
    dispatch(getManagmentRelationshipList());
    // window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (actionType && actionType == "edit") {
      setIsloading(true);
      dispatch(getEstateDetail(userMapperId, setIsloading));
    }
  }, [actionType]);

  // set estate details
  useEffect(() => {
    if (estateDetailsData && !livingWillData?.anatomical_gifts) {
      setLivingWillData(JSON.parse(JSON.stringify(estateDetailsData)));
    }
  }, [estateDetailsData]);

  const [livingWillData, setLivingWillData] = useState({
    agent_details: [
      {
        document_id: null,
        name: "",
        email: null,
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
      {
        document_id: null,
        name: "",
        email: null,
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
    ],
    witness_details: [
      {
        document_id: null,
        name: null,
        email: "",
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
      {
        document_id: null,
        name: null,
        email: "",
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
    ],
  });
  // removed extra parameter
  useEffect(() => {
    if (livingWillData?.agent_details?.length > 0) {
      if (livingWillData?.agent_details[0]?.relationship) {
        livingWillData.agent_details[0].relationship_id =
          managmentRelationshipList[
            managmentRelationshipList?.findIndex(
              (item) =>
                item.name == livingWillData.agent_details[0]["relationship"]
            )
          ]?.id;
      }
      if (livingWillData?.agent_details[1]?.relationship) {
        livingWillData.agent_details[1].relationship_id =
          managmentRelationshipList[
            managmentRelationshipList?.findIndex(
              (item) =>
                item.name == livingWillData.agent_details[1]["relationship"]
            )
          ]?.id;
      }
      livingWillData?.agent_details.forEach((object) => {
        delete object["id"];
      });
      livingWillData?.agent_details?.forEach((object) => {
        delete object["relationship"];
      });
      livingWillData?.agent_details?.forEach((object) => {
        delete object["document"];
      });
    }
    if (livingWillData?.witness_details?.length > 0) {
      livingWillData?.witness_details?.forEach((object) => {
        delete object["id"];
      });
      livingWillData?.witness_details?.forEach((object) => {
        delete object["relationship"];
      });
      livingWillData?.witness_details?.forEach((object) => {
        delete object["document"];
      });
    }
  }, [livingWillData]);
  // set value of anatomical gifts yes option
  useEffect(() => {
    if (
      livingWillData?.anatomical_gifts_yes_option !=
        "living_will_health_care_proxy_anatomical_gifts_yes_option_a" &&
      livingWillData?.anatomical_gifts_yes_option !=
        "living_will_health_care_proxy_anatomical_gifts_yes_option_b" &&
      managmentOptionsData?.length > 0
    ) {
      if (managmentOptionsData[0]?.value == "anatomical_gifts_yes_option") {
        livingWillData.anatomical_gifts_yes_option =
          managmentOptionsData[
            managmentOptionsData?.findIndex(
              (item) => item?.value == "anatomical_gifts_yes_option"
            )
          ]["options"][
            managmentOptionsData[
              managmentOptionsData?.findIndex(
                (item) => item.value == "anatomical_gifts_yes_option"
              )
            ]["options"]?.findIndex(
              (item) =>
                item.label == livingWillData?.anatomical_gifts_yes_option
            )
          ]?.value;
      }
    }
  }, [livingWillData?.anatomical_gifts_yes_option && managmentOptionsData]);
  const navigate = useNavigate();
  const date = new Date();
  const dispatch = useDispatch();
  //   handle preview
  const handlePreview = () => {
    if (
      witnessTwoSign?.current?.isEmpty() &&
      livingWillData.agent_details?.length > 0
    ) {
      let temp = [...livingWillData.agent_details];
      temp[1].document_id = null;
      temp[1].date = null;
    }
    if (
      witnessSign?.current?.isEmpty() &&
      livingWillData.witness_details?.length > 1
    ) {
      let temp = [...livingWillData.witness_details];
      temp[0].document_id = null;
      temp[0].date = null;
    }
    if (validateEmpty(livingWillData?.anatomical_gifts)) {
      errorToast("Please select anatomical gifts options");
      return;
    }
    if (livingWillData?.anatomical_gifts === "Yes") {
      if (validateEmpty(livingWillData?.anatomical_gifts_yes_option)) {
        errorToast("Please select anatomical gifts parts");
        return;
      }
    }
    if (
      livingWillData?.anatomical_gifts_yes_option ===
      "living_will_health_care_proxy_anatomical_gifts_yes_option_a"
    ) {
      if (validateEmpty(livingWillData?.specific_organisations)) {
        errorToast("Please select specific organisations");
        return;
      }
    }
    if (validateEmpty(livingWillData?.agent_details[0]?.name)) {
      errorToast("Please enter first agent name");
      return;
    }
    if (validateEmpty(livingWillData?.agent_details[0]?.address)) {
      errorToast("Please enter first agent address");
      return;
    }
    if (validateEmpty(livingWillData?.agent_details[0]?.relationship_id)) {
      errorToast("Please select first agent relationship");
      return;
    }
    if (livingWillData?.agent_details[0]?.relationship_id == 9) {
      if (validateEmpty(livingWillData?.agent_details[0]?.other_relationship)) {
        errorToast("Please enter first agent other relation");
        return;
      }
    }
    if (validateEmpty(livingWillData?.agent_details[0]?.phone)) {
      errorToast("Please enter first agent phone number ");
      return;
    }

    if (livingWillData?.agent_details[0]?.phone?.length != 10) {
      errorToast("Please enter first agent valid phone number ");
      return;
    }
    if (validateEmpty(livingWillData?.agent_details[0]?.email)) {
      errorToast("Please enter first agent email address");
      return;
    }
    if (!validEmail(livingWillData?.agent_details[0]?.email)) {
      errorToast("Please enter first agent valid email");
      return;
    }
    if (validateEmpty(livingWillData?.agent_details[1]?.name)) {
      errorToast("Please enter second agent name");
      return;
    }
    if (validateEmpty(livingWillData?.agent_details[1]?.address)) {
      errorToast("Please enter second agent address");
      return;
    }
    if (validateEmpty(livingWillData?.agent_details[1]?.relationship_id)) {
      errorToast("Please select second agent relationship");
      return;
    }
    if (livingWillData?.agent_details[1]?.relationship_id == 9) {
      if (validateEmpty(livingWillData?.agent_details[1]?.other_relationship)) {
        errorToast("Please enter second agent other relation");
        return;
      }
    }
    if (validateEmpty(livingWillData?.agent_details[1]?.phone)) {
      errorToast("Please enter second agent phone number ");
      return;
    }
    if (livingWillData?.agent_details[1]?.phone?.length != 10) {
      errorToast("Please enter  second agent valid phone number ");
      return;
    }
    if (validateEmpty(livingWillData?.agent_details[1]?.email)) {
      errorToast("Please enter second agent email address");
      return;
    }
    if (!validEmail(livingWillData?.agent_details[1]?.email)) {
      errorToast("Please enter  second agent valid email");
      return;
    }

    if (validateEmpty(livingWillData?.witness_details[0]?.name)) {
      errorToast("Please enter first witness name");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[0]?.email)) {
      errorToast("Please enter first witness email");
      return;
    }
    if (!validEmail(livingWillData?.witness_details[0]?.email)) {
      errorToast("Please enter  first witness valid email");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[0]?.address)) {
      errorToast("Please enter first witness address");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[0]?.city)) {
      errorToast("Please enter first witness city");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[0]?.zip_code)) {
      errorToast("Please enter first witness postal code");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[0]?.state)) {
      errorToast("Please select first witness state");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[1]?.name)) {
      errorToast("Please enter second witness name");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[1]?.email)) {
      errorToast("Please enter second witness email");
      return;
    }
    if (!validEmail(livingWillData?.witness_details[1]?.email)) {
      errorToast("Please enter second witness valid email");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[1]?.address)) {
      errorToast("Please enter second witness address");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[1]?.city)) {
      errorToast("Please enter second witness city");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[1]?.zip_code)) {
      errorToast("Please enter second witness postal code");
      return;
    }
    if (validateEmpty(livingWillData?.witness_details[1]?.state)) {
      errorToast("Please select second witness state");
      return;
    }

    setShowPreview(true);
  };

  //   upload signature
  const handleUploadSignature = (document, key, addRequest) => {
    let request = {
      document: document,
      type_id: siteSettingData?.DOCUMENT_TYPE_LIVING_WILL,
      entityId: siteSettingData?.ENTITY_TYPE_ESTATE_PLANNING,
      moduleId: siteSettingData?.MODULE_TYPE_ESTATE_PLANNING,
    };
    try {
      api.post(SILVERBACK.uploadBase64, request).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          if (key === "witnessOne") {
            let temp = livingWillData?.witness_details;
            temp[0].document_id = response?.data?.data?.documentId;
            setLivingWillData((livingWillData) => ({
              ...livingWillData,
              witness_details: temp,
              agentNameErr: "",
            }));
          }
          if (key === "witnessTwo") {
            let temp = livingWillData?.witness_details;
            temp[1].document_id = response?.data?.data?.documentId;
            setLivingWillData((livingWillData) => ({
              ...livingWillData,
              witness_details: temp,
              agentNameErr: "",
            }));
          }
          if (addRequest) {
            handleSave(key, response?.data?.data?.documentId);
          }
        } else {
          errorToast(response.data.detail, "account");
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
  //   add form
  const handleSubmit = () => {
    setIsloading(true);
    if (witnessTwoSign.current.isEmpty() && witnessSign.current.isEmpty()) {
      handleSave();
    }
    if (!witnessSign?.current?.isEmpty()) {
      const data = witnessSign?.current?.toDataURL();
      const myArray = data.split(",");
      handleUploadSignature(
        myArray[1],
        "witnessOne",
        witnessTwoSign?.current?.isEmpty() ? true : ""
      );
    }
    if (!witnessTwoSign?.current?.isEmpty()) {
      const data = witnessTwoSign?.current?.toDataURL();
      const myArray = data.split(",");
      handleUploadSignature(myArray[1], "witnessTwo", true);
    }
  };
  // handle save
  const handleSave = (key, documentId) => {
    let temp = livingWillData?.witness_details;
    if (key === "witnessOne") {
      temp[0].document_id = documentId;
      temp[0].date = moment(date).format();
    }
    if (key === "witnessTwo") {
      temp[1].document_id = documentId;
      temp[1].date = moment(date).format();
    }
    let request = {
      type: ESTATE_PLANNING_KEYS?.LIVING_WILL_HEALTH_CARE_PROXY,
      data: {
        principal_name: `${profileDetailData?.first_name} ${profileDetailData?.surename}`,
        principal_address: profileDetailData?.address,
        principal_dob: moment(profileDetailData?.dateOfBirth).format(
          "MM/DD/YYYY"
        ),
        financial_decisions: "",
        attorney_limitations: "",
        medical_decisions: "",
        effective_period: "",
        principal_signature:
          profileDetailData?.signatureData?.length > 0
            ? profileDetailData?.signatureData[0]?.id.toString()
            : "",
        principal_signature_date: moment(date).format(),
        notary_date: null,
        county: null,
        anatomical_gifts: livingWillData?.anatomical_gifts,
        anatomical_gifts_yes_option:
          livingWillData?.anatomical_gifts_yes_option,
        specific_organisations: livingWillData?.specific_organisations,
        witness_details:
          key === "witnessOne" || key === "witnessTwo"
            ? temp
            : livingWillData?.witness_details,
        agent_details: livingWillData?.agent_details,
      },
      userMapperId: userMapperId ? userMapperId * 1 : null,
    };
    if (userMapperId) {
      dispatch(updateEstateDocument(id, request, setIsloading, navigate));
    } else {
      dispatch(addEstateDocument(id, request, setIsloading, navigate));
    }
  };
  return (
    <div>
      <Box sx={{ p: isMobile ? 3 : "" }}>
        <TopHeading title={"Living Will Health Care Proxy"} />
        <Grid container mt={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{ color: "white" }}
                to={`/profile/estateDetail/${id}`}
              >
                Estate-Planning
              </Link>
              <Typography sx={{ color: "white" }}>Add</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Box>
      <PreviewLivingWillDocument
        open={showPreview}
        isLoading={isLoading}
        witnessSign={witnessSign}
        witnessTwoSign={witnessTwoSign}
        handleClose={() => setShowPreview(false)}
        profileDetailData={profileDetailData}
        livingWillData={livingWillData}
        handleSubmit={handleSubmit}
      />
      {/* loader */}
      <Spinner open={isLoading} />
      {/* Declaration */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 1 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="inforation-text">
          I {profileDetailData?.first_name} {profileDetailData?.surename} ,
          being of sound mind and disposing mind and memory, do hereby make and
          declare this to be my health-care directive, thereby revoking and
          making null and void any and all other health care directives, living
          wills and health care powers of attorney previously made by me.
        </Typography>
      </Box>
      {/* Living Will */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 1 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Living Will</Typography>
        <Typography className="inforation-text">
          I willfully and voluntarily make known my desire to prolong my life as
          long as reasonably possible within the limits of generally accepted
          health-care standards.
        </Typography>
      </Box>
      {/* Anatomical Gifts */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 1 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Anatomical Gifts </Typography>

        <Stack>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <input
              className="form-check-input"
              type="radio"
              onChange={() => {
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  anatomical_gifts: "Yes",
                }));
              }}
              style={{ width: 24, height: 24 }}
              checked={livingWillData?.anatomical_gifts === "Yes"}
            />
            &nbsp;&nbsp;&nbsp;
            <label className="radio-label-text">Yes</label>
          </div>
        </Stack>
        {livingWillData?.anatomical_gifts === "Yes" && (
          <Grid container>
            <Grid item sm={12} style={{ paddingLeft: "2.5rem" }}>
              <Typography className="inforation-text">
                I hereby authorize the making of anatomical gifts of the
                following parts of my body for the following purpose
              </Typography>

              {managmentOptionsData?.length > 0 && (
                <>
                  <Stack>
                    <div style={{ display: "flex", marginTop: "1rem" }}>
                      <input
                        className="form-check-input"
                        type="radio"
                        onChange={() => {
                          setLivingWillData((livingWillData) => ({
                            ...livingWillData,
                            anatomical_gifts_yes_option:
                              managmentOptionsData[0]?.options[0]?.value,
                          }));
                        }}
                        style={{ width: 24, height: 24 }}
                        checked={
                          livingWillData?.anatomical_gifts_yes_option ===
                          managmentOptionsData[0]?.options[0]?.value
                        }
                      />
                      &nbsp;&nbsp;&nbsp;
                      <label className="radio-label-text">
                        {" "}
                        {managmentOptionsData[0]?.options[0]?.label}
                      </label>
                    </div>
                  </Stack>
                  {livingWillData?.anatomical_gifts_yes_option ==
                    "living_will_health_care_proxy_anatomical_gifts_yes_option_a" && (
                    <>
                      <Grid container>
                        <Grid item xs={isMobile ? 12 : 4}>
                          <AdminInput
                            type="text"
                            color="white"
                            title=" Name"
                            onChange={(event) => {
                              setLivingWillData((livingWillData) => ({
                                ...livingWillData,
                                specific_organisations: event.target.value,
                              }));
                            }}
                            value={livingWillData?.specific_organisations}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Stack>
                    <div style={{ display: "flex", marginTop: "1rem" }}>
                      <input
                        className="form-check-input"
                        type="radio"
                        onChange={() => {
                          setLivingWillData((livingWillData) => ({
                            ...livingWillData,
                            anatomical_gifts_yes_option:
                              managmentOptionsData[0]?.options[1]?.value,
                            specific_organisations: null,
                          }));
                        }}
                        style={{ width: 24, height: 24 }}
                        checked={
                          livingWillData?.anatomical_gifts_yes_option ===
                          managmentOptionsData[0]?.options[1]?.value
                        }
                      />
                      &nbsp;&nbsp;&nbsp;
                      <label className="radio-label-text">
                        {" "}
                        {managmentOptionsData[0]?.options[1]?.label}
                      </label>
                    </div>
                  </Stack>
                </>
              )}
            </Grid>
          </Grid>
        )}
        <Stack>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <input
              className="form-check-input"
              type="radio"
              onChange={() => {
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  anatomical_gifts: "No",
                  anatomical_gifts_yes_option: null,
                  specific_organisations: null,
                }));
              }}
              style={{ width: 24, height: 24 }}
              checked={livingWillData?.anatomical_gifts == "No"}
            />
            &nbsp;&nbsp;&nbsp;
            <label className="radio-label-text">No</label>
          </div>
        </Stack>
      </Box>

      {/* Health Care Proxy */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 1 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Health Care Proxy</Typography>
        <Typography className="inforation-text">
          I {profileDetailData?.first_name} {profileDetailData?.surename},
          hereby appoint the following individual as my health care agent
          (“agent”) to make any and all health care decisions for me, except to
          the extent I state otherwise:
        </Typography>
        <Typography className="label-text" mt={2}>
          First Agent
        </Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={isMobile ? 12 : 4}>
            <AdminInput
              color="white"
              title="Name"
              onChange={(event) => {
                const temp = [...livingWillData.agent_details];
                temp[0].name = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
              value={livingWillData?.agent_details[0]?.name || ""}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} sx={{ mt: 1 }}>
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = livingWillData?.agent_details;
                temp[0].address = value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = livingWillData?.agent_details;
                temp[0].address = "";
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
              searchValue={livingWillData?.agent_details[0]?.address}
              handlePlaceSelected={(place) => {
                let temp = livingWillData?.agent_details;
                temp[0].address = place?.formatted_address;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Relation</InputLabel>
              <Select
                label="Relations"
                autoFocus={
                  livingWillData?.agent_details[0]?.relationship_id
                    ? true
                    : false
                }
                onChange={(event) => {
                  let temp = livingWillData?.agent_details;
                  temp[0].relationship_id = event.target.value;
                  temp[0].other_relationship = "";
                  setLivingWillData((livingWillData) => ({
                    ...livingWillData,
                    agent_details: temp,
                    relationship_idErr: "",
                  }));
                }}
                value={livingWillData?.agent_details[0]?.relationship_id || ""}
                sx={{
                  color: "white",
                  width: "100%",
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {managmentRelationshipList?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {livingWillData?.agent_details[0]?.relationship_id == 9 && (
            <Grid item xs={isMobile ? 12 : 4}>
              <AdminInput
                color="white"
                title="Enter other relation type"
                onChange={(event) => {
                  const temp = [...livingWillData.agent_details];
                  temp[0].other_relationship = event.target.value;
                  setLivingWillData((livingWillData) => ({
                    ...livingWillData,
                    agent_details: temp,
                  }));
                }}
                value={
                  livingWillData?.agent_details[0]?.other_relationship || ""
                }
              />{" "}
            </Grid>
          )}
          <Grid item xs={isMobile ? 12 : 4}>
           
            <AdminInput
              title="Phone"
              maxLength="10"
              color="white"
              onChange={(event) => {
                if (event.target.value) {
                  if (onlyNumber(event.target.value)) {
                    const temp = [...livingWillData.agent_details];
                    temp[0].phone = event.target.value;
                    setLivingWillData((livingWillData) => ({
                      ...livingWillData,
                      agent_details: temp,
                    }));
                  }
                } else {
                  const temp = [...livingWillData.agent_details];
                  temp[0].phone = '';
                  setLivingWillData((livingWillData) => ({
                    ...livingWillData,
                    agent_details: temp,
                  }));
                }
              }}
              value={livingWillData?.agent_details[0]?.phone || ""}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <AdminInput
              color="white"
              title="Alternative phone"
              maxLength="10"
            
              onChange={(event) => {
                if (event.target.value) {
                  if (onlyNumber(event.target.value)) {
                    const temp = [...livingWillData.agent_details];
                    temp[0].alternative_phone = event.target.value;
                    setLivingWillData((livingWillData) => ({
                      ...livingWillData,
                      agent_details: temp,
                    }));
                  }
                } else {
                  const temp = [...livingWillData.agent_details];
                  temp[0].alternative_phone = '';
                  setLivingWillData((livingWillData) => ({
                    ...livingWillData,
                    agent_details: temp,
                  }));
                }
              }}
              
              value={livingWillData?.agent_details[0]?.alternative_phone || ""}
            />
          
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <AdminInput
              color="white"
              title="Email"
              onChange={(event) => {
                const temp = [...livingWillData.agent_details];
                temp[0].email = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
              value={livingWillData?.agent_details[0]?.email || ""}
            />
          </Grid>
        </Grid>
        <Typography className="inforation-text">
          This health care proxy shall take effect in the event I become unable
          to make my own health care decisions.
        </Typography>
        <Typography className="inforation-text">
          I authorize my agent to direct the disposition of my remains.
        </Typography>{" "}
        <Typography className="inforation-text">
          I authorize my agent to consent to an autopsy of my remainst.
        </Typography>{" "}
        <Typography className="inforation-text">
          I direct my agent to make health care decisions in accordance with my
          wishes and instructions as stated above or as otherwise known to him
          or her. I also direct my agent to abide by any limitations on his or
          her authority as stated above or as otherwise known to him or her.
        </Typography>{" "}
        <Typography className="inforation-text">
          In the event the person I appoint above is unable, unwilling, or
          unavailable to act as my health care agent, I hereby appoint the
          following individual as my alternate health care agent (“agent”):
        </Typography>
        <Typography className="label-text" mt={2}>
          Second Agent
        </Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={isMobile ? 12 : 4}>
            <AdminInput
              color="white"
              title="Name"
              onChange={(event) => {
                const temp = [...livingWillData.agent_details];
                temp[1].name = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
              value={livingWillData?.agent_details[1]?.name || ""}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} sx={{ mt: 1 }}>
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = livingWillData?.agent_details;
                temp[1].address = value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = livingWillData?.agent_details;
                temp[1].address = "";
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
              searchValue={livingWillData?.agent_details[1]?.address}
              handlePlaceSelected={(place) => {
                let temp = livingWillData?.agent_details;
                temp[1].address = place?.formatted_address;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Relation</InputLabel>
              <Select
                label="Relations"
                autoFocus={
                  livingWillData?.agent_details[1]?.relationship_id
                    ? true
                    : false
                }
                onChange={(event) => {
                  let temp = livingWillData?.agent_details;
                  temp[1].relationship_id = event.target.value;
                  temp[1].other_relationship = "";
                  setLivingWillData((livingWillData) => ({
                    ...livingWillData,
                    agent_details: temp,
                    relationship_idErr: "",
                  }));
                }}
                value={livingWillData?.agent_details[1]?.relationship_id || ""}
                sx={{
                  color: "white",
                  width: "100%",
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {managmentRelationshipList?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {livingWillData?.agent_details[1]?.relationship_id == 9 && (
            <Grid item xs={isMobile ? 12 : 4}>
              <AdminInput
                color="white"
                title="Enter other relation type"
                onChange={(event) => {
                  const temp = [...livingWillData.agent_details];
                  temp[1].other_relationship = event.target.value;
                  setLivingWillData((livingWillData) => ({
                    ...livingWillData,
                    agent_details: temp,
                  }));
                }}
                value={
                  livingWillData?.agent_details[1]?.other_relationship || ""
                }
              />{" "}
            </Grid>
          )}
           <Grid item xs={isMobile ? 12 : 4}>
           
           <AdminInput
             title="Phone"
             maxLength="10"
             color="white"
             onChange={(event) => {
               if (event.target.value) {
                 if (onlyNumber(event.target.value)) {
                   const temp = [...livingWillData.agent_details];
                   temp[1].phone = event.target.value;
                   setLivingWillData((livingWillData) => ({
                     ...livingWillData,
                     agent_details: temp,
                   }));
                 }
               } else {
                 const temp = [...livingWillData.agent_details];
                 temp[1].phone = '';
                 setLivingWillData((livingWillData) => ({
                   ...livingWillData,
                   agent_details: temp,
                 }));
               }
             }}
             value={livingWillData?.agent_details[1]?.phone || ""}
           />
         </Grid>
         <Grid item xs={isMobile ? 12 : 4}>
           <AdminInput
             color="white"
             title="Alternative phone"
             maxLength="10"
           
             onChange={(event) => {
               if (event.target.value) {
                 if (onlyNumber(event.target.value)) {
                   const temp = [...livingWillData.agent_details];
                   temp[1].alternative_phone = event.target.value;
                   setLivingWillData((livingWillData) => ({
                     ...livingWillData,
                     agent_details: temp,
                   }));
                 }
               } else {
                 const temp = [...livingWillData.agent_details];
                 temp[1].alternative_phone = '';
                 setLivingWillData((livingWillData) => ({
                   ...livingWillData,
                   agent_details: temp,
                 }));
               }
             }}
             
             value={livingWillData?.agent_details[1]?.alternative_phone || ""}
           />
         
         </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <AdminInput
              color="white"
              title="Email"
              onChange={(event) => {
                const temp = [...livingWillData.agent_details];
                temp[1].email = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  agent_details: temp,
                }));
              }}
              value={livingWillData?.agent_details[1]?.email || ""}
            />
          </Grid>
        </Grid>
      </Box>

      {/* General provisions */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 1 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">General provisions</Typography>
        <Typography className="inforation-text">
          If any provision hereof is held to be invalid, such invalidity will
          not affect the other provisions of this document, and such other
          provisions will be given effect without the invalid provision.
        </Typography>
        <Typography className="inforation-text" my={2}>
          Pursuant to the Health Insurance Portability and Accountability Act of
          1996 (“HIPAA”) and any similar state laws, and exclusively for the
          purpose of making a determination of my incapacitation or inability to
          direct my own health care decisions and obtaining a physician
          affidavit of such, I authorize any health care provider to disclose to
          the person named herein as my health care agent or alternate health
          care agent, as applicable, any pertinent individually identifiable
          health information sufficient to determine whether I am by reason of
          illness or mental or physical disability incapacitated or incapable of
          directing my own health care decisions. In exercising such authority,
          my health care agent shall constitute my “personal representative” as
          defined by HIPAA.
        </Typography>
        <Typography className="inforation-text">
          On the determination of my incapacitation or incapability to direct my
          own health care decisions, I intend for the person named herein as my
          health care agent or alternate health care agent, as applicable, to be
          treated as my “personal representative” under HIPAA and any similar
          state laws, and as such to be treated
        </Typography>
        <Typography className="inforation-text" my={2}>
          as I would be with respect to my rights regarding the use and
          disclosure of my individually identifiable health information or other
          medical records.
        </Typography>{" "}
        <Typography className="inforation-text">
          It is my intent that this document be legally binding and effective.
          If the law does not recognize the legal validity of this document, it
          is my intention that this document be taken as a formal declaration of
          my intentions concerning all of the above provisions. Copies of this
          document have the same effect as the original.
        </Typography>{" "}
        <Typography className="inforation-text">
          All persons or entities that in good faith endeavor to carry out the
          provisions of this document shall not be liable to me, my estate, or
          my heirs, for any damages or claims arising because of their actions
          or inactions based on this document. My estate shall indemnify and
          hold them harmless.
        </Typography>
        <Typography className="inforation-text">
          In Witness Whereof, I have executed this document on the date below:
        </Typography>
      </Box>

      {/*Signature Of Principal */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 1 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Signature Of Principal</Typography>

        {profileDetailData?.signatureData?.length > 0 && (
          <SignatureImage
            url={profileDetailData?.signatureData[0]?.signature}
          />
        )}
      </Box>
      {/*Witness Declarations */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 1 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Witness Declarations</Typography>
        <Typography className="inforation-text">
          Each of the undersigned states as follows: I declare that the person
          who signed or asked another to sign this document is personally known
          to me and appears to be of sound mind and acting willingly and free
          from duress. He or she signed (or asked another to sign for him or
          her) this document in my presence and that person signed in my
          presence. I am not the person appointed as agent by this document.
        </Typography>
        <Grid container columnSpacing={2} mt={3.5}>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="label-text">First Witness</Typography>
            <AdminInput
              title="Name"
              color="white"
              onChange={(event) => {
                let temp = livingWillData?.witness_details;
                temp[0].name = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              value={livingWillData?.witness_details[0]?.name || ""}
            />
            <AdminInput
              title="Email"
              color="white"
              onChange={(event) => {
                let temp = livingWillData?.witness_details;
                temp[0].email = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              value={livingWillData?.witness_details[0]?.email || ""}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = livingWillData?.witness_details;
                temp[0].address = value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = livingWillData?.witness_details;
                temp[0].address = "";
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              searchValue={livingWillData?.witness_details[0]?.address}
              handlePlaceSelected={(place) => {
                let temp = livingWillData?.witness_details;
                temp[0].address = place?.formatted_address;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
            />
            <AdminInput
              title="City"
              color="white"
              onChange={(event) => {
                let temp = livingWillData?.witness_details;
                temp[0].city = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              value={livingWillData?.witness_details[0]?.city || ""}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AdminInput
                  title="Postal Codes"
                  maxLength="5"
                  color="white"
                  onChange={(event) => {
                    if (event.target.value) {
                      if (onlyNumber(event.target.value)) {
                        let temp = livingWillData?.witness_details;
                        temp[0].zip_code = event.target.value;
                        setLivingWillData((livingWillData) => ({
                          ...livingWillData,
                          witness_details: temp,
                        }));
                      }
                    } else {
                      let temp = livingWillData?.witness_details;
                      temp[0].zip_code = "";
                      setLivingWillData((livingWillData) => ({
                        ...livingWillData,
                        witness_details: temp,
                      }));
                    }
                  }}
                  value={livingWillData?.witness_details[0]?.zip_code || ""}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    label="State"
                    onChange={(event) => {
                      let temp = livingWillData?.witness_details;
                      temp[0].state = event.target.value;
                      temp[0].state_name =
                        stateListData[
                          stateListData?.findIndex(
                            (item) => item.id == event.target.value
                          )
                        ]?.description;
                      setLivingWillData((livingWillData) => ({
                        ...livingWillData,
                        witness_details: temp,
                      }));
                    }}
                    value={livingWillData?.witness_details[0]?.state || ""}
                    sx={{
                      color: "white",
                      width: "100%",
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "white",
                        },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {stateListData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SVSignaturepad customRef={witnessSign} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} mt={isMobile ? 4 : ""}>
            <Typography className="label-text">Second Witness</Typography>
            <AdminInput
              title="Name"
              color="white"
              onChange={(event) => {
                let temp = livingWillData?.witness_details;
                temp[1].name = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              value={livingWillData?.witness_details[1]?.name || ""}
            />
            <AdminInput
              title="Email"
              color="white"
              onChange={(event) => {
                let temp = livingWillData?.witness_details;
                temp[1].email = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              value={livingWillData?.witness_details[1]?.email || ""}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = livingWillData?.witness_details;
                temp[1].address = value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = livingWillData?.witness_details;
                temp[1].address = "";
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              searchValue={livingWillData?.witness_details[1]?.address}
              handlePlaceSelected={(place) => {
                let temp = livingWillData?.witness_details;
                temp[1].address = place?.formatted_address;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
            />
            <AdminInput
              title="City"
              color="white"
              onChange={(event) => {
                let temp = livingWillData?.witness_details;
                temp[1].city = event.target.value;
                setLivingWillData((livingWillData) => ({
                  ...livingWillData,
                  witness_details: temp,
                }));
              }}
              value={livingWillData?.witness_details[1]?.city || ""}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AdminInput
                  title="Postal Codes"
                  maxLength="5"
                  color="white"
                  onChange={(event) => {
                    if (event.target.value) {
                      if (onlyNumber(event.target.value)) {
                        let temp = livingWillData?.witness_details;
                        temp[1].zip_code = event.target.value;
                        setLivingWillData((livingWillData) => ({
                          ...livingWillData,
                          witness_details: temp,
                        }));
                      }
                    } else {
                      let temp = livingWillData?.witness_details;
                      temp[1].zip_code = "";
                      setLivingWillData((livingWillData) => ({
                        ...livingWillData,
                        witness_details: temp,
                      }));
                    }
                  }}
                  value={livingWillData?.witness_details[1]?.zip_code || ""}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    label="State"
                    onChange={(event) => {
                      let temp = livingWillData?.witness_details;
                      temp[1].state = event.target.value;
                      temp[1].state_name =
                        stateListData[
                          stateListData?.findIndex(
                            (item) => item.id == event.target.value
                          )
                        ]?.description;
                      setLivingWillData((livingWillData) => ({
                        ...livingWillData,
                        witness_details: temp,
                      }));
                    }}
                    value={livingWillData?.witness_details[1]?.state || ""}
                    sx={{
                      color: "white",
                      width: "100%",
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "white",
                        },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {stateListData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SVSignaturepad customRef={witnessTwoSign} />
          </Grid>
        </Grid>
      </Box>

      <Grid container px={2}>
        <Grid item xs={isMobile ? 12 : 3}>
          <AdminButton fullWidth title="Preview" onClick={handlePreview} />
        </Grid>
      </Grid>
    </div>
  );
}
