import { Icon } from "@iconify/react";
import { Box, Typography } from "@mui/material";
import AdminButton from "components/adminButton";
import AdminInput from "components/adminInput";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setPasswordByTeamMember } from "store/slice/profile";

export default function CreatePassword() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState("");
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState({});
  const { isValidLink, isLoading } = useSelector((state) => state.profile);
  useEffect(() => {
    if (id) {
      handleCreatePassword(id, "", "");
    }
  }, [id]);
  const handleCreatePassword = (id, password, navigate) => {
    let request = {
      password: password ? password : "",
      resetToken: id,
    };
    dispatch(setPasswordByTeamMember(request, navigate));
  };

  //  create Passoword
  const handleSubmit = () => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!formData.password) {
      setFormError((formError) => ({
        ...formError,
        password: "Please enter a password",
      }));
      return;
    } else if (!re.test(formData.password)) {
      setFormError((formError) => ({
        ...formError,
        password:
          "A password must contain at least eight characters, at least one number, both lower and uppercase letters, and special characters",
      }));
      return;
    }
    if (!formData.confirmPassword) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Please enter a confirm password",
      }));
      return;
    }
    if (formData.confirmPassword != formData.password) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Password and confirm password does not match",
      }));
      return;
    }
    handleCreatePassword(id, formData.password, navigate);
  };
  return (
    <>
      {!isValidLink ? (
        <>
          <Typography sx={{ textAlign: "center", my: "10px", fontSize: 22 }}>
            Link Expired or Invalid
            <Typography sx={{ mt: 1 }}>
              <Icon icon="lets-icons:sad-light" width={50} />
            </Typography>
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={{ textAlign: "center", my: "10px", fontSize: 18 }}>
            Create Password
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <AdminInput
              title="Password"
              color="white"
              required={true}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#C5C5C5",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              type={showPassword.password ? "text" : "password"}
              value={formData.password}
              onChange={(val) => {
                setFormData((formData) => ({
                  ...formData,
                  password: val.target.value,
                }));

                setFormError((formError) => ({
                  ...formError,
                  password: "",
                }));
              }}
              formError={formError.password}
              endIcon={
                <Icon
                  icon={
                    !showPassword.password ? "iconamoon:eye-off" : "tabler:eye"
                  }
                  width="28"
                  height="28"
                />
              }
              PasswordShowClick={() => {
                setShowPassword((showPassword) => ({
                  ...showPassword,
                  password: !showPassword.password,
                }));
              }}
            />
            <AdminInput
              title="Confirm password"
              color="white"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#C5C5C5",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              required={true}
              type={showPassword.confirmPassword ? "text" : "password"}
              value={formData.confirmPassword}
              onChange={(val) => {
                setFormData((formData) => ({
                  ...formData,
                  confirmPassword: val.target.value,
                }));
                setFormError((formError) => ({
                  ...formError,
                  confirmPassword: "",
                }));
              }}
              formError={formError.confirmPassword}
              endIcon={
                <Icon
                  icon={
                    !showPassword.confirmPassword
                      ? "iconamoon:eye-off"
                      : "tabler:eye"
                  }
                  width="28"
                  height="28"
                />
              }
              PasswordShowClick={() => {
                setShowPassword((showPassword) => ({
                  ...showPassword,
                  confirmPassword: !showPassword.confirmPassword,
                }));
              }}
            />

            <AdminButton
              onClick={handleSubmit}
              title={"Submit"}
              fullWidth
              height={40}
            />
          </Box>
          <Spinner open={isLoading} />
        </>
      )}
    </>
  );
}
