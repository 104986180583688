/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../services";
import { SILVERBACK, STATUS_CODES } from "../../services/apiConstant";
import { successToast, errorToast } from "components/showToast";
import {
  ACCOUNT_VERIFICATION_METHODS,
  SAVE_PROFILE_KEYS,
} from "utils/appConstant";
import { MESSAGES } from "utils/appMessages";
import secureLocalStorage from "react-secure-storage";
import { async } from "@firebase/util";

const slice = createSlice({
  name: "profile",
  initialState: {
    message: "",
    updateProfileDetail: null,
    textAuthenticationData: false,
    tOTPAuthenticationData: null,
    adminPorfileDetailData: null,
    uploadPorfilePicData: null,
    isLoading: false,
    isUploading: false,
    profileDetailData: null,
    eKycDetailData: null,
    userDashboardData: null,
    subscribeUserListData: null,
    stateListData: null,
    updateUserDetailsData: null,
    sendOtpData: null,
    netWorthDetailData: null,
    notificationListData: null,
    totalNotificationCount: null,
    unReadNotificationCount: null,
    eKYCListData: null,
    ticketListData: null,
    totalTicketCount: null,
    sendOtpAuthData: null,
    isValidLink: false,
    permissionListData: null,
    permissionLevelList: null,
    rolePermissionDetail: null,
    goalsDetailData: null,
    estateDetailData: null,
    netWorthSummaryData: null,
    assetsSummaryData: null,
    liabilitiesSummaryData: null,
    bankDetailData: null,
    otherAccountData: null,
    taxBracketlistData: null,
    taxBracketInputURLData: null,
    taxRateAddedSuccess: null,
    taxRateAddData: null,
    cardStatus: null,
    userNameListData: null,
    insuranceDetail: null,
    payRollIncomeData: null,
    realEstateDetailData: null,
    estatePdfListData: null,
    notesListData: null,
    addNotesData: null,
    notesDetailData: null,
    financialAccountData: null,
    ticketCategoryData: null,
    ticketSubCategoryData: null,
    ticketRaiseData: null,
    transactionListData: null,
    userActivityList: [],
    userActivityCount: null,
    cardsTransactionList: [],
    cardsTranscationCount: null,
    subAdminNotesData: [],
    subAdminNoteCount: null,
  },
  reducers: {
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },

    //For listing
    customerListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.customerListData = [];
      }
      state.customerListData = [...action.payload.data.userList];
      state.totalCount = action.payload.data.totalCount;
      state.isLoading = false;
    },

    getNotesListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.notesListData = [];
      }

      state.notesListData = [...action.payload.data.data];
      state.totalCount = action.payload.data.total;
      state.isLoading = false;
    },
    getSubAdminNotesListSuccess: (state, action) => {
      state.subAdminNotesData = action.payload.data.data;
      state.subAdminNoteCount = action.payload.data.total;
    },
    clearSubAdminNotesListSuccess: (state, action) => {
      state.subAdminNotesData = [];
      state.subAdminNoteCount = null;
    },
    subscribeUserListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.subscribeUserListData = [];
      }
      state.subscribeUserListData = [...action.payload.data.subscriberList];
      state.totalCount = action.payload.data.totalCount;
      state.isLoading = false;
    },
    netWorthListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.netWorthListData = [];
      }
      state.netWorthListData = [
        ...action.payload.data.financialAdviceRequestData,
      ];
      state.totalCount = action.payload.data.totalCount;
      state.isLoading = false;
    },

    getTicketListingSucces: (state, action) => {
      if (action.payload.page === 1) {
        state.ticketListData = [];
      }
      state.ticketListData = [...action.payload.data.ticketList];
      state.totalTicketCount = action.payload.data.totalCount;
      state.isLoading = false;
    },

    eKycListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.eKYCListData = [];
      }
      state.eKYCListData = [...action.payload.data.data];
      state.totalCount = action.payload.data.totalCount;
      state.isLoading = false;
    },

    upLoadingSuccess: (state, action) => {
      state.isUploading = action.payload;
    },
    updateProfileSuccess(state, action) {
      state.updateProfileDetail = action.payload;
    },
    getNetWorthDetailSuccess(state, action) {
      state.netWorthDetailData = action.payload;
    },
    textAuthenticationSuccess(state, action) {
      state.textAuthenticationData = action.payload;
    },
    tOtpAuthenticationSuccess(state, action) {
      state.tOTPAuthenticationData = action.payload?.data;
    },
    userDashboardSuccess(state, action) {
      state.userDashboardData = action.payload.data;
    },
    adminProfileDetailSuccess(state, action) {
      state.adminPorfileDetailData = action.payload;

      state.uploadPorfilePicData = {
        fullPath: action.payload.fullPath,
        path: action.payload.path,
      };
      state.isLoading = false;
    },
    uploadProfileSuccess(state, action) {
      state.uploadPorfilePicData = {
        fullPath: action.payload.fullPath,
        path: action.payload.path,
      };
      state.isUploading = false;
      state.isLoading = false;
    },

    clearProfileStateSuccess(state, action) {
      state.updateProfileDetail = null;
      state.adminPorfileDetailData = null;
      state.uploadPorfilePicData = null;
      state.isLoading = false;
      state.isUploading = false;
    },
    clearNotesDataSuccess(state, action) {
      state.notesDetailData = null;
    },

    getNotesDetailApiSuccess: (state, action) => {
      state.notesDetailData = action.payload;
    },

    getNotesDetailApiFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    createNotesApiSuccess: (state, action) => {
      state.notesListData = [action.payload, ...state.notesListData];
      state.totalCount = state.totalCount + 1;
      if (action.payload.updateIndexId) {
        const index = state.notesListData?.findIndex(
          (item) => item.id === action.payload.updateIndexId
        );
        state.notesListData[index] = action.payload;
      }
    },
    createSubAdminNotesSuccess: (state, action) => {
      if (action.payload.updateIndexId) {
        const index = state.subAdminNotesData?.findIndex(
          (item) => item.id === action.payload.updateIndexId
        );
        state.subAdminNotesData[index] = action.payload;
      } else {
        state.subAdminNotesData = [action.payload, ...state.subAdminNotesData];
      }
    },
    createNotesApiFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    profileDetailSuccess: (state, action) => {
      state.profileDetailData = action.payload;
      state.cardDetailData = action.payload.cardDetails;
    },

    profileDetailFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    eKycDetailSuccess: (state, action) => {
      state.eKycDetailData = action.payload.data;
    },

    eKycDetailFailed: (state, action) => {
      state.errorMessage = action.payload;
    },

    stateListSuccess: (state, action) => {
      state.stateListData = action.payload.data;
    },

    ticketCategoryApiSuccess: (state, action) => {
      state.ticketCategoryData = action.payload.data.finalData;
    },
    ticketCategoryApiFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    ticketSubCategoryApiSuccess: (state, action) => {
      state.ticketSubCategoryData = action.payload.data.finalData;
    },
    ticketSubCategoryApiFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    stateListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    sendOtpAuthDataClearSuccess: (state, action) => {
      state.sendOtpAuthData = null;
    },
    updateUserDetailsSuccess: (state, action) => {
      state.updateUserDetailsData = action.payload;
    },
    updateUserDetailsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    sendOtpForProfileSuccess: (state, action) => {
      state.sendOtpData = action.payload;
    },
    sendOtpForProfileFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    sendOtpAuthSuccess: (state, action) => {
      state.sendOtpAuthData = action.payload;
    },
    suspendAccoutSuccess: (state, action) => {
      let objId = action.payload.data.userData.id;
      const index = state.customerListData?.findIndex(
        (item) => item.id === objId
      );
      state.customerListData[index] = action.payload.data.userData;
      state.isLoading = false;
    },
    suspendAccoutProfileSuccess: (state, action) => {
      state.profileDetailData = action.payload.data.userData;
      state.isLoading = false;
    },
    suspendAccountFailed: (state, action) => {
      state.errorMessage = action.payload;
    },

    ekycApproveRejectSuccess: (state, action) => {
      let objId = action.payload?.deleteIndexId;
      state.eKYCListData = state.eKYCListData.filter(
        (item) => item.id != objId
      );
      state.isLoading = false;
    },
    ekycApproveRejectFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    getNotificationListSucces: (state, action) => {
      if (action.payload.page === 1) {
        state.notificationListData = [];
      }
      state.notificationListData = [...action.payload.data.notificationData];
      state.totalNotificationCount = action.payload.data.totalCount;
      state.unReadNotificationCount = action.payload.data.unReadCount;

      state.isLoading = false;
    },
    readNotificationSuccess: (state, action) => {
      let index = state.notificationListData?.findIndex(
        (item) => item.id === action.payload
      );
      state.notificationListData[index].isRead = true;
    },
    updateTicketSuccess: (state, action) => {
      let objId = action.payload.data.updatedTicket.id;
      let index = state.ticketListData.findIndex((item) => item.id === objId);
      state.ticketListData[index] = action.payload.data.updatedTicket;
      state.isLoading = false;
    },
    deleteTicketSuccess: (state, action) => {
      state.ticketListData = state.ticketListData.filter(
        (item) => item.id != action.payload
      );
      state.isLoading = false;
    },
    createTeamMemberSuccess: (state, action) => {
      state.customerListData = state.customerListData.concat(action.payload);
      state.totalCount = state.totalCount + 1;
      state.isLoading = false;
    },
    deleteTeamMemberSuccess: (state, action) => {
      state.customerListData = state.customerListData.filter(
        (item) => item.id != action.payload
      );
      state.totalCount = state.totalCount - 1;
      state.isLoading = false;
    },
    setPasswordByTeamMemberSuccess: (state, action) => {
      state.isValidLink = action.payload;
      state.isLoading = false;
    },
    getPermissionListSuccess: (state, action) => {
      state.permissionListData = action.payload.data.permissions;
      state.isLoading = false;
    },
    getPermissionLevelSuccess: (state, action) => {
      state.permissionLevelList = action.payload.data.permission_levels;
    },
    getRolePermissionDetailSuccess: (state, action) => {
      state.rolePermissionDetail = action.payload.data.role_permissions;
    },
    goaldDetailSuccess: (state, action) => {
      state.goalsDetailData = action.payload;
      state.isLoading = false;
    },
    allInsuranceDetailSuccess: (state, action) => {
      state.insuranceDetail = action.payload.data.insurances_data;
      state.totalCount = action.payload.data.totalCount;

      state.isLoading = false;
    },
    bankAccountDetailsSuccess: (state, action) => {
      state.bankDetailData = action.payload;
      state.isLoading = false;
    },
    otherAccountDetailsSuccess: (state, action) => {
      state.otherAccountData = action.payload;
      state.isLoading = false;
    },
    financialAccountDetailsSuccess: (state, action) => {
      state.financialAccountData = action.payload.data;
      state.isLoading = false;
    },
    payRollIncomeSuccess: (state, action) => {
      state.payRollIncomeData = action.payload;
      state.isLoading = false;
    },
    realEstateDetailSuccess: (state, action) => {
      state.realEstateDetailData = action.payload;
      state.isLoading = false;
    },
    netWorthSummarySuccess: (state, action) => {
      state.netWorthSummaryData = action.payload;
      state.isLoading = false;
    },

    transactionListApiSuccess: (state, action) => {
      state.transactionListData = action.payload.data.data;
      state.transactionTotalCount = action.payload.data.totalCount;
      state.isLoading = false;
    },
    estateDetailSuccess: (state, action) => {
      state.estateDetailData = action.payload.data.estateData;
    },

    userNameListSuccess: (state, action) => {
      state.userNameListData = action.payload.data;
    },

    assetsSummarySuccess: (state, action) => {
      state.assetsSummaryData = action.payload;
      state.isLoading = false;
    },
    liabilitiesSummarySuccess: (state, action) => {
      state.liabilitiesSummaryData = action.payload;
      state.isLoading = false;
    },
    clearBankDetailSucces: (state) => {
      // Reset state to initial state
      state.bankDetailData = null;
      state.isLoading = false;
    },
    clearOtherBanckDetailSuccess: (state) => {
      // Reset state to initial state
      state.otherAccountData = null;
      state.isLoading = false;
    },
    clearGoalDetailSuccess: (state) => {
      // Reset state to initial state
      state.goalsDetailData = null;
      state.isLoading = false;
    },
    clearNetworthDetailSuccess: (state) => {
      // Reset state to initial state
      state.netWorthSummaryData = null;
      state.isLoading = false;
    },
    clearProfileDetailDataSuccess: (state) => {
      state.profileDetailData = null;
      state.isLoading = false;
    },

    clearPayRollIcomeDetailSuccess: (state) => {
      // Reset state to initial state
      state.payRollIncomeData = null;
      state.isLoading = false;
    },
    clearRealEstateDetailSuccess: (state) => {
      // Reset state to initial state
      state.realEstateDetailData = null;
      state.isLoading = false;
    },
    taxBracketSuccess: (state, action) => {
      state.taxBracketlistData = action.payload.data.response;
    },

    taxBracketInputFaild: (state, action) => {
      state.errorMessage = action.payload;
    },

    ticketRaiseSuccess: (state, action) => {
      state.ticketRaiseData = action.payload.data.response;
      state.isLoading = false;
    },

    ticketRaiseFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    taxRateAddedSuccess: (state, action) => {
      state.taxRateAddData = action.payload;
    },
    changeCardStatusSuccess: (state, action) => {
      let objId = action.payload.updateIndexId;

      const index = state.cardDetailData?.findIndex(
        (item) => item.id === objId
      );
      console.log(index, "check");
      state.cardDetailData[index] = action.payload.data;
      console.log(index);
      // state.cardStatus = action.payload;
    },
    getEstatePdfListSuccess: (state, action) => {
      state.estatePdfListData = action.payload;
    },
    updateEstatePdfStatusSuccess: (state, action) => {
      let index = state.estatePdfListData[action.payload.key].findIndex(
        (item) => item.userMapperId === action.payload.data.userMapperId
      );
      state.estatePdfListData[action.payload.key][index].status =
        action.payload.data.status;
    },
    deleteEstateDocumentSuccess: (state, action) => {
      state.estatePdfListData[action.payload.key] = state.estatePdfListData[
        action.payload.key
      ].filter((item) => item.userMapperId != action.payload.id);
    },
    deleteRealEstateSuccess: (state, action) => {
      state.realEstateDetailData.data.details =
        state.realEstateDetailData?.data?.details.filter(
          (item) => item.id != action.payload
        );
    },
    deleteOtherAssestSuccess: (state, action) => {
      state.otherAccountData.data = state.otherAccountData.data.filter(
        (item) => item.id != action.payload
      );
    },
    deleteInsuranceSuccess: (state, action) => {
      state.insuranceDetail = state.insuranceDetail.filter(
        (item) => item.insurance_id != action.payload
      );
    },
    clearAssetsLiabilitiesDetailDataSuccess: (state, action) => {
      state.bankDetailData = action.payload;
      state.otherAccountData = action.payload;
      state.realEstateDetailData = action.payload;
      state.payRollIncomeData = action.payload;
    },
    getUserActivitySuccess: (state, action) => {
      state.userActivityList = action.payload.activities;
      state.userActivityCount = action.payload.totalCount;
    },
    getCardsTransactionListSuccess: (state, action) => {
      state.cardsTransactionList = action.payload.data;
      state.cardsTranscationCount = action.payload.total;
    },
  },
});

// ** Actions **
const {
  deleteOtherAssestSuccess,
  deleteRealEstateSuccess,
  deleteEstateDocumentSuccess,
  updateEstatePdfStatusSuccess,
  getEstatePdfListSuccess,
  customerListSuccess,
  customerListFailed,
  getAdminDashboardSuccess,
  loadingSuccess,
  upLoadingSuccess,
  twoFactorAuthStatusSuccess,
  deleteInsuranceSuccess,
  adminProfileDetailSuccess,
  updateProfileSuccess,
  getNetWorthDetailSuccess,
  uploadProfileSuccess,
  clearProfileStateSuccess,
  profileDetailSuccess,
  profileDetailFailed,
  suspendAccoutSuccess,
  suspendAccoutProfileSuccess,
  userDashboardSuccess,
  subscribeUserListSuccess,
  subscribeUserListFailed,
  netWorthListSuccess,
  netWorthListFailed,
  getTicketListingSucces,
  getTicketListingFailed,
  eKycListSuccess,
  eKycListFailed,
  suspendAccountFailed,
  stateListSuccess,
  ticketCategoryApiSuccess,
  ticketCategoryApiFailed,
  ticketSubCategoryApiSuccess,
  ticketSubCategoryApiFailed,
  updateUserDetailsSuccess,
  stateListFailed,
  sendOtpForProfileSuccess,
  sendOtpAuthSuccess,
  textAuthenticationSuccess,
  tOtpAuthenticationSuccess,
  updateUserDetailsFailed,
  getNotificationListSucces,
  readNotificationSuccess,
  eKycDetailSuccess,
  eKycDetailFailed,
  ekycApproveRejectSuccess,
  ekycApproveRejectFailed,
  updateTicketSuccess,
  deleteTicketSuccess,
  sendOtpAuthDataClearSuccess,
  createTeamMemberSuccess,
  deleteTeamMemberSuccess,
  setPasswordByTeamMemberSuccess,
  getPermissionListSuccess,
  getPermissionLevelSuccess,
  getRolePermissionDetailSuccess,
  goaldDetailSuccess,
  estateDetailSuccess,
  clearBankDetailSucces,
  clearOtherBanckDetailSuccess,
  clearGoalDetailSuccess,
  bankAccountDetailsSuccess,
  otherAccountDetailsSuccess,
  payRollIncomeSuccess,
  netWorthSummarySuccess,
  assetsSummarySuccess,
  liabilitiesSummarySuccess,
  taxBracketSuccess,
  taxRateAddedSuccess,
  changeCardStatusSuccess,
  userNameListSuccess,
  allInsuranceDetailSuccess,
  clearPayRollIcomeDetailSuccess,
  clearNetworthDetailSuccess,
  clearProfileDetailDataSuccess,
  clearRealEstateDetailSuccess,
  realEstateDetailSuccess,
  clearAssetsLiabilitiesDetailDataSuccess,
  getNotesListSuccess,
  getSubAdminNotesListSuccess,
  clearSubAdminNotesListSuccess,
  createNotesApiSuccess,
  createSubAdminNotesSuccess,
  createNotesApiFailed,
  getNotesDetailApiSuccess,
  getNotesDetailApiFailed,
  clearNotesDataSuccess,
  financialAccountDetailsSuccess,
  ticketRaiseSuccess,
  ticketRaiseFailed,
  transactionListApiSuccess,
  getUserActivitySuccess,
  getCardsTransactionListSuccess,
} = slice.actions;

//clear state
export const clearBankDetailData = () => async (dispatch) => {
  dispatch(clearBankDetailSucces());
};
export const clearAssetsLiabilitiesDetailData = () => async (dispatch) => {
  dispatch(clearAssetsLiabilitiesDetailDataSuccess(null));
};
export const clearOtherBanckDetail = () => async (dispatch) => {
  dispatch(clearOtherBanckDetailSuccess());
};
export const clearGoalDetailData = () => async (dispatch) => {
  dispatch(clearGoalDetailSuccess());
};
export const clearPayRollIcomeDetail = () => async (dispatch) => {
  dispatch(clearPayRollIcomeDetailSuccess());
};
export const clearRealEstateDetail = () => async (dispatch) => {
  dispatch(clearRealEstateDetailSuccess());
};
export const clearNetWorthDetails = () => async (dispatch) => {
  dispatch(clearNetworthDetailSuccess());
};
export const clearProfileDetailData = () => async (dispatch) => {
  dispatch(clearProfileDetailDataSuccess());
};

export const createNotesApi =
  (requestParams, status, setData, setIsLoading, handleClose, isSubAdminNote) =>
  async (dispatch) => {
    dispatch(loadingSuccess(status));
    try {
      await api
        .post(SILVERBACK.createNotesApi, requestParams)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            let user = response?.data?.data;
            successToast(response?.data?.message);
            if (setData) {
              setData({});
            }
            if (handleClose) {
              handleClose();
            }
            if (isSubAdminNote) {
              dispatch(
                createSubAdminNotesSuccess({
                  ...user?.note,
                  updateIndexId: requestParams?.id,
                })
              );
            } else {
              dispatch(
                createNotesApiSuccess({
                  ...user?.note,
                  updateIndexId: requestParams?.id,
                })
              );
            }
          } else {
            dispatch(createNotesApiFailed(response.error));
          }
          if (setIsLoading) {
            setIsLoading(false);
          }

          dispatch(loadingSuccess(false));
        });
    } catch (e) {
      if (setIsLoading) {
        setIsLoading(false);
      }

      return console.error(e?.message);
    }
  };

export const getNotesDetail = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api
      .get(`${SILVERBACK.getNotesDetailApi}/${data?.id}`)
      .then((response) => {
        if (response) {
          let user = response?.data?.data?.note;
          console.log("user", user);
          dispatch(getNotesDetailApiSuccess(user));
        } else {
          dispatch(getNotesDetailApiFailed(response.error));
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const profileDetail = (requestParams, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK.profileDetail, requestParams).then((response) => {
      if (response) {
        let user = response?.data?.data;
        dispatch(profileDetailSuccess(user?.userData));
      } else {
        dispatch(profileDetailFailed(response.error));
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const eKycDetail = (requestParams, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.get(SILVERBACK.eKycDetail + requestParams.id).then((response) => {
      if (response) {
        dispatch(eKycDetailSuccess(response.data));
      } else {
        dispatch(eKycDetailFailed(response.error));
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const stateList = () => async (dispatch) => {
  try {
    await api.post(SILVERBACK.stateList).then((response) => {
      if (response) {
        dispatch(stateListSuccess(response.data));
      } else {
        dispatch(stateListFailed(response.error));
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const ticketCategory = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.ticketCategoryApi).then((response) => {
      if (response) {
        dispatch(ticketCategoryApiSuccess(response.data));
      } else {
        dispatch(ticketCategoryApiFailed(response.error));
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const ticketSubCategory = (data) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK.ticketSubCategoryApi}/${data?.categoryId}`)
      .then((response) => {
        if (response) {
          dispatch(ticketSubCategoryApiSuccess(response.data));
        } else {
          dispatch(ticketSubCategoryApiFailed(response.error));
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const verifyOtpForProfile =
  (requestParams, type, setDialog, saveCustomerProfileData) =>
  async (dispatch) => {
    dispatch(loadingSuccess(true));
    api
      .post(`${SILVERBACK.verifyOtpForProfile}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (response.status == STATUS_CODES.SUCCESS) {
          successToast(result.message);
          //call update profile function.
          saveCustomerProfileData(
            type === SAVE_PROFILE_KEYS.email
              ? SAVE_PROFILE_KEYS.email
              : SAVE_PROFILE_KEYS.phoneNumber
          );
        } else {
          errorToast(result.detail);
        }
        dispatch(loadingSuccess(false));
        setDialog(false);
      })
      .catch(() => {
        dispatch(loadingSuccess(false));
      });
  };

export const sendOtpForProfile =
  (requestParam, setOtpDialog) => async (dispatch) => {
    dispatch(loadingSuccess(true));
    try {
      await api
        .post(SILVERBACK.sendOtpForProfile, requestParam)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            setOtpDialog(true);
            dispatch(sendOtpForProfileSuccess(response.data));
          } else {
            errorToast(response.data?.detail ?? MESSAGES.OTP_ERROR);
          }
          dispatch(loadingSuccess(false));
        });
    } catch (e) {
      return console.error(e?.message);
    }
  };

export const sendOtpForAuth =
  (requestParam, setMethods) => async (dispatch) => {
    dispatch(loadingSuccess(true));
    try {
      await api
        .post(SILVERBACK.sendOtpForAuth, requestParam)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            dispatch(sendOtpAuthSuccess(response.status));
            if (requestParam?.otpType === "email") {
              console.log("chec");
              setMethods(ACCOUNT_VERIFICATION_METHODS.EMAIL_OTP);
            }
            if (requestParam?.otpType === "phone") {
              setMethods(ACCOUNT_VERIFICATION_METHODS.PHONE_OTP);
            }
          } else {
            errorToast(response.data?.detail ?? MESSAGES.OTP_ERROR);
          }
          dispatch(loadingSuccess(false));
        });
    } catch (e) {
      return console.error(e?.message);
    }
  };

export const sendOtpAuthDataClear = () => async (dispatch) => {
  dispatch(sendOtpAuthDataClearSuccess());
};

export const updateUserDetails = (requestParam) => async (dispatch) => {
  dispatch(loadingSuccess(true));
  try {
    await api
      .post(SILVERBACK.updateUserDetails, requestParam)
      .then((response) => {
        if (response) {
          dispatch(updateUserDetailsSuccess(response.data));
          successToast(response?.data?.message);
        } else {
          dispatch(updateUserDetailsFailed(response.error));
          errorToast(response?.detail);
        }
        dispatch(loadingSuccess(false));
        //close dialog
      });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const suspendAccountProfile =
  (requestParams, status, setAlertDialogRemoveOpen, setDialog, setFormData) =>
  async (dispatch) => {
    dispatch(loadingSuccess(status));
    setAlertDialogRemoveOpen(false);
    try {
      await api
        .post(SILVERBACK.suspendAccount, requestParams)
        .then((response) => {
          adminProfileDetail;
          if (response?.status === STATUS_CODES.SUCCESS) {
            successToast(
              response?.data?.message
                ? response?.data?.message
                : response?.data?.data?.message
            );
            let id = requestParams?.userId;
            dispatch(
              suspendAccoutProfileSuccess({
                ...response.data,
                deleteIndexId: id,
              })
            );

            if (setFormData) {
              setFormData({});
            }

            if (setAlertDialogRemoveOpen) {
              setAlertDialogRemoveOpen(false);
            }
            if (setDialog) {
              setDialog(false);
            }
            dispatch(loadingSuccess(false));
          } else {
            errorToast(response?.data?.detail);
            if (setFormData) {
              setFormData({});
            }
            if (setDialog) {
              setDialog(false);
            }
            if (setAlertDialogRemoveOpen) {
              setAlertDialogRemoveOpen(false);
            }
            dispatch(loadingSuccess(false));
          }
        });
      dispatch(loadingSuccess(false));
    } catch (e) {
      dispatch(loadingSuccess(false));
      return console.error(e);
    }
  };

export const suspendAccount =
  (requestParams, status, setAlertDialogRemoveOpen, setDialog, setFormData) =>
  async (dispatch) => {
    dispatch(loadingSuccess(status));
    setAlertDialogRemoveOpen(false);
    try {
      await api
        .post(SILVERBACK.suspendAccount, requestParams)
        .then((response) => {
          adminProfileDetail;
          if (response?.status === STATUS_CODES.SUCCESS) {
            successToast(
              response?.data?.message
                ? response?.data?.message
                : response?.data?.data?.message
            );
            let id = requestParams?.userId;
            dispatch(
              suspendAccoutSuccess({
                ...response.data,
                deleteIndexId: id,
              })
            );

            if (setFormData) {
              setFormData({});
            }

            if (setAlertDialogRemoveOpen) {
              setAlertDialogRemoveOpen(false);
            }
            if (setDialog) {
              setDialog(false);
            }
            dispatch(loadingSuccess(false));
          } else {
            dispatch(suspendAccountFailed(response?.data?.detail));
            errorToast(response?.data?.detail);
            if (setFormData) {
              setFormData({});
            }
            if (setDialog) {
              setDialog(false);
            }
            if (setAlertDialogRemoveOpen) {
              setAlertDialogRemoveOpen(false);
            }
            dispatch(loadingSuccess(false));
          }
        });
      dispatch(loadingSuccess(false));
    } catch (e) {
      dispatch(loadingSuccess(false));
      return console.error(e);
    }
  };

export const ekycApproveRejectApi =
  (
    requestParams,
    status,
    setRejcectReasonDialog,
    setOpenUpdateKyc,
    setFormData
  ) =>
  async (dispatch) => {
    dispatch(loadingSuccess(status));

    try {
      await api
        .post(SILVERBACK.ekycApproveReject, requestParams)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            let id = requestParams?.changeRequestId;
            dispatch(
              ekycApproveRejectSuccess({
                ...response.data,
                deleteIndexId: id,
              })
            );
            successToast(response?.data?.message);
            setOpenUpdateKyc(false);
            setRejcectReasonDialog(false);

            // setFormData({});
          } else {
            dispatch(ekycApproveRejectFailed(response?.data?.detail));
            errorToast(response?.data?.detail);
            setOpenUpdateKyc(false);
            setRejcectReasonDialog(false);

            setFormData({});
          }
        });
      dispatch(loadingSuccess(false));
    } catch (e) {
      return console.error(e);
    }
  };

export const getAdminDashboard = (status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  api
    .get(`${SILVERBACK.getOrganiserOrders}`)
    .then((response) => {
      let result = response.data;
      if (result.status === STATUS_CODES.SUCCESS) {
        dispatch(getAdminDashboardSuccess(result.data));
      } else {
        errorToast("Error", result.message);
      }
      dispatch(loadingSuccess(false));
    })
    .catch(() => {});
};

export const editProfile = (requestParam, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  api
    .post(`${SILVERBACK.editProfile}`, requestParam)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(updateProfileSuccess(response.data));
        dispatch(adminProfileDetail(response.data));
        successToast(response.data.message);
        dispatch(loadingSuccess(false));
      }
      dispatch(loadingSuccess(false));
    })
    .catch(() => {});
};

export const editUpdateProfile = (requestParam, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  api
    .post(`${SILVERBACK.editProfile}`, requestParam)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(updateProfileSuccess(response.data));
        dispatch(adminProfileDetail(response.data));
        successToast(response.data.message);
        dispatch(loadingSuccess(false));
      }
      dispatch(loadingSuccess(false));
    })
    .catch(() => {});
};

export const getDetailOfNetWorth =
  (requestParam, status, navigate) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    api
      .post(`${SILVERBACK.getDetailOfNetWorth}`, requestParam)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getNetWorthDetailSuccess(response.data));
          // successToast(response.data.message);
          navigate("/netWorthDetails");
          dispatch(loadingSuccess(false));
        }
        dispatch(loadingSuccess(false));
      })
      .catch(() => {});
  };

export const adminProfileDetail = (navigate) => async (dispatch) => {
  api
    .get(`${SILVERBACK.adminProfileDetail}`)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        secureLocalStorage.setItem(
          "adminDetail",
          JSON.stringify(response.data)
        );
        // if (navigate) {
        //   location.reload();
        // }
        dispatch(adminProfileDetailSuccess(response.data));
      } else {
        errorToast("Error", response.message);
      }
    })
    .catch(() => {});
};

export const textAuthentication =
  (requestParam, status) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    api
      .post(`${SILVERBACK.textAuthApi}`, requestParam)
      .then((response) => {
        let result = response.data;
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(textAuthenticationSuccess(result));
        } else {
          // errorToast("Error", result.error);
        }
        dispatch(loadingSuccess(false));
      })
      .catch(() => {});
  };

export const forOTPAuthentication =
  (requestParam, status) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    api
      .get(`${SILVERBACK.tOTPAuth + requestParam}`, requestParam)
      .then((response) => {
        let result = response.data;
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(tOtpAuthenticationSuccess(result));
        } else {
          // errorToast( result?.error);
        }
        dispatch(loadingSuccess(false));
      })
      .catch(() => {});
  };

export const uploadProfile = (requestParam, status) => async (dispatch) => {
  dispatch(upLoadingSuccess(status));
  api
    .post(`${SILVERBACK.uploadProfile}`, requestParam, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      let result = response.data;
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(uploadProfileSuccess(result));
        successToast(result?.message);
      } else {
        errorToast(result?.message);
      }
      dispatch(upLoadingSuccess(false));
    })
    .catch(() => {
      dispatch(upLoadingSuccess(false));
    });
};

export const getUserDashboard = () => async (dispatch) => {
  api
    .get(`${SILVERBACK.getUserDashboard}`)
    .then((response) => {
      let result = response.data;
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(userDashboardSuccess(result));
      } else {
        errorToast("Error", result.error);
      }
    })
    .catch(() => {});
};

// *** For Listing Functions
export const getCustomerList = (data, isSearch, status) => async (dispatch) => {
  dispatch(loadingSuccess(isSearch ? !status : status));
  try {
    await api.post(SILVERBACK.getCustomerList, data).then((response) => {
      if (response) {
        dispatch(
          customerListSuccess({
            ...response.data,
            page: data.page,
          })
        );
      } else {
        dispatch(customerListFailed(response.error));
        errorToast(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const getSubscribeUserList =
  (data, isSearch, status) => async (dispatch) => {
    dispatch(loadingSuccess(isSearch ? !status : status));
    try {
      await api.post(SILVERBACK.subscribeUserList, data).then((response) => {
        if (response) {
          dispatch(
            subscribeUserListSuccess({
              ...response.data,
              page: data.page,
            })
          );
        } else {
          dispatch(subscribeUserListFailed(response.error));
          errorToast(response.error);
        }
      });
    } catch (e) {
      return console.error(e?.message);
    }
  };

export const getNetWorthList = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK.getNetWorthList, data).then((response) => {
      if (response) {
        dispatch(
          netWorthListSuccess({
            ...response.data,
            page: data.page,
          })
        );
      } else {
        dispatch(netWorthListFailed(response.error));
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};
export const getEKycList = (data, isSearch, status) => async (dispatch) => {
  dispatch(loadingSuccess(isSearch ? !status : status));
  try {
    await api.post(SILVERBACK.getEKycList, data).then((response) => {
      if (response) {
        dispatch(
          eKycListSuccess({
            ...response.data,
            page: data.page,
          })
        );
      } else {
        dispatch(eKycListFailed(response.error));
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const getTicketList = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK.getTicketList, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(
          getTicketListingSucces({
            ...response.data,
            page: data.page,
          })
        );
      } else {
        dispatch(getTicketListingFailed(response.error));
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};
// --------------------notification-----------------------

export const getNotificationList =
  (data, setViewMoreLoading) => async (dispatch) => {
    try {
      await api.post(SILVERBACK.getNotificationList, data).then((response) => {
        if (response) {
          dispatch(
            getNotificationListSucces({
              ...response.data,
              page: data.page,
            })
          );
        } else {
          errorToast.error(response.error);
        }
        if (setViewMoreLoading) {
          setViewMoreLoading(false);
        }
      });
    } catch (e) {
      return console.error(e?.message);
    }
  };
export const readNotification = (data, perPage) => async (dispatch) => {
  try {
    await api.post(SILVERBACK.readNotification, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        if (data?.readAll) {
          let request = {
            page: 1,
            perPage: perPage,
          };
          dispatch(getNotificationList(request));
        } else {
          dispatch(readNotificationSuccess(data?.notificationId));
        }
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const updateTicket = (data, setOpenEditTicket) => async (dispatch) => {
  dispatch(loadingSuccess(true));
  try {
    await api.post(SILVERBACK.updateTicket, data).then((response) => {
      let result = response.data;
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(updateTicketSuccess(response.data));
        successToast(result.message);
        setOpenEditTicket(false);
      } else {
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};
export const deleteTicket = (id, setOpenAlertDialog) => async (dispatch) => {
  dispatch(loadingSuccess(true));
  try {
    await api.delete(`${SILVERBACK.deleteTicket}/${id}`).then((response) => {
      let result = response.data;
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(deleteTicketSuccess(id));
        successToast(result.message);
        setOpenAlertDialog(false);
      } else {
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};
export const loading = (status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
};

export const clearProfileState = (status) => async (dispatch) => {
  dispatch(clearProfileStateSuccess(status));
};
export const clearNotesData = (status) => async (dispatch) => {
  dispatch(clearNotesDataSuccess(status));
};
//--------------------------create team member ---------------------

export const createTeamMember =
  (data, setOpenAlertDialog, setAddTeamFormData) => async (dispatch) => {
    api
      .post(`${SILVERBACK.createTeamMember}`, data)
      .then((response) => {
        let result = response.data;
        console.log("response", response);
        if (response.status == STATUS_CODES.SUCCESS) {
          dispatch(createTeamMemberSuccess(result));
          successToast("Team member added successfully");
          setAddTeamFormData({});
          setOpenAlertDialog(false);
        } else if (response.status == STATUS_CODES.FAILURE) {
          errorToast(response?.data?.detail, "support");
        } else if (response.status == STATUS_CODES.SERVER_ERROR) {
          errorToast(response?.data?.detail, "support");
        } else {
          errorToast(response?.data?.detail);
        }

        // dispatch(loadingSuccess(false));
      })
      .catch((e) => {
        return errorToast(e?.response?.data?.detail, "support");
      });
  };

// export const createTeamMember =
//   (data, setOpenAlertDialog) => async (dispatch) => {
//     dispatch(loadingSuccess(true));
//     try {
//       await api.post(SILVERBACK.createTeamMember, data).then((response) => {
//         let result = response.data;
//         if (response.status === STATUS_CODES.SUCCESS) {
//           dispatch(createTeamMemberSuccess(result));
//           successToast("Add team memeber successfully");
//           setOpenAlertDialog(false);
//         } else if (response.status === STATUS_CODES.FAILURE) {
//           errorToast(result.detail);
//         } else if (response.status === STATUS_CODES.SERVER_ERROR) {
//           errorToast(result.detail);
//         } else {
//           errorToast(result.detail);
//         }
//       });
//     } catch (e) {
//       return console.error(e?.message);
//     }
//   };

//--------------------------delete team member ---------------------

export const deleteTeamMember =
  (id, setOpenAlertDialog) => async (dispatch) => {
    dispatch(loadingSuccess(true));
    api
      .delete(`${SILVERBACK.deleteTeamMember}?user_id=${id}`)
      .then((response) => {
        let result = response.data;
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(deleteTeamMemberSuccess(id));
          successToast(response.data.message);
          setOpenAlertDialog(false);
        } else {
          errorToast.error(response.error);
        }
      })

      .catch((e) => {
        return errorToast(e?.response?.data?.detail, "support");
      });
  };

//--------------------------create password by team member ---------------------

export const setPasswordByTeamMember = (data, navigate) => async (dispatch) => {
  dispatch(loadingSuccess(true));
  try {
    await api
      .post(SILVERBACK.setPasswordByTeamMember, data)
      .then((response) => {
        let result = response.data;
        if (response.status === STATUS_CODES.SUCCESS) {
          if (navigate) {
            successToast(response.data.message);
            navigate("/login");
          } else {
            dispatch(setPasswordByTeamMemberSuccess(true));
          }
        } else {
          dispatch(setPasswordByTeamMemberSuccess(false));
          errorToast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e?.message);
  }
};
//--------------------------get permissions list ---------------------
export const getPermissionList = (status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.get(SILVERBACK.getPermissionList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getPermissionListSuccess(response.data));
      } else {
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    dispatch(loadingSuccess(false));
    return console.error(e?.message);
  }
};
//--------------------------get permissions level ---------------------
export const getPermissionLevel = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getPermissionLevel).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getPermissionLevelSuccess(response.data));
      } else {
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};
//--------------------------get permissions detail ---------------------

export const getRolePermissionDetail = (roleId) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK.getRolePermissionDetail}/${roleId}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getRolePermissionDetailSuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e?.message);
  }
};

//--------------------------get list of notes ---------------------

export const listOfNotesApi =
  (data, status, isSubAdminNotes, setViewMoreLoading) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    try {
      await api.post(SILVERBACK.listOfNotesApiURl, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          if (isSubAdminNotes) {
            dispatch(getSubAdminNotesListSuccess(response.data));
          } else {
            dispatch(getNotesListSuccess(response.data));
          }
        } else {
          errorToast.error(response.error);
        }
      });
      if (setViewMoreLoading) {
        setViewMoreLoading(false);
      }
      dispatch(loadingSuccess(false));
    } catch (e) {
      if (setViewMoreLoading) {
        setViewMoreLoading(false);
      }
      dispatch(loadingSuccess(false));

      return console.error(e?.message);
    }
  };

// clear notes data
export const clearListOfNotesApi = () => async (dispatch) => {
  dispatch(clearSubAdminNotesListSuccess());
};

//--------------------------get detail of goals ---------------------

export const goalsDetailApi = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api
      .get(`${SILVERBACK.goalsDetailURl}?user_id=${data.userId}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(goaldDetailSuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
      });
    dispatch(loadingSuccess(false));
  } catch (e) {
    dispatch(loadingSuccess(false));

    return console.error(e?.message);
  }
};
//--------------------------get detail of bank ---------------------

export const bankAccountDetails = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));

  try {
    await api
      .get(
        `${SILVERBACK.bankDetailsURL}/${data?.userId}/${data?.type}/${data?.subtype}`
      )
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(bankAccountDetailsSuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    dispatch(loadingSuccess(false));

    return console.error(e?.message);
  }
};

//--------------------------get insurance details of bank ---------------------

export const allInsuranceDetail = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));

  try {
    await api.post(SILVERBACK.allInsuranceDetailURL, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(allInsuranceDetailSuccess(response.data));
      } else {
        errorToast.error(response.error);
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    dispatch(loadingSuccess(false));

    return console.error(e?.message);
  }
};

//--------------------------get detail of payRoll account ---------------------

export const payRollIncome = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api
      .get(`${SILVERBACK.payRollIncomeURL}/${data?.userId}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(payRollIncomeSuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    dispatch(loadingSuccess(false));

    return console.error(e?.message);
  }
};

//--------------------------get detail of real estate  account ---------------------

export const realEstateDetail = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api
      .post(`${SILVERBACK.realEstateDetailURL}`, data)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(realEstateDetailSuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    dispatch(loadingSuccess(false));

    return console.error(e?.message);
  }
};

//--------------------------get detail of other account ---------------------

export const otherAccountDetails = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api
      .get(`${SILVERBACK.otherAccountDetailsURl}/${data?.userId}/${data?.type}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(otherAccountDetailsSuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    dispatch(loadingSuccess(false));

    return console.error(e?.message);
  }
};

//--------------------------get detail of financial Account ---------------------

export const financialAccountDetailsApi = (id) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK.financialAccountDetailsApiURL}?userId=${id}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(financialAccountDetailsSuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e?.message);
  }
};
//--------------------------get detail of netWoth ---------------------

export const netWorthSummary = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));

  try {
    await api
      .get(`${SILVERBACK.netWrothSummaryURL}/${data.userId}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(netWorthSummarySuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e?.message);
  }
};
//--------------------------get detail of netWoth ---------------------

export const assetsSummary = (data, setIsloading) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK.assetsSummaryURL}/${data.userId}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(assetsSummarySuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
        if (setIsloading) {
          setIsloading(false);
        }
      });
  } catch (e) {
    if (setIsloading) {
      setIsloading(false);
    }
    return console.error(e?.message);
  }
};
//--------------------------get detail of netWoth ---------------------

export const liabilitiesSummary = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));

  try {
    await api
      .get(`${SILVERBACK.liablilitiesSummaryURL}/${data.userId}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(liabilitiesSummarySuccess(response.data));
          dispatch(loadingSuccess(false));
        } else {
          errorToast.error(response.error);
        }
      });
    dispatch(loadingSuccess(false));
  } catch (e) {
    dispatch(loadingSuccess(false));

    return console.error(e?.message);
  }
};
//--------------------------get detail of estate ---------------------

export const estateDetailApi = (data) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK.estateDetailURL}?user_id=${data.userId}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(estateDetailSuccess(response.data));
        } else {
          errorToast.error(response.error);
        }
      });
  } catch (e) {
    return console.error(e?.message);
  }
};
//--------------------------update permissions ---------------------
export const updatePermissions = (data) => async () => {
  try {
    await api.post(SILVERBACK.updatePermissions, data).then((response) => {
      console.log("response", response);
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
      } else if (response.status === STATUS_CODES.FAILURE) {
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};
//  ------------------------send notification--------------

export const sendNotification =
  (data, setNotificationData, setNote, navigate) => async () => {
    try {
      await api.post(SILVERBACK.sendNotification, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          successToast(response.data.message);
          setNotificationData({});
          setNote("");
          navigate("/notification");
        } else {
          errorToast(response.detail);
        }
      });
    } catch (e) {
      return console.error(e?.message);
    }
  };
// ------------taxBracketList-----------

export const taxBracketList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.taxBracketURL).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(taxBracketSuccess(response.data));
      } else {
        errorToast(response.error);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// ------------------taxBracketinput---------
export const taxBracketinputForm =
  (requestParams, setTaxRateForm, setTaxModal) => async (dispatch) => {
    try {
      await api
        .post(SILVERBACK.taxBracketInputURL, requestParams)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            // let user = response.data.data;
            dispatch(taxRateAddedSuccess(response.data));
            successToast(response.data.message);
            setTaxRateForm({});
            setTaxModal(false);
          } else {
            console.log(response);
            errorToast(response.data.detail);
          }
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const changeCardStatus =
  (requestParam, setShowConfirmProfileDialog, setFormData) =>
  async (dispatch) => {
    api
      .post(SILVERBACK.activateDactivatCardURL, requestParam)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          let id = requestParam.card_id;
          dispatch(
            changeCardStatusSuccess({
              ...response.data,
              updateIndexId: id,
            })
          );
          setFormData({});
          setShowConfirmProfileDialog(false);
        } else {
          errorToast("Error", response.detail);
        }
      })

      .catch(() => {});
  };
export const userNameList = (requestParam) => async (dispatch) => {
  api
    .get(SILVERBACK.userNameListURL, requestParam)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        let id = requestParam.card_id;
        dispatch(userNameListSuccess(response.data));
      } else {
        errorToast("Error", response.detail);
      }
    })

    .catch(() => {});
};

// estate pdf list
export const getEstatePdfList = (userId, setIsLoading) => async (dispatch) => {
  api
    .get(`${SILVERBACK.getEstatePdfList}/${userId}`)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getEstatePdfListSuccess(response?.data?.data?.list));
      } else {
        errorToast("Error", response.detail);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    })

    .catch(() => {});
};
// update Estate Pdf Status

export const updateEstatePdfStatus =
  (requestParam, pdfKey, closeDialog) => async (dispatch) => {
    api
      .post(SILVERBACK.updateEstatePdfStatus, requestParam)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          successToast(response?.data?.message);
          dispatch(
            updateEstatePdfStatusSuccess({
              key: pdfKey,
              data: requestParam,
            })
          );
          closeDialog();
        } else {
          errorToast(response?.data?.detail);
        }
      })

      .catch(() => {});
  };

// deleteEstateDocument
export const deleteEstateDocument =
  (userMapperId, pdfKey, setIsLoading, setAcceptDialog) => async (dispatch) => {
    try {
      await api
        .delete(`${SILVERBACK.deleteEstateDocument}/${userMapperId}`)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            successToast(response.data.message);
            setAcceptDialog(false);
            dispatch(
              deleteEstateDocumentSuccess({
                id: userMapperId,
                key: pdfKey,
              })
            );

            // dispatch(getEstateListSuccess(response.data.data));
          } else {
            errorToast(response.data.detail);
          }
          setIsLoading(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

// delete real estate
export const deleteRealEstate =
  (id, setShowDeleteDialog, setIsloading) => async (dispatch) => {
    try {
      await api
        .delete(`${SILVERBACK.deleteRealEstate}/${id}`)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            console.log("response", response);
            dispatch(deleteRealEstateSuccess(id));
            successToast(response?.data?.message);
            setShowDeleteDialog(false);
          } else {
            errorToast(response.data.detail, "account");
          }
          setIsloading(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

// delete other assets
export const deleteOtherAssest =
  (id, setShowDeleteDialog, setIsloading) => async (dispatch) => {
    try {
      await api
        .delete(`${SILVERBACK.deleteOtherAssest}/${id}`)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            console.log("response", response);
            dispatch(deleteOtherAssestSuccess(id));
            successToast(response?.data?.message);
            setShowDeleteDialog(false);
          } else {
            errorToast(response.data.detail, "account");
          }
          setIsloading(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

//delete insurance
export const deleteInsurance =
  (id, setOpenAlertDialog, setInsuranceDetailData, setIsloading) =>
  async (dispatch) => {
    try {
      await api
        .delete(`${SILVERBACK.deleteInsurance}/${id}`)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            successToast(response?.data?.message);
            dispatch(deleteInsuranceSuccess(id));
            setOpenAlertDialog(false);
            setInsuranceDetailData({});
          } else {
            errorToast(response.data.detail);
          }

          setIsloading(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const ticketRaise = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK.ticketRaiseApi, data).then((response) => {
      if (response) {
        console.log("ticketRaiseData response", response);
        dispatch(ticketRaiseSuccess(response.data));
        successToast(response?.data?.message);
      } else {
        dispatch(ticketRaiseFailed(response.error));
        errorToast(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

export const transactionListApi = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK.transactionListApi, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(transactionListApiSuccess(response.data));
      } else {
        errorToast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e?.message);
  }
};

// get User Activity

export const getUserActivity =
  (data, setViewMoreLoading) => async (dispatch) => {
    try {
      await api.post(SILVERBACK.getUserActivity, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getUserActivitySuccess(response?.data?.data));
        } else {
          errorToast.error(response.error);
        }
        setViewMoreLoading(false);
      });
    } catch (e) {
      setViewMoreLoading(false);
      return console.error(e?.message);
    }
  };

// get Cards Transaction List
export const getCardsTransactionList =
  (data, setViewMoreLoading) => async (dispatch) => {
    try {
      await api
        .post(SILVERBACK.getCardsTransactionList, data)
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            dispatch(getCardsTransactionListSuccess(response?.data?.data));
          } else {
            errorToast.error(response.error);
          }
          if (setViewMoreLoading) {
            setViewMoreLoading(false);
          }
        });
    } catch (e) {
      if (setViewMoreLoading) {
        setViewMoreLoading(false);
      }
      return console.error(e?.message);
    }
  };

export default slice.reducer;
