import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdminButton from "components/adminButton";
import AdminInput from "components/adminInput";
import { errorToast } from "components/showToast";
import Spinner from "components/spinner";
import TopHeading from "components/topHeading";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "services";
import { SILVERBACK, STATUS_CODES } from "services/apiConstant";
import {
  addAdvertise,
  getAdvertiseDetail,
  getBannerCategoryList,
  updateAdvertise,
} from "store/slice/assets";
import { getSiteSetting } from "store/slice/settings";
import { color } from "theme/color";
import { validateEmpty } from "utils/constantFun";

export default function AddEditAdvertise() {
  const theme = useTheme();
  const date = new Date();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const { siteSettingData } = useSelector((state) => state.settings);
  const [isLoading, setIsLoading] = useState(false);
  const { bannerCategoryList, advertiseDetail } = useSelector(
    (state) => state.assets
  );

  const [image, setImage] = useState("");
  const bannerImage = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBannerCategoryList());
    dispatch(getSiteSetting());
  }, []);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      let request = {
        id: id,
      };
      dispatch(getAdvertiseDetail(request, setIsLoading));
    }
  }, [id]);
  useEffect(() => {
    if (advertiseDetail) {
      setFormData(JSON.parse(JSON.stringify(advertiseDetail)));

      if (advertiseDetail?.documents?.length > 0) {
        setImage(advertiseDetail?.documents[0]);
      }
    }
  }, [advertiseDetail]);
  const handleSubmit = () => {
    if (validateEmpty(formData?.title)) {
      setFormData((formData) => ({
        ...formData,
        titleErr: "Please enter title",
      }));
      return;
    }
    if (validateEmpty(formData?.categoryId)) {
      setFormData((formData) => ({
        ...formData,
        typeErr: "Please select type",
      }));
      return;
    }
    if (validateEmpty(formData?.startDate)) {
      setFormData((formData) => ({
        ...formData,
        startDateErr: "Please enter startDate",
      }));
      return;
    }
    if (validateEmpty(formData?.expireDate)) {
      setFormData((formData) => ({
        ...formData,
        expireDateErr: "Please enter expireDate",
      }));
      return;
    }
    if (validateEmpty(formData?.description)) {
      setFormData((formData) => ({
        ...formData,
        descriptionErr: "Please enter description",
      }));
      return;
    }
    if (formData?.document) {
      if (!formData?.document[0]) {
        setFormData((formData) => ({
          ...formData,
          documentErr: "Please add banner image",
        }));
        return;
      }
    } else {
      setFormData((formData) => ({
        ...formData,
        documentErr: "Please add banner image",
      }));
      return;
    }
    setIsLoading(true);
    if (id) {
      let request = {
        title: formData?.title,
        description: formData?.description,
        categoryId: formData?.categoryId,
        document: formData?.document,
        startDate: formData?.startDate,
        expireDate: formData?.expireDate,
        id: id,
        isActive: true,
      };
      dispatch(updateAdvertise(request, navigate, setIsLoading));
    } else {
      let request = {
        title: formData?.title,
        description: formData?.description,
        categoryId: formData?.categoryId,
        document: formData?.document,
        startDate: formData?.startDate,
        expireDate: formData?.expireDate,
      };

      dispatch(addAdvertise(request, navigate, setIsLoading));
    }
  };
  const uploadMedia = (formDataRequest) => {
    api
      .post(SILVERBACK.uploadDocuments, formDataRequest, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response?.status == STATUS_CODES.SUCCESS) {
          let temp = [response?.data?.data?.documentId];
          setFormData((formData) => ({
            ...formData,
            document: temp,
            documentErr: "",
          }));
          setImage(response?.data?.data?.fullPath);
        } else {
          errorToast(response.data.detail);
        }
      })
      .catch(() => {});
  };
  const handleBannerImage = (event) => {
    const formData = new FormData();
    let file = event.target.files[0];
    formData.append("file", file);
    formData.append("entityId", siteSettingData?.ENTITY_TYPE_BANNER);
    formData.append("moduleId", siteSettingData?.MODULE_TYPE_ADVERTISE);
    formData.append("typeId", siteSettingData?.DOCUMENT_TYPE_BANNER);
    uploadMedia(formData);
  };
  const compareDates = (d1, d2) => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (date1 < date2) {
      return false;
    } else if (date1 > date2) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <TopHeading
        sx={{ p: isMobile ? 1 : "" }}
        title={"Advertise Management"}
      />
      <Grid container mt={4}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/advertise-managment">
              Advertise Management
            </Link>
            <Typography sx={{ color: "white" }}>
              {id ? "Edit" : "Add"}
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <Grid container columnSpacing={4} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              type="text"
              color="white"
              title="Title"
              maxLength={30}
              onChange={(e) => {
                setFormData((formData) => ({
                  ...formData,
                  title: e.target.value?.trimStart(),
                  titleErr: "",
                }));
              }}
              value={formData?.title || ""}
              formError={formData?.titleErr}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                label=" Typed"
                onChange={(val) => {
                  if (val) {
                    setFormData((formData) => ({
                      ...formData,
                      categoryId: val.target.value,
                      typeErr: "",
                    }));
                  }
                }}
                value={formData?.categoryId || ""}
                sx={{
                  color: "white",
                  width: "100%",
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {bannerCategoryList?.map((item, index) => {
                  if (item?.isActive) {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
            {formData?.typeErr && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 12,
                  ml: 1,
                  mt: 1,
                }}
              >
                {formData?.typeErr}
              </Typography>
            )}
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              color={formData?.startDate ? "white" : "grey"}
              type={"date"}
              title={"Start Date"}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "white",
                },

                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)", // Change the color of the calendar icon
                },
              }}
              value={formData?.startDate || "dd/mm/yyyy"}
              formError={formData?.startDateErr}
              onChange={(e) => {
                setFormData((formData) => ({
                  ...formData,
                  startDate: e.target.value,
                  startDateErr: "",
                }));
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              color={formData?.expireDate ? "white" : "grey"}
              type={"date"}
              title={"Expire Date"}
              min={
                formData?.startDate
                  ? compareDates(formData?.startDate, date)
                    ? formData?.startDate
                    : moment(date).format("YYYY-MM-DD")
                  : moment(date).format("YYYY-MM-DD")
              }
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "white",
                },

                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)", // Change the color of the calendar icon
                },
              }}
              value={formData?.expireDate || "dd/mm/yyyy"}
              formError={formData?.expireDateErr}
              onChange={(e) => {
                formData.expireDate = e.target.value;
                setFormData((formData) => ({
                  ...formData,
                  expireDate: e.target.value,
                  expireDateErr: "",
                }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AdminInput
              multiline
              maxRows={8}
              type="text"
              color="white"
              title="Description"
              onChange={(e) => {
                setFormData((formData) => ({
                  ...formData,
                  description: e.target.value?.trimStart(),
                  descriptionErr: "",
                }));
              }}
              value={formData?.description || ""}
              formError={formData?.descriptionErr}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            {image ? (
              <Box
                sx={{
                  height: "auto",
                  width: "100%",
                  position: "relative",
                }}
              >
                <Avatar
                  onClick={() => {
                    setImage("");
                    setFormData((formData) => ({
                      ...formData,
                      document: "",
                    }));
                  }}
                  sx={{
                    height: 25,
                    width: 25,
                    background: "white",
                    position: "absolute",
                    top: 5,
                    right: 5,
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    icon={"gridicons:cross-circle"}
                    color="red"
                    width={28}
                  />
                </Avatar>

                <img
                  src={image}
                  alt=""
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "cover" }}
                />
              </Box>
            ) : (
              <Box
                onClick={() => bannerImage.current.click()}
                sx={{
                  border: "2px dashed #ffffffb8",

                  height: 180,
                  borderRadius: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <input
                  ref={bannerImage}
                  type="file"
                  style={{ display: "none" }}
                  className="imageUpload"
                  accept="image/*"
                  multiple
                  onChange={handleBannerImage}
                />
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography>
                    <Icon
                      icon={"zondicons:add-outline"}
                      color="grey"
                      width={40}
                    />
                  </Typography>
                  <Typography style={{ color: "grey", mt: 2, fontSize: 20 }}>
                    Add Banner Image
                  </Typography>
                </Box>
              </Box>
            )}
            {formData?.documentErr && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 12,
                  ml: 1,
                  mt: 1,
                }}
              >
                {formData?.documentErr}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container columnSpacing={4} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 2}>
            <AdminButton fullWidth title="Submit" onClick={handleSubmit} />
          </Grid>
        </Grid>

        <Spinner open={isLoading} />
      </Box>
    </div>
  );
}
