import { useEffect, useState } from "react";
import { useParams } from "react-router";
// import InsuranceSelect from './insuranceSelect';
import { useDispatch, useSelector } from "react-redux";
import { INSURANCET_TYPES } from "utils/appConstant";
import { validateEmpty } from "utils/constantFun";
import Spinner from "components/spinner";
import {
  Box,
  Breadcrumbs,
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import SVButton from "components/SVButton";
import AdminInput from "components/adminInput";
import { color } from "theme/color";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { IncludeInPlanSelect, InsuranceSelect } from "./insuranceSelect";
import { profileDetail } from "store/slice/profile";
import {
  createInsurance,
  getEliminationPeriod,
  getInflationTypes,
  getInsuranceDetails,
  getInsuranceYear,
  getTaxesList,
  updateInsurance,
} from "store/slice/assets";

export default function LongTermInsurance() {
  let screenType = "false";
  const dispatch = useDispatch();
  const { id } = useParams();
  const { insuranceId } = useParams();
  const { profileDetailData } = useSelector((state) => state.profile);
  const {
    insuranceDetails,
    insuranceYearsData,
    taxesListData,
    inflationTypesData,
    eliminationPeriodsData,
  } = useSelector((state) => state.assets);
  const [isLoading, setIsloading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
    var requestParams = {
      id: id,
    };
    dispatch(profileDetail(requestParams, true));
    dispatch(getInsuranceYear());
    dispatch(getInflationTypes());
    dispatch(getTaxesList());
    dispatch(getEliminationPeriod());
  }, []);
  const [longTermData, setLongTermData] = useState({});

  //  get insurance details
  useEffect(() => {
    if (insuranceId) {
      setIsloading(true);
      dispatch(getInsuranceDetails(insuranceId, setIsloading));
    }
  }, [insuranceId]);
  useEffect(() => {
    if (insuranceDetails) {
      setLongTermData(insuranceDetails);
    }
  }, [insuranceDetails]);
  const handleSubmit = () => {
    if (validateEmpty(longTermData?.policy_name)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        policy_nameErr: "Please enter policy name",
      }));
      return;
    }
    if (validateEmpty(longTermData?.purchase_year)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        purchase_yearErr: "Please select purchase year",
      }));
      return;
    }

    if (validateEmpty(longTermData?.annual_premium)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        annual_premiumErr: "Please enter annual premium",
      }));
      return;
    }
    if (validateEmpty(longTermData?.premium_ends_id)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        premium_ends_idErr: "Please select premium ends",
      }));
      return;
    }

    if (validateEmpty(longTermData?.daily_benefit)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        daily_benefitErr: "Please enter daily benefit",
      }));
      return;
    }
    if (validateEmpty(longTermData?.benefit_inflation)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        benefit_inflationErr: "Please enter benefit inflation",
      }));
      return;
    }
    if (validateEmpty(longTermData?.benefit_period_id)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        benefit_period_idErr: "Please select benefit period",
      }));
      return;
    }
    if (longTermData?.benefit_taxable == undefined) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        benefit_taxableErr: "Please select benefit taxable",
      }));
      return;
    }

    if (longTermData?.included_in_plan == undefined) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        included_in_planErr: "Please select included in plan",
      }));
      return;
    }
    if (validateEmpty(longTermData?.premium_inflation)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        premium_inflationErr: "Please enter premium inflation",
      }));
      return;
    }
    if (validateEmpty(longTermData?.premium_tax_id)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        premium_tax_idErr: "Please select premium tax",
      }));
      return;
    }
    if (validateEmpty(longTermData?.inflation_type_id)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        inflation_type_idErr: "Please select inflation type",
      }));
      return;
    }
    if (validateEmpty(longTermData?.elimination_period_id)) {
      setLongTermData((longTermData) => ({
        ...longTermData,
        elimination_period_idErr: "Please select elimination period",
      }));
      return;
    }
    setIsloading(true);
    let request = {
      user_id: id,
      policy_name: longTermData?.policy_name,
      owner: profileDetailData?.first_name,
      purchase_year: longTermData?.purchase_year,
      benefit_inflation: longTermData?.benefit_inflation,
      daily_benefit: longTermData?.daily_benefit,
      annual_premium: longTermData?.annual_premium,
      premium_tax_id: longTermData?.premium_tax_id,
      type_id: INSURANCET_TYPES?.LONG_TERM,
      benefit_period_id: longTermData?.benefit_period_id,
      premium_ends_id: longTermData?.premium_ends_id,
      included_in_plan: longTermData?.included_in_plan,
      benefit_taxable: longTermData?.benefit_taxable,
      inflation_type_id: longTermData?.inflation_type_id,
      elimination_period_id: longTermData?.elimination_period_id,
      premium_inflation: longTermData?.premium_inflation,
    };
    if (insuranceId) {
      dispatch(
        updateInsurance(request, insuranceId, id, navigate, setIsloading)
      );
    } else {
      dispatch(createInsurance(request, id, navigate, setIsloading));
    }
  };
  return (
    <div>
      <Spinner open={isLoading} />
      <div>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Insurance"} />

        <Grid container mt={4} sx={{ px: isMobile ? 2 : "" }}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} to="/users">
                Users
              </Link>
              <Link style={{ color: "white" }} to={`/profile/${id}`}>
                User Profile
              </Link>
              <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
                Assets-Liabilities Details
              </Link>
              <Link
                style={{ color: "white" }}
                to={`/assetsDetail/insurance/${id}`}
              >
                Insurance
              </Link>
              <Typography sx={{ color: "white" }}>Long Term</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box
          sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 2 }}
        >
          <h2
            className="textLinearBackground mt-3 "
            style={{ marginLeft: screenType === "mobile" ? "1rem" : "" }}
          >
            Long Term
          </h2>
          <Grid container columnSpacing={3}>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Policy name"
                maxLength={30}
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    policy_name: event.target.value,
                    policy_nameErr: "",
                  }));
                }}
                value={longTermData?.policy_name || ""}
                formError={longTermData?.policy_nameErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                name="occupation"
                title="Name"
                value={profileDetailData?.first_name || ""}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <InsuranceSelect
                title="Purchase year"
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    purchase_year: event.target.value,
                    purchase_yearErr: "",
                  }));
                }}
                value={longTermData?.purchase_year || ""}
                error={longTermData?.purchase_yearErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Annual premium"
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setLongTermData((longTermData) => ({
                      ...longTermData,
                      annual_premium: event.target.value * 1,
                      annual_premiumErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setLongTermData((longTermData) => ({
                        ...longTermData,
                        annual_premium: "",
                      }));
                    }
                  }
                }}
                value={longTermData?.annual_premium || ""}
                formError={longTermData?.annual_premiumErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <InsuranceSelect
                title={" Premium ends"}
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    premium_ends_id: event.target.value,
                    premium_ends_idErr: "",
                  }));
                }}
                value={longTermData?.premium_ends_id || ""}
                error={longTermData?.premium_ends_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                maxLength={13}
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Daily benefit "
                value={longTermData?.daily_benefit || ""}
                formError={longTermData?.daily_benefitErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setLongTermData((longTermData) => ({
                      ...longTermData,
                      daily_benefit: event.target.value * 1,
                      daily_benefitErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setLongTermData((longTermData) => ({
                        ...longTermData,
                        daily_benefit: "",
                      }));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Benefit inflation"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={longTermData?.benefit_inflation || ""}
                formError={longTermData?.benefit_inflationErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setLongTermData((longTermData) => ({
                        ...longTermData,
                        benefit_inflation: 100,
                        benefit_inflationErr: "",
                      }));
                    } else {
                      setLongTermData((longTermData) => ({
                        ...longTermData,
                        benefit_inflation: event.target.value * 1,
                        benefit_inflationErr: "",
                      }));
                    }
                  } else {
                    setLongTermData((longTermData) => ({
                      ...longTermData,
                      benefit_inflationErr: "",
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Benefit period"
                customOptions={insuranceYearsData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    benefit_period_id: event.target.value,
                    benefit_period_idErr: "",
                  }));
                }}
                value={longTermData?.benefit_period_id || ""}
                error={longTermData?.benefit_period_idErr}
              />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Benefit taxable"
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    benefit_taxable: event.target.value,
                    benefit_taxableErr: "",
                  }));
                }}
                value={
                  longTermData?.benefit_taxable == true ||
                  longTermData?.benefit_taxable == false
                    ? longTermData?.benefit_taxable
                    : ""
                }
                error={longTermData?.benefit_taxableErr}
              />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    included_in_plan: event.target.value,
                    included_in_planErr: "",
                  }));
                }}
                value={
                  longTermData?.included_in_plan == true ||
                  longTermData?.included_in_plan == false
                    ? longTermData?.included_in_plan
                    : ""
                }
                error={longTermData?.included_in_planErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Premium inflation"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={longTermData?.premium_inflation||''}
                formError={longTermData?.premium_inflationErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setLongTermData((longTermData) => ({
                        ...longTermData,
                        premium_inflation: 100,
                        premium_inflationErr: "",
                      }));
                    } else {
                      setLongTermData((longTermData) => ({
                        ...longTermData,
                        premium_inflation: event.target.value * 1,
                        premium_inflationErr: "",
                      }));
                    }
                  } else {
                    setLongTermData((longTermData) => ({
                      ...longTermData,
                      premium_inflation: "",
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Premium tax"
                customOptions={taxesListData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    premium_tax_id: event.target.value,
                    premium_tax_idErr: "",
                  }));
                }}
                value={longTermData?.premium_tax_id||''}
                error={longTermData?.premium_tax_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Inflation type"
                customOptions={inflationTypesData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    inflation_type_id: event.target.value,
                    inflation_type_idErr: "",
                  }));
                }}
                value={longTermData?.inflation_type_id||''}
                error={longTermData?.inflation_type_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Elimination period"
                customOptions={eliminationPeriodsData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setLongTermData((longTermData) => ({
                    ...longTermData,
                    elimination_period_id: event.target.value,
                    elimination_period_idErr: "",
                  }));
                }}
                value={longTermData?.elimination_period_id||''}
                error={longTermData?.elimination_period_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3} mt={5}>
              <SVButton fullWidth text={"Submit"} onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
