import React, { useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
// import { getTicketList } from "../../../store/slice/profile";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
import "./index.css";
// import moment from "moment";
import AdminInput from "../../../components/adminInput";
import moment from "moment";
import useStyles from "./style";
import EditUpdateDialog from "./sections/editUpdateDialog";
import AlertDialog from "components/alertDialog/alertDialog";
import profilePic from "../../../assets/img/default.jpg";
import SVButton from "components/SVButton";
import {
  ekycApproveRejectApi,
  eKycDetail,
  getEKycList,
} from "store/slice/profile";
import SVRejectDialog from "components/SVRejectDialog";
import { errorToast } from "components/showToast";
import TopHeading from "components/topHeading";
import { Helmet } from "react-helmet-async";
import { PAGINATION } from "utils/appConstant";
import { useParams } from "react-router-dom";

export default function index() {
  var searchInputRef = useRef();
  let page = PAGINATION.page;
  const { id } = useParams();
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  var classes = useStyles();
  const [pageCheck, setPageCheck] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const { totalCount, isLoading, eKYCListData, eKycDetailData } = useSelector(
    (state) => state.profile
  );
  const dispatch = useDispatch();
  const [rejcectReasonDialog, setRejcectReasonDialog] = useState(false);
  const [openUpdateKyc, setOpenUpdateKyc] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});

  const getEkycDetail = (id) => {
    setOpenUpdateKyc(true);
    var data = {
      id: id,
    };
    dispatch(eKycDetail(data, true));
  };

  const closeReasonDialog = () => {
    setFormError({});
    setFormData({});
    setRejcectReasonDialog(false);
    setOpenUpdateKyc(false);
  };

  const fetchEKYCList = (page, numberOfRows, search) => {
    var data = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: search ? search : searchValue,
      userId: id ? id * 1 : null,
    };
    let isSearch = searchValue ? true : false;
    dispatch(getEKycList(data, isSearch, true));
  };

  const ekycApproveReject = (type) => {
    let id = eKycDetailData.id;
    if (type === "reject") {
      if (!formData?.rejectingReason) {
        return errorToast("Please enter reason of rejection");
      }
    }
    var data = {
      changeRequestId: id,
      status: type === "approve" ? "approved" : "rejected",
      rejectReason: formData?.rejectingReason ? formData?.rejectingReason : "",
    };
    dispatch(
      ekycApproveRejectApi(
        data,
        true,
        setRejcectReasonDialog,
        setOpenUpdateKyc,
        setFormData
      )
    );
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleClick = (tableMeta, event) => {
  //   setAnchorEl(event.currentTarget);
  //   setSelectedRowIndex(tableMeta.rowIndex);
  // };

  useEffect(() => {
    fetchEKYCList();
  }, []);

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },

    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        getEKycList(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage - 1;
        }

        setPageCheck(currentPage);
        fetchEKYCList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      getEKycList(page, numberOfRows, "", "");
    },
  };

  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: page ?? 0,
      perPage: perPageRows,
      search: "",
      status: "",
    };
    let isSearch = false;
    dispatch(getEKycList(data, isSearch, true));
  };

  const columns = [
    {
      name: "fullPath",
      label: "Profile",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <img
                src={value ? value : profilePic}
                width={30}
                height={30}
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Grid>
          );
        },
      },
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },
    {
      name: "surename",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "key",
      label: "e-Kyc Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : ""}</Typography>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Requested Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography className={classes.ellipseText}>
              <Typography>{moment(value).format("MMMM Do, YYYY")}</Typography>
            </Typography>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          setSelectedRowIndex(tableMeta?.rowIndex);
          let id = eKYCListData[tableMeta?.rowIndex]?.id;
          return (
            <>
              <SVButton onClick={() => getEkycDetail(id)} text="Update e-KYC" />
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <>
        {" "}
        <Helmet>
          <title> E-Kyc </title>
        </Helmet>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"E-Kyc Requests"} />
        <Grid
          spacing={2}
          container
          sx={{ pt: 1, pb: 1, mt: 2, p: isMobile ? 1 : "" }}
        >
          <Grid item xs={isMobile ? 12 : 4}>
            <AdminInput
              sx={{ mt: -1, background: "#202020" }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  fetchEKYCList(page);
                }
              }}
              color="white"
              inputRef={searchInputRef}
              title="Search By Name"
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value) {
                  fetchEKYCList(page, "", e.target.value);
                } else {
                  fetchEKYCList(page, "", "");
                }
              }}
              value={searchValue}
            />
          </Grid>
        </Grid>
        <br />
        <div className="App wrapper">
          <>
            {eKYCListData && eKYCListData.length > 0 ? (
              <Grid container>
                <Grid xs={12}>
                  <MUIDataTable
                    data={eKYCListData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid #202020",
                    mt: 2,
                    height: 300,
                    backgroundColor: "#202020",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: 500,
                        mt: "7.5rem",
                        color: "white",
                      }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
          {openUpdateKyc && (
            <EditUpdateDialog
              openDialog={openUpdateKyc}
              closeEditDialog={() => setOpenUpdateKyc(false)}
              eKycDetailData={eKycDetailData}
              selectedRowIndex={selectedRowIndex}
              onSave={() => ekycApproveReject("approve")}
              onReject={() => setRejcectReasonDialog(true)}
            />
          )}

          {rejcectReasonDialog && (
            <SVRejectDialog
              open={rejcectReasonDialog}
              title="Warning"
              text="Please specify the reason for rejecting this user."
              handleClose={closeReasonDialog}
              cameFrom="rejectReason"
              handleAgree={() => {
                ekycApproveReject("reject");
              }}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              setFormError={setFormError}
            />
          )}

          {openAlertDialog && (
            <AlertDialog
              open={openAlertDialog}
              handleClose={() => setOpenAlertDialog(false)}
              title={"Delete Confirmation!"}
              text={"Are you sure you want to delete this ticket?"}
            />
          )}
        </div>
      </>

      <Spinner open={isLoading} />
    </>
  );
}
