import { Button } from "@mui/material";
import React from "react";

const SVOutlinedButton = (props) => {
    const { fullWidth,textTransform,border, text, onClick, sx, height, disabled, textColor,hoverTextColor, hoverColor} = props;
    return (
        <Button
            fullWidth={fullWidth}
            type="submit"
            disabled={disabled}
            variant="outlined"
            sx={{
                minWidth:80,
                fontSize: 15,
                textTransform:textTransform?textTransform:"none",
                color: textColor ? textColor : "#333333",
                backgroundColor: "#FFFFFF",
                border:border?border:"1px solid #333333",
                height: height ? height : 45,
                ...sx,
                ":hover": {
                    color: hoverTextColor ? hoverTextColor : "#333333",
                    backgroundColor: hoverColor ? hoverColor : "#ffffff"

                },
            }}
            onClick={onClick}
        >
            {text}
        </Button>
    );
}
export default React.memo(SVOutlinedButton)