import React from "react";
import { Box } from "@mui/material";
import { IoIosAddCircleOutline } from "react-icons/io";

const SVDocumentView = ({ ...props }) => {

    const { onClick } = props;
    return (

        <Box
            sx={{
                width: 100,
                height: 100,
                background: "white",
                borderRadius: 1,
                border: "1px solid #BFC1BD",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            onClick={onClick} >

            <IoIosAddCircleOutline fontSize={35} style={{ color: "#BFC1BD" }} />
        </Box>
    )

}

export default React.memo(SVDocumentView)