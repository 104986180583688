import { Icon } from "@iconify/react";
import { Card, Grid, Typography } from "@mui/material";
import SVDivider from "components/SVDivider";
import React from "react";
import { color } from "theme/color";
import { currencyFormat } from "utils/currencyFormatter";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { INSURANCET_TYPES } from "utils/appConstant";
import { useNavigate, useParams } from "react-router-dom";
import { clearInsuranceDetails } from "store/slice/assets";
export default function InsuranceCard(props) {
  const {
    data,
    key,
    setShowInsuranceDetail,
    setInsuranceDetailData,
    setInsuranceDeleteDialog,
    isMobile
  } = props;
  const { insuranceData } = useSelector((state) => state.assets);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  return (
    <>
      <Grid key={key} item xs={isMobile ? 12 : 6}>
        <Card
          sx={{
            background: color.topbar,
            borderRadius: 2,
            p: 2,
          }}
        >
          <Grid container columnSpacing={1}>
            <Grid item xs={1.5} sx={{ display: "flex", alignItems: "center" }}>
              <Icon color="grey" icon="ph:bank" width={50} />
            </Grid>

            <Grid item xs={6.5}>
              <Typography className="heading">
                {data?.type_id == INSURANCET_TYPES?.TERM_LIFE
                  ? "Death benefit"
                  : data?.type_id == INSURANCET_TYPES?.AUTO
                    ? " Bodily injury pa"
                    : data?.type_id == INSURANCET_TYPES?.INDIVIDUAL_DISABILITY
                      ? " Monthly benefit"
                      : data?.type_id == INSURANCET_TYPES?.LONG_TERM
                        ? " Daily benefit"
                        : data?.type_id == INSURANCET_TYPES?.WHOLE_LIFE
                          ? "Death benefit"
                          : "Coverage Amount"}
              </Typography>
              <Typography className="info-title-text">
                {data?.type_id == INSURANCET_TYPES?.TERM_LIFE
                  ? data?.death_benefit
                    ? currencyFormat(data?.death_benefit)
                    : 0
                  : data?.type_id == INSURANCET_TYPES?.AUTO
                    ? data?.bodily_injury_pa
                      ? currencyFormat(data?.bodily_injury_pa)
                      : 0
                    : data?.type_id == INSURANCET_TYPES?.INDIVIDUAL_DISABILITY
                      ? data?.monthly_benefit
                        ? currencyFormat(data?.monthly_benefit)
                        : 0
                      : data?.type_id == INSURANCET_TYPES?.LONG_TERM
                        ? data?.daily_benefit
                          ? currencyFormat(data?.daily_benefit)
                          : 0
                        : data?.type_id == INSURANCET_TYPES?.WHOLE_LIFE
                          ? data?.death_benefit
                            ? currencyFormat(data?.death_benefit)
                            : 0
                          : data?.coverage_amount
                            ? currencyFormat(data?.coverage_amount)
                            : 0}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", alignItems: "center", gap: 3 }}
            >
              <Icon
                icon="carbon:view"
                color="white"
                width={30}
                onClick={() => {
                  setShowInsuranceDetail(true);
                  setInsuranceDetailData(data);
                }}
                style={{ cursor: "pointer" }}
              />
              <Icon
                icon="uil:edit"
                color="white"
                width={30}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(clearInsuranceDetails());
                  if (data?.type_id == INSURANCET_TYPES?.TERM_LIFE) {
                    navigate(
                      `/assetsDetail/insurance/term-life-insurance/edit/${id}/${data?.insurance_id}`
                    );
                  }
                  if (data?.type_id == INSURANCET_TYPES?.UMBRELLA) {
                    navigate(
                      `/assetsDetail/insurance/umbrella-insurance/edit/${id}/${data?.insurance_id}`
                    );
                  }
                  if (data?.type_id == INSURANCET_TYPES?.WHOLE_LIFE) {
                    navigate(
                      `/assetsDetail/insurance/whole-life-insurance/edit/${id}/${data?.insurance_id}`
                    );
                  }
                  if (data?.type_id == INSURANCET_TYPES?.LONG_TERM) {
                    navigate(
                      `/assetsDetail/insurance/long-term-insurance/edit/${id}/${data?.insurance_id}`
                    );
                  }
                  if (data?.type_id == INSURANCET_TYPES?.AUTO) {
                    navigate(
                      `/assetsDetail/insurance/auto-insurance/edit/${id}/${data?.insurance_id}`
                    );
                  }
                  if (
                    data?.type_id == INSURANCET_TYPES?.INDIVIDUAL_DISABILITY
                  ) {
                    navigate(
                      `/assetsDetail/insurance/individual-disability-insurance/edit/${id}/${data?.insurance_id}`
                    );
                  }
                }}
              />
              <Icon
                icon="mingcute:delete-2-fill"
                color="red"
                width={30}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setInsuranceDeleteDialog(true);
                  setInsuranceDetailData(data);
                }}
              />
            </Grid>
          </Grid>
          <SVDivider style={{ border: "0.1px solid #707070" }} />
          <Grid container sx={{ mt: 2 }} diplay="flex">
            <Grid item xs={12} mt={2}>
              <Typography className="info-heading"> User Name</Typography>
              <Typography className="info-text ">
                {data?.owner ? data?.owner : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography className="info-heading">Insurance Type</Typography>
              <Typography className="info-text ">
                {insuranceData?.length > 0 &&
                  insuranceData[
                    insuranceData?.findIndex((item) => item.id == data.type_id)
                  ]?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography className="info-heading">Policy Name</Typography>
              <Typography className="info-text ">
                {data?.policy_name ? data?.policy_name : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography className="info-heading">Created</Typography>
              <Typography className="info-text ">
                {data?.type?.createdDate
                  ? moment(data?.type?.createdDate).format("DD/MM/YYYY")
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}
