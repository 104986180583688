import {
  Avatar,
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  editUpdateProfile,
  financialAccountDetailsApi,
  listOfNotesApi,
  transactionListApi,
} from "../../../store/slice/profile";
import React, { useEffect, useRef, useState } from "react";
import { color } from "theme/color";
import profilePic from "assets/img/default.jpg";
import AdminButton from "components/adminButton";
import { Link, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import ssn from "assets/img/ssn.png";
import { useDispatch, useSelector } from "react-redux";
import {
  profileDetail,
  suspendAccountProfile,
  stateList,
  updateUserDetails,
  sendOtpForProfile,
  verifyOtpForProfile,
  estateDetailApi,
  goalsDetailApi,
  netWorthSummary,
  clearNetWorthDetails,
  clearGoalDetailData,
  clearProfileDetailData,
  createNotesApi,
  // assetsSummary,
  // liabilitiesSummary,
} from "store/slice/profile";
import { getSiteSetting, getDocumentDataTypes } from "store/slice/settings";

import moment from "moment";
import AlertDialog from "components/alertDialog/alertDialog";
import "./index.css";
import {
  PAGINATION,
  PERMISSION_IDENTIFIER,
  SAVE_PROFILE_KEYS,
  TEAM_MEMBER_ROLE,
  USER_STAUTS,
} from "utils/appConstant";
import BalancView from "./sections/balancView";
import EditUpdateProfile from "./sections/editUpdateProfile";
import SVConfirmDialog from "components/SVConfirmDialog";
import OtpView from "./sections/otpView";
import { SILVERBACK } from "services/apiConstant";
import SVRejectDialog from "components/SVRejectDialog";
import { adminDetailData, validateEmpty, validEmail } from "utils/constantFun";
import { useTheme } from "@emotion/react";
import CardDetails from "./sections/cardDetails";
import Reward from "./sections/reward";
import SetGoals from "./sections/setGoals";
import Spinner from "components/spinner";
import { api } from "services";
import SVDivider from "components/SVDivider";
import AdminInput from "components/adminInput";
import PhoneInput from "react-phone-input-2";
import { MESSAGES } from "utils/appMessages";
import SVSelect from "components/SVSelect";
import MUIDataTable from "mui-datatables";
import CloseIcon from "@mui/icons-material/Close";
import SVButton from "components/SVButton";
import { useNavigate } from "react-router-dom";
import UserActivity from "./sections/userActivity";

// import Chart from "react-apexcharts";

const ProfileDetail = () => {
  const navigate = useNavigate();
  let page = PAGINATION.page;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const [openDialog, setOpenDialog] = useState(false);
  const [notesDialog, setNotesDialog] = useState(false);

  const [fieldToEdit, setFieldToEdit] = useState(null);
  const [editValue, setEditValue] = useState("");
  const param = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { profileId } = useParams();
  //selectors
  const {
    isLoading,
    profileDetailData,
    cardDetailData,
    stateListData,
    updateUserDetailsData,
    estateDetailData,
    goalsDetailData,
    netWorthSummaryData,
    notesListData,
    totalCount,
    financialAccountData,
    transactionListData,
    // transactionTotalCount,
    // assetsSummaryData,
    // liabilitiesSummaryData
  } = useSelector((state) => state.profile);

  const { siteSettingData } = useSelector((state) => state.settings);
  const { adminPorfileDetailData } = useSelector((state) => state.profile);
  const bannerImage = useRef(null);
  const [image, setImage] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState("");

  //states
  const [alertDialogRemoveOpenForSuspend, setAlertDialogRemoveOpenForSuspend] =
    useState(false);
  const [alertDialogRemoveOpen, setAlertDialogRemoveOpen] = useState(false);
  const [rejcectReasonDialog, setRejcectReasonDialog] = useState(false);
  const [formData, setFormData] = useState({});
  const [phoneField, setPhoneField] = useState();
  const [otp, setOtp] = useState("");
  const [alertForReactive, setAlertForReactive] = useState(false);
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [editProfile, setEditProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    ssn: "",
    address: "",
    phone: "",
    countryFlag: "",
    countryCode: "",
    postalCode: "",
    city: "",
    state: "",
    editFirstName: false,
    editLastName: false,
    editDOB: false,
    editSSN: false,
    editPhone: false,
    editEmail: false,
    editAddress: false,
    editCity: false,
    editState: false,
    editPostalCode: false,
  });

  const inputRef = useRef({});

  //const [profileData, setProfileData] = useState({});
  const [formError, setFormError] = useState({});
  const [showConfirmProfileDialog, setShowConfirmProfileDialog] =
    useState(false);
  const [confirmProfileType, setConfirmProfileType] = useState({
    type: "",
    title: "",
    message: "",
  });
  const [otpDialog, setOtpDialog] = useState(false);
  const [otpProfileType, setOtpProfileType] = useState({
    type: "",
    title: "",
    message: "",
  });
  const [alertForApproved, setAlertForApproved] = useState(false);

  //array to create document
  const [ssnDocuments, setSSNDocuments] = useState([]);
  const [dobDocuments, setDOBDocuments] = useState([]);

  const clearStateNetWorth = () => {
    dispatch(clearNetWorthDetails());
    dispatch(clearGoalDetailData());
    dispatch(clearProfileDetailData());
  };

  const topFiveNotes = notesListData?.slice(0, 2);

  // MUI Data Table options and columns
  const columns = [
    {
      name: "message",
      label: "Message",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },
    {
      name: "timestamp",
      label: "Timestamp",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: () => {
          return (
            <Grid>
              <AdminButton
                sx={{
                  background: "#1098DB",
                  fontSize: 12,
                }}
                title={"Change Status"}
              />
            </Grid>
          );
        },
      },
    },
    // Add more columns as needed
  ];

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      //setPageCheck(currentPage);
      listOfNotes(currentPage, "", "", "");
    },

    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      listOfNotes(page, numberOfRows, "", "");
    },
  };
  // Handle dialog open and close
  // const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => setOpenNotes(false);

  useEffect(() => {
    listOfNotes();
    financialAccountDetails();
    var data = {
      userId: param?.profileId,
    };
    dispatch(netWorthSummary(data));
  }, []);

  useEffect(() => {
    if (financialAccountData) {
      var data = {
        financialAccountId: financialAccountData[0]?.financialAccountId,
        fromDate: "",
        toDate: "",
        page: 0,
        perPage: 10,
        userId: param?.profileId,
      };
      dispatch(transactionListApi(data));
    }
  }, [financialAccountData]);

  const financialAccountDetails = () => {
    dispatch(financialAccountDetailsApi(param?.profileId));
  };

  // Handler for document upload
  const handleFileChange = (event) => {
    const formData = new FormData();
    let file = event.target.files[0];
    formData.append("file", file);
    formData.append("entityId", siteSettingData?.ENTITY_TYPE_BANNER);
    formData.append("moduleId", siteSettingData?.MODULE_TYPE_ADVERTISE);
    formData.append("typeId", siteSettingData?.DOCUMENT_TYPE_BANNER);
    uploadMedia(formData);
  };

  useEffect(() => {
    var data = {
      userId: param?.profileId,
    };
    dispatch(goalsDetailApi(data, true));
  }, []);

  useEffect(() => {
    var data = {
      userId: param?.profileId,
    };
    dispatch(estateDetailApi(data));
  }, []);

  useEffect(() => {
    getCustomerProfile();
    dispatch(stateList());
    //fetch site settigns
    dispatch(getSiteSetting());
    //fetch documents
    dispatch(getDocumentDataTypes());
  }, []);

  useEffect(() => {
    setShowConfirmProfileDialog(false);
    setEditProfile((editProfile) => ({
      ...editProfile,
      editFirstName: false,
      editLastName: false,
      editDOB: false,
      editSSN: false,
      editPhone: false,
      editEmail: false,
      editAddress: false,
      editCity: false,
      editState: false,
      editPostalCode: false,
    }));
  }, [updateUserDetailsData]);

  const uploadMedia = (formDataRequest, docType) => {
    api
      .post(SILVERBACK.uploadDocuments, formDataRequest, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("response", response);
        setImage(response?.data?.data?.fullPath);
        let imageData = response.data.data;
        if (docType === SAVE_PROFILE_KEYS.ssn) {
          setSSNDocuments((ssnDocuments) => [...ssnDocuments, imageData]);
        } else if (docType === SAVE_PROFILE_KEYS.dateOfBirth) {
          setDOBDocuments((dobDocuments) => [...dobDocuments, imageData]);
        }
      })
      .catch(() => {});
  };

  const handleFileEvent = (event, type) => {
    const formData = new FormData();
    // for (let i = 0; i < event.target.files.length; i++) {
    //   formData.append("files", event.target.files[i]);
    // }
    let file = event.target.files[0];
    formData.append("file", file);
    if (type === SAVE_PROFILE_KEYS.dateOfBirth) {
      formData.append("entityId", siteSettingData?.ENTITY_TYPE_DOB);
      formData.append("moduleId", siteSettingData?.MODULE_TYPE_USER);
      formData.append("typeId", siteSettingData?.DOCUMENT_TYPE_DOB);
    }
    if (type === SAVE_PROFILE_KEYS.ssn) {
      formData.append("entityId", siteSettingData?.ENTITY_TYPE_DOB);
      formData.append("moduleId", siteSettingData?.MODULE_TYPE_USER);
      formData.append("typeId", siteSettingData?.DOCUMENT_TYPE_SSN);
    }
    uploadMedia(formData, type);
  };

  const handlePendingRequest = () => {
    setAlertDialogRemoveOpen(true);
  };

  const approvedClick = () => {
    var requestParams = {
      userId: profileDetailData?.id,
      status: "approved",
      rejectReason: "",
    };
    dispatch(
      suspendAccountProfile(requestParams, true, setAlertDialogRemoveOpen)
    );
  };

  const closeReasonDialog = () => {
    setFormData({});
    setRejcectReasonDialog(false);
  };

  useEffect(() => {
    if (profileDetailData) {
      setEditProfile((editProfile) => ({
        ...editProfile,
        firstName: profileDetailData?.first_name ?? "",
        lastName: profileDetailData?.surename ?? "",
        email: profileDetailData?.email ?? "",
        dateOfBirth: profileDetailData?.dateOfBirth ?? "",
        ssn: profileDetailData?.ssn ?? "",
        address: profileDetailData?.address ?? "",
        phone: profileDetailData?.phone ?? "",
        countryFlag: profileDetailData?.countryFlag ?? "",
        countryCode: profileDetailData?.countryCode ?? "",
        postalCode: profileDetailData?.postalCode ?? "",
        city: profileDetailData?.locality ?? "",
        state: profileDetailData?.state ?? "",
      }));

      setPhoneField({
        phone: profileDetailData?.phone,
        countryCode: profileDetailData?.countryCode,
        countryFlag: profileDetailData?.countryFlag,
        value: profileDetailData?.countryCode + profileDetailData?.phone,
      });
    }
  }, [profileDetailData]);

  const rejectClick = () => {
    var requestParams = {
      userId: profileId,
      status: "rejected",
      rejectReason: formData?.rejectingReason,
    };
    dispatch(
      suspendAccountProfile(
        requestParams,
        true,
        setRejcectReasonDialog,
        setAlertDialogRemoveOpen,
        setFormData
      )
    );
  };

  const handlePhoneInput = (value) => {
    setPhoneField({
      phone: value?.phone.slice(value?.dial?.dialCode.length),
      countryCode: value?.dial?.dialCode,
      countryFlag: value?.dial?.countryCode,
      value: value?.phone,
    });
  };

  const handleAccountForSuspend = () => {
    setAlertDialogRemoveOpenForSuspend(true);
  };
  const handleReactiveAccount = () => {
    setAlertForReactive(true);
  };
  const suspendAccountClick = () => {
    var requestParams = {
      userId: profileId,
      status: "suspend",
    };
    dispatch(
      suspendAccountProfile(
        requestParams,
        true,
        setAlertDialogRemoveOpenForSuspend
      )
    );
  };

  const listOfNotes = (page, numberOfRows) => {
    var requestParams = {
      page: page ?? 0,
      user_id: profileId,
      perPage: numberOfRows ? numberOfRows : perPageRows,
    };
    dispatch(listOfNotesApi(requestParams, true));
  };

  const reactiveAccountClick = () => {
    var requestParams = {
      userId: profileId,
      status: "approved",
      rejectReason: "",
    };
    dispatch(suspendAccountProfile(requestParams, true, setAlertForReactive));
  };

  const approvedRejectedAccount = () => {
    var requestParams = {
      userId: profileId,
      status: "approved",
      rejectReason: "",
    };
    dispatch(
      suspendAccountProfile(requestParams, true, setAlertDialogRemoveOpen)
    );
  };

  /***Method will fetch customer profile data from server */
  const getCustomerProfile = () => {
    var requestParams = {
      id: profileId,
    };
    dispatch(profileDetail(requestParams, true));
  };

  const checkProfileAndShowConfirm = (type) => {
    if (type === SAVE_PROFILE_KEYS.firstName) {
      //show confirm dialog
      if (!editProfile?.firstName) {
        setFormError((formError) => ({
          ...formError,
          firstName: "Please enter first name",
        }));
        return;
      }
      setShowConfirmProfileDialog(true);
      setConfirmProfileType((confirmProfileType) => ({
        ...confirmProfileType,
        type: SAVE_PROFILE_KEYS.firstName,
        title: "Confirm",
        message: "Are you sure, you want to update customer first name?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.lastName) {
      setShowConfirmProfileDialog(true);
      setConfirmProfileType((confirmProfileType) => ({
        ...confirmProfileType,
        type: SAVE_PROFILE_KEYS.lastName,
        title: "Confirm",
        message: "Are you sure, you want to update customer last name?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.ssn) {
      setShowConfirmProfileDialog(true);

      setConfirmProfileType((confirmProfileType) => ({
        ...confirmProfileType,
        type: SAVE_PROFILE_KEYS.ssn,
        title: "Confirm",
        message: "Are you sure, you want to update customer SSN?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.email) {
      var data = {
        userId: profileDetailData?.id,
        verificationType: SAVE_PROFILE_KEYS?.email,
        phone: "",
        email: editProfile?.email,
      };
      dispatch(sendOtpForProfile(data, setOtpDialog));
      setOtpProfileType((otpProfileType) => ({
        ...otpProfileType,
        type: SAVE_PROFILE_KEYS.email,
        title: "Confirm",
        message: "Are you sure, you want to update customer email?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.phoneNumber) {
      var requestParam = {
        userId: profileDetailData?.id,
        verificationType: SAVE_PROFILE_KEYS?.phoneNumber,
        email: "",
        phone: phoneField?.phone,
      };
      dispatch(sendOtpForProfile(requestParam, setOtpDialog));
      setOtpProfileType((otpProfileType) => ({
        ...otpProfileType,
        type: SAVE_PROFILE_KEYS.phoneNumber,
        title: "Confirm",
        message: "Are you sure, you want to update customer phone number?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.dateOfBirth) {
      setShowConfirmProfileDialog(true);
      setConfirmProfileType((confirmProfileType) => ({
        ...confirmProfileType,
        type: SAVE_PROFILE_KEYS.dateOfBirth,
        title: "Confirm",
        message: "Are you sure, you want to update customer date of birth?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.streetAddress) {
      setShowConfirmProfileDialog(true);
      setConfirmProfileType((confirmProfileType) => ({
        ...confirmProfileType,
        type: SAVE_PROFILE_KEYS.streetAddress,
        title: "Confirm",
        message: "Are you sure, you want to update customer address?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.city) {
      setShowConfirmProfileDialog(true);
      setConfirmProfileType((confirmProfileType) => ({
        ...confirmProfileType,
        type: SAVE_PROFILE_KEYS.city,
        title: "Confirm",
        message: "Are you sure, you want to update customer city?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.state) {
      setShowConfirmProfileDialog(true);
      setConfirmProfileType((confirmProfileType) => ({
        ...confirmProfileType,
        type: SAVE_PROFILE_KEYS.state,
        title: "Confirm",
        message: "Are you sure, you want to update customer state?",
      }));
    } else if (type === SAVE_PROFILE_KEYS.postalCode) {
      setShowConfirmProfileDialog(true);
      setConfirmProfileType((confirmProfileType) => ({
        ...confirmProfileType,
        type: SAVE_PROFILE_KEYS.postalCode,
        title: "Confirm",
        message: "Are you sure, you want to update customer postal code?",
      }));
    }
  };

  const handleOpenDialog = (field, currentValue) => {
    setFieldToEdit(field);
    setEditValue(currentValue);
    setOpenDialog(true);
    setFormError({ value: "" }); // Reset errors when dialog is opened
  };

  const openNotesDialog = () => {
    setNotesDialog(true);
    setFormError({ notes: "" }); // Reset errors when dialog is opened
  };

  const closeNotesDialog = () => {
    setNotesDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const createNotes = () => {
    if (notes === "") {
      setFormError({ notes: "Please enter notes" });
      return;
    }

    var data = {
      to_user_id: profileDetailData?.id,
      message: notes,
    };
    dispatch(createNotesApi(data, true));
    closeNotesDialog();
  };

  const handleSave = () => {
    // Add your API call here to save the updated value
    if (fieldToEdit === "phone") {
      // Validate phone input
      if (!phoneField.value || phoneField.value.length < 10) {
        setFormError({ value: "Please enter a valid phone number." });
        return;
      }
    }

    if (fieldToEdit === "email") {
      if (validateEmpty(editValue)) {
        setFormError((formError) => ({
          ...formError,
          formError: MESSAGES.LOGIN.emptyEmail,
        }));
        return;
      }

      if (!validateEmpty(editValue) && !validEmail(editValue)) {
        setFormError((formError) => ({
          ...formError,
          formError: MESSAGES.LOGIN.validEmail,
        }));
        return;
      }
    }

    if (fieldToEdit === "address") {
      if (editProfile?.city === "") {
        setFormError({ city: "Please enter a city." });
        return;
      }
      if (editProfile?.postalCode === "") {
        setFormError({ postalCode: "Please enter a postal code." });
        return;
      }
      if (editProfile?.state === "") {
        setFormError({ state: "Please select state." });
        return;
      }
      if (editProfile?.address === "") {
        setFormError({ address: "Please fill the street address." });
        return;
      }
    }

    console.log(`Saving ${fieldToEdit}: ${editValue}`);
    var data = {
      first_name: "",
      surename: "",
      address: editProfile?.address,
      phone: phoneField?.phone,
      countryCode: phoneField?.countryCode,
      countryFlag: phoneField?.countryFlag,
      dateOfBirth: "",
      postalCode: editProfile?.postalCode,
      locality: editProfile?.city,
      state: editProfile?.state,
      profilePic: image,
    };
    dispatch(editUpdateProfile(data, true));
    handleCloseDialog();
  };

  //Save Profile
  const saveProfileData = (type) => {
    if (
      type === SAVE_PROFILE_KEYS.email ||
      type === SAVE_PROFILE_KEYS.phoneNumber
    ) {
      if (otp == "") {
        setFormError({
          ...formError,
          otp: "Please enter OTP",
        });
        return;
      }
      var otpData = {
        userId: profileDetailData?.id,
        verificationType:
          type === SAVE_PROFILE_KEYS.phoneNumber ? "phone" : "email",
        otp: otp,
      };
      //pass functions to call on success
      dispatch(
        verifyOtpForProfile(
          otpData,
          type,
          setOtpDialog,
          saveCustomerProfileData
        )
      );
      setOtp("");
    } else {
      saveCustomerProfileData(type);
    }
  };

  //saving customer profile to DB.
  const saveCustomerProfileData = (type) => {
    if (
      type === SAVE_PROFILE_KEYS.streetAddress ||
      SAVE_PROFILE_KEYS.city ||
      SAVE_PROFILE_KEYS.state ||
      SAVE_PROFILE_KEYS.postalCode
    ) {
      type = SAVE_PROFILE_KEYS.streetAddress;
    }
    let request = {
      userId: profileId,
      key: type,
      value: {
        first_name: editProfile?.firstName,
        surename: editProfile?.lastName,
        dateOfBirth: editProfile?.dateOfBirth,
        phone: editProfile?.phone,
        email: editProfile?.email,
        ssn: editProfile?.ssn,
        address: editProfile?.address,
        postalCode: editProfile?.postalCode,
        locality: editProfile?.city,
        state: editProfile?.state,
        moduleId: siteSettingData.MODULE_TYPE_USER,
        documentIds: [0],
        entityId: 0,
      },
    };
    let documentID = [];
    if (type == SAVE_PROFILE_KEYS.ssn) {
      for (let data of ssnDocuments) {
        let id = data.documentId;
        documentID.push(id);
      }
      request.value.documentIds = documentID;
      request.value.entityId = siteSettingData.ENTITY_TYPE_SSN;
    } else if (type === SAVE_PROFILE_KEYS.dateOfBirth) {
      for (let data of dobDocuments) {
        let id = data.documentId;
        documentID.push(id);
      }
      request.value.documentIds = documentID;
      request.value.entityId = siteSettingData.ENTITY_TYPE_DOB;
    }
    dispatch(updateUserDetails(request));
  };

  return (
    <div>
      <>
        {" "}
        <Grid container sx={{ p: 3, mt: -5 }}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{ color: "white" }}
                onClick={clearStateNetWorth}
                to={"/users"}
              >
                Users
              </Link>
              <Typography sx={{ color: "white" }}>User Profile</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container sx={{ p: 3, mt: -4 }}>
          <Grid
            xs={isMobile ? 12 : 12}
            sx={{
              backgroundColor: color.black,
              color: color.main,

              borderRadius: 2,
              p: 2,
            }}
          >
            <Grid container>
              <Grid item xs={isMobile ? 12 : 3}>
                <Grid container>
                  <Grid item>
                    <img
                      src={
                        profileDetailData?.fullPath
                          ? profileDetailData?.fullPath
                          : profilePic
                      }
                      width={120}
                      height={120}
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    {adminDetailData?.roleId ===
                      adminPorfileDetailData?.user_permissions[
                        PERMISSION_IDENTIFIER.USERS
                      ] >=
                        2 ||
                      (TEAM_MEMBER_ROLE.SUPERADMIN_ROLE && (
                        <Grid>
                          {profileDetailData?.status ===
                            USER_STAUTS.PENDING_REVIEW && (
                            <>
                              <Grid container>
                                <Grid item>
                                  <AdminButton
                                    sx={{
                                      background: "#1098DB",
                                      fontSize: 12,
                                    }}
                                    title={"Pending Request"}
                                    onClick={handlePendingRequest}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Typography
                                  sx={{ color: "grey", mt: 1, fontSize: 10 }}
                                >
                                  <span style={{ color: color.main }}>
                                    Note:
                                  </span>{" "}
                                  Request an open account. Please select the
                                  Pending Request option to approve or reject.{" "}
                                </Typography>
                              </Grid>
                            </>
                          )}

                          {profileDetailData?.status ===
                            USER_STAUTS.APPROVED && (
                            <>
                              <Grid container>
                                <Grid item>
                                  <AdminButton
                                    disabled={
                                      profileDetailData?.status === "suspend"
                                        ? true
                                        : false
                                    }
                                    sx={{ background: "red", fontSize: 12 }}
                                    onClick={handleAccountForSuspend}
                                    title={"Suspend Account"}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}

                          {profileDetailData?.status ===
                            USER_STAUTS.SUSPENDED && (
                            <>
                              <Grid container>
                                <Grid item>
                                  <AdminButton
                                    sx={{ background: "green", fontSize: 12 }}
                                    onClick={handleReactiveAccount}
                                    title={"Reactive Account"}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}

                          {profileDetailData?.status ===
                            USER_STAUTS.REJECTED && (
                            <>
                              <Grid container spacing={2}>
                                <Grid item display="flex">
                                  <AdminButton
                                    sx={{
                                      background: "#4BA64F",
                                      fontSize: 12,
                                    }}
                                    onClick={() => setAlertForApproved(true)}
                                    title={"Approve"}
                                  />
                                </Grid>
                                <Grid item display="flex">
                                  <AdminButton
                                    sx={{
                                      background: "#87ceeb",
                                      fontSize: 12,
                                    }}
                                    onClick={() => {
                                      setOpenEditProfile(true);
                                    }}
                                    title={"Edit Details"}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid
                      container
                      display={"flex"}
                      spacing={1}
                      sx={{ pb: 1 }}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          className="textLinearBackground"
                          sx={{ fontSize: 22 }}
                        >
                          <b>
                            {profileDetailData?.first_name
                              ? profileDetailData?.first_name
                              : ""}{" "}
                            {profileDetailData?.surename
                              ? profileDetailData?.surename
                              : ""}
                          </b>
                        </Typography>
                      </Grid>
                      {profileDetailData?.status === USER_STAUTS.APPROVED && (
                        <Grid item sx={{ mt: 0.5 }}>
                          <Icon
                            icon="pepicons-pop:checkmark-filled-circle-filled"
                            color={color.green}
                            width={20}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Grid container spacing={1} alignItems="center">
                      <Grid item alignItems="center" display="flex">
                        <Icon
                          icon="clarity:date-line"
                          width="20px"
                          height="20px"
                        />
                      </Grid>
                      <Grid item>
                        <Typography>
                          {moment(profileDetailData?.dateOfBirth).format(
                            "MMMM Do, YYYY"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center">
                      <Grid item alignItems="center" display="flex">
                        <Icon
                          icon="pepicons-pencil:phone-circle"
                          width="18px"
                          height="18px"
                        />
                      </Grid>
                      <Grid item>
                        <Typography>
                          {profileDetailData?.phone ?? ""}
                        </Typography>
                      </Grid>
                      <Grid item alignItems="center" display="flex">
                        <IconButton
                          onClick={() =>
                            handleOpenDialog("phone", profileDetailData?.phone)
                          }
                        >
                          <Icon
                            icon="material-symbols:edit"
                            width="20px"
                            height="20px"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      sx={{ mt: "-1rem" }}
                    >
                      <Grid item alignItems="center" display="flex">
                        <Icon
                          icon="material-symbols-light:mail-outline"
                          width="20px"
                          height="20px"
                        />
                      </Grid>
                      <Grid item>
                        <Typography>
                          {profileDetailData?.email ?? ""}
                        </Typography>
                      </Grid>
                      <Grid item alignItems="center" display="flex">
                        <IconButton
                          onClick={() =>
                            handleOpenDialog("email", profileDetailData?.email)
                          }
                        >
                          <Icon
                            icon="material-symbols:edit"
                            width="20px"
                            height="20px"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center">
                      <Grid item alignItems="center" display="flex">
                        <img src={ssn} alt="SSN" width="20px" height="20px" />
                      </Grid>
                      <Grid item>
                        <Typography>{profileDetailData?.ssn ?? ""}</Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center">
                      <Grid item alignItems="center" display="flex">
                        <Icon
                          icon="carbon:location"
                          width="20px"
                          height="20px"
                        />
                      </Grid>
                      <Grid item>
                        <Typography sx={{ color: "primary.main" }}>
                          {profileDetailData?.address}{" "}
                          {profileDetailData?.locality}{" "}
                          {profileDetailData?.stateName}{" "}
                          {profileDetailData?.postalCode}
                        </Typography>
                      </Grid>
                      <Grid item alignItems="center" display="flex">
                        <IconButton
                          onClick={() =>
                            handleOpenDialog(
                              "address",
                              profileDetailData?.address
                            )
                          }
                        >
                          <Icon
                            icon="material-symbols:edit"
                            width="20px"
                            height="20px"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={isMobile ? 12 : 4}>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid
                      container
                      display={"flex"}
                      spacing={1}
                      sx={{ pb: 1 }}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <Typography
                          className="textLinearBackground"
                          sx={{ fontSize: 22 }}
                        >
                          <b>Notes</b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={isMobile ? 12 : ""}
                        sx={{ textAlign: isMobile ? "center" : "" }}
                      >
                        <SVButton
                          onClick={openNotesDialog}
                          text="Add Notes"
                          sx={{
                            borderRadius: 10,
                            fontSize: isMobile && 10,
                            height: 32,
                            p: 2,
                          }}
                        />
                      </Grid>
                    </Grid>
                    {notesListData?.length > 0 ? (
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        sx={{ mb: 2 }}
                      >
                        {topFiveNotes?.map((note) => (
                          <Grid item key={note.id}>
                            <Typography
                              variant="body2"
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                              }}
                            >
                              {note.message}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {moment(note?.timestamp).format("MMMM Do, YYYY")}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="28vh" // Adjust the height as needed
                        textAlign="center"
                      >
                        <Typography variant="h6" color="textSecondary">
                          No notes found
                        </Typography>
                      </Box>
                    )}
                    {notesListData?.length > 0 && (
                      <Box display={"flex"} justifyContent={"center"}>
                        <SVButton
                          onClick={() =>
                            navigate(`/profile/notes/${param?.profileId}`)
                          }
                          text={"View All"}
                          sx={{
                            borderRadius: 10,
                            fontSize: isMobile && 10,
                            height: 32,

                            p: 2,
                          }}
                        />
                      </Box>
                    )}
                    <Dialog
                      PaperProps={{
                        style: {
                          backgroundColor: "#202020",
                        },
                      }}
                      open={openNotes}
                      onClose={handleCloseNotes}
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogTitle sx={{ m: 0, p: 2 }}>
                        <Typography variant="h6" style={{ color: color.main }}>
                          All Notes
                        </Typography>
                      </DialogTitle>{" "}
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseNotes}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <SVDivider style={{ marginTop: 10 }} />
                      <DialogContent
                        sx={{
                          overflowY: "auto", // Ensure content can scroll
                          scrollbarWidth: "none", // Hide scrollbar in Firefox
                          msOverflowStyle: "none", // Hide scrollbar in Internet Explorer and Edge
                          "&::-webkit-scrollbar": {
                            display: "none", // Hide scrollbar in WebKit browsers
                          },
                        }}
                      >
                        <MUIDataTable
                          data={notesListData}
                          columns={columns}
                          options={options}
                        />
                      </DialogContent>
                    </Dialog>

                    {/* Dialog for notes dialog for add notes */}
                    <Dialog
                      fullWidth
                      scroll="paper"
                      open={notesDialog}
                      onClose={closeNotesDialog}
                    >
                      <DialogTitle>Add Notes</DialogTitle>
                      <SVDivider />
                      <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <AdminInput
                              autoFocus
                              margin="dense"
                              title="Notes"
                              type="text"
                              fullWidth
                              value={notes}
                              onChange={(e) => {
                                let val = e.target.value;
                                if (val) {
                                  setNotes(e.target.value);
                                  setFormError((formError) => ({
                                    ...formError,
                                    notes: "",
                                  }));
                                } else {
                                  if (val) {
                                    setFormError((formError) => ({
                                      ...formError,
                                      notes: "",
                                    }));
                                  }
                                }
                              }}
                              variant="outlined"
                              formError={formError.notes}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <AdminButton
                          onClick={closeNotesDialog}
                          title={"Cancel"}
                          sx={{ fontSize: 14 }}
                          fullWidth={true}
                        />

                        <AdminButton
                          onClick={createNotes}
                          title={"Save"}
                          sx={{ fontSize: 14 }}
                          fullWidth={true}
                        />
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Dialog for editing fields */}
        <Dialog
          fullWidth
          scroll="paper"
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>Edit {fieldToEdit}</DialogTitle>
          <SVDivider />
          <DialogContent>
            {fieldToEdit === "phone" && (
              <Grid item xs={12}>
                <PhoneInput
                  country={"us"} // Default country
                  value={phoneField?.value || ""}
                  onChange={(phone, dial) => {
                    handlePhoneInput({ phone, dial });
                    if (phoneField?.value !== "") {
                      setFormError((formError) => ({
                        ...formError,
                        editValue: "",
                      }));
                    }
                  }}
                />
                <Typography sx={{ color: "red", p: 1 }}>
                  {formError?.editValue}
                </Typography>
              </Grid>
            )}

            {fieldToEdit === "email" && (
              <AdminInput
                autoFocus
                margin="dense"
                title={`Edit ${fieldToEdit}`}
                type="text"
                fullWidth
                value={editValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setEditValue(value);
                  if (value) {
                    setFormError({ value: "" });
                  }
                }}
                variant="outlined"
                formError={formError.formError}
              />
            )}

            {fieldToEdit === "address" && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AdminInput
                    autoFocus
                    margin="dense"
                    title="Street Address"
                    type="text"
                    fullWidth
                    value={editProfile?.address}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          address: e.target.value,
                        }));
                        setFormError((formError) => ({
                          ...formError,
                          address: "",
                        }));
                      } else {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          address: "",
                        }));
                      }
                    }}
                    variant="outlined"
                    formError={formError.address}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AdminInput
                    title="City"
                    type="text"
                    fullWidth
                    value={editProfile?.city}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          city: e.target.value,
                        }));
                        setFormError((formError) => ({
                          ...formError,
                          city: "",
                        }));
                      } else {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          city: "",
                        }));
                      }
                    }}
                    variant="outlined"
                    formError={formError.city}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SVSelect
                    fullWidth="true"
                    value={editProfile?.state}
                    label="State"
                    options={stateListData}
                    emptyText="Choose State"
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          state: e.target.value,
                        }));
                        setFormError((formError) => ({
                          ...formError,
                          state: "",
                        }));
                      } else {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          state: "",
                        }));
                      }
                    }}
                    formError={formError?.state}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AdminInput
                    title="Postal Code"
                    type="text"
                    fullWidth
                    value={editProfile?.postalCode}
                    onChange={(e) => {
                      let val = e.target.value;
                      const numeric = /^[0-9\b]+$/;
                      if (val && numeric.test(val)) {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          postalCode: e.target.value,
                        }));
                        setFormError((formError) => ({
                          ...formError,
                          postalCode: "",
                        }));
                      } else {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          postalCode: "",
                        }));
                      }
                    }}
                    formError={formError?.postalCode}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid item xs={12} mt={2}>
                    {image ? (
                      <Box
                        sx={{
                          height: "auto",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <Avatar
                          onClick={() => {
                            setImage("");
                            setFormData((formData) => ({
                              ...formData,
                              document: "",
                            }));
                          }}
                          sx={{
                            height: 25,
                            width: 25,
                            background: "white",
                            position: "absolute",
                            top: 5,
                            right: 5,
                            cursor: "pointer",
                          }}
                        >
                          <Icon
                            icon={"gridicons:cross-circle"}
                            color="red"
                            width={28}
                          />
                        </Avatar>

                        <img
                          src={image}
                          alt=""
                          width={"100%"}
                          height={"100%"}
                          style={{ objectFit: "cover" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        onClick={() => bannerImage.current.click()}
                        sx={{
                          border: "2px dashed #ffffffb8",

                          height: 180,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          ref={bannerImage}
                          type="file"
                          style={{ display: "none" }}
                          className="imageUpload"
                          accept="image/*"
                          multiple
                          onChange={handleFileChange}
                        />
                        <Box
                          sx={{
                            display: "grid",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography>
                            <Icon
                              icon={"zondicons:add-outline"}
                              color="grey"
                              width={40}
                            />
                          </Typography>
                          <Typography
                            style={{ color: "grey", mt: 2, fontSize: 20 }}
                          >
                            Upload Document
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {/* {formData?.documentErr && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 12,
                  ml: 1,
                  mt: 1,
                }}
              >
                {formData?.documentErr}
              </Typography>
            )} */}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <AdminButton
              onClick={handleCloseDialog}
              title={"Cancel"}
              sx={{ fontSize: 14 }}
              fullWidth={true}
            />

            <AdminButton
              onClick={handleSave}
              title={"Save"}
              sx={{ fontSize: 14 }}
              fullWidth={true}
            />
          </DialogActions>
        </Dialog>
        {profileDetailData?.status === USER_STAUTS.PENDING_REVIEW ? (
          <></>
        ) : (
          <>
            {" "}
            <Grid container sx={{ p: 3, mt: -3 }}>
              <Grid
                container
                sx={{ background: color.black, p: 3, borderRadius: 1.2 }}
              >
                <Grid item xs={12}>
                  <BalancView
                    transactionListData={transactionListData}
                    profileId={profileId}
                    financialAccountId={
                      financialAccountData &&
                      financialAccountData[0]?.financialAccountId
                    }
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Reward />
                </Grid>

                <Grid item xs={12} sx={{ mb: 2 }}>
                  <CardDetails
                    username="Admin"
                    profileDetailData={profileDetailData}
                    cardDetailData={cardDetailData}
                  />
                </Grid>

                <Grid item xs={12} sx={{ mb: 2 }}>
                  <SetGoals
                    netWorthSummaryData={netWorthSummaryData}
                    estateDetailData={estateDetailData}
                    goalsDetailData={goalsDetailData}
                    profileDetailData={profileDetailData}
                  />
                </Grid>

                <Grid item xs={12} sx={{ mb: 2 }}>
                  <UserActivity cardDetailData={cardDetailData} />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>

      {alertDialogRemoveOpen && (
        <SVConfirmDialog
          openDialog={alertDialogRemoveOpen}
          title={"Pending Request"}
          message={
            "Do you want to approve this account please click on approve button or if you want to reject this account click on reject button"
          }
          onClose={() => setAlertDialogRemoveOpen(false)}
          handleDisagree={() => setRejcectReasonDialog(true)}
          handleAgree={() => {
            approvedClick();
          }}
          cancelButtonText="Reject"
          agreeButtonText="Approve"
        />
      )}
      {alertDialogRemoveOpenForSuspend && (
        <AlertDialog
          open={alertDialogRemoveOpenForSuspend}
          title="Warning"
          text="Are you sure you want to suspend this account?"
          handleClose={() => {
            setAlertDialogRemoveOpenForSuspend(false);
          }}
          cameFrom="suspend"
          handleAgree={() => {
            suspendAccountClick();
          }}
        />
      )}
      {rejcectReasonDialog && (
        <SVRejectDialog
          open={rejcectReasonDialog}
          title="Warning"
          text="Please specify the reason for rejecting this user."
          handleClose={closeReasonDialog}
          cameFrom="rejectReason"
          handleAgree={() => {
            rejectClick();
          }}
          formData={formData}
          setFormData={setFormData}
          formError={formError}
          setFormError={setFormError}
        />
      )}
      {alertForReactive && (
        <AlertDialog
          open={alertForReactive}
          title="Warning"
          text="Are you sure you want to re-active this account?"
          handleClose={() => {
            setAlertForReactive(false);
          }}
          cameFrom="review"
          handleAgree={() => {
            reactiveAccountClick();
          }}
        />
      )}
      {alertForApproved && (
        <AlertDialog
          open={alertForApproved}
          title="Warning"
          text="Are you sure you want to approve this account?"
          handleClose={() => {
            setAlertForApproved(false);
          }}
          cameFrom="approvedRejectedAccount"
          handleAgree={() => {
            approvedRejectedAccount();
          }}
        />
      )}
      {showConfirmProfileDialog && (
        <SVConfirmDialog
          openDialog={showConfirmProfileDialog}
          title={confirmProfileType.title}
          message={confirmProfileType.message}
          onClose={() => setShowConfirmProfileDialog(false)}
          handleDisagree={() => setShowConfirmProfileDialog(false)}
          handleAgree={() => {
            saveProfileData(confirmProfileType.type);
          }}
          cancelButtonText="Not Now"
          agreeButtonText="Yes, Confirm!"
        />
      )}
      {otpDialog && (
        <OtpView
          openDialog={otpDialog}
          title={otpProfileType?.title}
          message={otpProfileType?.message}
          onClose={() => {
            setOtp("");
            setOtpDialog(false);
          }}
          handleDisagree={() => {
            setOtp("");
            setOtpDialog(false);
          }}
          handleAgree={() => {
            saveProfileData(otpProfileType?.type);
          }}
          cancelButtonText="Not Now"
          agreeButtonText="Yes, Confirm!"
          setFormError={setFormError}
          formError={formError?.otp}
          otp={otp}
          setOtp={setOtp}
        />
      )}
      {openEditProfile && (
        <EditUpdateProfile
          openDialog={openEditProfile}
          closeEditDialog={() => setOpenEditProfile(false)}
          editProfile={editProfile}
          phoneField={phoneField}
          setEditProfile={setEditProfile}
          setFormError={setFormError}
          formError={formError}
          stateListData={stateListData}
          handlePhoneInput={handlePhoneInput}
          onSave={(type) => {
            checkProfileAndShowConfirm(type);
          }}
          isLoading={isLoading}
          onDocumentChange={handleFileEvent}
          ssnDocumentsList={ssnDocuments}
          dobDocumentsList={dobDocuments}
          inputRef={inputRef}
        />
      )}
      <Spinner open={isLoading} />
    </div>
  );
};

export default ProfileDetail;
