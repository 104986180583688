import moment from "moment";
import secureLocalStorage from "react-secure-storage";

export const path = window.location;

export const searchFromJson = (fromArray, what) => {
  let foundObject = fromArray.find((element) => element.name === what);
  return foundObject;
};

/***check valid number */
export const isValidNumber = (str) => {
  const pattern = /^[0-9\b]+$/;
  return pattern.test(str);
};

export const validEmail = (val) => {
  const pattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  return pattern.test(val);
};

export const validPassword = (val) => {
  const pattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return pattern.test(val);
};

export const validateEmpty = (str) => {
  return !str || str.length === 0;
};

/**** Local Image Path url
 * @param filePath - file pick by user
 */
export const localImageURL = (filePath) => {
  return URL.createObjectURL(filePath);
};

//delete items from array
export const deleteItem = (itemData, idx) => {
  return itemData.filter((item) => item.uId !== idx);
};

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};

export const todayDate = () => {
  return moment(new Date()).format("yyyy-MM-DD");
};

export const currentTime = () => {
  return moment(new Date()).format("HH:mm");
};

export const getUserInfo = () => {
  let userData = secureLocalStorage.getItem(
    process.env.REACT_APP_USER_STORAGE_KEY
  );
  return userData;
};

export const capitalizeFirstLetter = (string) => {
  let val = string.replace(/_/g, " ");
  const toTitleCase = val.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
  return toTitleCase;
};
/**Conver html into plain string */
export const htmlToText = (val) => {
  return val.replace(/<[^>]+>/g, "");
};

export const getUserName = () => {
  let userData = secureLocalStorage.getItem(
    process.env.REACT_APP_USER_STORAGE_KEY
  );
  return userData ? userData.name : "";
};

export const diffDays = (lastDate) => {
  var startDate = Date.parse(lastDate);
  var endDate = Date.parse(todayDate());
  var timeDiff = Math.abs(startDate - endDate);
  var result = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return result;
};

/**Convert date time according to event calender below format*
 * new Date(2023, 7, 2, 8, 30, 0),
 * @author : Expertinasia Pvt. Ltd.
 */
export const convertUTCToLocalTime = (eventDate) => {
  var localTime = moment.utc(eventDate).toDate();
  let day = moment(localTime, "YYYY/MM/DD").date();
  let month = moment(localTime, "YYYY/MM/DD").month();
  let year = moment(localTime, "YYYY/MM/DD").year();
  let hours = moment(localTime, "YYYY/MM/DD HH:mm").hours();
  let minutes = moment(localTime, "YYYY/MM/DD HH:mm").minutes();
  localTime = new Date(year, month, day, hours, minutes, 0);
  return localTime;
};

let loginData = secureLocalStorage.getItem("adminDetail");
export const adminDetailData = JSON.parse(loginData);

function isEmptyValue(value) {
  if (value == null) return true; // null or undefined
  if (typeof value === "string" && value.trim() === "") return true; // empty string
  if (typeof value === "number" && value === 0) return true; // number zero
  // if (typeof value === 'boolean' && value === false) return true; // boolean false
  if (Array.isArray(value) && value.length === 0) return true; // empty array
  if (typeof value === "object" && Object.keys(value).length === 0) return true; // empty object
  return false;
}

export const areAllKeysEmpty = (obj) => {
  return Object.values(obj).every(isEmptyValue);
};

export const onlyNumber = (input) => {
  const regex = /^[0-9]+$/;
  if (regex.test(input)) {
    return true;
  } else {
    return false;
  }
};

