import React, { useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
import AdminInput from "../../../components/adminInput";
import { useEffect } from "react";
import { appColors, color } from "../../../theme/color";
import AdminButton from "../../../components/adminButton";
import { useTheme } from "@emotion/react";
import ViewdetailsDialog from "../../../components/viewdetailsDialog";
import {
  getCustomerList,
  getUserDashboard,
  stateList,
  suspendAccount,
  ticketCategory,
  ticketRaise,
  ticketSubCategory,
} from "../../../store/slice/profile";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
import AlertDialog from "../../../components/alertDialog/alertDialog";
import "./index.css";
import profilePic from "../../../assets/img/default.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import UserDashboard from "./userDashboard/index";
import { SILVERBACK } from "../../../services/apiConstant";
import { api } from "../../../services";
import { toast } from "react-toastify";
import { errorToast } from "components/showToast";
import SVRejectDialog from "components/SVRejectDialog";
import SVConfirmDialog from "components/SVConfirmDialog";
import {
  PAGINATION,
  PERMISSION_IDENTIFIER,
  TEAM_MEMBER_ROLE,
} from "utils/appConstant";
import SVSelect from "components/SVSelect";
import { IoTicketOutline } from "react-icons/io5";
import SVDivider from "components/SVDivider";

export default function User() {
  let page = PAGINATION.page;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);

  const theme = useTheme();
  const location = useLocation();
  const statusData = location?.state?.searchFilter;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [statusFilter, setStatusFilter] = useState(
    statusData ? statusData : ""
  );
  // const [date, setDate] = useState("dd/mm/yyyy");
  const [startDate, setStartDate] = useState("dd/mm/yyyy");
  const [endDate, setEndDate] = useState("dd/mm/yyyy");
  const [dateOfBirth, setDateOfBirth] = useState("dd/mm/yyyy");
  // const [dateOfBirthForSend, setDateOfBirthForSend] = useState("");
  const [stateListing, setStateListing] = useState("");

  const [startDateForSend, setStartDateForSend] = useState("");
  const [endDateForSend, setEndDateForSend] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  // const [pageCheck, setPageCheck] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [viewDialog, setViewDialog] = useState(false);
  const [rejcectReasonDialog, setRejcectReasonDialog] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [csvData, setCsvData] = useState("");
  const [alertDialogRemoveOpen, setAlertDialogRemoveOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [alertForReactive, setAlertForReactive] = useState(false);
  const [alertDialogRemoveOpenForSuspend, setAlertDialogRemoveOpenForSuspend] =
    useState(false);
  // useEffect(() => {
  //   if (statusData) {
  //     var request = {
  //       page: page,
  //       perPage: perPageRows,
  //       search: searchValue,
  //       startDate: "",
  //       dateOfBirth: "",
  //       endDate: "",
  //       userTypeFilter: "",
  //       state: "",
  //       status: statusFilter === "users" ? "" : statusData,
  //     };
  //     let isSearch = searchValue ? true : false;
  //     dispatch(getCustomerList(request, isSearch, true));
  //   }
  // }, [statusData]);
  const {
    customerListData,
    profileDetailData,
    userDashboardData,
    totalCount,
    isLoading,
    stateListData,
    adminPorfileDetailData,
    ticketCategoryData,
    ticketSubCategoryData,
  } = useSelector((state) => state.profile);


  var searchInputRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFormData({});
    setFormData({});
  };

  const handleSave = () => {
    if (formData?.issueType === "") {
      setFormError({ issueType: "Please select issue type" });
      return;
    }
    if (formData?.issueSubType === "") {
      setFormError({ issueSubType: "Please select issue sub type" });
      return;
    }
    if (formData?.typeMessage === "") {
      setFormError({ typeMessage: "Please enter message" });
      return;
    }

    var data = {
      category_id: formData?.issueType,
      sub_category_id: formData?.issueSubType,
      message: formData?.typeMessage,
      userId: customerListData[selectedRowIndex]?.id,
    };
    dispatch(ticketRaise(data, true));
    handleCloseDialog();
  };

  const handleClick = (tableMeta, event) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(tableMeta.rowIndex);
  };
  const handleClose = () => {
    setViewDialog(false);
    setAnchorEl(null);
  };

  const closeReasonDialog = () => {
    setFormError({});
    setFormData({});
    setRejcectReasonDialog(false);
    setAlertDialogRemoveOpen(false);
  };
  const handleChange = (event) => {
    setStatusFilter(event.target.value);
    var data = {
      page: page,
      perPage: perPageRows,
      search: searchValue,
      startDate: "",
      endDate: "",
      status: event.target.value === "users" ? "" : event.target.value,
      userTypeFilter: "",
      state: "",
      dateOfBirth: "",
    };
    let isSearch = searchValue ? true : false;
    dispatch(getCustomerList(data, isSearch, true));
  };

  const exportCsv = () => {
    // setDate("dd/mm/yyyy");
    api
      .get(`${SILVERBACK.downloadCsv}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setCsvData(response?.data?.data?.filePath);
        toast.success(response.data.message);
      })
      .catch(() => {
        ("error");
      });
  };

  const handleStartDate = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD 00:00:00");
    setStartDate(value);
    setStartDateForSend(valDate);
  };
  const handleStateList = (value) => {
    setStateListing(value ? value : "");
    var data = {
      page: page,
      perPage: perPageRows,
      search: searchValue,
      startDate: "",
      dateOfBirth: "",
      endDate: "",
      userTypeFilter: "",
      state: value ? value : "",
      status: statusFilter === "users" ? "" : statusFilter,
    };
    let isSearch = searchValue ? true : false;
    dispatch(getCustomerList(data, isSearch, true));
  };

  const handleDOB = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD");
    // setDateOfBirthForSend(value);
    setDateOfBirth(value === "" ? "dd/mm/yyyy" : value);
    var data = {
      page: page,
      perPage: perPageRows,
      search: searchValue,
      startDate: "",
      dateOfBirth:
        valDate === "dd/mm/yyyy" || valDate === "Invalid date" ? "" : valDate,
      endDate: "",
      userTypeFilter: "",
      state: "",
      status: statusFilter === "users" ? "" : statusFilter,
    };
    let isSearch = searchValue ? true : false;
    dispatch(getCustomerList(data, isSearch, true));
  };

  const handleEndDate = (value, numberOfRows) => {
    const valDate = moment(value).format("YYYY-MM-DD 23:59:59");
    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(
      valDate === "dd/mm/yyyy" || valDate === "Invalid date"
        ? "dd/mm/yyyy"
        : startDate
    );
    var data = {
      page: page,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: searchValue,
      startDate:
        valDate == "Invalid date" || valDate == "" ? "" : startDateForSend,
      endDate: valDate !== "Invalid date" ? valDate : "",
      status: statusFilter === "users" ? "" : statusFilter,
      dateOfBirth: "",
    };
    let isSearch = searchValue ? true : false;
    dispatch(getCustomerList(data, isSearch, true));
  };

  const customerList = (page, numberOfRows) => {
    endDateForSend === "Invalid date" && setEndDateForSend("dd/mm/yyyy");
    var data = {
      page: page,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: searchValue,
      startDate: "",
      endDate: "",
      status: statusFilter
        ? statusFilter === "users"
          ? ""
          : statusFilter
        : statusData || "",
      dateOfBirth: "",
    };
    let isSearch = searchValue ? true : false;
    dispatch(getCustomerList(data, isSearch, true));
  };

  const userDashboard = () => {
    dispatch(getUserDashboard());
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    var data = {
      page: page,
      perPage: perPageRows,
      search: e.target.value,
      startDate: "",
      endDate: "",
      status: "",
      userTypeFilter: "",
      state: "",
      dateOfBirth: "",
    };
    let isSearch = e.target.value ? true : false;
    dispatch(getCustomerList(data, isSearch, true));
  };

  const handleClickOpen = () => {
    let id = customerListData[selectedRowIndex]?.id;
    navigate(`/profile/${id}`);
    setAnchorEl(null);
  };

  const handlePendingRequest = () => {
    setAlertDialogRemoveOpen(true);
    setAnchorEl(null);
  };

  const handleDeleteAccountForSuspend = () => {
    setAlertDialogRemoveOpenForSuspend(true);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (csvData) {
      let path = process.env.REACT_APP_API_URL + "/" + csvData;
      const link = document.createElement("a");
      link.href = path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [csvData]);

  useEffect(() => {
    customerList(page);
    userDashboard();
    dispatch(stateList());
    dispatch(ticketCategory());
  }, []);

  useEffect(() => {
    console.log("formData?.issueType", formData?.issueType);
    if (formData?.issueType) {
      var data = {
        categoryId: formData?.issueType,
      };
      dispatch(ticketSubCategory(data));
    }
  }, [formData?.issueType]);

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      //setPageCheck(currentPage);
      customerList(currentPage, "", "", "");
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      customerList(page, numberOfRows, "", "");
    },
  };

  const handleReactiveAccount = () => {
    setAlertForReactive(true);
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: 0,
      perPage: 10,
      search: "",
      startDate: "",
      endDate: "",
      status: "",
    };
    let isSearch = false;
    dispatch(getCustomerList(data, isSearch, true));
  };

  const approvedAccount = () => {
    let id = customerListData[selectedRowIndex]?.id;
    var requestParams = {
      userId: id,
      status: "approved",
    };
    dispatch(suspendAccount(requestParams, true, setAlertDialogRemoveOpen));
  };
  const reactiveAccountClick = () => {
    let id = customerListData[selectedRowIndex]?.id;
    var requestParams = {
      userId: id,
      status: "approved",
    };
    dispatch(suspendAccount(requestParams, true, setAlertForReactive));
  };

  const rejectAccount = () => {
    if (!formData?.rejectingReason) {
      return errorToast("Please enter reason of rejection");
    }
    let id = customerListData[selectedRowIndex]?.id;
    var requestParams = {
      userId: id,
      status: "rejected",
      rejectReason: formData?.rejectingReason,
    };
    dispatch(
      suspendAccount(
        requestParams,
        true,
        setRejcectReasonDialog,
        setAlertDialogRemoveOpen,
        setFormData
      )
    );
  };

  const accountSuspend = () => {
    let id = customerListData[selectedRowIndex]?.id;
    var requestParams = {
      userId: id,
      status: "suspend",
    };
    dispatch(
      suspendAccount(requestParams, true, setAlertDialogRemoveOpenForSuspend)
    );
  };

  const columns = [
    {
      name: "ssn",
      label: "SSN",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fullPath",
      label: "Profile",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <img
                src={value ? value : profilePic}
                width={30}
                height={30}
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Grid>
          );
        },
      },
    },
    {
      name: "first_name",
      label: "First Name",
      customBodyRender: (value) => {
        return (
          <Typography>
            <b>{value ? "no" : ""}</b>
          </Typography>
        );
      },
    },
    {
      name: "surename",
      label: "Last Name",
    },

    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (params) => {
          const email = params;
          return (
            <Tooltip title={email} arrow>
              <div
                style={{
                  width: 200,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {email}
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "dateOfBirth",
      label: "DOB",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{moment(value).format("MMMM Do, YYYY")}</Typography>
            </Grid>
          );
        },
      },
    },

    {
      name: "createdDate",
      label: "Joining Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{moment(value).format("MMMM Do, YYYY")}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Account Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value === "review" && (
                  <>
                    <span
                      style={{
                        color: color.main,
                        background: "#1098DB",
                        fontSize: 11,
                        padding: 4,
                        borderRadius: "10%",
                      }}
                    >
                      <b>Pending</b>
                    </span>
                  </>
                )}
                {value === "approved" && (
                  <>
                    <span
                      style={{
                        color: color.main,
                        background: "#4BA64F",
                        fontSize: 11,
                        padding: 4,
                        borderRadius: "10%",
                      }}
                    >
                      <b>Active</b>
                    </span>
                  </>
                )}
                {value === "suspend" && (
                  <span
                    style={{
                      color: color.main,
                      background: "#E63C39",
                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b> Suspended</b>
                  </span>
                )}

                {value === "rejected" && (
                  <span
                    style={{
                      color: color.main,
                      background: "#DD7176",

                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b>Rejected</b>
                  </span>
                )}
              </Typography>
            </Grid>
          );
        },
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = customerListData[tableMeta.rowIndex]?.id;
          return (
            <div
              style={{ marginTop: -3, marginBottom: isMobile ? "1rem" : "" }}
            >
              <Icon
                onClick={(event) => handleClick(tableMeta, event)}
                aria-describedby={id}
                icon="ph:dots-three-circle-fill"
                color={appColors.input}
                width={isMobile ? 30 : 30}
                height={isMobile ? 30 : 30}
                style={{ paddingTop: isMobile ? 0 : 2, cursor: "pointer" }}
              />

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleClickOpen()}>
                      <ListItemIcon>
                        <Icon
                          icon="ri:eye-fill"
                          color="#21618C"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="View"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <IoTicketOutline
                          color="#21618C"
                          style={{ fontSize: 25 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => handleOpenDialog()}
                        primary="Create Ticket"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {(adminPorfileDetailData?.roleId ||
                    adminPorfileDetailData?.user_permissions[
                      PERMISSION_IDENTIFIER.USERS
                    ] >= 2) === TEAM_MEMBER_ROLE.SUPERADMIN_ROLE && (
                    <>
                      {customerListData &&
                        customerListData[selectedRowIndex]?.status ==
                          "approved" && (
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => handleDeleteAccountForSuspend()}
                            >
                              <ListItemIcon>
                                <Icon
                                  icon="material-symbols:block"
                                  color="black"
                                  width="25"
                                  height="25"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Suspend Account"
                                style={{ marginLeft: "-1rem" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}

                      {customerListData[selectedRowIndex]?.status ===
                        "suspend" && (
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handleReactiveAccount()}
                          >
                            <ListItemIcon>
                              <Icon
                                icon="fontisto:checkbox-active"
                                color="#1098DB"
                                width="25"
                                height="25"
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Reactive Account"
                              style={{ marginLeft: "-1rem" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                      {customerListData[selectedRowIndex]?.status ===
                        "review" && (
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handlePendingRequest()}
                          >
                            <ListItemIcon>
                              <Icon
                                icon="material-symbols-light:pending-actions"
                                color="#1098DB"
                                width="25"
                                height="25"
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Pending Request"
                              style={{ marginLeft: "-1rem" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </>
                  )}
                </List>
              </Popover>
            </div>
          );
        },
      },
    },
  ];
  return (
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>
      <>
        <UserDashboard userDashboardData={userDashboardData} />
        <Grid spacing={2} container sx={{ pt: 1, pb: 1, mt: 2 }}>
          <Grid item xs={isMobile ? 6 : 4}>
            <AdminInput
              sx={{
                mt: -1,
                background: "#202020",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  customerList(page);
                }
              }}
              color="white"
              inputRef={searchInputRef}
              title="Search Customer/SSN"
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 4}>
            <FormControl
              fullWidth
              sx={{
                mt: -1,
                background: "#202020",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">Filter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusFilter}
                label="Filter"
                onChange={handleChange}
                style={{ color: "white" }}
              >
                <MenuItem value={"users"}>All Users</MenuItem>
                <MenuItem value={"approve"}>Active</MenuItem>
                <MenuItem value={"suspend"}>Suspend</MenuItem>
                <MenuItem value={"rejected"}>Reject</MenuItem>
                <MenuItem value={"review"}>Pending</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={isMobile ? 6 : 4}>
            <SVSelect
              color="white"
              sx={{
                mt: -0.5,
                background: "#202020",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                    color: "white",
                    borderRadius: 0,
                  },
                },
                "& .MuiSelect-select": {
                  color: "white", // Change the color of the text inside the select
                },
              }}
              fullWidth="true"
              value={stateListing || ""}
              label="State"
              options={stateListData}
              emptyText="Choose State"
              onChange={(e) => handleStateList(e.target.value)}
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 3}>
            <AdminInput
              color="white"
              type={"date"}
              title={"Start Date"}
              // min={currnetDate}
              sx={{
                mt: -1,
                pr: 2,
                background: "#202020",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },

                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)", // Change the color of the calendar icon
                },
              }}
              value={startDate || ""}
              onChange={(e) => handleStartDate(e.target.value)}
              readOnly={isMobile}
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 3}>
            <AdminInput
              color="white"
              type={"date"}
              title={"End Date"}
              // min={currnetDate}
              sx={{
                mt: -1,
                pr: 2,
                background: "#202020",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)", // Change the color of the calendar icon
                },
              }}
              value={endDate || ""}
              onChange={(e) => handleEndDate(e.target.value)}
              readOnly={isMobile}
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 3}>
            <AdminInput
              color="white"
              type={"date"}
              title={"DOB"}
              // min={currnetDate}
              sx={{
                mt: -1,
                pr: 2,
                background: "#202020",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)", // Change the color of the calendar icon
                },
              }}
              value={dateOfBirth || ""}
              placeholder="dd/mm/yyyy"
              onChange={(e) => handleDOB(e.target.value)}
              readOnly={isMobile}
            />
          </Grid>

          <Grid
            item
            xs={isMobile ? 6 : 3}
            sx={{
              mt: -3.5,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <AdminButton
              title={"Export"}
              onClick={exportCsv}
              sx={{ background: "#1098DB ", width: 130, height: 52, mt: 2.5 }}
            />
          </Grid>
        </Grid>
      </>

      <br />

      <div className="App wrapper">
        <>
          {customerListData && customerListData.length > 0 ? (
            <Grid container>
              <Grid xs={12}>
                <MUIDataTable
                  data={customerListData}
                  options={options}
                  columns={columns}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid #202020",
                  mt: 2,
                  height: 300,
                  backgroundColor: "#202020",
                }}
              >
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 500,
                      mt: "7.5rem",
                      color: "white",
                    }}
                  >
                    No Record Found
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </div>
      {viewDialog && (
        <>
          <ViewdetailsDialog
            open={viewDialog}
            handleClose={() => {
              setViewDialog(false);
              setAnchorEl(null);
            }}
            data={profileDetailData}
            cameFrom={"customer"}
          />
        </>
      )}
      <Spinner open={isLoading} />

      {alertDialogRemoveOpen && (
        <SVConfirmDialog
          openDialog={alertDialogRemoveOpen}
          title={"Pending Request"}
          message={
            "Do you want to approve this account please click on approve button or if you want to reject this account click on reject button"
          }
          onClose={() => setAlertDialogRemoveOpen(false)}
          handleDisagree={() => setRejcectReasonDialog(true)}
          handleAgree={() => {
            approvedAccount();
            approvedAccount();
          }}
          cancelButtonText="Reject"
          agreeButtonText="Approve"
        />
      )}
      {alertDialogRemoveOpenForSuspend && (
        <AlertDialog
          open={alertDialogRemoveOpenForSuspend}
          title="Warning"
          text="Are you sure you want to suspend this account?"
          handleClose={() => {
            setAlertDialogRemoveOpenForSuspend(false);
          }}
          cameFrom="suspend"
          handleAgree={() => {
            accountSuspend();
          }}
        />
      )}

      {alertForReactive && (
        <AlertDialog
          open={alertForReactive}
          title="Warning"
          text="Are you sure you want to re-active this account?"
          handleClose={() => {
            setAlertForReactive(false);
          }}
          cameFrom="reactive"
          handleAgree={() => {
            reactiveAccountClick();
          }}
        />
      )}

      {rejcectReasonDialog && (
        <SVRejectDialog
          open={rejcectReasonDialog}
          title="Warning"
          text="Please specify the reason for rejecting this user."
          handleClose={closeReasonDialog}
          cameFrom="rejectReason"
          handleAgree={() => {
            rejectAccount();
          }}
          formData={formData}
          setFormData={setFormData}
          formError={formError}
          setFormError={setFormError}
        />
      )}

      {/* Dialog for editing fields */}
      <Dialog
        fullWidth
        scroll="paper"
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Ticket Raise</DialogTitle>
        <SVDivider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SVSelect
                fullWidth="true"
                value={formData?.issueType ?? ""}
                label="Issue Type"
                options={ticketCategoryData}
                emptyText="Issue Type"
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) {
                    setFormData((formData) => ({
                      ...formData,
                      issueType: e.target.value,
                    }));
                    setFormError((formError) => ({
                      ...formError,
                      issueType: "",
                    }));
                  } else {
                    setFormData((formData) => ({
                      ...formData,
                      issueType: "",
                    }));
                  }
                }}
              />
              <Typography sx={{ color: "red", p: 1 }}>
                {formError?.issueType}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SVSelect
                fullWidth="true"
                value={formData?.issueSubType ?? ""}
                label="Issue Sub Type"
                options={ticketSubCategoryData}
                emptyText="Issue Sub Type"
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) {
                    setFormData((formData) => ({
                      ...formData,
                      issueSubType: e.target.value,
                    }));
                    setFormError((formError) => ({
                      ...formError,
                      issueSubType: "",
                    }));
                  } else {
                    setFormData((formData) => ({
                      ...formData,
                      issueSubType: "",
                    }));
                  }
                }}
              />
              <Typography sx={{ color: "red", p: 1 }}>
                {formError?.issueSubType}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AdminInput
                multiline
                maxRows={4}
                title="Type Message"
                value={formData?.typeMessage || ""}
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) {
                    setFormData((formData) => ({
                      ...formData,
                      typeMessage: e.target.value,
                    }));
                    setFormError((formError) => ({
                      ...formError,
                      typeMessage: "",
                    }));
                  } else {
                    setFormData((formData) => ({
                      ...formData,
                      typeMessage: "",
                    }));
                  }
                }}
                formError={formError?.typeMessage}
              />{" "}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AdminButton
            onClick={handleCloseDialog}
            title={"Cancel"}
            sx={{ fontSize: 14 }}
            fullWidth={true}
          />

          <AdminButton
            onClick={handleSave}
            title={"Submit"}
            sx={{ fontSize: 14 }}
            fullWidth={true}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
