import {
  Box,
  Breadcrumbs,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdminButton from "components/adminButton";
import GoogleAutoComplete from "components/googleAutoComplete";
import Spinner from "components/spinner";
import TopHeading from "components/topHeading";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { addRealEstate, clearRealEstateDetail, getRealEstateInfo } from "store/slice/assets";
import { color } from "theme/color";
import { currencyFormat } from "utils/currencyFormatter";

export default function RealEstate() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [placeId, setPlaceId] = useState(null);
  const dispatch = useDispatch();
  const { realEstateDetail, addRealEstateData } = useSelector(
    (state) => state.assets
  );
  useEffect(() => {
    if (placeId) {
      setIsLoading(true);
      var data = {
        address: formattedAddress,
        placeId: placeId,
        latitude: selectedLocation?.latitude,
        longitude: selectedLocation?.longitude,
      };
      dispatch(getRealEstateInfo(data, setIsLoading));
    }
  }, [placeId]);

  const hanldeAddRealEstate = () => {
    setIsLoading(true);
    var data = {
      userId: id,
      address: formattedAddress,
      first_name: realEstateDetail?.ownerFirstName,
      last_name: realEstateDetail?.ownerLastName,
      full_name: realEstateDetail?.ownerFullName,
      latitude: realEstateDetail?.latitude,
      longitude: realEstateDetail?.longitude,
      current_value: realEstateDetail?.currentValue,
      actual_value: realEstateDetail?.actualValue,
      placeId: placeId,
    };
    dispatch(addRealEstate(data, setIsLoading));
  };
  return (
    <div>
      <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Real Estate"} />
      <Spinner open={isLoading} />
      <Grid container mt={4}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/users">
              Users
            </Link>
            <Link style={{ color: "white" }} to={`/profile/${id}`}>
              User Profile
            </Link>
            <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
              Assets-Liabilities Details
            </Link>
            <Typography sx={{ color: "white" }}>Add</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <GoogleAutoComplete
          onChangeValue={(value) => {
            setSearchValue(value);
          }}
          handleClearSearch={() => {
            setSearchValue("");
            setFormattedAddress(""); // Clear the formatted_address
            dispatch(clearRealEstateDetail());
          }}
          searchValue={searchValue}
          handlePlaceSelected={(place) => {
            setSearchValue(place?.formatted_address);
            setFormattedAddress(place?.formatted_address);
            setPlaceId(place.place_id); // Set the placeId
            setSelectedLocation({
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            });
          }}
        />
        {realEstateDetail && (
          <Box p={2} backgroundColor="#202020" mt={2} borderRadius={2}>
            <Stack
              direction={"row"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography variant="h6" color={"white"}>
                Property Details
              </Typography>
              <AdminButton
                sx={{ mt: -1, mb: 0 }}
                title={addRealEstateData ? "Added" : "Add"}
                  onClick={() => {
                    if (!addRealEstateData) {
                        hanldeAddRealEstate();
                    }
                  }}
              />
            </Stack>
            <hr />
            <Stack mt={4}>
              <Typography color={"white"}>Owner Full Name</Typography>
              <Typography variant="h6" color={"white"}>
                {" "}
                {realEstateDetail?.ownerFullName
                  ? realEstateDetail?.ownerFullName
                  : "-"}
              </Typography>
            </Stack>
            <Stack mt={2}>
              <Typography color={"white"}> Current Value</Typography>
              <Typography variant="h6" color={"white"}>
                {" "}
                {realEstateDetail?.currentValue
                  ? currencyFormat(realEstateDetail?.currentValue)
                  : 0}
              </Typography>
            </Stack>
            <Stack mt={2}>
              <Typography color={"white"}> Last Sell Value</Typography>
              <Typography variant="h6" color={"white"}>
                {" "}
                {realEstateDetail?.actualValue
                  ? currencyFormat(realEstateDetail?.actualValue)
                  : 0}
              </Typography>
            </Stack>
            <Stack mt={2}>
              <Typography color={"white"}> Address</Typography>
              <Typography variant="h6" color={"white"}>
                {" "}
                {realEstateDetail?.address ? realEstateDetail?.address : "-"}
              </Typography>
            </Stack>
            <Stack mt={2}>
              <Typography color={"white"}> Created</Typography>
              <Typography variant="h6" color={"white"}>
                {" "}
                {moment(realEstateDetail?.timestamp).format("MMMM DD, YYYY")}
              </Typography>
            </Stack>
          </Box>
        )}
      </Box>
    </div>
  );
}
