import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    ellipseText: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width:150
  },
}));

export default useStyles;