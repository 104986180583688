/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../services";
import { SILVERBACK, STATUS_CODES } from "../../services/apiConstant";
import { errorToast, successToast } from "components/showToast";
import toast from "react-hot-toast";

const slice = createSlice({
  name: "estate",
  initialState: {
    estateListData: [],
    estateTotalCount: null,
    userEstateLimit: [],
    managmentRelationshipList: [],
    managmentOptionsData: null,
    roleListData: null,
    pressReleaseData: [],
    totalBlogsCount: null,
    isLoading: false,
    pressReleaseDetail: null,
  },
  reducers: {
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },
    getEstateListSuccess: (state, action) => {
      state.estateListData = action.payload.list;
      state.estateTotalCount = action.payload.total_count;
    },
    getUserEstateLimitSuccess: (state, action) => {
      state.userEstateLimit = action.payload;
    },
    getEstateDetailSuccess: (state, action) => {
      state.estateDetailsData = action.payload.data;
    },
    clearEstateDetailsSuccess: (state, action) => {
      state.estateDetailsData = action.payload;
    },
    getManagmentRelationshipListSuccess: (state, action) => {
      state.managmentRelationshipList = action.payload.data;
    },
    getManagmentOptionsSuccess: (state, action) => {
      state.managmentOptionsData = action.payload;
    },

    clearManagmentDataSuccess: (state, action) => {
      state.managmentOptionsData = action.payload;
    },
    getRoleListSuccess: (state, action) => {
      state.roleListData = action.payload;
    },
    deleteRoleSuccess: (state, action) => {
      state.roleListData = state.roleListData.filter(
        (item) => item.id != action.payload
      );
    },
    getPressReleaseListSuccess: (state, action) => {
      state.isLoading = false;
      if (action.payload.page === 1) {
        state.pressReleaseData = [];
      }
      state.pressReleaseData = [...action.payload.blogs];
      state.totalBlogsCount = action.payload?.total_count;
      state.isLoading = false;
    },
    deletePressReleaseBlogSuccess: (state, action) => {
      state.pressReleaseData = state.pressReleaseData.filter(
        (item) => item.id != action.payload
      );
      state.totalBlogsCount = state.totalBlogsCount - 1;
      state.isLoading = false;
    },
    getPressReleaseDetailsSuccess: (state, action) => {
      state.pressReleaseDetail = action.payload;
    },
    clearPressReleaseDetailsSuccess: (state, action) => {
      state.pressReleaseDetail = action.payload;
    },
  },
});

// ** Actions **
const {
  getEstateListSuccess,
  getUserEstateLimitSuccess,
  getEstateDetailSuccess,
  clearEstateDetailsSuccess,
  getManagmentRelationshipListSuccess,
  getManagmentOptionsSuccess,
  getRoleListSuccess,
  clearManagmentDataSuccess,
  deleteRoleSuccess,
  getPressReleaseListSuccess,
  loadingSuccess,
  getPressReleaseDetailsSuccess,
  deletePressReleaseBlogSuccess,
  clearPressReleaseDetailsSuccess,
} = slice.actions;

// get Estate List
export const getEstateList = (data, setIsLoading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK.getEstateList, data).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getEstateListSuccess(response.data.data));
      } else {
        errorToast(response.data.detail);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// update Site Setting

export const updateSiteSetting =
  (requestParam, setIsLoading) => async (dispatch) => {
    api
      .post(SILVERBACK.updateSiteSetting, requestParam)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          successToast(response?.data?.message);
        } else {
          errorToast("Error", response.detail);
        }
        setIsLoading(false);
      })

      .catch(() => {});
  };
//get User Estate Limit
export const getUserEstateLimit = (id, setIsLoading) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK.getUserEstateLimit}/${id}`).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getUserEstateLimitSuccess(response.data.data.limits));
      } else {
        errorToast(response.data.detail);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// update Estate Limit
export const updateEstateLimit =
  (data, setIsLoading, closeDialog) => async () => {
    try {
      await api.post(SILVERBACK.updateEstateLimit, data).then((response) => {
        if (response.status == STATUS_CODES.SUCCESS) {
          toast.success(response.data.message);
          closeDialog();
        } else {
          errorToast(response.data.detail);
        }
        setIsLoading(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

//get  Estate detail
export const getEstateDetail = (id, setIsLoading) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK.getEstateDetail}/${id}`).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getEstateDetailSuccess(response.data.data));
      } else {
        errorToast(response.data.detail);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// add Estate Document
export const addEstateDocument =
  (id, requestParam, setIsLoading, navigate) => async () => {
    try {
      await api
        .post(`${SILVERBACK.addEstateDocument}/${id}`, requestParam)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            console.log("response", response);
            successToast(response.data.message);
            navigate(`/profile/estateDetail/${id}`);
          } else {
            errorToast(response.data.detail);
          }
          if (setIsLoading) {
            setIsLoading(false);
          }
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

// add Estate Document
export const updateEstateDocument =
  (id, requestParam, setIsLoading, navigate) => async () => {
    try {
      await api
        .post(`${SILVERBACK.updateEstateDocument}`, requestParam)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            console.log("response", response);
            successToast(response.data.message);
            navigate(`/profile/estateDetail/${id}`);
          } else {
            errorToast(response.data.detail);
          }
          if (setIsLoading) {
            setIsLoading(false);
          }
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const clearEstateDetails = () => async (dispatch) => {
  dispatch(clearEstateDetailsSuccess(null));
};
//get ManagmentR elationship List
export const getManagmentRelationshipList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getManagmentRelationshipList).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getManagmentRelationshipListSuccess(response.data.data));
      } else {
        errorToast(response.data.detail);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
//get Managment Options
export const getManagmentOptions = (formType) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK.getManagmentOptions}/${formType}`)
      .then((response) => {
        if (response.status == STATUS_CODES.SUCCESS) {
          dispatch(
            getManagmentOptionsSuccess(response?.data?.data?.fieldOptionsData)
          );
        } else {
          errorToast(response.data.detail);
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};
export const clearManagmentData = () => async (dispatch) => {
  dispatch(clearManagmentDataSuccess(null));
};

//get Managment Options
export const reshareSignatureLink =
  (userMapperId, setIsLoading) => async () => {
    try {
      await api
        .get(`${SILVERBACK.reshareSignatureLink}/${userMapperId}`)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            successToast(response?.data?.message);
          } else {
            errorToast(response.data.detail);
          }
          setIsLoading(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

//  get Role list

export const getRoleList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK.getRoleList).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getRoleListSuccess(response?.data?.data?.roles));
      } else {
        errorToast(response.data.detail);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//  add Role

export const addRole = (request, onClose) => async (dispatch) => {
  try {
    await api.post(SILVERBACK.addRole, request).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        dispatch(getRoleList());
        onClose();
      } else {
        errorToast(response.data.detail);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//deleteRole
export const deleteRole = (id, setOpenAlertDialog) => async (dispatch) => {
  try {
    await api.delete(`${SILVERBACK.deleteRole}/${id}`).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        dispatch(deleteRoleSuccess(id));
      } else {
        errorToast(response.data.detail);
      }
      setOpenAlertDialog(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// getPressReleaseList
export const getPressReleaseList = (data) => async (dispatch) => {
  dispatch(loadingSuccess(true));
  try {
    await api.post(SILVERBACK.getPressReleaseList, data).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(
          getPressReleaseListSuccess({
            ...response.data.data,
            page: data.page,
          })
        );
      } else {
        errorToast(response.data.detail);
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// add Press Release Blog
export const addPressReleaseBlog =
  (data, setIsLoading, navigate) => async () => {
    try {
      await api.post(SILVERBACK.addPressReleaseBlog, data).then((response) => {
        if (response.status == STATUS_CODES.SUCCESS) {
          successToast(response.data.message);
          navigate("/press-release");
        } else {
          errorToast(response.data.detail);
        }
        setIsLoading(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

// delete Press Release Blog

export const deletePressReleaseBlog = (id, setDialog) => async (dispatch) => {
  dispatch(loadingSuccess(true));

  try {
    await api
      .delete(`${SILVERBACK.deletePressReleaseBlog}/${id}`)
      .then((response) => {
        if (response.status == STATUS_CODES.SUCCESS) {
          dispatch(deletePressReleaseBlogSuccess(id));
          successToast(response.data.message);
        } else {
          errorToast(response.data.detail);
        }
        dispatch(loadingSuccess(false));
        setDialog(false);
      });
  } catch (e) {
    return console.error(e.message);
  }
};

// get PressRelease Details
export const getPressReleaseDetails =
  (data, setIsLoading) => async (dispatch) => {
    dispatch(loadingSuccess(true));
    try {
      await api
        .post(SILVERBACK.getPressReleaseDetails, data)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            dispatch(getPressReleaseDetailsSuccess(response.data.data));
          } else {
            errorToast(response.data.detail);
          }
          setIsLoading(false);
          dispatch(loadingSuccess(false));
        });
    } catch (e) {
      return console.error(e.message);
    }
  };
// clear PressRelease Details
export const clearPressReleaseDetails = () => async (dispatch) => {
  dispatch(clearPressReleaseDetailsSuccess(null));
};
// update PressRelease Blog
export const updatePressReleaseBlog =
  (data, setIsLoading, navigate) => async () => {
    try {
      await api
        .post(SILVERBACK.updatePressReleaseBlog, data)
        .then((response) => {
          if (response.status == STATUS_CODES.SUCCESS) {
            successToast(response.data.message);
            navigate("/press-release");
          } else {
            errorToast(response.data.detail);
          }
          setIsLoading(false);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };
export default slice.reducer;
