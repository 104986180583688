import { Card, Divider, Grid, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { color } from "../../../../theme/color";
import { useSelector, useDispatch } from "react-redux";
import {
  forOTPAuthentication,
  textAuthentication,
} from "../../../../store/slice/profile";
import Spinner from "../../../../components/spinner";
import SVConfirmDialog from "components/SVConfirmDialog";
// import profilePic from "../../../../assets/img/default.jpg";

export default function index(props) {
  const { handleChange } = props;
  const dispatch = useDispatch();
  const { isLoading, adminPorfileDetailData, tOTPAuthenticationData } =
  
    useSelector((state) => state.profile);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [checkedValueForEmail, setCheckedValueEmail] = useState(false);
  const [checkedValueForPhone, setCheckedValueForPhone] = useState(false);
  const [totpChecked, setTotpChecked] = useState(false);

  useEffect(() => {
    if (adminPorfileDetailData) {
      setTotpChecked(adminPorfileDetailData?.mfa_enabled);
      setCheckedValueEmail(adminPorfileDetailData?.emailAuthentication);
      setCheckedValueForPhone(adminPorfileDetailData?.textAuthentication);
    }
  }, [adminPorfileDetailData]);

  const phoneAuthentication = (value, type) => {
    if (adminPorfileDetailData?.phone == "") {
      setConfirmationDialog(true);
    }
    if (type === "emailAuthentication") {
      setCheckedValueEmail(!checkedValueForEmail);
    } else {
      setCheckedValueForPhone(!checkedValueForPhone);
    }

    var data = {
      notificationType:
        type === "textAuthentication"
          ? "textAuthentication"
          : "emailAuthentication",
      status: value,
    };
    dispatch(textAuthentication(data,true));
  };

  const tOtpAuthentication = (value) => {
    setTotpChecked(!totpChecked);
    dispatch(forOTPAuthentication(value,true));
  };

  return (
    <>
      {!isLoading && (
        <>
          <Card
            sx={{
              maxWidth: "92vw",
              p: 5,
              color: color.fourth,
              marginTop: -2,
              background: "#202020",
            }}
          >
            {/* <Typography sx={{ color: "white", fontWeight: 600 }}>
          Setup Mutlti Factor Security
        </Typography> */}
            <Grid container sx={{ my: "1rem" }}>
              <Grid xs={12}>
                <Grid container component="main">
                  <Grid item xs={12} sm={8} md={10}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: color.main,
                      }}
                    >
                      Two-factor Authentication
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Switch
                      checked={totpChecked}
                      onChange={(e) => tOtpAuthentication(e.target.checked)}
                      color="success"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} sx={{ mt: -1.5 }}>
                <Typography sx={{ color: color.fourth }}>
                  Use your google authentication app for added security.
                </Typography>
              </Grid>
            </Grid>
            {tOTPAuthenticationData?.url && (
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item>
                  <img
                    width={300}
                    height={300}
                    src={
                      process.env.REACT_APP_API_URL +
                      "/" +
                      tOTPAuthenticationData?.url
                    }
                  />
                </Grid>
              </Grid>
            )}

            <Divider />
            <Grid container sx={{ my: "1rem" }}>
              <Grid xs={12}>
                <Grid container component="main">
                  <Grid item xs={12} sm={8} md={10}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: color.main,
                      }}
                    >
                      Email Notifications
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Switch
                      checked={checkedValueForEmail}
                      onChange={(e) =>
                        phoneAuthentication(
                          e.target.checked,
                          "emailAuthentication"
                        )
                      }
                      color="success"
                      // sx={{
                      //   "&.MuiSwitch-root .Mui-checked": {
                      //     color: "green",
                      //   },
                      // }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} sx={{ mt: -1.5 }}>
                <Typography sx={{ color: color.fourth }}>
                  Receive important notifications via your primary email.
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <Grid container sx={{ my: "1rem" }}>
              <Grid xs={12}>
                <Grid container component="main">
                  <Grid item xs={12} sm={8} md={10}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: color.main,
                      }}
                    >
                      Phone Authentication Code
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Switch
                      checked={checkedValueForPhone}
                      onChange={(event) =>
                        phoneAuthentication(
                          event.target.checked,
                          "textAuthentication"
                        )
                      }
                      color="success"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} sx={{ mt: -1.5 }}>
                <Typography sx={{ color: color.fourth }}>
                  Authenticate using SMS for quick access.
                </Typography>
              </Grid>
            </Grid>
          </Card>
          {confirmationDialog && (
            <SVConfirmDialog
              openDialog={confirmationDialog}
              title={"Phone Verification"}
              message={
                "Please add your phone number to complete the verification processs"
              }
              onClose={() => setConfirmationDialog(false)}
              handleDisagree={() => setConfirmationDialog(false)}
              handleAgree={(event) => {
                handleChange(event, "1");
              }}
              cancelButtonText="No"
              agreeButtonText="Yes"
            />
          )}
        </>
      )}
      <Spinner open={isLoading} />
    </>
  );
}
