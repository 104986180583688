import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/scroll-to-top/scrolltotop";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./components/notification";

function App() {
  // const CloseButton = ({ closeToast }) => (
  //   <Button size="small" onClick={closeToast} style={{ color: "white" }}>
  //     Ok
  //   </Button>
  // );
  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
            <Suspense fallback={<div>Loading...</div>}>
              <Router />
            </Suspense>
        </BrowserRouter>
      </HelmetProvider>
      {/* <Toaster
        theme="colored"
        position="top-left"
        autoClose={5000}
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          success: {
            style: {
              background: 'green',
            },
          },
          error: {
            style: {
              background: 'red',
            },
          },
        }}
      /> */}
      <Notification />
    </>
  );
}

export default App;
