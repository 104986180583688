import { Grid, Card, Typography, Box, Tab, Avatar, Badge } from "@mui/material";
// components
import image from "../../../assets/img/default.jpg";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ChangePassword from "../changePassword";
import ProfileDetails from "./profileDetails";
import SecurityPrivacy from "./security&privacy";
import ManageTeam from "./manageTeam";
import styled from "@emotion/styled";
import { color } from "../../../theme/color";
import { useDispatch, useSelector } from "react-redux";
import {
  adminProfileDetail,
  uploadProfile,
} from "../../../store/slice/profile";
import { TEAM_MEMBER_ROLE } from "utils/appConstant";
// import { adminDetailData } from "utils/constantFun";
import Spinner from "components/spinner";
// sections

// ----------------------------------------------------------------------

export default function Settings() {
  const dispatch = useDispatch();
  const {
    uploadPorfilePicData,
    isUploading,
    adminPorfileDetailData,
    isLoading,
  } = useSelector((state) => state.profile);
  const [value, setValue] = useState("1");
  const profileImage = useRef();

  useEffect(() => {
    dispatch(adminProfileDetail());
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    const data = {
      file: file,
      folderName: "profilePic",
    };
    dispatch(uploadProfile(data, true));
  };

  const SmallAvatar = styled(Avatar)(() => ({
    width: 30,
    height: 30,
    background: color?.main,
    cursor: "pointer",
  }));
  return (
    <>
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            <Card style={{ background: "white", borderRadius: 0 }}>
              <Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "black",
                    height: "8rem",
                  }}
                ></Grid>
                <Grid
                  item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Box sx={{ width: 100, my: "-3.1rem" }}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        isUploading ? (
                          <Icon
                            icon="line-md:uploading-loop"
                            color="#000"
                            width="30"
                            height="30"
                          />
                        ) : (
                          <SmallAvatar
                            alt="Remy Sharp"
                            width={20}
                            onClick={() => profileImage.current.click()}
                          >
                            <Icon
                              icon="ion:camera-sharp"
                              color={color.buttonColor}
                              height="18"
                            />
                          </SmallAvatar>
                        )
                      }
                    >
                      <img
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={
                          uploadPorfilePicData?.fullPath != null
                            ? uploadPorfilePicData?.fullPath
                            : image
                        }
                      />
                    </Badge>
                    <input
                      ref={profileImage}
                      type="file"
                      style={{ display: "none" }}
                      className="imageUpload"
                      accept="image/*"
                      onChange={onChange}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    mt: "4rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "bold", color: "black" }}
                    component="h1"
                    variant="h6"
                  >
                    {adminPorfileDetailData?.first_name}
                    {adminPorfileDetailData?.surename}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{ display: "flex", justifyContent: "center", mb: 4 }}
                >
                  <Typography sx={{ fontSize: 12, color: "#878a99!important" }}>
                    {adminPorfileDetailData?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} lg={10}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ py: 5, my: -6 }}>
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    sx={{ color: "#000" }}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Profile Details" value="1" />
                    <Tab label="Change Password" value="2" />
                    {adminPorfileDetailData?.roleId ===
                      TEAM_MEMBER_ROLE.SUPERADMIN_ROLE && (
                      <Tab label="Manage Team" value="3" />
                    )}
                    <Tab label="Security & Privacy" value="4" />
                  </TabList>
                </Box>

                <TabPanel sx={{ color: "black", p: 5 }} value="1">
                  <ProfileDetails onChange={handleChange} />
                </TabPanel>
                <TabPanel sx={{ color: "black", p: 5 }} value="2">
                  <ChangePassword />
                </TabPanel>
                <TabPanel sx={{ color: "black", p: 5 }} value="3">
                  <ManageTeam />
                </TabPanel>
                <TabPanel sx={{ color: "black", p: 5 }} value="4">
                  <SecurityPrivacy handleChange={handleChange} />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </>
      <Spinner open={isLoading} />
    </>
  );
}
