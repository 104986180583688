import AuthLayout from "../layout/auth";
import DashboardLayout from "../layout/dashboard";
import Login from "../pages/auth/login";
import Otp from "../pages/auth/otp";
import ForgotPassword from "../pages/auth/forgot";
import ResetPassword from "../pages/auth/resetPassword";
import Dashboard from "../pages/admin/dashboard/index";
import User from "../pages/admin/user/index";
import ProfileDetail from "../pages/admin/profileDetail/index";
import NetWorthDetail from "../pages/admin/netWorth/netWorthDetails/index";
import LoanRequest from "../pages/admin/loanRequest/index";
import CardRequest from "../pages/admin/cardRequest/index";
import Transactions from "../pages/admin/transactions/index";
import Adds from "../pages/admin/adds/index";
import Page404 from "../pages/page404/index";
import DownloadApp from "../pages/admin/downloadApp/index";
import NetWorth from "../pages/admin/netWorth/index";
import TicketManagement from "../pages/admin/ticketManagement/index";
import EkycList from "../pages/admin/eKyc/index";
import Settings from "../pages/admin/settings/index";
import SubscribeUser from "../pages/admin/subscriberUser/index";
import { Navigate, useRoutes } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { STORAGE_KEY } from "utils/appConstant";
import CreatePassword from "pages/admin/createPassword";
import Notification from "pages/admin/notification";
import SendNotification from "pages/admin/notification/sendNotification";
import Taxrate from "pages/admin/taxRate";
import AssetsDetail from "pages/admin/profileDetail/sections/assetsLiabilities";
import BankDetails from "pages/admin/profileDetail/sections/bankDetails";
import SetGoalDetails from "pages/admin/profileDetail/sections/setGoalDetails";
import EstateDetail from "pages/admin/profileDetail/sections/estate/estateDetails";
import EstateDocument from "pages/admin/estatePlanning";
import LastWillDocument from "pages/admin/profileDetail/sections/estate/estateDocuments/lastWillDocument";
import HippaDocument from "pages/admin/profileDetail/sections/estate/estateDocuments/hipaaDocument";
import PowerOfAttorneyDocument from "pages/admin/profileDetail/sections/estate/estateDocuments/powerOfAttorney";
import LivingWillDocument from "pages/admin/profileDetail/sections/estate/estateDocuments/livingWill";
import RealEstate from "pages/admin/profileDetail/sections/assets/realEstate";
import OtherAssets from "pages/admin/profileDetail/sections/assets/other";
import Insurance from "pages/admin/profileDetail/sections/assets/insurances";
import TermLifeInsurance from "pages/admin/profileDetail/sections/assets/insurances/subComponent/termLifeInsurance";
import LongTermInsurance from "pages/admin/profileDetail/sections/assets/insurances/subComponent/longtermInsurance";
import IndividualDisability from "pages/admin/profileDetail/sections/assets/insurances/subComponent/individualDisability";
import AutoInsurance from "pages/admin/profileDetail/sections/assets/insurances/subComponent/autoInsurance";
import UmbrellaInsurance from "pages/admin/profileDetail/sections/assets/insurances/subComponent/umbrella";
import WholeLifeInsurance from "pages/admin/profileDetail/sections/assets/insurances/subComponent/wholeLife";
import Investment from "pages/admin/profileDetail/sections/assets/investment";
import Loans from "pages/admin/profileDetail/sections/liabilities/loans";
import OtherLiabilities from "pages/admin/profileDetail/sections/liabilities/other";
import ManualAssets from "pages/admin/profileDetail/sections/assets/manual";
import AdvertiseManagment from "pages/admin/advertiseManagment";
import AddEditAdvertise from "pages/admin/advertiseManagment/addEditAdvertise";
import PressRelease from "pages/admin/pressRelease";
import AddPressRelease from "pages/admin/pressRelease/addPressRelease";
import Notes from "pages/admin/profileDetail/sections/notes";
import TransactionList from "pages/admin/profileDetail/sections/transactioList";
// layouts
// const AuthLayout = lazy(() => import("../layout/auth"));
// const DashboardLayout = lazy(() => import("../layout/dashboard"));
// const Login = lazy(() => import("../pages/auth/login"));
// const Otp = lazy(() => import("../pages/auth/otp"));
// const ForgotPassword = lazy(() => import("../pages/auth/forgot"));
// const ResetPassword = lazy(() => import("../pages/auth/resetPassword"));

//Pages
// const Dashboard = lazy(() => import("../pages/admin/dashboard"));
// const User = lazy(() => import("../pages/admin/user"));
// const ProfileDetail = lazy(() => import("../pages/admin/profileDetail/index"));
// const AccountRequest = lazy(() => import("../pages/admin/accountRequest"));
// const SubscribeUser = lazy(() => import("../pages/admin/subscriberUser/index"));

// const LoanRequest = lazy(() => import("../pages/admin/loanRequest"));
// const CardRequest = lazy(() => import("../pages/admin/cardRequest"));
// const Transactions = lazy(() => import("../pages/admin/transactions"));
// const Adds = lazy(() => import("../pages/admin/adds"));
// const Settings = lazy(() => import("../pages/admin/settings"));
// const DownloadApp = lazy(() => import("../pages/admin/downloadApp"));
// const Page404 = lazy(() => import("../pages/page404"));

export default function Router() {
  const authData = secureLocalStorage.getItem(STORAGE_KEY.USER_AUTH);
  const isLoggedIn = authData?.access_token;
  const urlPath = window.location.pathname;
  const routes = useRoutes([
    {
      path: "/",
      element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/login" />,
      children: [
        { path: "login", element: <Login /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "forgotPassword/:type/:id", element: <ResetPassword /> },
        {
          path: "team-member/email-verification/:id",
          element: <CreatePassword />,
        },
        { path: "verify-account", element: <Otp /> },
      ],
    },
    { path: "*", element: <Page404 /> },
    { path: "downloadApp", element: <DownloadApp /> },
    {
      path: "/",
      element:
        isLoggedIn && urlPath.includes("forgotPassword") ? (
          <Navigate to="/forgotPassword/:type/:id" />
        ) : isLoggedIn && !urlPath.includes("forgotPassword") ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <User /> },
        { path: "profileDetail", element: <ProfileDetail /> },
        { path: "netWorthDetails", element: <NetWorthDetail /> },
        { path: "/profile/:profileId", element: <ProfileDetail /> },
        { path: "subscribeUser", element: <SubscribeUser /> },
        { path: "advertise-managment", element: <AdvertiseManagment /> },
        { path: "advertise-managment/add", element: <AddEditAdvertise /> },
        { path: "advertise-managment/edit/:id", element: <AddEditAdvertise /> },
        { path: "netWorth", element: <NetWorth /> },
        { path: "press-release", element: <PressRelease /> },
        { path: "press-release/add", element: <AddPressRelease /> },
        { path: "press-release/edit/:id", element: <AddPressRelease /> },
        { path: "assetsDetail/:id", element: <AssetsDetail /> },
        { path: "assetsDetail/insurance/:id", element: <Insurance /> },
        { path: "assetsDetail/investment/:id", element: <Investment /> },
        { path: "assetsDetail/loans/:id", element: <Loans /> },

        {
          path: "assetsDetail/other-liabilities/add/:id",
          element: <OtherLiabilities />,
        },

        {
          path: "assetsDetail/insurance/term-life-insurance/add/:id",
          element: <TermLifeInsurance />,
        },
        {
          path: "assetsDetail/insurance/term-life-insurance/edit/:id/:insuranceId",
          element: <TermLifeInsurance />,
        },
        {
          path: "assetsDetail/insurance/long-term-insurance/add/:id",
          element: <LongTermInsurance />,
        },
        {
          path: "assetsDetail/insurance/long-term-insurance/edit/:id/:insuranceId",
          element: <LongTermInsurance />,
        },
        {
          path: "assetsDetail/insurance/individual-disability-insurance/add/:id",
          element: <IndividualDisability />,
        },
        {
          path: "assetsDetail/insurance/individual-disability-insurance/edit/:id/:insuranceId",
          element: <IndividualDisability />,
        },
        {
          path: "assetsDetail/insurance/auto-insurance/add/:id",
          element: <AutoInsurance />,
        },
        {
          path: "assetsDetail/insurance/auto-insurance/edit/:id/:insuranceId",
          element: <AutoInsurance />,
        },
        {
          path: "assetsDetail/insurance/umbrella-insurance/add/:id",
          element: <UmbrellaInsurance />,
        },
        {
          path: "assetsDetail/insurance/umbrella-insurance/edit/:id/:insuranceId",
          element: <UmbrellaInsurance />,
        },
        {
          path: "assetsDetail/insurance/whole-life-insurance/add/:id",
          element: <WholeLifeInsurance />,
        },
        {
          path: "assetsDetail/insurance/whole-life-insurance/edit/:id/:insuranceId",
          element: <WholeLifeInsurance />,
        },
        { path: "assetsDetail/real-estate/add/:id", element: <RealEstate /> },
        { path: "assetsDetail/other-assets/add/:id", element: <OtherAssets /> },
        {
          path: "assetsDetail/manual-assets/add/:id",
          element: <ManualAssets />,
        },
        { path: "/profile/notes/:id", element: <Notes/> },
        { path: "/profile/transaction/:id", element: <TransactionList/> },


        { path: "/profile/estateDetail/:id", element: <EstateDetail /> },
        {
          path: "/profile/estate/last-will-and-testament/add/:id",
          element: <LastWillDocument />,
        },
        {
          path: "/profile/estate/last-will-and-testament/:actionType/:id/:userMapperId",
          element: <LastWillDocument />,
        },
        {
          path: "/profile/estate/hippa-release-and-authorization/:actionType/:id",
          element: <HippaDocument />,
        },
        {
          path: "/profile/estate/hippa-release-and-authorization/:actionType/:id/:userMapperId",
          element: <HippaDocument />,
        },

        {
          path: "/profile/estate/power-of-attorney/:actionType/:id",
          element: <PowerOfAttorneyDocument />,
        },
        {
          path: "/profile/estate/power-of-attorney/:actionType/:id/:userMapperId",
          element: <PowerOfAttorneyDocument />,
        },
        {
          path: "/profile/estate/living-will-health-care-proxy/:actionType/:id",
          element: <LivingWillDocument />,
        },
        {
          path: "/profile/estate/living-will-health-care-proxy/:actionType/:id/:userMapperId",
          element: <LivingWillDocument />,
        },
        { path: "bankDetails/:id", element: <BankDetails /> },
        { path: "setGoalDetails/:id", element: <SetGoalDetails /> },
        { path: "ticketManagement", element: <TicketManagement /> },
        { path: "ticketManagement/:id", element: <TicketManagement /> },

        { path: "estate-planning", element: <EstateDocument /> },

        { path: "eKycList", element: <EkycList /> },
        { path: "eKycList/:id", element: <EkycList /> },
        { path: "notification", element: <Notification /> },
        {
          path: "notification/sendNotification",
          element: <SendNotification />,
        },
        { path: "loanRequest", element: <LoanRequest /> },
        { path: "cardRequest", element: <CardRequest /> },
        { path: "transactions", element: <Transactions /> },
        { path: "adds", element: <Adds /> },
        { path: "tax-rate", element: <Taxrate /> },
        { path: "settings", element: <Settings /> },
      ],
    },
  ]);

  return routes;
}
