import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import SVButton from "components/SVButton";
import SVOutlinedButton from "components/SVOutlinedButton";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
// import { color } from "theme/color";
import SVDivider from "components/SVDivider";
import AdminInput from "components/adminInput";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const taxRatemodal = ({ ...props }) => {
  const {
    openDialog,
    onClose,
    title,
    setTaxRateForm,
    isLoading,
    agreeButtonText,
    cancelButtonText,
    setFormError,
    formError,
    handleSubmit,
    taxRateForm,

    handleCancel,
  } = props;

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i); // Change 10 to the desired range of years

  const handleChange = (e) => {
    if (e.target.value) {
      setTaxRateForm((taxRateForm) => ({
        ...taxRateForm,
        year: e.target.value,
      }));
      setFormError((formError) => ({
        ...formError,
        year: "",
      }));
    } else {
      setTaxRateForm((taxRateForm) => ({
        ...taxRateForm,
        year: "",
      }));
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth={"sm"}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, mb: -1 }} id="customized-dialog-title">
          {title}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <SVDivider style={{ marginTop: 10 }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminInput
                required
                type="text"
                title={"Amount "}
                color="black"
                value={taxRateForm?.amount || ""}
                fullWidth={true}
                maxLength={10}
                onChange={(e) => {
                  const numeric = /^[0-9\b]+$/;
                  if (e.target.value === "" || numeric.test(e.target.value)) {
                    setTaxRateForm((taxRateForm) => ({
                      ...taxRateForm,
                      amount: e.target.value,
                    }));
                    setFormError((formError) => ({
                      ...formError,
                      amount: "",
                    }));
                  } else {
                    setTaxRateForm((taxRateForm) => ({
                      ...taxRateForm,
                      amount: "",
                    }));
                  }
                }}
                formError={formError?.amount}
              />
            </Grid>
            <Grid item xs={12}>
              <AdminInput
                required
                type={"text"}
                title={"Rate of Interest"}
                value={taxRateForm?.rateOfInterest || ""}
                fullWidth={true}
                maxLength={2}
                onChange={(e) => {
                  const numeric = /^[0-9\b]+$/;
                  if (e.target.value === "" || numeric.test(e.target.value)) {
                    setTaxRateForm((taxRateForm) => ({
                      ...taxRateForm,
                      rateOfInterest: e.target.value,
                    }));
                    setFormError((formError) => ({
                      ...formError,
                      rateOfInterest: "",
                    }));
                  } else {
                    setTaxRateForm((taxRateForm) => ({
                      ...taxRateForm,
                      rateOfInterest: "",
                    }));
                  }
                }}
                formError={formError?.rateOfInterest}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-select-small-label">Year</InputLabel>

                <Select
                  sx={{
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "grey",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth
                  label="Year"
                  value={taxRateForm?.year || ""}
                  onChange={(e) => handleChange(e)}
                  variant="outlined"
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <SVDivider style={{ marginTop: 10 }} />
        <Grid container sx={{ justifyContent: "end" }}>
          <DialogActions>
            <SVButton
              onClick={handleSubmit}
              text={agreeButtonText}
              isLoading={isLoading}
            />
            <SVOutlinedButton onClick={handleCancel} text={cancelButtonText} />
          </DialogActions>
        </Grid>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default React.memo(taxRatemodal);
