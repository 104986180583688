import {
  Typography,
  Box,
  Card,
  Grid,
  useMediaQuery,
  List,
  ListItem,
} from "@mui/material";
// components
import AdminButton from "../../../components/adminButton";
import { color } from "../../../theme/color";
import { Icon } from "@iconify/react";
import { useState } from "react";
import AdminInput from "../../../components/adminInput";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
import { changePassword } from "../../../store/slice/auth";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = () => {
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!formData.oldPassword) {
      setFormError((formError) => ({
        ...formError,
        oldPassword: "Please enter old password",
      }));
      return;
    } else if (!re.test(formData.oldPassword)) {
      setFormError((formError) => ({
        ...formError,
        oldPassword:
          "A password must contain at least eight characters, at least one number, both lower and uppercase letters, and special characters",
      }));
      return;
    }
    if (!formData.newPassword) {
      setFormError((formError) => ({
        ...formError,
        newPassword: "Please enter a password",
      }));
      return;
    } else if (!re.test(formData.newPassword)) {
      setFormError((formError) => ({
        ...formError,
        newPassword:
          "A password must contain at least eight characters, at least one number, both lower and uppercase letters, and special characters",
      }));
      return;
    }
    if (!formData.confirmPassword) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Please enter a confirm password",
      }));
      return;
    }

    if (formData.confirmPassword != formData.newPassword) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Password and confirm password does not match",
      }));
      return;
    }
    const data = {
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    };
    dispatch(changePassword(data, true));
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: isMobile ? "100vw" : "92vw",
          p: isMobile ? 1 : 5,
          color: color.main,
          marginTop: -2,
          background: "#202020",
        }}
      >
        <Typography sx={{ color: "white", fontWeight: 500 }}>
          Change Password
        </Typography>
        <Grid container item>
          <Grid item xs={12} md={12}>
            <Box noValidate sx={{ mt: 1 }}>
              <AdminInput
                color="white"
                title="Old password"
                required={true}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#C5C5C5",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
                type={showPassword.old ? "text" : "password"}
                value={formData.oldPassword}
                onChange={(e) => {
                  if (e) {
                    setFormData((formData) => ({
                      ...formData,
                      oldPassword: e.target.value,
                    }));
                    if (e) {
                      setFormError((formError) => ({
                        ...formError,
                        oldPassword: "",
                      }));
                    }
                  }
                }}
                formError={formError.oldPassword}
                endIcon={
                  <Icon
                    icon={
                      !showPassword.old ? "iconamoon:eye-off" : "tabler:eye"
                    }
                    color="grey"
                    width="28"
                    height="28"
                  />
                }
                PasswordShowClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    old: !showPassword.old,
                  }))
                }
              />
              <AdminInput
                color="white"
                required={true}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#C5C5C5",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
                title="New password"
                type={showPassword.new ? "text" : "password"}
                value={formData.newPassword}
                InputLabelProps={{
                  style: {
                    color: "grey",
                  },
                }}
                onChange={(e) => {
                  if (e) {
                    setFormData((formData) => ({
                      ...formData,
                      newPassword: e.target.value,
                    }));
                    if (e) {
                      setFormError((formError) => ({
                        ...formError,
                        newPassword: "",
                      }));
                    }
                  }
                }}
                formError={formError.newPassword}
                endIcon={
                  <Icon
                    icon={
                      !showPassword.new ? "iconamoon:eye-off" : "tabler:eye"
                    }
                    width="28"
                    height="28"
                    color="grey"
                  />
                }
                PasswordShowClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    new: !showPassword.new,
                  }))
                }
              />
              <AdminInput
                color="white"
                required={true}
                title="Confirm password"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#C5C5C5",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
                type={showPassword.confirmPassword ? "text" : "password"}
                value={formData.confirmPassword}
                InputLabelProps={{
                  style: {
                    color: "grey",
                  },
                }}
                onChange={(e) => {
                  if (e) {
                    setFormData((formData) => ({
                      ...formData,
                      confirmPassword: e.target.value,
                    }));
                    if (e) {
                      setFormError((formError) => ({
                        ...formError,
                        confirmPassword: "",
                      }));
                    }
                  }
                }}
                formError={formError.confirmPassword}
                endIcon={
                  <Icon
                    icon={
                      !showPassword.confirmPassword
                        ? "iconamoon:eye-off"
                        : "tabler:eye"
                    }
                    width="28"
                    height="28"
                    color="grey"
                  />
                }
                PasswordShowClick={() =>
                  setShowPassword((showPassword) => ({
                    ...showPassword,
                    confirmPassword: !showPassword.confirmPassword,
                  }))
                }
              />
            </Box>
            <Grid item sx={{ mt: 1, fontWeight: 500 }}>
              <Typography>New password must contain :</Typography>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>At least 8 characters</ListItem>
                <ListItem>At least 1 lower letter (a-z)</ListItem>

                <ListItem>At least 1 uppercase letter(A-Z)</ListItem>

                <ListItem>At least 1 number (0-9)</ListItem>

                <ListItem>At least 1 special characters</ListItem>
              </List>
            </Grid>
            <Grid
              xs={12}
              item
              sx={{ display: "flex", justifyContent: "right" }}
            >
              <AdminButton
                onClick={handleSubmit}
                title={"Submit"}
                sx={{ width: 150 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Spinner open={isLoading} />
      </Card>
    </>
  );
}
