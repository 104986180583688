import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
import { getNotificationList, readNotification } from "store/slice/profile";
import AdminButton from "components/adminButton";
import { Icon } from "@iconify/react";
import { color } from "theme/color";
import TopHeading from "components/topHeading";
import { Helmet } from "react-helmet-async";
import SVButton from "components/SVButton";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TYPE } from "utils/appConstant";

export default function Notification() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { notificationListData, isLoading, totalNotificationCount } =
    useSelector((state) => state.profile);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchNotificationList();
  }, []);

  // const dispatch = useDispatch();

  const fetchNotificationList = (perPage) => {
    let request = {
      page: 0,
      perPage: perPage ? perPage : limit,
    };
    dispatch(getNotificationList(request, setViewMoreLoading));
  };

  const handleReadNotification = (readAll, id) => {
    let request = {
      readAll: readAll,
      notificationId: id,
    };
    dispatch(readNotification(request, limit));
  };
  return (
    <>
      {!isLoading && (
        <>
          {" "}
          <Helmet>
            <title> Notifications </title>
          </Helmet>
          <div className="App wrapper">
            <Stack
              direction={"row"}
              sx={{
                display: isMobile ? "block" : "flex",
                justifyContent: "space-between",
                px: 3,
                pb: 2,
              }}
            >
              <TopHeading
                sx={{ p: isMobile ? 1 : "" }}
                title={"Notifications List"}
              />
              <SVButton
                text="Send Promotional Message"
                onClick={() => navigate("/notification/sendNotification")}
                sx={{ px: 3, mt: isMobile ? 2 : -1.5 }}
              />
            </Stack>
            <Grid container>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  my: 3,
                }}
              >
                <Grid item>
                  <Typography
                    onClick={() => {
                      handleReadNotification(true);
                    }}
                    sx={{
                      fontSize: 18,
                      fontWeight: 500,
                      color: "white",
                      cursor: "pointer",
                      mr: isMobile ? 3 : "",
                    }}
                  >
                    Read All
                  </Typography>
                </Grid>
              </Grid>
              <>
                {notificationListData && notificationListData.length > 0 ? (
                  <>
                    {notificationListData?.map((item, index) => {
                      return (
                        <>
                          <Card
                            key={index}
                            sx={{
                              background: "white",
                              p: 3,
                              width: "100%",
                              mt: 1,
                              mx: isMobile ? 3 : "",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (
                                item?.notification_type ==
                                NOTIFICATION_TYPE?.USER_REGISTER_NOTIFICATION_TO_ADMIN
                              ) {
                                navigate(
                                  `/profile/${JSON.parse(item?.notification_data)?.id}`
                                );
                              }
                              if (
                                item?.notification_type ==
                                NOTIFICATION_TYPE?.ADMIN_NOTIFICATION_FOR_ESTATE_FORM_SUBMITTION
                              ) {
                                navigate(
                                  `/profile/estateDetail/${JSON.parse(item?.notification_data)?.id}`
                                );
                              }
                              if (
                                item?.notification_type ==
                                NOTIFICATION_TYPE?.ADMIN_RAISE_SUPPORT_TICKET_NOTIFICATION
                              ) {
                                navigate(
                                  `/ticketManagement/${JSON.parse(item?.notification_data)?.id}`
                                );
                              }

                              if (
                                item?.notification_type ==
                                NOTIFICATION_TYPE?.EKYC_REQUEST
                              ) {
                                navigate(
                                  `/ekycList/${JSON.parse(item?.notification_data)?.userId}`
                                );
                              }

                              if (!item?.isRead) {
                                handleReadNotification(false, item?.id);
                              }
                            }}
                          >
                            <Grid container>
                              <Grid xs={11}>
                                <Stack>
                                  <Typography
                                    sx={{ display: "inline", fontSize: 16 }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {item?.title}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      display: "inline",
                                      fontSize: 12,
                                      pt: 1,
                                    }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {item?.message}
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid
                                sm={1}
                                display={"flex"}
                                justifyContent={"end"}
                              >
                                {/* <Icon
                              Icon="cryptocurrency-color:dot"
                              color="black"
                            /> */}
                                {!item?.isRead && (
                                  <Icon
                                    icon="octicon:dot-fill-16"
                                    width={20}
                                    color={color.buttonColor}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Card>
                        </>
                      );
                    })}
                    {viewMoreLoading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 3,
                          width: "100%",
                        }}
                      >
                        <CircularProgress
                          sx={{
                            color: "#1a90ff",
                          }}
                        />
                      </Box>
                    ) : (
                      totalNotificationCount > limit && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <AdminButton
                            onClick={() => {
                              let perPage = limit + 10;
                              setLimit(perPage);
                              setViewMoreLoading(true);
                              fetchNotificationList(perPage);
                            }}
                            title={"View More"}
                          />
                        </Box>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      sx={{
                        border: "1px solid #202020",
                        mt: 2,
                        height: 300,
                        backgroundColor: "#202020",
                      }}
                    >
                      <Grid
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 24,
                            fontWeight: 500,
                            mt: "7.5rem",
                            color: "white",
                          }}
                        >
                          No Record Found
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            </Grid>
          </div>
        </>
      )}

      <Spinner open={isLoading} />
    </>
  );
}
