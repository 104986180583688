import { common } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import {
  error,
  primary,
  info,
  neutral,
  success,
  warning,
  secondary,
  color,
} from "./color";

export function createPalette() {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: "#171717",
      paper: common.white,
    },
    divider: color.silver,
    error,
    info,
    mode: "light",
    neutral,
    primary: primary,
    secondary: secondary,
    success,
    text: {
      primary: color.black,
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
  };
}
