import React from "react";
import {
   // IconButton,
    Card,
    CardMedia,
    Box,
   // Typography,
} from "@mui/material";
// import { IoTrashOutline } from "react-icons/io5";

const SVDocumentView = ({ ...props }) => {

    const { media } = props;
    return (

        <Card sx={{ maxWidth: 100 }}>
            <Box sx={{ position: 'relative' }}>
                <CardMedia
                    component="img"
                    height="100"
                    image={media}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        textAlign: "center",
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.54)',
                        color: 'white',

                    }}
                >
                    {/* <IconButton onClick={onClick}>
                        <IoTrashOutline style={{ color: "white" }} fontSize={20} />
                        <Typography sx={{ color: "white", pl: 1 }}>Delete</Typography>
                    </IconButton> */}
                </Box>
            </Box>
        </Card>
    )

}

export default React.memo(SVDocumentView)