import Box from "@mui/material/Box";
import AdminInput from "components/adminInput";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select, Stack
} from "@mui/material";
export function DashboardDateFilter({
  handleChange,
  day,
  dateInput,
  handleStartDate,
  handleEndDate,
  startDate,
  endDate,
}) {
  return (
    <>
      <section>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Days</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={day}
              label="Days"
              onChange={handleChange}
              sx={{
                color: "white",
                "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              }}
            >
              <MenuItem value={7}>Last 7 days</MenuItem>
              <MenuItem value={15}>Last 15 days</MenuItem>
              <MenuItem value={30}>Last month</MenuItem>
              <MenuItem value={90}>Last 3 months</MenuItem>
              <MenuItem value={180}>Last 6 months</MenuItem>
              <MenuItem value={0}>Custom</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 200 }}>
          {dateInput && (
            <>
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <AdminInput
                  color="white"
                  type={"date"}
                  title={"Start Date"}
                  max={endDate ?? ""}
                  sx={{
                    mt: 2,

                    background: "#202020",
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#202020 !important",
                      },
                    },
                    '& input[type="date"]::-webkit-calendar-picker-indicator': {
                      filter: "invert(70%)", // Change the color of the calendar icon
                    },
                  }}
                  value={startDate || ""}
                  onChange={(e) => handleStartDate(e.target.value)}
                />

                <AdminInput
                  color="white"
                  type={"date"}
                  title={"End Date"}
                  min={startDate ?? ""}
                  sx={{
                    mt: 2,
                    background: "#202020",
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#202020 !important",
                      },
                    },
                    '& input[type="date"]::-webkit-calendar-picker-indicator': {
                      filter: "invert(70%)", // Change the color of the calendar icon
                    },
                  }}
                  value={endDate || ""}
                  onChange={(e) => handleEndDate(e.target.value)}
                />
              </Stack>
            </>
          )}
        </Box>
      </section>
    </>
  );
}
