import * as React from "react";
import Link from "@mui/material/Link";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useState } from "react";
import { color } from "../../theme/color";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Card, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../store/slice/auth";
// import { forgetPassword } from "../../store/slice/auth";
// TODO remove, this demo shouldn't need to reset the theme.

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState({});
  const handleSubmit = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (!email) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter your email address",
      }));
      return;
    } else if (!email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!validEmail.test(email)) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    }
    var data = {
      email: email,
    };

    dispatch(forgetPassword(data, true));
  };

  return (
    <>
      <Grid container display={"flex"} justifyContent="center">
        <Grid item>
          <Card sx={{ background: color.topbar, p: 4, width: 320 }}>
            <Typography
              sx={{ textAlign: "center", my: "10px", color: color.main }}
            >
              Please enter your email.
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <AdminInput
                color="white"
                title="Email Adress"
                required={true}
                sx={{
                  mt: "30px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#C5C5C5",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
                type="email"
                value={email}
                onChange={(val) => {
                  if (val) {
                    setEmail(val.target.value);
                    setFormError((formError) => ({
                      ...formError,
                      email: "",
                    }));
                  }
                }}
                formError={formError.email}
              />
              <Link
                sx={{
                  float: "right",
                  textDecoration: "none",
                  fontSize: 13,
                  color: color.buttonColor,
                  cursor: "pointer",
                  mt: "6px",
                  mb: "4px",
                }}
                variant="body2"
                onClick={() => Navigate("/")}
              >
                Back to login
              </Link>
              <AdminButton
                onClick={handleSubmit}
                title={"Submit"}
                fullWidth
                height={40}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
