import { useTheme } from "@emotion/react";
import {
  useMediaQuery,
  Card,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { color } from "theme/color";
import SVButton from "components/SVButton";
import { useEffect, useState } from "react";
import { getSiteSetting, updateSiteSetting } from "store/slice/settings";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import SVDivider from "components/SVDivider";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const GlobalEstateDocumentNumber = ({ ...props }) => {
  const { openDialog,setOpen, setIsLoading,isLoading } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [documentData, setDocumentData] = useState({});
  const { siteSettingData } = useSelector((state) => state.settings);
  useEffect(() => {
    dispatch(getSiteSetting(setIsLoading));
  }, []);
  useEffect(() => {
    if (siteSettingData) {
      setDocumentData(siteSettingData);
    }
  }, [siteSettingData]);
  const countData = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const handleSave = () => {
    setIsLoading(true);
    let request = {
      siteSettingData: documentData,
    };
    dispatch(updateSiteSetting(request, setIsLoading,closeDialog));
  };
  const closeDialog=()=>{
    setOpen(false)
  }
  return (
    <React.Fragment>
      <BootstrapDialog
        PaperProps={{
          style: {
            backgroundColor: "#202020",
          },
        }}
        fullWidth={"sm"}
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h6" style={{ color: color.main }}>
            {/* {type === "accept" ? "Accept" : "Reject "} The Estate Planning Documents */}
          Documents Limit
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <SVDivider style={{ marginTop: 10 }} />
        <DialogContent>
          <div>
            {/* LAST WILL AND TESTAMENT CREATION */}
            <Card
              sx={{
                color: color.main,
                background: "#0E0E0E",
                p: 3,
              }}
            >
              <Typography variant="h6">
                Last Will And Testament Creation
              </Typography>
              <Grid container mt={4} gap={2}>
                <Grid xs={isMobile ? 12 : 5.8}>
                  <Typography fontWeight={600} mb={0.5}>
                    Number Of Creation
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={
                        documentData?.ESTATE_LAST_WILL_AND_TESTAMENT_CREATION_LIMIT ||
                        ""
                      }
                      onChange={(e) => {
                        setDocumentData((documentData) => ({
                          ...documentData,
                          ESTATE_LAST_WILL_AND_TESTAMENT_CREATION_LIMIT:
                            e.target.value,
                        }));
                      }}
                      sx={{
                        color: "white",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {countData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={isMobile ? 12 : 5.8}>
                  <Typography fontWeight={600} mb={0.5}>
                    Number Of Revision
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={
                        documentData?.ESTATE_LAST_WILL_AND_TESTAMENT_UPDATION_LIMIT ||
                        ""
                      }
                      onChange={(e) => {
                        setDocumentData((documentData) => ({
                          ...documentData,
                          ESTATE_LAST_WILL_AND_TESTAMENT_UPDATION_LIMIT:
                            e.target.value,
                        }));
                      }}
                      sx={{
                        color: "white",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {countData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
            {/* Power Of Attorney */}
            <Card
              sx={{
                color: color.main,
                background: "#0E0E0E",
                p: 3,
                mt: 2,
              }}
            >
              <Typography variant="h6">Power Of Attorney</Typography>
              <Grid container mt={4} gap={2}>
                <Grid xs={isMobile ? 12 : 5.8}>
                  <Typography fontWeight={600} mb={0.5}>
                    Number Of Creation
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={
                        documentData?.ESTATE_POWER_OF_ATTORNEY_CREATION_LIMIT ||
                        ""
                      }
                      onChange={(e) => {
                        setDocumentData((documentData) => ({
                          ...documentData,
                          ESTATE_POWER_OF_ATTORNEY_CREATION_LIMIT:
                            e.target.value,
                        }));
                      }}
                      sx={{
                        color: "white",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {countData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={isMobile ? 12 : 5.8}>
                  <Typography fontWeight={600} mb={0.5}>
                    Number Of Revision
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={
                        documentData?.ESTATE_POWER_OF_ATTORNEY_UPDATION_LIMIT ||
                        ""
                      }
                      onChange={(e) => {
                        setDocumentData((documentData) => ({
                          ...documentData,
                          ESTATE_POWER_OF_ATTORNEY_UPDATION_LIMIT:
                            e.target.value,
                        }));
                      }}
                      sx={{
                        color: "white",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {countData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>

            {/* Hippa Release And Authorization */}
            <Card
              sx={{
                color: color.main,
                background: "#0E0E0E",
                p: 3,
                mt: 2,
              }}
            >
              <Typography variant="h6">
                Hipaa Release And Authorization
              </Typography>
              <Grid container mt={4} gap={2}>
                <Grid xs={isMobile ? 12 : 5.8}>
                  <Typography fontWeight={600} mb={0.5}>
                    Number Of Creation
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={
                        documentData?.ESTATE_HIPPA_RELEASE_AND_AUTHORIZATION_CREATION_LIMIT ||
                        ""
                      }
                      onChange={(e) => {
                        setDocumentData((documentData) => ({
                          ...documentData,
                          ESTATE_HIPPA_RELEASE_AND_AUTHORIZATION_CREATION_LIMIT:
                            e.target.value,
                        }));
                      }}
                      sx={{
                        color: "white",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {countData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={isMobile ? 12 : 5.8}>
                  <Typography fontWeight={600} mb={0.5}>
                    Number Of Revision
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={
                        documentData?.ESTATE_HIPPA_RELEASE_AND_AUTHORIZATION_UPDATION_LIMIT ||
                        ""
                      }
                      onChange={(e) => {
                        setDocumentData((documentData) => ({
                          ...documentData,
                          ESTATE_HIPPA_RELEASE_AND_AUTHORIZATION_UPDATION_LIMIT:
                            e.target.value,
                        }));
                      }}
                      sx={{
                        color: "white",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {countData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>

            {/* Living Will/Health Care Proxy */}
            <Card
              sx={{
                color: color.main,
                background: "#0E0E0E",
                p: 3,
                mt: 2,
              }}
            >
              <Typography variant="h6">
                Living Will/Health Care Proxy
              </Typography>
              <Grid container mt={4} gap={2}>
                <Grid xs={isMobile ? 12 : 5.8}>
                  <Typography fontWeight={600} mb={0.5}>
                    Number Of Creation
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={
                        documentData?.ESTATE_LIVING_WELL_HEALTH_CARE_PROXY_CREATION_LIMIT ||
                        ""
                      }
                      onChange={(e) => {
                        setDocumentData((documentData) => ({
                          ...documentData,
                          ESTATE_LIVING_WELL_HEALTH_CARE_PROXY_CREATION_LIMIT:
                            e.target.value,
                        }));
                      }}
                      sx={{
                        color: "white",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {countData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={isMobile ? 12 : 5.8}>
                  <Typography fontWeight={600} mb={0.5}>
                    Number Of Revision
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={
                        documentData?.ESTATE_WELL_HEALTH_CARE_PROXY_UPDATION_LIMIT ||
                        ""
                      }
                      onChange={(e) => {
                        setDocumentData((documentData) => ({
                          ...documentData,
                          ESTATE_WELL_HEALTH_CARE_PROXY_UPDATION_LIMIT:
                            e.target.value,
                        }));
                      }}
                      sx={{
                        color: "white",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {countData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>

            <Grid container mt={4}>
              <Grid xs={12}>
                <SVButton
                isLoading={isLoading}
                  onClick={handleSave}
                  text="Save"
                  sx={{ px: 3, width: "100%" }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default React.memo(GlobalEstateDocumentNumber);
