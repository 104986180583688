import { useEffect, useState } from "react";
import { useParams } from "react-router";
// import InsuranceSelect from './insuranceSelect';
import { useDispatch, useSelector } from "react-redux";
import { INSURANCET_TYPES } from "utils/appConstant";
import { validateEmpty } from "utils/constantFun";
import Spinner from "components/spinner";
import {
  Box,
  Breadcrumbs,
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import SVButton from "components/SVButton";
import AdminInput from "components/adminInput";
import { color } from "theme/color";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { IncludeInPlanSelect, InsuranceSelect } from "./insuranceSelect";
import { profileDetail } from "store/slice/profile";
import {
  createInsurance,
  getBenefitPeriods,
  getBenefitsTypes,
  getEliminationPeriod,
  getInflationTypes,
  getInsuranceDetails,
  updateInsurance,
} from "store/slice/assets";

export default function IndividualDisability() {
  let screenType = "false";
  const dispatch = useDispatch();
  const { id } = useParams();
  const { insuranceId } = useParams();
  const { profileDetailData } = useSelector((state) => state.profile);
  const {
    insuranceDetails,
    benefitsTypesData,
    benefitPeriodData,
    inflationTypesData,
    eliminationPeriodsData,
  } = useSelector((state) => state.assets);
  const [isLoading, setIsloading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
    var requestParams = {
      id: id,
    };
    dispatch(profileDetail(requestParams, true));
    dispatch(getInflationTypes());
    dispatch(getEliminationPeriod());
    dispatch(getBenefitPeriods());
    dispatch(getBenefitsTypes());
  }, []);
  const [disabilityData, setDisabilityData] = useState({});

  //  get insurance details
  useEffect(() => {
    if (insuranceId) {
      setIsloading(true);
      dispatch(getInsuranceDetails(insuranceId, setIsloading));
    }
  }, [insuranceId]);
  useEffect(() => {
    if (insuranceDetails) {
      setDisabilityData(insuranceDetails);
    }
  }, [insuranceDetails]);
  const handleSubmit = () => {
    if (validateEmpty(disabilityData?.policy_name)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        policy_nameErr: "Please enter policy name",
      }));
      return;
    }
    if (validateEmpty(disabilityData?.annual_premium)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        annual_premiumErr: "Please enter annual premium",
      }));
      return;
    }
    if (validateEmpty(disabilityData?.benefit_type_id)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        benefit_type_idErr: "Please select benefit type",
      }));
      return;
    }
    if (validateEmpty(disabilityData?.monthly_benefit)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        monthly_benefitErr: "Please enter monthly benefit",
      }));
      return;
    }
    if (disabilityData?.monthly_benefit_cap == undefined) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        monthly_benefit_capErr: "Please select monthly benefit cap",
      }));
      return;
    }
    if (validateEmpty(disabilityData?.benefit_inflation)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        benefit_inflationErr: "Please enter benefit inflation",
      }));
      return;
    }
    if (validateEmpty(disabilityData?.benefit_period_id)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        benefit_period_idErr: "Please select benefit period",
      }));
      return;
    }

    if (validateEmpty(disabilityData?.policy_ends_period_id)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        policy_ends_period_idErr: "Please select policy ends",
      }));
      return;
    }
    if (validateEmpty(disabilityData?.policy_end_year)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        policy_end_yearErr: "Please enter policy ends year",
      }));
      return;
    }
    if (disabilityData?.included_in_plan == undefined) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        included_in_planErr: "Please select included in plan",
      }));
      return;
    }
    if (disabilityData?.benefit_taxable == undefined) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        benefit_taxableErr: "Please select benefit taxable",
      }));
      return;
    }
    if (validateEmpty(disabilityData?.inflation_type_id)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        inflation_type_idErr: "Please select inflation type",
      }));
      return;
    }
    if (validateEmpty(disabilityData?.elimination_period_id)) {
      setDisabilityData((disabilityData) => ({
        ...disabilityData,
        elimination_period_idErr: "Please select elimination period",
      }));
      return;
    }
    setIsloading(true);

    let request = {
      user_id: id,
      type_id: INSURANCET_TYPES?.INDIVIDUAL_DISABILITY,
      policy_name: disabilityData?.policy_name,
      owner: profileDetailData?.first_name,
      annual_premium: disabilityData?.annual_premium,
      policy_ends_period_id: disabilityData?.policy_ends_period_id,
      policy_end_year: disabilityData?.policy_end_year,
      benefit_type_id: disabilityData?.benefit_type_id,
      monthly_benefit: disabilityData?.monthly_benefit,
      monthly_benefit_cap: disabilityData?.monthly_benefit_cap,
      benefit_inflation: disabilityData?.benefit_inflation,
      benefit_period_id: disabilityData?.benefit_period_id,
      benefit_taxable: disabilityData?.benefit_taxable,
      inflation_type_id: disabilityData?.inflation_type_id,
      elimination_period_id: disabilityData?.elimination_period_id,
      included_in_plan: disabilityData?.included_in_plan,
    };
    if (insuranceId) {
      dispatch(
        updateInsurance(request, insuranceId, id, navigate, setIsloading)
      );
    } else {
      dispatch(createInsurance(request, id, navigate, setIsloading));
    }
  };
  return (
    <div>
      <Spinner open={isLoading} />
      <div>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Insurance"} />

        <Grid container mt={4} sx={{ px: isMobile ? 2 : "" }}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} to="/users">
                Users
              </Link>
              <Link style={{ color: "white" }} to={`/profile/${id}`}>
                User Profile
              </Link>
              <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
                Assets-Liabilities Details
              </Link>
              <Link
                style={{ color: "white" }}
                to={`/assetsDetail/insurance/${id}`}
              >
                Insurance
              </Link>
              <Typography sx={{ color: "white" }}>
                Individual Disability
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box
          sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 2 }}
        >
          <h2
            className="textLinearBackground mt-3 "
            style={{ marginLeft: screenType === "mobile" ? "1rem" : "" }}
          >
            Individual Disability
          </h2>
          <Grid container columnSpacing={3}>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Policy name"
                maxLength={30}
                onChange={(event) => {
                  setDisabilityData((disabilityData) => ({
                    ...disabilityData,
                    policy_name: event.target.value,
                    policy_nameErr: "",
                  }));
                }}
                value={disabilityData?.policy_name || ""}
                formError={disabilityData?.policy_nameErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                name="occupation"
                title="Name"
                value={profileDetailData?.first_name || ""}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Annual premium"
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setDisabilityData((disabilityData) => ({
                      ...disabilityData,
                      annual_premium: event.target.value * 1,
                      annual_premiumErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setDisabilityData((disabilityData) => ({
                        ...disabilityData,
                        annual_premium: "",
                      }));
                    }
                  }
                }}
                value={disabilityData?.annual_premium || ""}
                formError={disabilityData?.annual_premiumErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Benefit type"
                customOptions={benefitsTypesData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setDisabilityData((disabilityData) => ({
                    ...disabilityData,
                    benefit_type_id: event.target.value,
                    benefit_type_idErr: "",
                  }));
                }}
                value={disabilityData?.benefit_type_id || ""}
                error={disabilityData?.benefit_type_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                maxLength={13}
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Monthly benefit "
                value={disabilityData?.monthly_benefit || ""}
                formError={disabilityData?.monthly_benefitErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setDisabilityData((disabilityData) => ({
                      ...disabilityData,
                      monthly_benefit: event.target.value * 1,
                      monthly_benefitErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setDisabilityData((disabilityData) => ({
                        ...disabilityData,
                        monthly_benefit: "",
                      }));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title={"Monthly benefit cap"}
                onChange={(event) => {
                  setDisabilityData((disabilityData) => ({
                    ...disabilityData,
                    monthly_benefit_cap: event.target.value,
                    monthly_benefit_capErr: "",
                  }));
                }}
                value={
                  disabilityData?.monthly_benefit_cap == true ||
                  disabilityData?.monthly_benefit_cap == false
                    ? disabilityData?.monthly_benefit_cap
                    : ""
                }
                error={disabilityData?.monthly_benefit_capErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Benefit inflation"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={disabilityData?.benefit_inflation || ""}
                formError={disabilityData?.benefit_inflationErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setDisabilityData((disabilityData) => ({
                        ...disabilityData,
                        benefit_inflation: 100,
                        benefit_inflationErr: "",
                      }));
                    } else {
                      setDisabilityData((disabilityData) => ({
                        ...disabilityData,
                        benefit_inflation: event.target.value * 1,
                        benefit_inflationErr: "",
                      }));
                    }
                  } else {
                    setDisabilityData((disabilityData) => ({
                      ...disabilityData,
                      benefit_inflation: "",
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Benefit period"
                customOptions={benefitPeriodData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setDisabilityData((disabilityData) => ({
                    ...disabilityData,
                    benefit_period_id: event.target.value,
                    benefit_period_idErr: "",
                  }));
                }}
                value={disabilityData?.benefit_period_id || ""}
                error={disabilityData?.benefit_period_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Grid container columnSpacing={3}>
                <Grid item xs={6}>
                  <InsuranceSelect
                    title={" Policy ends"}
                    onChange={(event) => {
                      setDisabilityData((disabilityData) => ({
                        ...disabilityData,
                        policy_ends_period_id: event.target.value,
                        policy_ends_period_idErr: "",
                      }));
                    }}
                    value={disabilityData?.policy_ends_period_id || ""}
                    error={disabilityData?.policy_ends_period_idErr}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdminInput
                    color="white"
                    type="text"
                    maxLength={4}
                    title="Year's"
                    onChange={(event) => {
                      const numeric = /^[0-9\b]+$/;
                      if (
                        event.target.value &&
                        numeric.test(event.target.value)
                      ) {
                        setDisabilityData((disabilityData) => ({
                          ...disabilityData,
                          policy_end_year: event.target.value * 1,
                          policy_end_yearErr: "",
                        }));
                      } else {
                        if (!event.target.value) {
                          setDisabilityData((disabilityData) => ({
                            ...disabilityData,
                            policy_end_year: "",
                          }));
                        }
                      }
                    }}
                    value={disabilityData?.policy_end_year || ""}
                    formError={disabilityData?.policy_end_yearErr}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                onChange={(event) => {
                  setDisabilityData((disabilityData) => ({
                    ...disabilityData,
                    included_in_plan: event.target.value,
                    included_in_planErr: "",
                  }));
                }}
                value={
                  disabilityData?.included_in_plan == true ||
                  disabilityData?.included_in_plan == false
                    ? disabilityData?.included_in_plan
                    : ""
                }
                error={disabilityData?.included_in_planErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Benefit taxable"
                onChange={(event) => {
                  setDisabilityData((disabilityData) => ({
                    ...disabilityData,
                    benefit_taxable: event.target.value,
                    benefit_taxableErr: "",
                  }));
                }}
                value={
                  disabilityData?.benefit_taxable == true ||
                  disabilityData?.benefit_taxable == false
                    ? disabilityData?.benefit_taxable
                    : ""
                }
                error={disabilityData?.benefit_taxableErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Inflation type"
                customOptions={inflationTypesData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setDisabilityData((disabilityData) => ({
                    ...disabilityData,
                    inflation_type_id: event.target.value,
                    inflation_type_idErr: "",
                  }));
                }}
                value={disabilityData?.inflation_type_id || ""}
                error={disabilityData?.inflation_type_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Elimination period"
                customOptions={eliminationPeriodsData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setDisabilityData((disabilityData) => ({
                    ...disabilityData,
                    elimination_period_id: event.target.value,
                    elimination_period_idErr: "",
                  }));
                }}
                value={disabilityData?.elimination_period_id || ""}
                error={disabilityData?.elimination_period_idErr}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={isMobile ? 12 : 3} mt={5}>
              <SVButton fullWidth text={"Submit"} onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
