import {
  Card,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import { color } from "theme/color";
import React, { useEffect, useRef } from "react";
import SVDivider from "components/SVDivider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { getinit, plaidExchange } from "store/slice/assets";
import { useDispatch, useSelector } from "react-redux";
import { PlaidLink } from "react-plaid-link";
import { liabilitiesSummary } from "store/slice/profile";
import Spinner from "components/spinner";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddLiabilitiesDialog = ({ ...props }) => {
  const { open, setOpen, userId, setIsloading, isLoading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const closeDialog = () => {
    setOpen(false);
  };
  const { getToken } = useSelector((state) => state.assets);
  const plaidLinkRef = useRef(null);

  useEffect(() => {
    // Fetch token from the API before initializing PlaidLink
    dispatch(getinit(id));
  }, []);

  const fetchData = () => {
    var data = {
      userId: id,
    };
    dispatch(liabilitiesSummary(data, setIsloading));
  };
  // Define your PlaidLinkOptions
  const config = {
    onSuccess: (public_token, metadata) => {
      console.log("metaData", metadata);
      // Handle success
    },
    onExit: (err, metadata) => {
      // Handle exit

      console.log("err", err);
      console.log("metadata", metadata);
    },
    onEvent: (eventName, metadata) => {
      console.log("eventName", eventName);
      console.log("metaData", metadata);

      // Handle event
    },
    token: getToken?.link_token, // Your generated Link token
  };

  //  for credit card
  const handleLinkCallback = (public_token, metadata) => {
    var accounts = [];

    metadata?.accounts?.forEach((item) => {
      accounts.push({
        id: item?.id,
        name: item?.name,
        mask: item?.mask,
        subtype: item?.subtype,
        type: item?.type,
      });
    });
    setIsloading(true);
    var data = {
      institution: {
        id: metadata?.institution?.institution_id,
        name: metadata?.institution?.name,
      },
      publicToken: metadata?.public_token,

      accounts: accounts,
    };
    dispatch(plaidExchange(id, data, setIsloading, fetchData, closeDialog));
    // Handle PlaidLink callback here
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="paper"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: "#202020",
          },
        }}
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h6" style={{ color: color.main }}>
            Liabilities
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <SVDivider style={{ marginTop: 10 }} />
        <DialogContent>
          {/* Credit Card */}
          <PlaidLink
            token={config.token}
            onSuccess={handleLinkCallback}
            onExit={config.onExit}
            onEvent={config.onEvent}
            ref={plaidLinkRef}
            style={{
              cursor: "pointer",
              width: "100%",
              background: "transparent",
              border: "none",
            }}
          >
            <Card
              sx={{
                color: color.main,
                background: "#0E0E0E",
                cursor: "pointer",
                p: 2,
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Credit Card</Typography>
              <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
            </Card>
          </PlaidLink>
          {/* Loans */}
          <Card
            onClick={() => {
              navigate(`/assetsDetail/loans/${userId}`);
            }}
            sx={{
              color: color.main,
              background: "#0E0E0E",
              cursor: "pointer",
              p: 2,
              mt: 1,
              mx: 0.5,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>Loans</Typography>
            <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
          </Card>
            {/* manual */}
        <Card
          onClick={() => {
            // dispatch(clearRealEstateDetail());
            navigate(`/assetsDetail/manual-assets/add/${userId}`);
          }}
          sx={{
            color: color.main,
            background: "#0E0E0E",
            cursor: "pointer",
            p: 2,
            mx: 0.5,
            mt: 1.8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>Manual</Typography>
          <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
        </Card>
          {/* other */}
          <Card
            onClick={() => {
              navigate(`/assetsDetail/other-liabilities/add/${userId}`);
            }}
            sx={{
              color: color.main,
              background: "#0E0E0E",
              cursor: "pointer",
              p: 2,
              mx: 0.5,
              mt: 1.8,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>Others</Typography>
            <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
          </Card>
        </DialogContent>
        <Spinner open={isLoading} />
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(AddLiabilitiesDialog);
