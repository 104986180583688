import {
  Card,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import { color } from "theme/color";
import React, { useEffect, useRef } from "react";
import SVDivider from "components/SVDivider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearRealEstateDetail,
  getinit,
  getinitIncome,
  plaidExchange,
  plaidExchangeIncome,
} from "store/slice/assets";
import { useDispatch, useSelector } from "react-redux";
import { PlaidLink } from "react-plaid-link";
import { assetsSummary } from "store/slice/profile";
import Spinner from "components/spinner";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddAssetsDialog = ({ ...props }) => {
  const { open, setOpen, userId, setIsloading, isLoading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const closeDialog = () => {
    setOpen(false);
  };
  const { getTokenIncome, getToken } = useSelector((state) => state.assets);
  const plaidLinkRef = useRef(null);

  useEffect(() => {
    // Fetch token from the API before initializing PlaidLink
    dispatch(getinitIncome(id));
    dispatch(getinit(id));
  }, [dispatch]);

  const fetchAssetsData = () => {
    var data = {
      userId: id,
    };
    dispatch(assetsSummary(data, setIsloading));
  };
  // Define your PlaidLinkOptions
  const config = {
    onSuccess: (public_token, metadata) => {
      console.log("metaData", metadata);

      // Handle success
    },
    onExit: (err, metadata) => {
      // Handle exit

      console.log("err", err);
      console.log("metadata", metadata);
    },
    onEvent: (eventName, metadata) => {
      console.log("eventName", eventName);
      console.log("metaData", metadata);

      // Handle event
    },
    token: getTokenIncome?.link_token, // Your generated Link token
  };
  //  for bank
  const configBank = {
    onSuccess: (public_token, metadata) => {
      console.log("metaData", metadata);
      // Handle success
    },
    onExit: (err, metadata) => {
      // Handle exit

      console.log("err", err);
      console.log("metadata", metadata);
    },
    onEvent: (eventName, metadata) => {
      console.log("eventName", eventName);
      console.log("metaData", metadata);

      // Handle event
    },
    token: getToken?.link_token, // Your generated Link token
  };
  // Handle PlaidLink callback fro income
  const handleLinkCallback = (public_token, metadata) => {
    var accounts = [];

    metadata?.accounts?.forEach((item) => {
      accounts.push({
        id: item?.id,
        name: item?.name,
        mask: item?.mask,
        subtype: item?.subtype,
        type: item?.type,
      });
    });
    setIsloading(true);
    var data = {
      institution: {
        id: metadata?.institution?.institution_id,
        name: metadata?.institution?.name,
      },
      publicToken: metadata?.public_token,

      accounts: accounts,
    };
    dispatch(
      plaidExchangeIncome(id, data, setIsloading, fetchAssetsData, closeDialog)
    );
    // Handle PlaidLink callback here
  };
  //  for bank
  const handleLinkCallbackForBank = (public_token, metadata) => {
    var accounts = [];

    metadata?.accounts?.forEach((item) => {
      accounts.push({
        id: item?.id,
        name: item?.name,
        mask: item?.mask,
        subtype: item?.subtype,
        type: item?.type,
      });
    });
    setIsloading(true);
    var data = {
      institution: {
        id: metadata?.institution?.institution_id,
        name: metadata?.institution?.name,
      },
      publicToken: metadata?.public_token,

      accounts: accounts,
    };
    dispatch(
      plaidExchange(id, data, setIsloading, fetchAssetsData, closeDialog)
    );
    // Handle PlaidLink callback here
  };

  return (
    // <React.Fragment>
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "#202020",
        },
      }}
      onClose={closeDialog}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6" style={{ color: color.main }}>
          Assets
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider style={{ marginTop: 10 }} />
      <DialogContent>
        {/* real estate */}
        <Card
          onClick={() => {
            dispatch(clearRealEstateDetail());
            navigate(`/assetsDetail/real-estate/add/${userId}`);
          }}
          sx={{
            color: color.main,
            background: "#0E0E0E",
            cursor: "pointer",
            p: 2,
            mx: 0.5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>Real Estate</Typography>
          <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
        </Card>

        {/* Income */}

        <PlaidLink
          token={config.token}
          onSuccess={handleLinkCallback}
          onExit={config.onExit}
          onEvent={config.onEvent}
          ref={plaidLinkRef}
          style={{
            cursor: "pointer",
            width: "100%",
            background: "transparent",
            border: "none",
          }}
        >
          <Card
            sx={{
              color: color.main,
              background: "#0E0E0E",
              cursor: "pointer",
              p: 2,
              mt: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>Income</Typography>
            <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
          </Card>
        </PlaidLink>
        {/* Investments */}
        <Card
          onClick={() => {
            navigate(`/assetsDetail/investment/${userId}`);
          }}
          sx={{
            color: color.main,
            background: "#0E0E0E",
            cursor: "pointer",
            p: 2,
            mx: 0.5,
            mt: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>Investments</Typography>
          <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
        </Card>
        {/* Banks */}
        <PlaidLink
          token={configBank.token}
          onSuccess={handleLinkCallbackForBank}
          onExit={configBank.onExit}
          onEvent={configBank.onEvent}
          ref={plaidLinkRef}
          style={{
            cursor: "pointer",
            width: "100%",
            background: "transparent",
            border: "none",
          }}
        >
          <Card
            sx={{
              color: color.main,
              background: "#0E0E0E",
              cursor: "pointer",
              p: 2,
              mt: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>Banks</Typography>
            <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
          </Card>
        </PlaidLink>
        {/* insurance */}
        <Card
          onClick={() => {
            navigate(`/assetsDetail/insurance/${userId}`);
          }}
          sx={{
            color: color.main,
            background: "#0E0E0E",
            cursor: "pointer",
            p: 2,
            mt: 1,
            mx: 0.5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>Insurance</Typography>
          <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
        </Card>
        {/* manual */}
        <Card
          onClick={() => {
            // dispatch(clearRealEstateDetail());
            navigate(`/assetsDetail/manual-assets/add/${userId}`);
          }}
          sx={{
            color: color.main,
            background: "#0E0E0E",
            cursor: "pointer",
            p: 2,
            mx: 0.5,
            mt: 1.8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>Manual</Typography>
          <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
        </Card>
        {/* other */}
        <Card
          onClick={() => {
            // dispatch(clearRealEstateDetail());
            navigate(`/assetsDetail/other-assets/add/${userId}`);
          }}
          sx={{
            color: color.main,
            background: "#0E0E0E",
            cursor: "pointer",
            p: 2,
            mx: 0.5,
            mt: 1.8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>Others</Typography>
          <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
        </Card>
      </DialogContent>
      <Spinner open={isLoading} />
    </Dialog>
    // </React.Fragment>
  );
};

export default React.memo(AddAssetsDialog);
