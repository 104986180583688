import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Icon } from "@iconify/react";
import EditRoleDialog from "../teamManagment/subComponent/editRole/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getPermissionLevel,
  getPermissionList,
  getRolePermissionDetail,
} from "store/slice/profile";
import Spinner from "components/spinner";
import { deleteRole, getRoleList } from "store/slice/estate";
import SVButton from "components/SVButton";
import AddRoleDialog from "../teamManagment/subComponent/addRole/index";
import AlertDialog from "components/alertDialog/alertDialog";
export default function Role() {
  // var page = 1;
  // const perPage = 10;
  const dispatch = useDispatch();
  // const [perPageRows, setNumberOfRows] = useState(perPage);
  // const [pageCheck, setPageCheck] = useState(1);
  const [openAddTeamDialog, setOpenAddTeamDialog] = useState(false);
  const [addRoleDialog, setAddRoleDialog] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const { isLoading } = useSelector((state) => state.profile);
  const { roleListData } = useSelector((state) => state.estate);
  //  fetch permission list
  useEffect(() => {
    dispatch(getPermissionList(true));
    fetchRoleList();
    dispatch(getPermissionLevel());
  }, []);

  //   fetch team list
  const fetchRoleList = () => {
    dispatch(getRoleList());
  };
  //  mui data table options
  const options = {
    count: roleListData?.length,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },

    // onChangePage(currentPage) {
    //   if (currentPage >= pageCheck) {
    //     if (currentPage === 0) {
    //       currentPage = currentPage + 1;
    //     }
    //     setPageCheck(currentPage);
    //     page = currentPage + 1;
    //     fetchRoleList(page, "");
    //   } else {
    //     if (currentPage > 0) {
    //       page = currentPage - 1;
    //     }

    //     setPageCheck(currentPage);
    //     fetchRoleList(page, "");
    //   }
    // },
    // onChangeRowsPerPage(numberOfRows) {
    //   setNumberOfRows(numberOfRows);
    //   fetchRoleList(page, numberOfRows);
    // },
  };
  //  mui data table column

  const columns = [
    {
      name: "name",
      label: "Role Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Stack gap={3} direction={"row"}>
              <Icon
                icon="carbon:edit"
                height={30}
                color="#1098DB"
                onClick={() => {
                  handleEditClick(value);
                }}
              />

              <Icon
                height={30}
                onClick={() => {
                  setRoleId(value);
                  setOpenAlertDialog(true);
                }}
                icon="ic:baseline-delete"
                color="red"
              />
            </Stack>
          );
        },
      },
    },
  ];

  //  update click
  const handleEditClick = (id) => {
    let index = roleListData?.findIndex((item) => item.id == id);
    setRoleId(id);
    setRoleName(roleListData[index]?.name);
    dispatch(getRolePermissionDetail(id));
    setOpenAddTeamDialog(true);
  };
  const handleDelete = () => {
    dispatch(deleteRole(roleId,setOpenAlertDialog));
  };
  return (
    <>
      {!isLoading && (
        <>
          <EditRoleDialog
            roleName={roleName}
            openDialog={openAddTeamDialog}
            setOpenDialog={setOpenAddTeamDialog}
            roleId={roleId}
          />
          <AddRoleDialog
            openDialog={addRoleDialog}
            setOpenDialog={setAddRoleDialog}
          />
          <AlertDialog
            open={openAlertDialog}
            handleClose={() => setOpenAlertDialog(false)}
            title={"Remove Confirmation!"}
            text={"Are you sure you want to remove this role?"}
            handleAgree={handleDelete}
          />
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
              p: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 500,
                color: "white",
                marginTop: "0.5rem",
              }}
            >
              {"Role"}
            </Typography>
            <SVButton
              text={"Add Role"}
              onClick={() => setAddRoleDialog(true)}
            />
          </Grid>

          {roleListData && roleListData.length > 0 ? (
            <Grid container sx={{ mt: 3.1 }}>
              <Grid xs={12}>
                <MUIDataTable
                  data={roleListData}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid #202020",
                  mt: 2,
                  height: 300,
                  backgroundColor: "#202020",
                }}
              >
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 500,
                      mt: "7.4rem",
                      color: "white",
                    }}
                  >
                    No Record Found
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      <Spinner open={isLoading} />
    </>
  );
}
