import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  financialAccountDetailsApi,
  transactionListApi,
} from "store/slice/profile";
import { color } from "theme/color";
import { PAGINATION } from "utils/appConstant";
import { useTheme } from "@emotion/react";
import AdminInput from "components/adminInput";
import Spinner from "components/spinner";

const TransactionList = () => {
  const param = useParams();
  const {
    transactionListData,
    transactionTotalCount,
    isLoading,
    financialAccountData,
  } = useSelector((state) => state.profile);
  console.log("open={isLoading}", transactionTotalCount);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [startDate, setStartDate] = useState("dd/mm/yyyy");
  const [endDate, setEndDate] = useState("dd/mm/yyyy");
  const [startDateForSend, setStartDateForSend] = useState("");
  const [endDateForSend, setEndDateForSend] = useState("");
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const [page, setPage] = useState(PAGINATION.page);

  // Define fetchTransactionList before using it

  const fetchTransactionList = useCallback(() => {
    endDateForSend === "Invalid date" && setEndDateForSend("dd/mm/yyyy");
    const financialId = financialAccountData?.[0]?.financialAccountId;

    if (!financialId) {
      console.log(
        "No financialId available. Financial Account Data:",
        financialAccountData
      );
      return;
    }

    console.log("Fetching transactions with:", {
      financialId,
      fromDate: startDateForSend,
      toDate: endDateForSend,
      page,
      perPage: perPageRows,
      userId: param?.id,
    });

    const requestParams = {
      financialAccountId: financialId,
      fromDate: startDateForSend,
      toDate: endDateForSend,
      page,
      perPage: perPageRows,
      userId: param?.id,
    };

    dispatch(transactionListApi(requestParams, true))
      .then((response) => {
        console.log("API Response:", response);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, [
    dispatch,
    endDateForSend,
    financialAccountData,
    page,
    param?.id,
    perPageRows,
    startDateForSend,
  ]);

  useEffect(() => {
    console.log(param, "paramIn");

    if (param?.id) {
      console.log(param, "paramOut");
      dispatch(financialAccountDetailsApi(param?.id))
        .then(() => {
          console.log("Financial account details fetched.");
        })
        .catch((error) => {
          console.error("Error fetching financial account details:", error);
        });
    }
  }, [param?.id, dispatch]);

  useEffect(() => {
    fetchTransactionList();
  }, [fetchTransactionList]);

  const handleStartDate = (value) => {
    const formattedDate = moment(value).format("YYYY-MM-DD");
    setStartDate(value);
    setStartDateForSend(formattedDate);
  };

  const handleEndDate = (value) => {
    const formattedDate = moment(value).format("YYYY-MM-DD");
    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(formattedDate === "Invalid date" ? "dd/mm/yyyy" : startDate);

    fetchTransactionList();
  };

  const columns = [
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
            }}
          >
            {value}
          </Typography>
        ),
      },
    },

    {
      name: "name",
      label: "From",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
            }}
          >
            {value}
          </Typography>
        ),
      },
    },

    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <Typography>
            {value ? moment(value).format("MMMM Do, YYYY, h:mm A") : "-"}{" "}
          </Typography>
        ),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          let color;
          let background;
          switch (value) {
            case "pending":
              color = "#fff";
              background = "#1098DB";
              break;
            case "COMPLETED":
              color = "#fff";
              background = "#4BA64F";
              break;
            case "REJECTED":
              color = "#fff";
              background = "#DD7176";
              break;
            default:
              color = "#fff";
              background = "grey";

          }

          return (
            <Typography>
              <span
                style={{
                  color: color,
                  background: background,
                  fontSize: 11,
                  padding: 4,
                  borderRadius: "10%",
                }}
              >
                <b>Pending</b>
              </span>
            </Typography>
          );
        },
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
              color: "white",
            }}
          >
            $ {value?.value}
          </Typography>
        ),
      },
    },
  ];

  const options = {
    count: transactionTotalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage: (currentPage) => {
      setPage(currentPage);
      fetchTransactionList();
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setNumberOfRows(numberOfRows);
      fetchTransactionList();
    },
  };

  return (
    <div>
      <Grid container sx={{ p: 3, mt: -5 }}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/users">
              Users
            </Link>
            <Link style={{ color: "white" }} to={`/profile/${param?.id}`}>
              User Profile
            </Link>
            <Typography sx={{ color: "white" }}>
              Transactions History
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Box sx={{ background: color.black, borderRadius: "10px", p: 3 }}>
        <Grid
          item
          sx={{ mb: 4 }}
          display="flex"
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography
              style={{ fontSize: 20 }}
              className="textLinearBackground"
            >
              Transactions History
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }} display="flex">
          <Grid item xs={isMobile ? 6 : 3}>
            <AdminInput
              color="white"
              type={"date"}
              title={"Start Date"}
              sx={{
                mt: -1,
                pr: 2,
                background: "#202020",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)",
                },
              }}
              value={startDate || ""}
              onChange={(e) => handleStartDate(e.target.value)}
              placeholder="Start Date"
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <AdminInput
              color="white"
              type={"date"}
              title={"End Date"}
              sx={{
                mt: -1,
                pr: 2,
                background: "#202020",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)",
                },
              }}
              value={endDate || ""}
              onChange={(e) => handleEndDate(e.target.value)}
              placeholder="End Date"
            />
          </Grid>
        </Grid>
        {transactionListData?.length > 0 ? (
          <MUIDataTable
            data={transactionListData}
            columns={columns}
            options={options}
          />
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 18,
                  textAlign: "center",
                  fontWeight: 400,
                  color: "#C5C5C5",
                }}
              >
                No Transactions Available
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>
      {isLoading && <Spinner open={isLoading} />}
    </div>
  );
};

export default TransactionList;
