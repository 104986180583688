import { Typography, Box, Container } from "@mui/material";
import { color } from "../../../theme/color";
// components
// sections

// ----------------------------------------------------------------------

export default function LoanRequest() {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          mx: "auto",
          my: "auto",
          position: "absolute",
          textAlign: "center",
          left: "50%",
          top: "45%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          sx={{ color: color.main, fontSize: 22, fontWeight: 500 }}
          component="h1"
          variant="h2"
        >
          Coming Soon
        </Typography>
      </Box>
    </Container>
  );
}
