import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./slice/auth";
import profile from "./slice/profile";
import settings from "./slice/settings";
import estate from "./slice/estate";
import assets from "./slice/assets";
const rootReducer = combineReducers({
  auth,
  profile,
  settings,
  estate,
  assets
});

const store = configureStore({
  reducer: rootReducer,
});
export default store;
