import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function SignatureImage({url}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container mt={2}>
      <Grid
        item
        xs={isMobile?12:3}
        sx={{
          border: "1px solid black",
          borderRadius: 1,
          backgroundImage: `url(${url})`,
          backgroundColor:'white',
          height: 200,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></Grid>
    </Grid>
  );
}
