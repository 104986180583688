import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdminButton from "components/adminButton";
import AdminInput from "components/adminInput";
import Spinner from "components/spinner";
import TopHeading from "components/topHeading";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSiteSetting } from "store/slice/settings";
import { color } from "theme/color";
import { PRESS_RELEASE_TYPES } from "utils/appConstant";
import { validateEmpty } from "utils/constantFun";
import TextEditor from "./textEditor";
import SVButton from "components/SVButton";
import { api } from "services";
import { SILVERBACK, STATUS_CODES } from "services/apiConstant";
import { errorToast, successToast } from "components/showToast";
import toast from "react-hot-toast";
import {
  addPressReleaseBlog,
  getPressReleaseDetails,
  updatePressReleaseBlog,
} from "store/slice/estate";
export default function AddPressRelease() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const { siteSettingData } = useSelector((state) => state.settings);
  const { pressReleaseDetail } = useSelector((state) => state.estate);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [val, setVal] = useState("");
  const uploadImage = useRef(null);
  const coverImage = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSiteSetting());
  }, []);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      let request = {
        id: id,
      };
      dispatch(getPressReleaseDetails(request, setIsLoading));
    }
  }, [id]);
  useEffect(() => {
    if (pressReleaseDetail) {
      setFormData(pressReleaseDetail);
      setVal(pressReleaseDetail?.content);
      setTags(pressReleaseDetail?.tags);
    }
  }, [pressReleaseDetail]);
  // action for  tags
  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      tags: tags,
    }));
  }, [tags]);
  useEffect(() => {
    if (val) {
      setFormData((formData) => ({
        ...formData,
        contentErr: "",
      }));
    }
  }, [val]);
  const handleTags = () => {
    setTags((tags) => [...tags, formData?.tagValue]);
    setFormData((formData) => ({
      ...formData,
      tagValue: "",
    }));
  };
  const handleRemoveTags = (Index) => {
    const temp = [...tags];
    temp.splice(Index, 1);
    setTags(temp);
  };

  const handleSubmit = () => {
    if (!formData?.documents || formData?.documents?.length == 0) {
      setFormData((formData) => ({
        ...formData,
        coverImageErr: "Please upload cover image",
      }));
      return;
    }
    if (validateEmpty(formData?.title)) {
      setFormData((formData) => ({
        ...formData,
        titleErr: "Please enter title",
      }));
      return;
    }
    if (validateEmpty(formData?.type)) {
      setFormData((formData) => ({
        ...formData,
        typeErr: "Please select type",
      }));
      return;
    }

    if (validateEmpty(formData?.subtitle)) {
      setFormData((formData) => ({
        ...formData,
        subtitleErr: "Please enter subtitle",
      }));
      return;
    }
    if (tags?.length < 1) {
      setFormData((formData) => ({
        ...formData,
        tagsErr: "Please enter tags",
      }));
      return;
    }
    if (validateEmpty(val)) {
      setFormData((formData) => ({
        ...formData,
        contentErr: "Please enter content",
      }));
      return;
    }
    setIsLoading(true);
    if (id) {
      let request = {
        title: formData?.title,
        subtitle: formData?.subtitle,
        tags: formData?.tags,
        document: formData?.document,
        type: formData?.type,
        content: val,
        id: id * 1,
      };
      dispatch(updatePressReleaseBlog(request, setIsLoading, navigate));
    } else {
      let request = {
        title: formData?.title,
        subtitle: formData?.subtitle,
        tags: formData?.tags,
        document: formData?.document,
        type: formData?.type,
        content: val,
      };
      dispatch(addPressReleaseBlog(request, setIsLoading, navigate));
    }
  };
  const uploadMedia = (formDataRequest, type) => {
    api
      .post(SILVERBACK.uploadPressReleaseImage, formDataRequest, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response?.status == STATUS_CODES.SUCCESS) {
          if (type == "cover") {
            let temp = [];
            let documentTemp = [];
            temp.push(response?.data?.data?.fullpath);
            documentTemp.push(response?.data?.data?.documentId);
            successToast("Cover image uploaded succesfully!");
            setFormData((formData) => ({
              ...formData,
              documents: temp,
              document: documentTemp,
              coverImageErr: "",
            }));
          } else {
            setFormData((formData) => ({
              ...formData,
              image: response?.data?.data?.fullpath,
            }));
            successToast("Image uploaded successfully!");
          }
        } else {
          errorToast(response.data.detail);
        }
      })
      .catch(() => {});
  };
  const handleUploadImage = (event, type) => {
    const formData = new FormData();
    let file = event.target.files[0];
    formData.append("file", file);
    formData.append("entityId", siteSettingData?.ENTITY_TYPE_BLOG);
    formData.append("moduleId", siteSettingData?.MODULE_TYPE_BLOG);
    formData.append("typeId", siteSettingData?.DOCUMENT_TYPE_IMAGE);
    uploadMedia(formData, type);
  };
  console.log("pressReleaseDetail", formData);
  return (
    <div>
      <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Press Release"} />
      <Grid container mt={4}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/press-release">
              Press Release
            </Link>
            <Typography sx={{ color: "white" }}>
              {id ? "Edit" : "Add"}
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <Grid container columnSpacing={4} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 4} mt={2}>
            {formData?.documents?.length > 0 ? (
              <Box
                sx={{
                  height: 380,
                  width: "100%",
                  position: "relative",
                  border: "1px solid #C5C5C5",
                  borderRadius: 2,
                }}
              >
                <Avatar
                  onClick={() => {
                    setFormData((formData) => ({
                      ...formData,
                      documents: [],
                      document: [],
                    }));
                  }}
                  sx={{
                    height: 25,
                    width: 25,
                    background: "white",
                    position: "absolute",
                    top: 10,
                    right: 10,
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    icon={"gridicons:cross-circle"}
                    color="red"
                    width={28}
                  />
                </Avatar>

                <img
                  src={formData?.documents[0]}
                  alt=""
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "contain" }}
                />
              </Box>
            ) : (
              <Box
                onClick={() => coverImage.current.click()}
                sx={{
                  border: "2px dashed #ffffffb8",

                  height: 180,
                  borderRadius: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <input
                  ref={coverImage}
                  type="file"
                  style={{ display: "none" }}
                  className="imageUpload"
                  accept="image/*"
                  multiple
                  onChange={(event) => handleUploadImage(event, "cover")}
                />
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography>
                    <Icon
                      icon={"zondicons:add-outline"}
                      color="white"
                      width={40}
                    />
                  </Typography>
                  <Typography style={{ color: "white", mt: 2, fontSize: 20 }}>
                    Add Cover Image
                  </Typography>
                </Box>
              </Box>
            )}
            {formData?.coverImageErr && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 12,
                  ml: 1,
                  mt: 1,
                }}
              >
                {formData?.coverImageErr}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              type="text"
              color="white"
              title="Title"
              maxLength={100}
              onChange={(e) => {
                setFormData((formData) => ({
                  ...formData,
                  title: e.target.value?.trimStart(),
                  titleErr: "",
                }));
              }}
              value={formData?.title || ""}
              formError={formData?.titleErr}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                label=" Typed"
                onChange={(val) => {
                  if (val) {
                    setFormData((formData) => ({
                      ...formData,
                      type: val.target.value,
                      typeErr: "",
                    }));
                  }
                }}
                value={formData?.type || ""}
                sx={{
                  color: "white",
                  width: "100%",
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C5C5C5",
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {PRESS_RELEASE_TYPES?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {formData?.typeErr && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 12,
                  ml: 1,
                  mt: 1,
                }}
              >
                {formData?.typeErr}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <AdminInput
              type="text"
              color="white"
              title="Sub-Title"
              onChange={(e) => {
                setFormData((formData) => ({
                  ...formData,
                  subtitle: e.target.value?.trimStart(),
                  subtitleErr: "",
                }));
              }}
              value={formData?.subtitle || ""}
              formError={formData?.subtitleErr}
            />
          </Grid>
          <Grid item xs={12}>
            <AdminInput
              type="text"
              color="white"
              title="Tag"
              endIcon={
                <AdminButton
                  title={"Add "}
                  sx={{ mb: 3 }}
                  onClick={handleTags}
                  disabled={formData?.tagValue ? false : true}
                />
              }
              value={formData?.tagValue}
              onChange={(event) =>
                setFormData((formData) => ({
                  ...formData,
                  tagValue: event.target.value?.trimStart(),
                  tagsErr: "",
                }))
              }
            />
            {formData?.tagsErr && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 12,
                  ml: 1,
                }}
              >
                {formData?.tagsErr}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            {formData?.tags?.map((data, index) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    color: "white",
                    backgroundColor: color.buttonColor,
                    mx: 1,
                    fontSize: 17,
                  }}
                  label={data}
                  onDelete={() => handleRemoveTags(index)}
                  deleteIcon={
                    <Icon color="white" icon="entypo:circle-with-cross" />
                  }
                />
              );
            })}
          </Grid>

          {formData?.image ? (
            <Grid item xs={isMobile ? 12 : 3}>
              <Box sx={{ border: "1px solid #C5C5C5", borderRadius: 1, p: 2 }}>
                <Box
                  sx={{
                    height: "200px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Avatar
                    onClick={() => {
                      setFormData((formData) => ({
                        ...formData,
                        image: "",
                      }));
                    }}
                    sx={{
                      height: 25,
                      width: 25,
                      background: "white",
                      position: "absolute",
                      top: 5,
                      right: 5,
                      cursor: "pointer",
                    }}
                  >
                    <Icon
                      icon={"gridicons:cross-circle"}
                      color="red"
                      width={28}
                    />
                  </Avatar>

                  <img
                    src={formData?.image}
                    alt=""
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "cover" }}
                  />
                </Box>
                {formData?.image && (
                  <Grid container py={2}>
                    <SVButton
                      fullWidth
                      text="Copy Link"
                      onClick={() => {
                        navigator.clipboard.writeText(formData?.image);
                        toast.success("Copy to Clipboard");
                      }}
                    />

                    {/* <Grid item xs={6}>
                    <SVButton fullWidth text="Upload Image" />
                  </Grid> */}
                  </Grid>
                )}
              </Box>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Box>
                  <SVButton
                    text="Upload Image"
                    onClick={() => uploadImage.current.click()}
                  />

                  <input
                    ref={uploadImage}
                    type="file"
                    style={{ display: "none" }}
                    className="imageUpload"
                    accept="image/*"
                    multiple
                    onChange={handleUploadImage}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={12} mt={4}>
          <TextEditor initialValue={val} getValue={(value) => setVal(value)} />
          {formData?.contentErr && (
            <Typography
              sx={{
                color: theme.palette.error.main,
                fontSize: 12,
                ml: 1,
                mt: 1,
              }}
            >
              {formData?.contentErr}
            </Typography>
          )}
        </Grid>
        <Grid container columnSpacing={4} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 2}>
            <AdminButton fullWidth title="Submit" onClick={handleSubmit} />
          </Grid>
        </Grid>

        <Spinner open={isLoading} />
      </Box>
    </div>
  );
}
