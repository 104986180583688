import * as React from "react";
import Link from "@mui/material/Link";
import { Icon } from "@iconify/react";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useState } from "react";
import { color } from "../../theme/color";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, verifyCaptcha } from "../../store/slice/auth";
import Spinner from "../../components/spinner";
import { Box, Card, Grid, Typography } from "@mui/material";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import { validateEmpty, validEmail, validPassword } from "utils/constantFun";
import { MESSAGES } from "utils/appMessages";
import { APP_SETTINGS } from "utils/appConstant";
import theme from "theme";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useSelector((state) => state.auth);
  const [captchaToken, setCaptchaToken] = useState("");

  const recaptchaRef = React.createRef();
  const fcmTOKEN = Cookies.get(process.env.REACT_APP_FCM_TOKEN_KEY);
  const handleSubmit = () => {
    if (validateEmpty(email)) {
      setFormError((formError) => ({
        ...formError,
        email: MESSAGES.LOGIN.emptyEmail,
      }));
      return;
    }
    if (!validateEmpty(email) && !validEmail(email)) {
      setFormError((formError) => ({
        ...formError,
        email: MESSAGES.LOGIN.validEmail,
      }));
      return;
    }
    if (validateEmpty(password)) {
      setFormError((formError) => ({
        ...formError,
        password: MESSAGES.LOGIN.emptyPassword,
      }));
      return;
    }
    if (!validPassword(password)) {
      setFormError((formError) => ({
        ...formError,
        password: MESSAGES.LOGIN.validPassword,
      }));
      return;
    }
    if (!captchaToken) {
      setFormError((formError) => ({
        ...formError,
        captchaTokenErr: MESSAGES.LOGIN.humanCaptcha,
      }));
      return;
    }
    let requestParam = {
      email: email,
      password: password,
      type: "",
      otp: "",
      deviceType: APP_SETTINGS.DEVICE_TYPE,
      deviceToken: fcmTOKEN ?? "",
    };
    dispatch(login(requestParam, navigate, true));
  };

  const onCaptchaChange = (value) => {
    setFormError((formError) => ({
      ...formError,
      captchaTokenErr: "",
    }));
    if (value) {
      setCaptchaToken(value);
      dispatch(verifyCaptcha({ key: value }));
    }
  };
  return (
    <>
      <Grid container display={"flex"} justifyContent={"center"}>
        <Grid item>
          <Card sx={{ background: color.topbar, p: 4, width: 320 }}>
            <Box noValidate sx={{ mt: 1 }}>
              <AdminInput
                title="Email address"
                required={true}
                sx={{
                  mt: "30px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#C5C5C5",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
                type="email"
                value={email}
                color={"white"}
                onChange={(val) => {
                  if (val) {
                    setEmail(val.target.value);
                    if (val) {
                      setFormError((formError) => ({
                        ...formError,
                        email: "",
                      }));
                    }
                  }
                }}
                formError={formError.email}
              />
              <AdminInput
                title="Password"
                required={true}
                type={showPassword ? "text" : "password"}
                value={password}
                color={"white"}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#C5C5C5",
                    },

                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
                onChange={(val) => {
                  if (val) {
                    setPassword(val.target.value);
                    if (val) {
                      setFormError((formError) => ({
                        ...formError,
                        password: "",
                      }));
                    }
                  }
                }}
                formError={formError.password}
                endIcon={
                  <Icon
                    icon={!showPassword ? "iconamoon:eye-off" : "tabler:eye"}
                    width="25"
                    height="25"
                  />
                }
                PasswordShowClick={() => setShowPassword(!showPassword)}
              />
              <div
                className="captcha"
                style={{ transform: "scale(1.06)",transformOrigin:"0 0",marginTop:'0.5rem' }}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  onChange={onCaptchaChange}
                  theme={"dark"}
                />
              </div>
              <Typography
                sx={{ color: theme.palette.error.main, fontSize: 12, ml: 1 }}
              >
                {formError?.captchaTokenErr ? formError?.captchaTokenErr : ""}
              </Typography>
            </Box>
            <Grid
              xs={12}
              item
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <AdminButton onClick={handleSubmit} title={"Sign In"} fullWidth />
            </Grid>
            <Grid xs={12} item sx={{ display: "flex", justifyContent: "end" }}>
              <Link
                sx={{
                  textDecoration: "none",
                  fontSize: 14,
                  color: color.buttonColor,
                  cursor: "pointer",
                }}
                variant="body2"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </Link>
            </Grid>

            <Spinner open={isLoading} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
