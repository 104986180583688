export const MESSAGES = {
  LOGIN: {
    emptyEmail: "Please enter email address.",
    validEmail: "Please enter valid email address",
    emptyPassword: "Please enter your password.",
    validPassword:
      "A password must contain at least eight characters, at least one number, both lower and uppercase letters, and special characters",
    humanCaptcha: "Please verify that your are human.",
  },
  OTP: {
    emptyEmailOTP: "Please enter one time code recieved on your email.",
    OTP_ERROR:
      "Something went wrong to send OTP on this number. Please try after sometime or contact to tech support.",
  },
};
