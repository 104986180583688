// component
import { Icon } from "@iconify/react";
import { PERMISSION_IDENTIFIER } from "utils/appConstant";

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <Icon icon="material-symbols:dashboard" width={20} />,
  },
  {
    id: PERMISSION_IDENTIFIER.USERS,
    title: "Users",
    path: "/users",
    subPath: "/profile",
    icon: <Icon icon="ph:users-three-bold" width={20} />,
  },
  {
    id: PERMISSION_IDENTIFIER.SUBSCRIBE_USER,
    title: "Subscribe Users",
    path: "/subscribeUser",
    icon: <Icon icon="fluent-mdl2:subscribe" width={20} />,
  },
  {
    id: PERMISSION_IDENTIFIER.NET_WORTH,
    title: "Financial Request",
    path: "/netWorth",
    subPath: "/netWorthDetails",
    icon: <Icon icon="game-icons:cash" width={20} />,
  },
  {
    title: "Federal Tax Rate",
    path: "/tax-rate",
    icon: <Icon icon="streamline:bag-rupee" width={20} />,
  },
  {
    id: PERMISSION_IDENTIFIER.TICKET_MANAGMENT,
    title: "Ticket Management",
    path: "/ticketManagement",
    icon: <Icon icon="ion:ticket-outline" width={20} />,
  },
  {
    id: PERMISSION_IDENTIFIER.E_KYC_REQUEST_,
    title: "E-KYC Request",
    path: "/ekycList",
    icon: <Icon icon="icon-park-outline:doc-success" width={20} />,
  },
  {
    title: "Notifications List",
    path: "/notification",
    icon: <Icon icon="mdi:message-notification-outline" width={20} />,
  },
  {
    title: "Estate Planning",
    path: "/estate-planning",
    icon: (
      <Icon
        icon="material-symbols:real-estate-agent-outline-rounded"
        width={20}
      />
    ),
  },
  {
    title: "Advertise ",
    path: "/advertise-managment",
    subPath: "/advertise-managment",
    icon: (
      <Icon
        icon="ri:advertisement-line"
        width={20}
      />
    ),
  },
  {
    title: "Press Release ",
    path: "/press-release",
    subPath: "/press-release",
    icon: (
      <Icon
        icon="mdi:email-newsletter"
        width={20}
      />
    ),
  },
  // {
  //   title: "Loan Request",
  //   path: "/loanRequest",
  //   icon: <Icon icon="solar:hand-money-linear" width={20} />,
  // },
  // {
  //   title: "Card Request",
  //   path: "/cardRequest",
  //   icon: <Icon icon="mynaui:credit-card-plus" width={20} />,
  // },
  // {
  //   title: "Transactions",
  //   path: "/transactions",
  //   icon: <Icon icon="vaadin:money-exchange" width={20} />,
  // },
  // {
  //   title: "Adds",
  //   path: "/adds",
  //   icon: <Icon icon="ri:advertisement-line" width={20} />,
  // },
];

export default navConfig;
