import React, { useEffect, useState } from "react";
import {
  Dialog,
  // DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import SVDivider from "components/SVDivider";
import SVOutlinedButton from "components/SVOutlinedButton";
import SVButton from "components/SVButton";
import AdminInput from "components/adminInput";
import { MESSAGES } from "utils/appMessages";
import { validEmail, validateEmpty } from "utils/constantFun";
import theme from "theme";
import { useDispatch, useSelector } from "react-redux";
import { createTeamMember } from "store/slice/profile";
import { getRoleList } from "store/slice/estate";
// import "./index.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddTeamDialog = ({ ...props }) => {
  const { openDialog, setOpenDialog } = props;
  const [addTeamFormData, setAddTeamFormData] = useState({});
  const { roleListData } = useSelector((state) => state.estate);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoleList());
  }, []);
  const onClose = () => {
    let tempObj = {};
    setAddTeamFormData(tempObj);
    setOpenDialog(false);
  };
  const handleAdd = () => {
    if (validateEmpty(addTeamFormData?.first_name)) {
      setAddTeamFormData((addTeamFormData) => ({
        ...addTeamFormData,
        first_nameErr: "Please enter first name",
      }));
      return;
    }
    if (validateEmpty(addTeamFormData?.surename)) {
      setAddTeamFormData((addTeamFormData) => ({
        ...addTeamFormData,
        surenameErr: "Please enter last name",
      }));
      return;
    }
    if (validateEmpty(addTeamFormData?.email)) {
      setAddTeamFormData((addTeamFormData) => ({
        ...addTeamFormData,
        emailErr: MESSAGES.LOGIN.emptyEmail,
      }));
      return;
    }

    if (
      !validateEmpty(addTeamFormData?.email) &&
      !validEmail(addTeamFormData?.email)
    ) {
      setAddTeamFormData((addTeamFormData) => ({
        ...addTeamFormData,
        emailErr: MESSAGES.LOGIN.validEmail,
      }));
      return;
    }
    if (validateEmpty(addTeamFormData?.role)) {
      setAddTeamFormData((addTeamFormData) => ({
        ...addTeamFormData,
        roleErr: "Please select role",
      }));
      return;
    }
    let request = {
      first_name: addTeamFormData?.first_name,
      surename: addTeamFormData?.surename,
      email: addTeamFormData?.email,
      roles: addTeamFormData?.role ? [addTeamFormData?.role] : [],
      isEmailVerified: true,
    };
    dispatch(createTeamMember(request, setOpenDialog, setAddTeamFormData));
  };
  return (
    <BootstrapDialog
      fullWidth
      scroll="paper"
      open={openDialog}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">{"Add Team Member"}</Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider />
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AdminInput
                title="First Name"
                sx={{
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "black",
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "black",
                    },
                }}
                type="text"
                value={addTeamFormData?.first_name || ""}
                onChange={(val) => {
                  setAddTeamFormData((addTeamFormData) => ({
                    ...addTeamFormData,
                    first_name: val.target.value,
                    first_nameErr: "",
                  }));
                }}
                formError={addTeamFormData.first_nameErr}
              />
            </Grid>
            <Grid item xs={12}>
              <AdminInput
                title="Last Name"
                sx={{
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "black",
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "black",
                    },
                }}
                type="text"
                value={addTeamFormData?.surename || ""}
                onChange={(val) => {
                  setAddTeamFormData((addTeamFormData) => ({
                    ...addTeamFormData,
                    surename: val.target.value,
                    surenameErr: "",
                  }));
                }}
                formError={addTeamFormData?.surenameErr}
              />
            </Grid>
            <Grid item xs={12}>
              <AdminInput
                title="Email Address"
                sx={{
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "black",
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "black",
                    },
                }}
                type="text"
                value={addTeamFormData?.email || ""}
                onChange={(val) => {
                  setAddTeamFormData((addTeamFormData) => ({
                    ...addTeamFormData,
                    email: val.target.value,
                    emailErr: "",
                  }));
                }}
                formError={addTeamFormData?.emailErr}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1.5 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select Role
                </InputLabel>
                <Select
                  sx={{
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "grey",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addTeamFormData?.role || ""}
                  label="Change Priorityyy"
                  onChange={(val) => {
                    setAddTeamFormData((addTeamFormData) => ({
                      ...addTeamFormData,
                      role: val.target.value * 1,
                      roleErr: "",
                    }));
                  }}
                >
                  {roleListData?.map((item, i) => {
                    return (
                      <MenuItem value={item?.id} key={i}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Typography
                  sx={{ color: theme.palette.error.main, fontSize: 12, ml: 1 }}
                >
                  {addTeamFormData?.roleErr ? addTeamFormData?.roleErr : ""}
                </Typography>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{ mt: 2 }}
            >
              <SVButton text="Add" sx={{ height: 40 }} onClick={handleAdd} />

              <SVOutlinedButton
                text="Cancel"
                sx={{ height: 40, ml: 1 }}
                onClick={onClose}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default React.memo(AddTeamDialog);
