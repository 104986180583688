import {
    Box,
    Dialog,
    DialogContent,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { DialogTitle } from "@mui/material";
import "../index.css";
import { useSelector } from "react-redux";
import SVButton from "components/SVButton";
import SignatureImage from "../../signatureImage";
import Spinner from "components/spinner";
import { Icon } from "@iconify/react";
import { color } from "theme/color";
export default function PreviewLastWillDocument(props) {
  const {
    profileDetailData,
    open,
    lastWillData,
    viewDetails,
    witnessSign,
    witnessTwoSign,
    handleSubmit,
    handleClose,
    isLoading,
    childerName,
  } = props;
  const { stateListData } = useSelector((state) => state.profile);
  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Last Will And Testament</h2>
        <Icon icon="ooui:close" height={30} onClick={handleClose} />
      </DialogTitle>
      <Spinner open={isLoading} />
      <DialogContent>
        {/* Declaration */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">Declaration</Typography>
          <Typography className="preview-inforation-text">
            I &nbsp;
            <span style={{ color: color.buttonColor }}>
              {profileDetailData?.first_name} {profileDetailData?.surename}
            </span>
            , a resident of the state of{" "}
            <span style={{ color: color.buttonColor }}>
              {stateListData?.length > 0 &&
                stateListData[
                  stateListData?.findIndex(
                    (item) => item.id == profileDetailData?.state
                  )
                ]?.description}{" "}
            </span>
            and county of{" "}
            <span style={{ color: color.buttonColor }}>
              {profileDetailData?.address}
            </span>{" "}
            and being of sound mind and memory, do hereby make, publish, and
            declare this to be my last will and testament, thereby revoking and
            making null and void any and all other last will and testaments
            and/or codicils to last will and testaments heretofore made by me.
            All references herein to{" "}
            <span style={{ color: color.buttonColor }}>“this Will”</span> refer
            only to this last will and testament.
          </Typography>
        </Box>
        {/* Family */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">Family</Typography>
          <Typography className="preview-inforation-text">
            At the time of executing this Will, I am{" "}
            <span style={{ color: color.buttonColor }}>
              {lastWillData?.marital_status}
            </span>{" "}
            . The names of my children are listed below. Unless otherwise
            specifically indicated in this Will, any provision for my children
            includes the below-named children as well as any child of mine
            hereafter born or adopted.
          </Typography>

          {childerName?.length > 0 && (
            <>
              <Box sx={{ display: "flex" }}>
                <Typography className="field-text">Childern :</Typography>
                &nbsp;
                <Typography className="title-text">
                  {lastWillData?.children_count}
                </Typography>
              </Box>
              {childerName?.map((item, i) => {
                return (
                  <>
                    <Box sx={{ display: "flex", mt: 1 }}>
                      <Typography className="field-text">
                        Child {i + 1} :
                      </Typography>
                      &nbsp;{" "}
                      <Typography className="title-text">{item}</Typography>
                    </Box>
                  </>
                );
              })}
            </>
          )}
        </Box>
        {/* Executor Nomination */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Executor Nomination
          </Typography>
          <Typography className="preview-inforation-text">
            I nominate{" "}
            <span style={{ color: color.buttonColor }}>
              {lastWillData?.executor_name_first}
            </span>{" "}
            to be the executor of this Will.
          </Typography>
          <Typography className="preview-inforation-text">
            If, for any reason, my first nominee executor is unable or unwilling
            to serve or to continue to serve as executor of this Will, I
            nominate{" "}
            <span style={{ color: color.buttonColor }}>
              {lastWillData?.executor_name_second}
            </span>{" "}
            to be the executor of this Will.
          </Typography>
          <Typography className="preview-inforation-text">
            If, for any reason, all of the nominees designated above are unable
            or unwilling to serve or to continue to serve as executor of this
            Will, I nominate{" "}
            <span style={{ color: color.buttonColor }}>
              {lastWillData?.executor_name_third}
            </span>{" "}
            to be the executor of this Will.
          </Typography>

          <Typography className="preview-inforation-text">
            If none of the nominated executors are able and willing to serve or
            continue to serve, and the vacancy is not filled as set forth above,
            the majority of estate beneficiaries shall nominate a successor
            executor. If the majority of estate beneficiaries are unable to
            nominate a successor executor, the vacancy will be filled pursuant
            to a petition filed in a court of competent jurisdiction by the
            resigning executor or any person interested in the estate.
          </Typography>
        </Box>
        {/* Miscellaneous Executor Provisions */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Miscellaneous Executor Provisions
          </Typography>
          <Typography className="preview-inforation-text">
            The term{" "}
            <span style={{ color: color.buttonColor }}> “executor”</span>
            &nbsp; includes any executrix, personal representative, or
            administrator, if those terms are used in the statutes of any state
            that has jurisdiction over all or any portion of my estate.
          </Typography>
          <Typography className="preview-inforation-text">
            My executor will have broad and reasonable discretion in the
            administration of my estate to exercise all of the powers permitted
            to be exercised by an executor under state law, including the power
            to sell estate assets with or without notice, at either public or
            private sale, and to do everything he or she deems advisable and in
            the best interest of my estate and the beneficiaries thereof, all
            without the necessity of court approval or supervision. I direct
            that my executor perform all acts and exercise all such rights and
            privileges, although not specifically mentioned in this Will, with
            relation to any such property, as if the absolute owner thereof and,
            in connection therewith, to make, execute, and deliver any
            instruments, and to enter into any covenants or agreements binding
            my estate or any portion thereof.
          </Typography>
          <Typography className="preview-inforation-text">
            If there are two co-executors serving, they shall act by unanimous
            agreement. If there are more than two co-executors serving, they
            shall act in accordance with the decision made by the majority of
            co-executors.
          </Typography>

          <Typography className="preview-inforation-text">
            Subject to specific provisions to the contrary, I authorize my
            executor to distribute a share of my estate given to a minor
            beneficiary, up to the whole thereof, to a custodian under the
            applicable Transfers to Minors Act or Gifts to Minors Act, if in the
            executor’s discretion, it is in the best interests of the
            beneficiary. The executor may also make distributions to a minor by
            making distributions to the guardian of the minor’s person, or the
            guardian of the minor’s estate.
          </Typography>
          <Typography className="preview-inforation-text">
            No person named as an executor is required to post any bond.
          </Typography>
          <Typography className="preview-inforation-text">
            I authorize my executor to make the following choices or elections
            in my executor’s absolute discretion, regardless of the resulting
            effect on any other provisions of this Will or on any person
            interested in my estate or in the amount of any of the taxes
            referred to:
          </Typography>
          <Typography className="preview-inforation-text">
            <p>
              (a) choose a valuation date for estate or inheritance tax purposes
              or choose the methods to pay estate or inheritance taxes;
            </p>
            <p>
              (b) elect to treat or use an item, for either federal or state tax
              purposes, as either an income tax deduction or as a deduction for
              estate or inheritance tax purposes;
            </p>
            <p>
              (c) determine when a particular item is to be treated as taken
              into income or used as a tax deduction, to the extent the law
              provides that choice; and
            </p>
            <p>
              (d) disclaim all or any portion of any interest in property
              passing to my estate at or after my death, even though any of
              these actions may subject my estate to additional tax liabilities.
              No person adversely affected by my executor’s exercise of
              discretion under this clause is entitled to any reimbursement or
              adjustment, and my executor is not required to make any adjustment
              between income and principal or in the amount of any property
              passing under this Will as a result of any election under this
              provision.
            </p>
          </Typography>
          <Typography className="preview-inforation-text">
            I authorize my executor, without obtaining court approval, to employ
            professional investment counsel on such terms as my executor
            considers proper, and to pay the fees of investment counsel as an
            expense of administration of my estate. However, my executor is
            under no obligation to employ any investment counsel.
          </Typography>
          <Typography className="preview-inforation-text">
            I authorize my executor either to continue the operation of any
            business belonging to my estate for such time and in such manner as
            my executor may consider advisable and in the best interest of my
            estate, or to sell or liquidate the business at such time and on
            such terms as my executor may consider advisable and in the best
            interest of my estate. Any such good faith operation, sale, or
            liquidation by my executor will be at the risk of my estate and
            without liability on the part of my executor for any losses that may
            result.
          </Typography>
        </Box>
        {/* Debt */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">Debt</Typography>
          <Typography className="preview-inforation-text">
            I direct that as soon as is practical after my death, the executor
            named pursuant to this Will review all of my just debts and
            obligations, including last illness and funeral expenses, except for
            those secured long-term debts that may be assumed by the beneficiary
            of such property, unless such assumption is prohibited by law or on
            agreement by the beneficiary. The executor is further directed to
            pay any attorneys’ fees and any other estate administration
            expenses. The executor shall pay these just debts only after a
            creditor provides timely and sufficient evidence to support its
            claim and in accordance with applicable state law.
          </Typography>
          <Typography className="preview-inforation-text">
            I direct that any estate, inheritance, and succession taxes,
            including any interest and penalties thereon, imposed by the federal
            government or any state, district, or territory, attributable to
            assets includible in my estate, passing either under or outside of
            this Will, be apportioned among the persons interested in my estate
            in accordance with applicable state and federal law. My executor is
            authorized and directed to seek reimbursement from the beneficiaries
            of my estate of any taxes paid by my executor to the extent allowed
            by law.
          </Typography>
          <Typography className="preview-inforation-text">
            If my executor cannot collect from any person interested in the
            estate the amount of tax apportioned to that person, the amount not
            recoverable will be equitably apportioned among the other persons
            interested in the estate who are subject to apportionment.
          </Typography>{" "}
          <Typography className="preview-inforation-text">
            If a person is charged with or required to pay tax in an amount
            greater than his or her prorated amount because another person does
            not pay his or her prorated amount, the person charged with or
            required to pay the greater amount has a right of reimbursement
            against the other person.
          </Typography>{" "}
          <Typography className="preview-inforation-text">
            I further direct that if any beneficiary named in this Will is
            indebted to me at the time of my death, and evidence of such
            indebtedness is provided or made available to my executor, that
            share of my estate that I give to any and each such beneficiary be
            reduced in value by an amount equal to the proven indebtedness of
            such beneficiary unless:
          </Typography>{" "}
          <Typography className="preview-inforation-text">
            <p>
              (i) I have specifically provided in this Will for the forbearance
              of such debt, or
            </p>
            <p>(ii) such beneficiary is the sole principal beneficiary.</p>
          </Typography>
        </Box>

        {/* Simultaneous Death
         */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Simultaneous Death
          </Typography>
          <Typography className="preview-inforation-text">
            If it cannot be established if a beneficiary of my estate survived
            me, the provisions of the applicable Uniform Simultaneous Death Act,
            as amended, or any substantially similar successor act effective on
            the date of my death, will apply.
          </Typography>
        </Box>

        {/* NonLiability Of Fiduciaries */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            NonLiability Of Fiduciaries
          </Typography>
          <Typography className="preview-inforation-text">
            Any fiduciary, including my executor and any trustee, who in good
            faith endeavors to carry out the provisions of this Will, will not
            be liable to me, my estate, my heirs, or my beneficiaries for any
            damages or claims arising because of their actions or inaction, or
            the actions of any predecessor fiduciary acting pursuant to this
            Will. My estate will indemnify and hold them harmless.
          </Typography>
        </Box>

        {/* Saving Clause */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Saving Clause
          </Typography>
          <Typography className="preview-inforation-text">
            If a court of competent jurisdiction at any time invalidates or
            finds unenforceable any provision of this Will, such invalidation
            will not invalidate the whole of this Will. All of the remaining
            provisions will be undisturbed as to their legal force and effect.
            If a court finds that an invalidated or unenforceable provision
            would become valid if it were limited, then such provision will be
            deemed to be written, deemed, construed, and enforced as so limited.
          </Typography>
          <Typography className="preview-inforation-text">
            <span style={{ color: color.buttonColor }}>IN WITNESS WHEREOF</span>{" "}
            , I, the undersigned testator, declare that I sign and execute this
            instrument on the date written below as my last will and testament
            and further declare that I sign it willingly, that I execute it as
            my free and voluntary act for the purposes expressed in this
            document, and that I am eighteen years of age or older, of sound
            mind and memory, and under no constraint or undue influence.
          </Typography>
        </Box>

        {/*Signature Of Principal */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Signature Of Principal
          </Typography>

          {profileDetailData?.signatureData?.length > 0 && (
            <SignatureImage
              url={profileDetailData?.signatureData[0]?.signature}
            />
          )}
        </Box>
        {/* Attestation Clause */}

        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Attestation Clause
          </Typography>
          <Typography className="preview-inforation-text">
            This last will and testament, which has been separately signed by
            Bradley Richmond Everett, the testator, was on the date indicated
            below signed and declared by the above named testator as his or her
            last will and testament in the presence of each of us. We, in the
            presence of the testator and each other, at the testator’s request,
            under penalty of perjury, hereby subscribe our names as witnesses to
            the declaration and execution of the last will and testament by the
            testator, and we declare that, to the best of our knowledge, said
            testator is eighteen years of age or older, of sound mind and memory
            and under no constraint or undue influence.
          </Typography>
          <Box mt={2}>
            <Typography className="preview-label-text">
              First Witness
            </Typography>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Email Address</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {lastWillData?.witness_details[0]?.email}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Address</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {lastWillData?.witness_details[0]?.address}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">City</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {lastWillData?.witness_details[0]?.city}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Postal Code</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {lastWillData?.witness_details[0]?.zip_code}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">State</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {stateListData?.length > 0 &&
                  stateListData[
                    stateListData?.findIndex(
                      (item) =>
                        item?.id == lastWillData?.witness_details[0]?.state
                    )
                  ]?.description}
              </Typography>
            </Stack>
            {viewDetails
              ? lastWillData?.witness_details[0]?.document && (
                  <SignatureImage
                    url={lastWillData?.witness_details[0]?.document}
                  />
                )
              : !witnessSign?.current?.isEmpty() && (
                  <SignatureImage url={witnessSign?.current?.toDataURL()} />
                )}
          </Box>
          <Box mt={2}>
            <Typography className="preview-label-text">
              Second Witness
            </Typography>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Email Address</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {lastWillData?.witness_details[1]?.email}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Address</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {lastWillData?.witness_details[1]?.address}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">City</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {lastWillData?.witness_details[1]?.city}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Postal Code</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {lastWillData?.witness_details[1]?.zip_code}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">State</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {stateListData?.length > 0 &&
                  stateListData[
                    stateListData?.findIndex(
                      (item) =>
                        item?.id == lastWillData?.witness_details[1]?.state
                    )
                  ]?.description}
              </Typography>
            </Stack>
            {viewDetails
              ? lastWillData?.witness_details[1]?.document && (
                  <SignatureImage
                    url={lastWillData?.witness_details[1]?.document}
                  />
                )
              : !witnessTwoSign?.current?.isEmpty() && (
                  <SignatureImage url={witnessTwoSign?.current?.toDataURL()} />
                )}
          </Box>
        </Box>

        {!viewDetails && (
          <Grid container mt={3} columnSpacing={5}>
            <Grid item xs={6}>
              <SVButton text="Edit" fullWidth onClick={handleClose} />
            </Grid>
            <Grid item xs={6}>
              <SVButton text="Submit" fullWidth onClick={handleSubmit} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
