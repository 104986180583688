import * as React from "react";
// import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import mobileImage from "../../../assets/img/001.png";
import { Typography, useMediaQuery } from "@mui/material";
// import playStoreImg from "../../../assets/img/googleplaystore.png";
// import appStoreImg from "../../../assets/img/appstore.png";
import QRImg from "../../../assets/img/qr.png";
import { useTheme } from "@mui/material";
import AdminButton from "../../../components/adminButton";
import Logo from "../../../assets/img/Logo.png";

// TODO remove, this demo shouldn't need to reset the theme.

export default function index() {
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      container
      component="main"
      justifyContent="center"
      alignContent="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: "#000",
        width: "100%",
      }}
    >
      {isMobile ? (
        <></>
      ) : (
        <Grid item style={{ justifyContent: "center", display: "flex" }}>
          <img
            style={{ objectFit: "contain", height: 500 }}
            src={mobileImage}
            alt=""
            // height={"100%"}
            // width={"100%"}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        justifyContent="center"
        alignContent="center"
      >
        <Grid item style={{ justifyContent: "center", display: "flex" }}>
          <img
            style={{ objectFit: "contain", height: 80 }}
            src={Logo}
            alt=""
            // height={"100%"}
            // width={"100%"}
          />
        </Grid>

        <Typography
          color="white"
          sx={{
            fontSize: 20,
            fontFamily: "Poppins",
            textAlign: "center",
            p: 3,
          }}
        >
          Download Android Build Using QR or Button
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          <img src={QRImg} alt="" height="200" width={200} />
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontFamily: "Poppins",
            textAlign: "center",
            p: 2,
          }}
        >
          Scan Me
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            mt: -4,
          }}
        >
          <a
            href={"https://qa-admin.silverbackmobile.com/build/SilverBack.apk"}
            download="SilverBack"
            style={{ textDecoration: "none" }}
          >
            <AdminButton
              title={"Download Apk"}
              sx={{ fontFamily: "Poppins", m: 6, width: 200 }}
            />
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
}
