import {
  Breadcrumbs,
  Card,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { color } from "theme/color";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/spinner";
import { Icon } from "@iconify/react";
import SVDivider from "components/SVDivider";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  bankAccountDetails,
  otherAccountDetails,
  clearBankDetailData,
  clearOtherBanckDetail,
  allInsuranceDetail,
  payRollIncome,
  clearPayRollIcomeDetail,
  realEstateDetail,
  clearRealEstateDetail,
  deleteRealEstate,
  deleteOtherAssest,
  deleteInsurance,
} from "store/slice/profile";
import moment from "moment";
import DataNotFound from "components/dataNotFound/dataNotFound";
import { currencyFormat } from "utils/currencyFormatter";
import { ASSET, LIABILITIES } from "utils/appConstant";
import { capitalizeFirstLetter } from "utils/constantFun";
import investmentIcom from "../../../../assets/img/incomeIcon.svg";
import incomeIconImg from "../../../../assets/img/incomeIconImg.svg";
import realEstateIconImg from "../../../../assets/img/realEstateIconImg.svg";
import AlertDialog from "components/alertDialog/alertDialog";
import { getBenefitsTypes, getInsuranceTypes } from "store/slice/assets";
import InsuranceCard from "./insuranceDetails";
import InsuranceDetailDialog from "./insuranceDetails/insuranceDetailDialog";

const BankDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const prams = useParams();
  const [isLoading, setIsloading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [showInsuranceDetail, setShowInsuranceDetail] = useState(false);
  const [assetsType, setAssetsType] = useState("");
  const [insuranceDetailData, setInsuranceDetailData] = useState({});
  const [insuranceDeleteDialog, setInsuranceDeleteDialog] = useState(false);
  const [realStateId, setRealStateId] = useState("");
  const { insuranceData } = useSelector((state) => state.assets);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  console.log(isMobile);
  const {
    bankDetailData,
    otherAccountData,
    insuranceDetail,
    payRollIncomeData,
    realEstateDetailData,
  } = useSelector((state) => state.profile);
  const clearState = () => {
    dispatch(clearBankDetailData());
    dispatch(clearOtherBanckDetail());
    dispatch(clearPayRollIcomeDetail());
    dispatch(clearRealEstateDetail());
  };

  useEffect(() => {
    if (location?.state?.type === ASSET.REAL_ESTATE) {
      var sendParam = {
        user_id: prams?.id,
        page: 0,
        perPage: 20,
      };
      dispatch(realEstateDetail(sendParam, true));
    }
    if (location?.state?.type === "income") {
      var request = {
        userId: prams?.id,
      };
      dispatch(payRollIncome(request, true));
    }
    if (
      location?.state?.type === ASSET.ASSET ||
      location?.state?.type === LIABILITIES.LIABILITIES
    ) {
      var data = {
        userId: prams?.id,
        type: location?.state?.type,
        subtype: location?.state?.subtype,
      };
      dispatch(otherAccountDetails(data, true));
    }

    if (
      location?.state?.type === ASSET.INVESTMENT ||
      location?.state?.type === ASSET.DEPOSITORY ||
      location?.state?.type === LIABILITIES.CREDIT ||
      location?.state?.type === LIABILITIES.LOAN
    ) {
      var data1 = {
        userId: prams?.id,
        type: location?.state?.type,
        subtype: location?.state?.subtype,
      };
      dispatch(bankAccountDetails(data1, true));
    }
    if (location?.state?.type === ASSET.INSURANCE) {
      var requestParams = {
        user_id: prams?.id,
        page: 0,
        perPage: 50,
      };
      dispatch(allInsuranceDetail(requestParams, true));
    }
    dispatch(getInsuranceTypes());
    dispatch(getBenefitsTypes());
  }, []);
  const handleDelete = () => {
    setIsloading(true);
    if (assetsType == "Other") {
      dispatch(
        deleteOtherAssest(realStateId, setShowAlertDialog, setIsloading)
      );
    } else {
      dispatch(deleteRealEstate(realStateId, setShowAlertDialog, setIsloading));
    }
  };
  const handleInsuranceDelete = () => {
    setIsloading(true);
    dispatch(
      deleteInsurance(
        insuranceDetailData?.insurance_id,
        setInsuranceDeleteDialog,
        setInsuranceDetailData,
        setIsloading
      )
    );
  };
  return (
    <>
      <>
        <Grid container sx={{ p: 3, mt: -5 }}>
          <AlertDialog
            title={`Delete ${assetsType}  `}
            open={showAlertDialog}
            isLoading={isLoading}
            handleClose={() => setShowAlertDialog(false)}
            handleAgree={handleDelete}
            text={`Are you sure you want to delete this ${assetsType == "Other" ? "Other" : `${assetsType}asset`}  ? `}
          />
          <AlertDialog
            title={`Delete ${
              insuranceData?.length > 0 &&
              insuranceData[
                insuranceData?.findIndex(
                  (item) => item.id == insuranceDetailData.type_id
                )
              ]?.name
            } insurance `}
            open={insuranceDeleteDialog}
            isLoading={isLoading}
            handleClose={() => setInsuranceDeleteDialog(false)}
            handleAgree={handleInsuranceDelete}
            text={`Are you sure you want to delete this ${
              insuranceData?.length > 0 &&
              insuranceData[
                insuranceData?.findIndex(
                  (item) => item.id == insuranceDetailData.type_id
                )
              ]?.name
            } insurance ? `}
          />
          {insuranceDetailData && (
            <InsuranceDetailDialog
              data={insuranceDetailData}
              open={showInsuranceDetail}
              handleClose={() => {
                setInsuranceDetailData({});
                setShowInsuranceDetail(false);
              }}
            />
          )}
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} onClick={clearState} to="/users">
                Users
              </Link>
              <Link
                style={{ color: "white" }}
                onClick={clearState}
                to={`/profile/${prams?.id}`}
              >
                User Profile
              </Link>
              <Link
                style={{ color: "white" }}
                onClick={clearState}
                to={`/assetsDetail/${prams?.id}`}
              >
                Assets-Liabilities Details
              </Link>
              <Typography sx={{ color: "white" }}>
                {(location?.state?.type === ASSET.ASSET && ASSET.OTHER1) ||
                  (location?.state?.type === LIABILITIES.LIABILITIES &&
                    ASSET.OTHER1)}
                {location?.state?.type === ASSET.DEPOSITORY && ASSET.BANK}
                {location?.state?.type === LIABILITIES.CREDIT &&
                  LIABILITIES.CREDIT_CARD}
                {location?.state?.type === ASSET.OTHER && ASSET.OTHER1}
                {(location?.state?.type === ASSET.REAL_ESTATE ||
                  location?.state?.type === ASSET.INVESTMENT ||
                  location?.state?.type === ASSET.INSURANCE ||
                  location?.state?.type === ASSET.INCOME) &&
                  capitalizeFirstLetter(location?.state?.type)}{" "}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box sx={{ background: color.black, borderRadius: "10px", p: 3 }}>
          <Grid container display={"flex"} sx={{ mb: 2 }}>
            <Grid item>
              <Typography
                sx={{ fontSize: 32 }}
                className="textLinearBackground"
              >
                {location?.state?.type === ASSET.ASSET ||
                  (location?.state?.type === LIABILITIES.LIABILITIES &&
                    ASSET.OTHER1)}
                {location?.state?.type === ASSET.DEPOSITORY && ASSET.BANK}
                {location?.state?.type === LIABILITIES.CREDIT &&
                  LIABILITIES.CREDIT_CARD}
                {location?.state?.type === ASSET.OTHER && ASSET.OTHER1}
                {(location?.state?.type === ASSET.REAL_ESTATE ||
                  location?.state?.type === ASSET.INVESTMENT ||
                  location?.state?.type === ASSET.INSURANCE ||
                  location?.state?.type === ASSET.INCOME) &&
                  capitalizeFirstLetter(location?.state?.type)}{" "}
              </Typography>
            </Grid>
          </Grid>
          {/* banks */}
          <Grid container spacing={2}>
            {bankDetailData?.data.length > 0 ? (
              <>
                {bankDetailData?.data?.map((data, index) => {
                
                  return (
                    <Grid key={index} item xs={isMobile ? 12 : 6}>
                      <Card
                        sx={{
                          background: color.topbar,
                          borderRadius: 2,
                          p: 2,
                          mb: 2,
                        }}
                      >
                        <Grid container gap={4}>
                          <Grid item>
                            {(data?.type === ASSET.INVESTMENT && (
                              <img src={investmentIcom} width={50} />
                            )) ||
                              (data?.type === "depository" && (
                                <Icon color="grey" icon="ph:bank" width={50} />
                              ))}
                          </Grid>

                          <Grid item>
                            <Typography className="heading">
                              {data?.type === ASSET.INVESTMENT &&
                                "Investment Value"}
                              {data?.type === "credit" && "Spend"}
                              {data?.type === "depository" &&
                                "Available Balance"}
                              {data?.type === "loan" && "Loan Amount"}
                            </Typography>
                            <Typography className="info-title-text">
                              {currencyFormat(
                                data?.balance_current
                                  ? data?.balance_current
                                  : 0
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <SVDivider style={{ border: "0.1px solid #707070" }} />
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              {data?.type === ASSET.INVESTMENT &&
                                ASSET.INVESTMENT_NAME}
                              {data?.type === LIABILITIES.CREDIT &&
                                LIABILITIES.CARD_NAME}
                              {data?.type == ASSET.DEPOSITORY &&
                                ASSET.ACCOUNT_NAME}
                              {data?.type === LIABILITIES.LOAN &&
                                LIABILITIES.LOAN_NAME}
                            </Typography>
                            <Typography className="info-text">
                              {data?.name ? data?.name : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              {data?.type === ASSET.INVESTMENT &&
                                ASSET.INVESTMENT_TYPE}
                              {data?.type === LIABILITIES.CREDIT &&
                                LIABILITIES.CARD_TYPE}
                              {data?.type == ASSET.DEPOSITORY &&
                                LIABILITIES.ACCOUNT_TYPE}
                              {data?.type === LIABILITIES.LOAN &&
                                LIABILITIES.LOAN_TYPE}
                            </Typography>
                            <Typography className="info-text">
                              {data?.type ? data?.type : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              {data?.type === LIABILITIES.CREDIT
                                ? "Created"
                                : "Start Date"}
                            </Typography>
                            <Typography className="info-text">
                              {data?.createdDate
                                ? moment(data?.createdDate).format(
                                    "MMMM Do, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        {data?.type === ASSET.DEPOSITORY && (
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item>
                              <Typography className="info-heading">
                                Description
                              </Typography>
                              <Typography className="info-text">
                                {data?.official_name
                                  ? data?.official_name
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {data?.type === LIABILITIES.CREDIT && (
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item>
                              <Typography className="info-heading">
                                Credit Limit
                              </Typography>
                              <Typography className="info-text">
                                {data?.balance_limit
                                  ? data?.balance_limit
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Card>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <>
                {payRollIncomeData ||
                otherAccountData ||
                insuranceDetail ||
                realEstateDetailData ? (
                  ""
                ) : (
                  <DataNotFound />
                )}
              </>
            )}
          </Grid>

{/* income */}
          <Grid container spacing={2}>
            {payRollIncomeData?.data?.items?.length > 0 && (
              <>
                {payRollIncomeData?.data?.items?.map((data, index) => {
                  return (
                    <Grid key={index} item xs={isMobile ? 12 : 6}>
                      <Card
                        sx={{
                          background: color.topbar,
                          borderRadius: 2,
                          p: 2,
                          mb: 2,
                        }}
                      >
                        <Grid container gap={4}>
                          <Grid item>
                            <img src={incomeIconImg} width={50} />
                          </Grid>

                          <Grid item>
                            <Typography className="heading">
                              Current Amount
                            </Typography>
                            <Typography
                              sx={{ fontSize: 29, color: color.main }}
                            >
                              {currencyFormat(
                                data?.netpay?.current_amount
                                  ? data?.netpay?.current_amount
                                  : 0
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <SVDivider style={{ border: "0.1px solid #707070" }} />
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              Institution Name
                            </Typography>
                            <Typography
                            className="info-text"
                            >
                              {data?.institution_name
                                ? data?.institution_name
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              Rate Of Pay
                            </Typography>
                            <Typography
                             className="info-text"
                            >
                              {data?.account?.rate_of_pay_pay_rate
                                ? data?.account?.rate_of_pay_pay_rate
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              Created
                            </Typography>
                            <Typography
                             className="info-text"
                            >
                              {data?.created_at
                                ? moment(data?.created_at).format(
                                    "MMMM Do, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        {data?.type === "depository" && (
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item>
                              <Typography
                              className="info-heading"
                              >
                                Description
                              </Typography>
                              <Typography
                               className="info-text"
                              >
                                {data?.official_name
                                  ? data?.official_name
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {data?.type === "credit" && (
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item>
                              <Typography
                               className="info-heading"
                              >
                                Credit Limit
                              </Typography>
                              <Typography
                                className="info-text"
                              >
                                {data?.balance_limit
                                  ? data?.balance_limit
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Card>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>

          {/* real estate */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {realEstateDetailData?.data?.details?.length > 0 && (
              <>
                {realEstateDetailData?.data?.details?.map((data, index) => {
                  return (
                    <Grid key={index} item xs={isMobile ? 12 : 6}>
                      <Card
                        sx={{
                          background: color.topbar,
                          borderRadius: 2,
                          p: 2,
                          mb: 2,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={1.5}>
                            <img src={realEstateIconImg} width={50} />
                          </Grid>

                          <Grid item xs={8.5}>
                            <Typography className="heading">
                              Current Value
                            </Typography>
                            <Typography className="info-title-text">
                              {currencyFormat(
                                data?.current_value ? data?.current_value : 0
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Icon
                              icon="mingcute:delete-2-fill"
                              color="red"
                              width={35}
                              style={{ cursor: "pointer", marginRight: "1rem" }}
                              onClick={() => {
                                setAssetsType("Real Estate");
                                setShowAlertDialog(true);
                                setRealStateId(data?.id);
                              }}
                            />
                          </Grid>
                        </Grid>
                        <SVDivider style={{ border: "0.1px solid #707070" }} />
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              Owner Full Name
                            </Typography>
                            <Typography className="info-text ">
                              {data?.full_name ? data?.full_name : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              Last Sell Value
                            </Typography>
                            <Typography className="info-text ">
                              {currencyFormat(
                                data?.actual_value ? data?.actual_value : 0
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              Address
                            </Typography>
                            <Typography className="info-text ">
                              {data?.address ? data?.address : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography className="info-heading">
                              Created
                            </Typography>
                            <Typography className="info-text ">
                              {data?.timestamp
                                ? moment(data?.timestamp).format(
                                    "MMMM Do, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        {data?.type === "depository" && (
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item>
                              <Typography className="info-heading">
                                Description
                              </Typography>
                              <Typography className="info-text ">
                                {data?.official_name
                                  ? data?.official_name
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {data?.type === "credit" && (
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item>
                              <Typography className="info-heading">
                                Credit Limit
                              </Typography>
                              <Typography className="info-text ">
                                {data?.balance_limit
                                  ? data?.balance_limit
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Card>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
          {/* other assets */}
          <Grid container spacing={2}>
            {otherAccountData?.data.length > 0 && (
              <>
                {otherAccountData?.data?.map((data, index) => {
                  return (
                    <Grid key={index} item xs={isMobile ? 4 : 6}>
                      <Card
                        sx={{
                          background: color.topbar,
                          borderRadius: 2,
                          p: 2,
                          // mb: 1,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={1.5}>
                            <Icon color="grey" icon="ph:bank" width={50} />
                          </Grid>

                          <Grid item xs={9.5}>
                            <Typography className="heading">
                              {data?.type === "asset" && "Annual Earned"}
                              {data?.type === "liability" && "Current value"}
                            </Typography>
                            <Typography className="info-title-text">
                              {data?.type === "asset" && (
                                <>
                                  {data?.annualIncome
                                    ? currencyFormat(data?.annualIncome)
                                    : 0}
                                </>
                              )}
                              {data?.type === "liability" && (
                                <>
                                  {data?.currentValue
                                    ? currencyFormat(data?.currentValue)
                                    : 0}
                                </>
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Icon
                              icon="mingcute:delete-2-fill"
                              color="red"
                              width={35}
                              style={{ cursor: "pointer", marginRight: "1rem" }}
                              onClick={() => {
                                setAssetsType("Other");
                                setShowAlertDialog(true);
                                setRealStateId(data?.id);
                              }}
                            />
                          </Grid>
                        </Grid>
                        <SVDivider style={{ border: "0.1px solid #707070" }} />
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography sx={{ fontSize: 10, color: "#707070" }}>
                              {data?.type === "asset" && "Asset Name"}
                              {data?.type === "liability" && "Liabilities name"}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 15, color: color.main }}
                            >
                              {data?.name ? data?.name : "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        {data?.type === "asset" && (
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item>
                              <Typography
                                sx={{ fontSize: 10, color: "#707070" }}
                              >
                                Loan Amount
                              </Typography>
                              <Typography
                                sx={{ fontSize: 15, color: color.main }}
                              >
                                {currencyFormat(
                                  data?.currentValue ? data?.currentValue : 0
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}

                        <Grid container sx={{ mt: 2 }}>
                          <Grid item>
                            <Typography sx={{ fontSize: 10, color: "#707070" }}>
                              Created
                            </Typography>
                            <Typography
                              sx={{ fontSize: 15, color: color.main }}
                            >
                              {data?.timestamp
                                ? moment(data?.timestamp).format(
                                    "MMMM Do, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
          {/* insurance */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {insuranceDetail?.length > 0 &&
              insuranceDetail?.map((data, index) => {
                return (
                  <>
                    <InsuranceCard
                    isMobile={isMobile}
                      setInsuranceDeleteDialog={setInsuranceDeleteDialog}
                      key={index}
                      data={data}
                      setShowInsuranceDetail={setShowInsuranceDetail}
                      setInsuranceDetailData={setInsuranceDetailData}
                    />
                  </>
                );
              })}
          </Grid>
        </Box>
      </>

      <Spinner open={isLoading} />
    </>
  );
};

export default BankDetails;
