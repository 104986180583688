import { Icon } from "@iconify/react";
import { Card, useMediaQuery, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import { color } from "../../../../theme/color";
import { useTheme } from "@emotion/react";

export default function index(props) {
  const { userDashboardData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container spacing={4}>
      <Grid item xs={isMobile ? 12 : 3}>
        <Card
          sx={{
            maxWidth: "100vw",
            color: color.main,
            background: "#202020",
            p: 1,
            height: "82.05px",
          }}
        >
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={6}>
              <Card
                sx={{
                  width: isMobile ? 50 : 70,
                  color: color.main,
                  background: "#FB9107",
                  p: 1,
                  height: isMobile ? 49 : 69,
                  borderRadius: 0,
                  ml: 2,
                  mt: -3,
                  position: "absolute",
                  zIndex: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  icon="mingcute:bank-line"
                  width={isMobile ? 25 : 50}
                  height={isMobile ? 25 : 50}
                  color={color.main}
                />
              </Card>
            </Grid>
            <Grid item >
              <Typography sx={{ fontSize: 12, color: "grey" }}>
                All Accounts
              </Typography>
              <Typography sx={{ fontSize: 20 }}>
                {userDashboardData?.allUserCount
                  ? userDashboardData?.allUserCount
                  : "0"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <Card
          sx={{
            maxWidth: "100vw",
            color: color.main,
            background: "#202020",
            p: 1,
            height: "82.05px",
          }}
        >
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={6}>
              <Card
                sx={{
                  width: isMobile ? 50 : 70,
                  color: color.main,
                  background: "#4BA64F",
                  p: 1,
                  height: isMobile ? 49 : 69,
                  borderRadius: 0,
                  ml: 2,
                  mt: -3,
                  position: "absolute",
                  zIndex: 0,
                }}
              >
                <Icon
                  icon="pepicons-pop:checkmark-outlined"
                  width={isMobile ? 25 : 50}
                  height={isMobile ? 25 : 50}
                  color={color.main}
                />
              </Card>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: 12, color: "grey" }}>
                Active
              </Typography>
              <Typography sx={{ fontSize: 20 }}>
                {" "}
                {userDashboardData?.approveUser
                  ? userDashboardData?.approveUser
                  : "0"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <Card
          sx={{
            maxWidth: "100vw",
            color: color.main,
            background: "#202020",
            p: 1,
            height: "82.05px",
          }}
        >
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={6}>
              <Card
                sx={{
                  width: isMobile ? 50 : 70,
                  color: color.main,
                  background: "#E63C39",
                  p: 1,
                  height: isMobile ? 49 : 69,
                  borderRadius: 0,
                  ml: 2,
                  mt: -3,
                  position: "absolute",
                  zIndex: 0,
                }}
              >
                <Icon
                  icon="material-symbols:block"
                  width={isMobile ? 25 : 50}
                  height={isMobile ? 25 : 50}
                  color={color.main}
                />
              </Card>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: 12, color: "grey" }}>
                Suspended
              </Typography>
              <Typography sx={{ fontSize: 20 }}>
                {userDashboardData?.suspendUser
                  ? userDashboardData?.suspendUser
                  : "0"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={isMobile ?12 : 3}>
        <Card
          sx={{
            maxWidth: "100vw",
            color: color.main,
            background: "#202020",
            p: 1,
            height: "82.05px",
          }}
        >
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={6}>
              <Card
                sx={{
                  width: isMobile ? 50 : 70,
                  color: color.main,
                  background: "#07B0C5",
                  p: 1,
                  height: isMobile ? 49 : 69,
                  borderRadius: 0,
                  ml: 2,
                  mt: -3,
                  position: "absolute",
                  zIndex: 0,
                }}
              >
                <Icon
                  icon="solar:history-line-duotone"
                  width={isMobile ? 25 : 50}
                  height={isMobile ? 25 : 50}
                  color={color.main}
                />
              </Card>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: 12, color: "grey" }}>
                Pending
              </Typography>
              <Typography sx={{ fontSize: 20 }}>
                {userDashboardData?.pendingUser
                  ? userDashboardData?.pendingUser
                  : "0"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
