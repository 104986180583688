import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import Cookies from "js-cookie";

const firebaseConfig = {
  apiKey: "AIzaSyDCnCSseW3EfEOv2zCYqBENCK5HBxff0rc",
  authDomain: "silverback-mobile-2024.firebaseapp.com",
  projectId: "silverback-mobile-2024",
  storageBucket: "silverback-mobile-2024.appspot.com",
  messagingSenderId: "909618343007",
  appId: "1:909618343007:web:49b0682ab04f797a62bfea",
  measurementId: "G-JM3CYFBGHM",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const messaging = getMessaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const requestForToken = async () => {
  if (messaging) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        //Voluntary Application Server Identification or VAPID key.
        //{ vapidKey: `BHpyLSxJehOnwFKLws1JPmmZlyQG_YRBLQdsoWljlAEOYCwsRDA2ttU5A9PaxXffCQyiHXupnCPy9g2sxi8LOlg` }
        return getToken(messaging, {
          vapidKey: `BHpyLSxJehOnwFKLws1JPmmZlyQG_YRBLQdsoWljlAEOYCwsRDA2ttU5A9PaxXffCQyiHXupnCPy9g2sxi8LOlg`,
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log("Client Token: ", currentToken);
              Cookies.set(process.env.REACT_APP_FCM_TOKEN_KEY, currentToken, {
                secure: true,
              });
            } else {
              console.log("Failed to generate the registration token.");
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred when requesting to receive the token.",
              err
            );
          });
      } else {
        console.log("User Permission Denied.");
      }
    });
  }
};
