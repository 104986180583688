import React, { useEffect, useState } from "react";
import {
  Dialog,
  Typography,
  DialogTitle,
  Grid,
  Divider,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import SVDivider from "components/SVDivider";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { updatePermissions } from "store/slice/profile";
// import "./index.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditRoleDialog = ({ ...props }) => {
  const { openDialog, setOpenDialog, roleId ,roleName} = props;
  const {
    permissionListData,
    rolePermissionDetail,
    // permissionLevelList
  } = useSelector((state) => state.profile);
  const [permissionDetailData, setPermissionDetailData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (rolePermissionDetail) {
      setPermissionDetailData(rolePermissionDetail);
    }
  }, [rolePermissionDetail]);
  const permissionLevelList = [
    {
      id: 1,
      name: "Read",
    },
    {
      id: 2,
      name: "Update",
    },
    {
      id: 3,
      name: "Create",
    },
    {
      id: 4,
      name: "Delete",
    },
  ];
  const onClose = () => {
    setOpenDialog(false);
  };
  const handlePermissions = (permission_id, indentifier, permissionId) => {
    if (permissionDetailData[indentifier]) {
      let temp = { ...permissionDetailData };
      temp[indentifier] = permissionId;
      setPermissionDetailData(temp);
    } else {
      let temp = { ...permissionDetailData };
      let obj = { ...temp, [indentifier]: permissionId };
      setPermissionDetailData(obj);
    }
    let request = {
      role_id: roleId,
      permission_id: permission_id,
      permission_level_id: permissionId,
    };
    dispatch(updatePermissions(request));
  };
  return (
    <BootstrapDialog
      fullWidth
      scroll="paper"
      open={openDialog}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">{roleName}  Role</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider />
      {/* <DialogContent> */}
      {/* <DialogContentText> */}
      <Grid container sx={{ my: 2, textAlign: "center" }}>
        <Grid xs={3}></Grid>
        <Divider sx={{ my: -2 }} orientation="vertical" flexItem />

        <Grid xs={2}>
          <Typography variant="h6" color={"black"}>
            Read
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant="h6" color={"black"}>
            Update
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant="h6" color={"black"}>
            Create
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant="h6" color={"black"}>
            Delete
          </Typography>
        </Grid>
      </Grid>
      <SVDivider />
      {permissionListData?.map((permission, index) => {
        return (
          <>
            <Grid
              container
              sx={{
                textAlign: "center",
                // background: index % 2 === 0 ? "#dff6ffc2" : "",
              }}
              key={index}
            >
              <Grid
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color={"black"}>
                  {permission?.name}
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid>
                <FormControl>
                  <RadioGroup
                    value={
                      permissionDetailData &&
                      permissionDetailData[permission?.identifier]
                    }
                    sx={{ gap: 5, pl: 4 }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {permissionLevelList?.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={item?.id}
                          control={
                            <Radio
                              onClick={() =>
                                handlePermissions(
                                  permission?.id,
                                  permission?.identifier,
                                  item?.id
                                )
                              }
                              checkedIcon={
                                <Icon
                                  icon={"fluent:radio-button-24-filled"}
                                  width={35}
                                  color="#1098DB"
                                />
                              }
                              icon={
                                <Icon
                                  icon={"fluent:radio-button-24-regular"}
                                  width={35}
                                />
                              }
                            />
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <SVDivider />
          </>
        );
      })}
      {/* </DialogContentText> */}
      {/* </DialogContent> */}
    </BootstrapDialog>
  );
};

export default React.memo(EditRoleDialog);
