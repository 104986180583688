import { useTheme } from "@emotion/react";
import TopHeading from "components/topHeading";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import SVButton from "components/SVButton";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import {
  ESTATE_PLANNING_KEYS,
  ESTATE_STATUS,
  PAGINATION,
} from "utils/appConstant";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getEstateList } from "store/slice/estate";
import { capitalizeFirstLetter } from "utils/constantFun";
import { Icon } from "@iconify/react";
import { color } from "../../../theme/color";
import Spinner from "components/spinner";
import GlobalEstateDocumentNumber from "./globalEstateDocument";
import AdminInput from "components/adminInput";
import { Link } from "react-router-dom";
export default function EstateDocument() {
  var page = PAGINATION.page;
  const theme = useTheme();
  const location = useLocation();
  const statusData = location?.state?.statusData;
  const searchFilter = location?.state?.searchFilter;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const dispatch = useDispatch();
  var [searchValue, setSearchValue] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  var [formType, setFormType] = useState(searchFilter);
  var [statusType, setStatusType] = useState(statusData);
  var [startDate, setStartDate] = useState("dd/mm/yyyy");
  var [endDate, setEndDate] = useState("dd/mm/yyyy");
  const [showDocumentNoDialog, setShowDocumentNoDialog] = useState(false);
  const { estateListData, estateTotalCount } = useSelector(
    (state) => state.estate
  );
  useEffect(() => {
    setIsLoading(true);
    fetchEstateDocumentList();
  }, []);
  const fetchEstateDocumentList = (page, numberOfRows) => {
    var data = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      formType: formType ? formType : "",
      statusType: statusType ? statusType : "",
      userName: searchValue ? searchValue : "",
      startDate:
        startDate != "dd/mm/yyyy"
          ? moment(startDate).format("YYYY-MM-DD 00:00:00")
          : null,
      endDate:
        endDate != "dd/mm/yyyy"
          ? moment(endDate).format("YYYY-MM-DD 00:00:00")
          : null,
    };
    dispatch(getEstateList(data, setIsLoading));
  };
  const options = {
    count: estateTotalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      //setPageCheck(currentPage);
      fetchEstateDocumentList(currentPage, "", "", "");
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchEstateDocumentList(page, numberOfRows, "", "");
    },
  };

  const columns = [
    {
      name: "principal_name",
      label: "Principal Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (params) => {
          const email = params;
          return (
            // <Tooltip title={email} arrow>
            <Typography> {email}</Typography>
          );
        },
      },
    },
    {
      name: "formType",
      label: "Document Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>
              {value ? capitalizeFirstLetter(value?.replace(/_/g, " ")) : "-"}
            </Typography>
          );
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              {value === ESTATE_STATUS?.REVIEW && (
                <>
                  <span
                    style={{
                      color: color.main,
                      background: "#1098DB",
                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b>{value ? capitalizeFirstLetter(value) : "-"}</b>
                  </span>
                </>
              )}
              {value === ESTATE_STATUS?.SIGNATURE_PENDING && (
                <>
                  <span
                    style={{
                      color: color.main,
                      background: "yellow",
                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b>{value ? capitalizeFirstLetter(value) : "-"}</b>
                  </span>
                </>
              )}
              {value === ESTATE_STATUS?.APPROVE && (
                <span
                  style={{
                    color: color.main,
                    background: "#4BA64F",
                    fontSize: 11,
                    padding: 4,
                    borderRadius: "10%",
                  }}
                >
                  <b> {value ? capitalizeFirstLetter(value) : "-"}</b>
                </span>
              )}

              {value === ESTATE_STATUS?.REJECT && (
                <span
                  style={{
                    color: color.main,
                    background: "red",
                    fontSize: 11,
                    padding: 4,
                    borderRadius: "10%",
                  }}
                >
                  <b>{value ? capitalizeFirstLetter(value) : "-"}</b>
                </span>
              )}
            </>
          );
        },
      },
    },

    {
      name: "principal_signature_date",
      label: "Created At",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>{moment(value).format("MMMM Do, YYYY")}</Typography>
          );
        },
      },
    },

    {
      name: "userId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div style={{ paddingLeft: "1rem" }}>
              <Link to={`/profile/estateDetail/${value}`}>
                <Icon
                  icon="carbon:view"
                  color={color.buttonColor}
                  width={isMobile ? 30 : 30}
                  height={isMobile ? 30 : 30}
                />
              </Link>
            </div>
          );
        },
      },
    },
  ];
  const handleStartDate = (value) => {
    // const valDate = moment(value).format("YYYY-MM-DD 00:00:00");
    setStartDate(value);
  };
  const handleEndDate = (value, numberOfRows) => {
    const valDate = moment(value).format("YYYY-MM-DD 23:59:59");
    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(
      valDate === "dd/mm/yyyy" || valDate === "Invalid date"
        ? "dd/mm/yyyy"
        : startDate
    );

    var data = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      formType: formType ? formType : "",
      statusType: statusType ? statusType : "",
      userName: searchValue ? searchValue : "",
      startDate:
        valDate == "Invalid date" || valDate == ""
          ? null
          : moment(startDate).format("YYYY-MM-DD 00:00:00"),
      endDate: valDate !== "Invalid date" ? valDate : null,
    };
    dispatch(getEstateList(data, setIsLoading));
  };

  return (
    <div>
      <Stack
        direction={"row"}
        sx={{
          display: isMobile ? "block" : "flex",
          justifyContent: "space-between",
          px: 1,
          pb: 2,
        }}
      >
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Estate Planning"} />
        <SVButton
          text="Set Documents Limit"
          onClick={() => setShowDocumentNoDialog(true)}
          sx={{ px: 3, mt: isMobile ? 2 : -1.5 }}
        />
      </Stack>
      {/*update  document number dialog */}
      <GlobalEstateDocumentNumber
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        openDialog={showDocumentNoDialog}
        setOpen={setShowDocumentNoDialog}
      />
      {/* loader */}
      <Spinner open={isLoading} />

      <Grid spacing={2} container sx={{ pt: 1, pb: 1, mt: 2 }}>
        <Grid item xs={isMobile ? 6 : 4}>
          <AdminInput
            sx={{
              mt: -1,
              background: "#202020",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#202020 !important",
                },
              },
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                fetchEstateDocumentList(page);
              }
            }}
            color="white"
            title="Search Principal"
            type="email"
            endIcon={
              searchValue && (
                <Icon
                  icon="material-symbols:cancel"
                  color="red"
                  onClick={() => {
                    setSearchValue("");
                    searchValue = "";
                    fetchEstateDocumentList();
                  }}
                />
              )
            }
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value);
              fetchEstateDocumentList();
            }}
          />
        </Grid>

        <Grid item xs={isMobile ? 6 : 4}>
          <FormControl
            fullWidth
            sx={{
              mt: -1,
              background: "#202020",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#202020 !important",
                },
              },
            }}
          >
            <InputLabel id="demo-simple-select-label">Form Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formType}
              label="Filter"
              onChange={(event) => {
                formType = event.target.value;
                setFormType(event.target.value);
                fetchEstateDocumentList();
              }}
              style={{ color: "white" }}
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={ESTATE_PLANNING_KEYS?.POWER_OF_ATTORNEY}>
                {capitalizeFirstLetter(
                  ESTATE_PLANNING_KEYS?.POWER_OF_ATTORNEY?.replace(/_/g, " ")
                )}
              </MenuItem>
              <MenuItem
                value={ESTATE_PLANNING_KEYS?.HIPAA_RELEASE_AND_AUTHORIZATION}
              >
                Hipaa Release And Authorization
              </MenuItem>
              <MenuItem
                value={ESTATE_PLANNING_KEYS?.LIVING_WILL_HEALTH_CARE_PROXY}
              >
                Living will/Health Care Proxy
              </MenuItem>
              <MenuItem value={ESTATE_PLANNING_KEYS?.LAST_WILL_AND_TESTAMENT}>
                {capitalizeFirstLetter(
                  ESTATE_PLANNING_KEYS?.LAST_WILL_AND_TESTAMENT?.replace(
                    /_/g,
                    " "
                  )
                )}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <FormControl
            fullWidth
            sx={{
              mt: -1,
              background: "#202020",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#202020 !important",
                },
              },
            }}
          >
            <InputLabel id="demo-simple-select-label">Status Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statusType}
              label="Filter"
              onChange={(event) => {
                statusType = event.target.value;
                setStatusType(event.target.value);
                fetchEstateDocumentList();
              }}
              style={{ color: "white" }}
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={ESTATE_STATUS?.REVIEW}>
                {capitalizeFirstLetter(ESTATE_STATUS?.REVIEW)}
              </MenuItem>
              <MenuItem value={ESTATE_STATUS?.APPROVE}>
                {capitalizeFirstLetter(ESTATE_STATUS?.APPROVE)}
              </MenuItem>
              <MenuItem value={ESTATE_STATUS?.REJECT}>
                {capitalizeFirstLetter(ESTATE_STATUS?.REJECT)}
              </MenuItem>
              <MenuItem value={ESTATE_STATUS?.SIGNATURE_PENDING}>
                {capitalizeFirstLetter(ESTATE_STATUS?.SIGNATURE_PENDING)}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <AdminInput
            color="white"
            type={"date"}
            title={"Start Date"}
            // min={currnetDate}
            sx={{
              mt: -1,
              pr: 2,
              background: "#202020",
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#202020 !important",
                },
              },

              '& input[type="date"]::-webkit-calendar-picker-indicator': {
                filter: "invert(70%)", // Change the color of the calendar icon
              },
            }}
            value={startDate || ""}
            onChange={(e) => handleStartDate(e.target.value)}
            // value={startDate || ""}
            // onChange={(e) => {
            //   startDate = e.target.value;
            //   setStartDate(e.target.value);
            //   fetchEstateDocumentList();
            // }}
          />
        </Grid>

        <Grid item xs={isMobile ? 6 : 3}>
          <AdminInput
            color="white"
            type={"date"}
            title={"End Date"}
            // min={currnetDate}
            sx={{
              mt: -1,
              pr: 2,
              background: "#202020",
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#202020 !important",
                },
              },
              '& input[type="date"]::-webkit-calendar-picker-indicator': {
                filter: "invert(70%)", // Change the color of the calendar icon
              },
            }}
            value={endDate || ""}
            onChange={(e) => handleEndDate(e.target.value)}
            // onChange={(e) => {
            //   endDate = e.target.value;
            //   setEndDate(e.target.value);
            //   fetchEstateDocumentList();
            // }}
            readOnly={isMobile}
          />
        </Grid>
      </Grid>
      <div className="App wrapper mt-5">
        <>
          {estateListData?.length > 0 ? (
            <Grid container>
              <Grid xs={12}>
                <MUIDataTable
                  // title={"Events List"}
                  data={estateListData}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid #202020",
                  mt: 2,
                  height: 300,
                  backgroundColor: "#202020",
                }}
              >
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 500,
                      mt: "7.5rem",
                      color: "white",
                    }}
                  >
                    No Record Found
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </div>
    </div>
  );
}
