import React from "react";
import { Box, Card, Grid, Typography, useMediaQuery } from "@mui/material";
import { color } from "../../../../theme/color";
import { useTheme } from "@emotion/react";
import { currencyFormat } from "utils/currencyFormatter";
import SVButton from "components/SVButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function BalanceView({ ...props }) {
  const {
    transactionListData,
    profileId,
    //  financialAccountId
  } = props;
  const topTwoTransaction = transactionListData?.slice(0, 2);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card sx={{ background: color.dark, borderRadius: "2px", p: 0, mb: 2 }}>
      <Card
        sx={{
          maxWidth: "100vw",
          color: color.main,
          background: color.topbar,
          p: 2,
        }}
      >
        <Grid container display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={6}>
            <Typography
              className="textLinearBackground"
              sx={{ fontSize: 22, mb: 1, color: color.main }}
            >
              Balance
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className="textLinearBackground"
              sx={{ fontSize: 22, mb: 1, color: color.main }}
            >
              Transactions
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card
                  sx={{
                    color: color.black,
                    background: color.liner,
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={{ fontSize: 17, mb: 1 }}>
                    Total Balance
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 25,
                      mb: 1,
                      color: color.black,
                      mt: -1,
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    {currencyFormat(0)}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    color: color.black,
                    background: color.liner,
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={{ fontSize: 17, mb: 1 }}>
                    Spend Account
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 25,
                      mb: 1,
                      color: color.black,
                      mt: -1,
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    {currencyFormat(0)}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    color: color.black,
                    background: color.liner,
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={{ fontSize: 17, mb: 1 }}>
                    Savings Account
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 25,
                      mb: 1,
                      color: color.black,
                      mt: -1,
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    {currencyFormat(0)}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    color: color.black,
                    background: color.liner,
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={{ fontSize: 17, mb: 1 }}>
                    Splurge Account
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 25,
                      mb: 1,
                      color: color.black,
                      mt: -1,
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    {currencyFormat(0)}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            {transactionListData?.length > 0 ? (
              <Grid container direction="column" spacing={2} sx={{ mb: 2 }}>
                {topTwoTransaction?.map((item) => (
                  <Grid item key={item?.id} xs={isMobile ? 12 : 6}>
                    <Card
                      sx={{
                        background: color.black,
                        p: 2.1,

                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "8px",
                        alignContent: "center",
                      }}
                    >
                      <Grid>
                        <Typography
                          variant="body2"
                          sx={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 1,
                            color: "white",
                          }}
                        >
                          {item?.type} - ${item?.amount?.value}{" "}
                          {item?.amount?.currencyCode}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {moment(item?.date).format("MMMM Do, YYYY")} - Status:{" "}
                          {item?.status}
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="28vh" // Adjust the height as needed
                textAlign="center"
              >
                <Typography variant="h6" color="textSecondary">
                  No Transactions Yet
                </Typography>
              </Box>
            )}
            {transactionListData?.length > 0 && (
             <Box display={'flex'} justifyContent={'center'}>
               <SVButton
                onClick={() => navigate(`/profile/transaction/${profileId}`)}
                text={"View All"}
                sx={{
                  borderRadius: 10,
                  fontSize: isMobile && 10,
                  height: 32,
                  p: 2,
                }}
               
              />
             </Box>
            )}
            {/* Add content here or leave empty */}
          </Grid>
        </Grid>
      </Card>
    </Card>
  );
}
