import React, { useEffect, useState } from "react";
import AdminButton from "../../../../components/adminButton";
import { Card, Grid, Typography, useMediaQuery } from "@mui/material";
import AdminInput from "../../../../components/adminInput";
import { color } from "../../../../theme/color";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminProfileDetail,
  editProfile,
  sendOtpForProfile,
  verifyOtpForProfile,
} from "../../../../store/slice/profile";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./style.css";
import Spinner from "../../../../components/spinner";
import OtpView from "pages/admin/profileDetail/sections/otpView";

export default function index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const { adminPorfileDetailData, uploadPorfilePicData, isLoading } =
    useSelector((state) => state.profile);
  const [phoneField, setPhoneField] = useState();
  const [otpDialog, setOtpDialog] = useState(false);
  const [otp, setOtp] = useState("");
  useEffect(() => {
    dispatch(adminProfileDetail());
  }, []);

  useEffect(() => {
    if (adminPorfileDetailData) {
      setFormData({
        firstName: adminPorfileDetailData?.first_name,
        lastName: adminPorfileDetailData?.surename,
        email: adminPorfileDetailData?.email,
        address: adminPorfileDetailData?.address,
        phone: adminPorfileDetailData?.phone,
        countryFlag: adminPorfileDetailData?.countryFlag,
        countryCode: adminPorfileDetailData?.countryCode,
        profilePic: adminPorfileDetailData.profilePic,
      });
      setPhoneField({
        phone: adminPorfileDetailData?.phone,
        countryCode: adminPorfileDetailData?.countryCode,
        countryFlag: adminPorfileDetailData?.countryFlag,
        value:
          adminPorfileDetailData?.countryCode &&
          adminPorfileDetailData?.phone &&
          adminPorfileDetailData?.countryCode + adminPorfileDetailData?.phone,
      });
    }
  }, [adminPorfileDetailData]);

  const sendOptApi = () => {
    var data = {
      userId: adminPorfileDetailData?.id,
      verificationType: "phone",
      phone: phoneField?.phone,
      email: "",
    };
    dispatch(sendOtpForProfile(data, setOtpDialog));
  };
  const verifyOtp = () => {
    var otpData = {
      userId: adminPorfileDetailData?.id,
      verificationType: "phone",
      otp: otp,
    };
    dispatch(verifyOtpForProfile(otpData, setOtpDialog));
  };

  const updateProfile = () => {
    if (!formData.firstName) {
      setFormError((formError) => ({
        ...formError,
        firstName: "Please enter first name",
      }));
      return;
    }
    if (!formData.lastName) {
      setFormError((formError) => ({
        ...formError,
        lastName: "Please enter last name",
      }));
      return;
    }

    var data = {
      first_name: formData.firstName,
      surename: formData.lastName,
      address: formData.address,
      phone: phoneField?.phone,
      countryCode: phoneField?.countryCode,
      countryFlag: phoneField?.countryFlag,
      dateOfBirth: "",
      postalCode: "",
      locality: "",
      state: "",
      profilePic: uploadPorfilePicData?.path,
    };
    dispatch(editProfile(data, true));
  };

  const handlePhoneInput = (value) => {
    setPhoneField({
      phone: value?.phone.slice(value?.dial?.dialCode.length),
      countryCode: value?.dial?.dialCode,
      countryFlag: value?.dial?.countryCode,
      value: value?.phone,
    });
  };

  return (
    <>
      <>
        {" "}
        {!isLoading && (
          <Card
            sx={{
              maxWidth: "92vw",
              p: isMobile ? 2 : 5,
              color: color.fourth,
              background: "#202020",

              marginTop: -2,
            }}
          >
            <Grid
              container
              component="main"
              sx={{ alignItems: "center", px: isMobile ? 0 : 0 }}
            >
              <Typography sx={{ color: "white", fontWeight: 500 }}>
                Profile Details
              </Typography>

              <Grid container spacing={2} component="main">
                <Grid item xs={12} sm={12} md={6}>
                  <AdminInput
                    color="white"
                    title="First Name"
                    value={formData.firstName || ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#C5C5C5 ",
                        },
                      },
                    }}
                    onChange={(e) => {
                      if (e) {
                        setFormData((formData) => ({
                          ...formData,
                          firstName: e.target.value,
                        }));
                        if (e) {
                          setFormError((formError) => ({
                            ...formError,
                            firstName: "",
                          }));
                        }
                      }
                    }}
                    onBlur={
                      adminPorfileDetailData?.first_name !==
                        formData.firstName && updateProfile
                    }
                    formError={formError.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <AdminInput
                    color="white"
                    title="Last Name"
                    value={formData.lastName || ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#C5C5C5",
                        },
                      },
                    }}
                    onChange={(e) => {
                      if (e) {
                        setFormData((formData) => ({
                          ...formData,
                          lastName: e.target.value,
                        }));
                        if (e) {
                          setFormError((formError) => ({
                            ...formError,
                            lastName: "",
                          }));
                        }
                      }
                    }}
                    onBlur={
                      adminPorfileDetailData?.surename !== formData.lastName &&
                      updateProfile
                    }
                    formError={formError.lastName}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} sx={{ mt: -1 }}>
                  <AdminInput
                    color="white"
                    title="Email"
                    value={formData.email || ""}
                    disabled={true}
                    sx={{
                      "& .MuiInputBase-root.Mui-disabled": {
                        "& > fieldset": {
                          borderColor: "grey !important",
                        },
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "grey",
                      },
                    }}
                    onChange={(e) => {
                      if (e) {
                        setFormData((formData) => ({
                          ...formData,
                          email: e.target.value,
                        }));
                        if (e) {
                          setFormError((formError) => ({
                            ...formError,
                            email: "",
                          }));
                        }
                      }
                    }}
                    formError={formError.email}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} sx={{ mt: -1 }}>
                  <AdminInput
                    color="white"
                    title="Address"
                    value={formData.address || ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#C5C5C5",
                        },
                      },
                    }}
                    onChange={(e) => {
                      if (e) {
                        setFormData((formData) => ({
                          ...formData,
                          address: e.target.value,
                        }));
                        if (e) {
                          setFormError((formError) => ({
                            ...formError,
                            address: "",
                          }));
                        }
                      }
                    }}
                    onBlur={
                      adminPorfileDetailData?.address !== formData.address &&
                      updateProfile
                    }
                    formError={formError.address}
                  />
                </Grid>
                <Grid item xs={6} sm={12} md={6} sx={{ mt: 1 }}>
                  <PhoneInput
                    country={"us"}
                    inputStyle={{
                      width: "100%",
                      color: "white",
                      background: color.topbar,
                      height: "52px",
                    }}
                    withDarkTheme
                    value={phoneField?.value}
                    onChange={(phone, dial) => {
                      handlePhoneInput({ phone, dial });
                    }}
                    // onBlur={updateProfile}
                  />
                </Grid>
                {adminPorfileDetailData?.phone !== phoneField?.phone && (
                  <Grid item xs={6} sm={12} md={6} sx={{ mt: -1.2 }}>
                    <AdminButton onClick={sendOptApi} title={"Verify Phone"} />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                xs={12}
                item
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                {/* <Grid item>
              <AdminButton onClick={updateProfile} title={"Update"} />
            </Grid> */}
              </Grid>
            </Grid>
            {otpDialog && (
              <OtpView
                openDialog={otpDialog}
                title={"Confirm"}
                message={"Please enter the OTP sent to your phone number"}
                onClose={() => {
                  setOtp("");
                  setOtpDialog(false);
                }}
                handleDisagree={() => {
                  setOtp("");
                  setOtpDialog(false);
                }}
                handleAgree={() => {
                  verifyOtp();
                }}
                cancelButtonText="Not Now"
                agreeButtonText="Yes, Confirm!"
                setFormError={setFormError}
                formError={formError?.otp}
                otp={otp}
                setOtp={setOtp}
              />
            )}
          </Card>
        )}
      </>
      <Spinner open={isLoading} />
    </>
  );
}
