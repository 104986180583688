import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { DialogTitle } from "@mui/material";
import "../index.css";
import moment from "moment";
import { areAllKeysEmpty } from "utils/constantFun";
import { useSelector } from "react-redux";
import SVButton from "components/SVButton";
import SignatureImage from "../../signatureImage";
import Spinner from "components/spinner";
import { Icon } from "@iconify/react";
export default function PreviewHipaaDocument(props) {
  const {
    profileDetailData,
    open,
    HipaaData,
    viewDetails,
    witnessSign,
    witnessTwoSign,
    handleSubmit,
    handleClose,
    isLoading,
  } = props;
  const { stateListData } = useSelector((state) => state.profile);
  const date = new Date();
  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Hippa Release And Authorization</h2>
        <Icon icon="ooui:close"  height={30} onClick={handleClose} />
      </DialogTitle>
      <Spinner open={isLoading} />
      <DialogContent>
        {/* Authority Granted */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black" }}>
          <Typography className="preview-inforation-text">
            I {profileDetailData?.first_name} {profileDetailData?.surename} ,
            hereby authorize the following persons (hereinafter collectively
            referred to as agent") to act as my agents with regard to the
            matters specified in this HIPAA Release and Authorization
            (“Release”)
          </Typography>
          <Typography className="preview-heading-text">
            Authority Granted
          </Typography>
          <Box mt={2}>
            <Typography className="preview-label-text">First Agent</Typography>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Name</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography className="field-text">:</Typography>&nbsp;&nbsp;
              <Typography className="title-text">
                {HipaaData?.agent_details[0]?.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Address</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>&nbsp;&nbsp;
              <Typography className="title-text">
                {HipaaData?.agent_details[0]?.address}
              </Typography>
            </Stack>
          </Box>
          <Box mt={2}>
            <Typography className="preview-label-text">Second Agent</Typography>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Name</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography className="field-text">:</Typography>&nbsp;&nbsp;
              <Typography className="title-text">
                {HipaaData?.agent_details[1]?.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Address</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>&nbsp;&nbsp;
              <Typography className="title-text">
                {HipaaData?.agent_details[1]?.address}
              </Typography>
            </Stack>
          </Box>{" "}
          <Box mt={2}>
            <Typography className="preview-label-text">Third Agent</Typography>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Name</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography className="field-text">:</Typography>&nbsp;&nbsp;
              <Typography className="title-text">
                {HipaaData?.agent_details[2]?.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Address</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>&nbsp;&nbsp;
              <Typography className="title-text">
                {HipaaData?.agent_details[2]?.address}
              </Typography>
            </Stack>
          </Box>
          <Typography className="preview-inforation-text" mt={2}>
            For purposes of this Release, the following persons shall also be
            treated as my agents in addition to the persons listed above :
          </Typography>
          <Typography className="preview-inforation-text">
            (i) any person designated as a primary or successor agent in a
            durable power of attorney which I have executed, whether or not such
            person is presently serving as such;
          </Typography>{" "}
          <Typography className="preview-inforation-text">
            (ii) any person presently serving as trustee or named as a successor
            trustee in any revocable trust created by me.
          </Typography>
          <Typography className="preview-inforation-text" mt={1}>
            This Release and all the provisions contained herein are effective
            immediately. I intend for my agent to be treated as I would be
            treated with respect to my rights regarding the use and disclosure
            of my individually identifiable health information and other medical
            records. This Release authority applies to any information governed
            by the Health Insurance Portability and Accountability Act of 1996
            (HIPAA), 42 U.S.C. Sections 1320d to 1320d-9 and 45 C.F.R. Sections
            164.500 to 164.534, as may be amended from time to time.
          </Typography>
          <Typography className="preview-inforation-text" mt={1}>
            I hereby revoke any and all other HIPAA releases heretofore made by
            me.
          </Typography>
        </Box>

        {/* Authorization */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Authorization
          </Typography>
          <Typography className="preview-inforation-text">
            Thereby authorize any doctor, physician, medical specialist,
            psychiatrist, chiropractor, health-care professional, dentist,
            optometrist, health plan, hospital, hospice, clinic, laboratory,
            pharmacy or pharmacy benefit manager, medical facility, pathologist,
            or other provider of medical or mental health care, as well as any
            insurance company and the Medical Information Bureau Inc. or other
            health-care clearinghouse that has paid for or is seeking payment
            from me for such services (referred to herein as a "covered
            entity"), to give, disclose and release to my agent who is named
            herein and who is currently serving as such and without restriction,
            all of my individually identifiable health information and medical
            records regarding any past, present or future medical or mental
            health condition, including all information relating to the
            diagnosis and treatment of HIV/AIDS, sexually transmitted diseases,
            mental illness, and drug or alcohol abuse. Additionally, this
            disclosure shall include the ability to ask questions and discuss
            this protected medical information with the person or entity who has
            possession of the protected medical information even if Iam fully
            competent to ask questions and discuss this matter at the time. It
            is my intention to give a full authorization to access any protected
            medical information to my agent. Health information and medical
            records as indicated above shall be released at my request or at the
            request of my agent named herein as may be needed to assist in my
            treatment, make decisions about my care or for any other reason, at
            my discretion or at the discretion of my agent.
          </Typography>
          <Typography className="preview-inforation-text">
            The authority given to my agent shall supersede any prior agreement
            that I may have made with my health-care providers to restrict
            access to or disclosure of my individually identifiable health
            information. The individually identifiable health information and
            other medical records given, disclosed, or released to my agent may
            be subject to re-disclosure by my agent and may no longer be
            protected by HIPAA.
          </Typography>{" "}
        </Box>

        {/* Termination */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">Termination</Typography>
          <Typography className="preview-inforation-text">
            This Release shall terminate on the first to occur of :
          </Typography>
          <Typography className="preview-inforation-text">
            (i) two years following my death, or
          </Typography>{" "}
          <Typography className="preview-inforation-text">
            (ii) on my written revocation actually received by the covered
            entity. Proof of receipt of my written revocation may be by
            certified mail, registered mail, facsimile, electronic mail, or any
            other means evidencing actual receipt by the covered entity. This
            Release shall not be affected by my subsequent disability or
            incapacity. There are no exceptions to my right to revoke this
            Release.
          </Typography>
        </Box>

        {/* Release */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">Release</Typography>
          <Typography className="preview-inforation-text">
            Each covered entity that acts in reliance on this Release shall be
            released from liability that may result from disclosing my
            individually identifiable health information and other medical
            records.
          </Typography>
        </Box>

        {/* Legal Action */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Legal Action{" "}
          </Typography>
          <Typography className="preview-inforation-text">
            I authorize my agent to bring a legal action against a covered
            entity which refuses to accept and recognize this Release. No
            covered entity may condition treatment, payment, enrollment or
            eligibility for benefits on whether I sign this authorization when
            the prohibition on conditioning of authorizations in 45 C.F.R.
            Section 164.508(b)(4) applies. Further, to fulfill my intent as
            expressed herein, I authorize my agent to sign any documentation
            that my agent deems necessary or appropriate to secure the
            disclosure of my individually identifiable health information and
            other medical records.
          </Typography>
        </Box>

        {/* Subsequent Disclosure Of Information */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Subsequent Disclosure Of Information
          </Typography>
          <Typography className="preview-inforation-text">
            Any information disclosed to my agent under this Release may
            subsequently be disclosed to another party by my agent. My agent
            shall not be required to indemnify a covered entity or perform any
            act if information is subsequently disclosed by my agent.
          </Typography>
        </Box>
        {/* Copies And Facsimiles */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Copies And Facsimiles
          </Typography>
          <Typography className="preview-inforation-text">
            Copies or facsimiles of this Release shall be as valid as the
            original Release.
          </Typography>
        </Box>

        {/*Signature Of Principal */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Signature Of Principal
          </Typography>
          <Typography className="preview-inforation-text">
            I sign my name to this HIPAA Release and Authorization on{" "}
            {moment(date).format("DD MMM, YYYY")}
          </Typography>
          {profileDetailData?.signatureData?.length > 0 && (
            <SignatureImage
              url={profileDetailData?.signatureData[0]?.signature}
            />
          )}
        </Box>
        {/* witness */}

        {(!areAllKeysEmpty(
          HipaaData?.witness_details?.length > 0
            ? HipaaData?.witness_details[0]
            : {}
        ) ||
          !areAllKeysEmpty(
            HipaaData?.witness_details?.length > 1
              ? HipaaData?.witness_details[1]
              : {}
          )) && (
          <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
            <Typography className="preview-heading-text">
              Witness Declarations (Optional){" "}
            </Typography>
            <Typography className="preview-inforation-text">
              By signing as a witness, I acknowledge that the principal signed
              this HIPAA Release and Authorization in my presence and in the
              presence of the other witness, or that the principal acknowledged
              to me that the principal's signature was affixed by him or her or
              at his or her direction. I also acknowledge that the principal has
              stated that this HIPAA Release and Authorization reflects his or
              her wishes and that he or she has signed it voluntarily.
            </Typography>
            {!areAllKeysEmpty(
              HipaaData?.witness_details?.length > 0
                ? HipaaData?.witness_details[0]
                : {}
            ) && (
              <Box mt={2}>
                <Typography className="preview-label-text">
                  First Witness
                </Typography>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">Email Address</Typography>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {HipaaData?.witness_details[0]?.email}
                  </Typography>
                </Stack>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">Address</Typography>
                  &nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {HipaaData?.witness_details[0]?.address}
                  </Typography>
                </Stack>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">City</Typography>
                  &nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {HipaaData?.witness_details[0]?.city}
                  </Typography>
                </Stack>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">Postal Code</Typography>
                  &nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {HipaaData?.witness_details[0]?.zip_code}
                  </Typography>
                </Stack>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">State</Typography>
                  &nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {stateListData?.length > 0 &&
                      stateListData[
                        stateListData?.findIndex(
                          (item) =>
                            item?.id == HipaaData?.witness_details[0]?.state
                        )
                      ]?.description}
                  </Typography>
                </Stack>
                {viewDetails
                  ? HipaaData?.witness_details[0]?.document && (
                      <SignatureImage
                        url={HipaaData?.witness_details[0]?.document}
                      />
                    )
                  : !witnessSign?.current?.isEmpty() && (
                      <SignatureImage url={witnessSign?.current?.toDataURL()} />
                    )}
              </Box>
            )}
            {!areAllKeysEmpty(
              HipaaData?.witness_details?.length > 1
                ? HipaaData?.witness_details[1]
                : {}
            ) && (
              <Box mt={2}>
                <Typography className="preview-label-text">
                  Second Witness
                </Typography>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">Email Address</Typography>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {HipaaData?.witness_details[1]?.email}
                  </Typography>
                </Stack>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">Address</Typography>
                  &nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {HipaaData?.witness_details[1]?.address}
                  </Typography>
                </Stack>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">City</Typography>
                  &nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {HipaaData?.witness_details[1]?.city}
                  </Typography>
                </Stack>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">Postal Code</Typography>
                  &nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {HipaaData?.witness_details[1]?.zip_code}
                  </Typography>
                </Stack>
                <Stack direction={"row"} mt={1}>
                  <Typography className="field-text">State</Typography>
                  &nbsp;&nbsp;
                  <Typography className="field-text">:</Typography>
                  &nbsp;&nbsp;
                  <Typography className="title-text">
                    {stateListData?.length > 0 &&
                      stateListData[
                        stateListData?.findIndex(
                          (item) =>
                            item?.id == HipaaData?.witness_details[1]?.state
                        )
                      ]?.description}
                  </Typography>
                </Stack>
                {viewDetails
                  ? HipaaData?.witness_details[1]?.document && (
                      <SignatureImage
                        url={HipaaData?.witness_details[1]?.document}
                      />
                    )
                  : !witnessTwoSign?.current?.isEmpty() && (
                      <SignatureImage
                        url={witnessTwoSign?.current?.toDataURL()}
                      />
                    )}
              </Box>
            )}
          </Box>
        )}
        {!viewDetails && (
          <Grid container mt={3} columnSpacing={5}>
            <Grid item xs={6}>
              <SVButton text="Edit" fullWidth onClick={handleClose} />
            </Grid>
            <Grid item xs={6}>
              <SVButton text="Submit" fullWidth onClick={handleSubmit} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
