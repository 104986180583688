import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
// import "./index.css";
import { Helmet } from "react-helmet-async";
import moment from "moment";
// import useStyles from "./style";
import AlertDialog from "components/alertDialog/alertDialog";
import TopHeading from "components/topHeading";
import { PAGINATION } from "utils/appConstant";
import SVButton from "components/SVButton";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/constantFun";
import { Icon } from "@iconify/react";
import { color } from "theme/color";
import {
  clearPressReleaseDetails,
  deletePressReleaseBlog,
  getPressReleaseList,
} from "store/slice/estate";

export default function PressRelease() {
  let page = PAGINATION.page;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { totalBlogsCount, isLoading, pressReleaseData } = useSelector(
    (state) => state.estate
  );
  const [blogsData, setBlogsData] = useState("");
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const dispatch = useDispatch();

  const fetchPressReleaseList = (page, numberOfRows) => {
    var data = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: "",
    };

    dispatch(getPressReleaseList(data, true));
  };

  useEffect(() => {
    fetchPressReleaseList();
  }, []);

  const options = {
    count: totalBlogsCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      //setPageCheck(currentPage);
      fetchPressReleaseList(currentPage, "", "", "");
    },

    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchPressReleaseList(page, numberOfRows, "", "");
    },
  };

  const handleDeletePressRelease = () => {
    dispatch(deletePressReleaseBlog(blogsData, setOpenAlertDialog));
  };

  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "subtitle",
      label: "Subtitle",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : ""}</Typography>;
        },
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>{value ? capitalizeFirstLetter(value) : ""}</Typography>
          );
        },
      },
    },

    {
      name: "timestamp",
      label: "Created At",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{moment(value).format("MMMM Do, YYYY")}</Typography>
            </Grid>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let index = pressReleaseData.findIndex((item) => item.id === value);
          return (
            <Stack direction={"row"} gap={3}>
              <Icon
                onClick={() => {
                  dispatch(clearPressReleaseDetails());
                  navigate(
                    `/press-release/edit/${pressReleaseData[index]?.id}`
                  );
                }}
                icon="uil:edit"
                width={30}
                style={{ cursor: "pointer" }}
                color={color.buttonColor}
              />
              <Icon
                onClick={() => {
                  setBlogsData(pressReleaseData[index]?.id);
                  setOpenAlertDialog(true);
                }}
                icon="mingcute:delete-2-fill"
                color="red"
                width={30}
                style={{ cursor: "pointer" }}
              />
            </Stack>
          );
        },
      },
    },
  ];
  return (
    <>
      <>
        <Helmet>
          <title>Press Release </title>
        </Helmet>
        <Stack
          direction={"row"}
          sx={{
            display: isMobile ? "block" : "flex",
            justifyContent: "space-between",
            px: 3,
            pb: 2,
          }}
        >
          <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Press Release"} />
          <SVButton
            text="Add"
            onClick={() => {
              dispatch(clearPressReleaseDetails());
              navigate("/press-release/add");
            }}
            sx={{ px: 3, mt: isMobile ? 2 : -1.5 }}
          />
        </Stack>
        <br />
        <>
          {pressReleaseData && pressReleaseData.length > 0 ? (
            <Grid container>
              <Grid xs={12}>
                <MUIDataTable
                  data={pressReleaseData}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid #202020",
                  mt: 2,
                  height: 300,
                  backgroundColor: "#202020",
                }}
              >
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 500,
                      mt: "7.5rem",
                      color: "white",
                    }}
                  >
                    No Record Found
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
        {openAlertDialog && (
          <AlertDialog
            open={openAlertDialog}
            isLoading={isLoading}
            handleClose={() => setOpenAlertDialog(false)}
            title={"Delete Confirmation!"}
            text={"Are you sure you want to delete this press release?"}
            handleAgree={handleDeletePressRelease}
          />
        )}
      </>

      <Spinner open={isLoading} />
    </>
  );
}
