import React from "react";
import { Card, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import { color } from "../../../../theme/color";
import SVButton from "components/SVButton";
import { Icon } from "@iconify/react";
import { useTheme } from "@emotion/react";
import makeGroup from "../../../../assets/img/makeGroup.png";
import { currencyFormat } from "utils/currencyFormatter";

export default function Reward() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card sx={{ background: color.topbar, p: 3 }}>
      <Grid container display={"flex"} justifyContent={"space-between"}>
        <Grid item>
          <Typography
            className="textLinearBackground"
            sx={{ fontSize: 22, mb: 1, mt: -1 }}
          >
            Rewards
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={isMobile ? 12 : 6}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Grid
                elevation={0}
                sx={{
                  backgroundImage: isMobile ? "" : `url(${makeGroup})`,
                  background: isMobile ? color.black : "",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "8px",
                  height: isMobile ? "11.5vh" : "20.2rem",
                  backgroundSize: isMobile ? "" : "cover",
                  backgroundRepeat: isMobile ? "" : "no-repeat",
                  p: 2,
                  mb: isMobile ? -2 : "",
                }}
              >
                <Typography
                  className="textLinearBackground"
                  sx={{ fontSize: 22 }}
                >
                  Cashback
                </Typography>
                <Typography className="textLinearBackground" variant="h5">
                  {currencyFormat(0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={isMobile ? 12 : 6}>
              <Card
                sx={{
                  background: color.black,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "8px",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Typography fontSize={14} sx={{ color: color.main, my: 2 }}>
                  Times of Redeem
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "500", color: color.main, mb: 1 }}
                >
                  {currencyFormat(0)}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Card
                sx={{
                  background: color.black,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "8px",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Typography fontSize={14} sx={{ color: color.main, my: 2 }}>
                  Total Amount Of Redeemad
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "500", color: color.main, mb: 1 }}
                >
                  {currencyFormat(0)}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderRadius: "8px",
                  background: color.black,
                }}
              >
                <Grid
                  container
                  sx={{
                    background: color.dark,
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <Icon
                          color="white"
                          icon="clarity:date-line"
                          width="25px"
                          height="25px"
                          style={{ marginRight: "0.6rem" }}
                        />
                      </Grid>
                      <Typography className="textLinearBackground">
                        Redeem History <br />
                        <Typography variant="h5"> 25 feb 2024</Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 12 : ""}
                    sx={{ textAlign: isMobile ? "center" : "" }}
                  >
                    <SVButton
                      text="See History"
                      sx={{
                        borderRadius: 10,
                        fontSize: isMobile && 10,
                        height: 32,
                        p: 2,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ p: 2 }}>
                  <Grid xs={12}>
                    <Card
                      sx={{
                        background: color.topbar,
                        p: 2,
                      }}
                    >
                      <Grid
                        container
                        display={"flex"}
                        justifyContent={"space-between"}
                        sx={{ color: color.main }}
                      >
                        <Grid item>
                          <Typography sx={{ fontSize: 12 }}>
                            Cashback Earned
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ fontSize: 12 }}>
                            25 feb 11:00pm
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={isMobile ? 2 : 1}>
                          <Box
                            sx={{
                              background: color.black,
                              color: color.main,
                              borderRadius: "5px",
                              width: "25px",
                              height: "25px",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Icon
                              style={{ background: "black" }}
                              icon="iconamoon:gift-light"
                              fontSize={18}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 7 : 9}>
                          <Typography
                            sx={{ color: color.main, fontSize: isMobile && 10 }}
                          >
                            Send Money to gold purchase
                          </Typography>
                        </Grid>

                        <Grid item xs={isMobile ? 3 : 2}>
                          <Typography
                            style={{ color: color.active, fontWeight: "bold" }}
                          >
                            {" "}
                            +{currencyFormat(0)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
