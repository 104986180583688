import { useEffect, useState } from "react";
import { useParams } from "react-router";
// import InsuranceSelect from './insuranceSelect';
import { useDispatch, useSelector } from "react-redux";
import { INSURANCET_TYPES } from "utils/appConstant";
import { validateEmpty } from "utils/constantFun";
import Spinner from "components/spinner";
import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import SVButton from "components/SVButton";
import AdminInput from "components/adminInput";
import { color } from "theme/color";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { IncludeInPlanSelect, InsuranceSelect } from "./insuranceSelect";
import { profileDetail } from "store/slice/profile";
import {
  createInsurance,
  getInsuranceDetails,
  updateInsurance,
} from "store/slice/assets";

export default function TermLifeInsurance() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { insuranceId } = useParams();
  const { profileDetailData } = useSelector((state) => state.profile);
  const { insuranceDetails } = useSelector((state) => state.assets);
  const [isLoading, setIsloading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
    var requestParams = {
      id: id,
    };
    dispatch(profileDetail(requestParams, true));
  }, []);
  const [termLifeData, setTermLifeData] = useState({});

  //  get insurance details
  useEffect(() => {
    if (insuranceId) {
      setIsloading(true);
      dispatch(getInsuranceDetails(insuranceId, setIsloading));
    }
  }, [insuranceId]);
  useEffect(() => {
    if (insuranceDetails) {
      setTermLifeData(insuranceDetails);
    }
  }, [insuranceDetails]);
  const handleSubmit = () => {
    if (validateEmpty(termLifeData?.policy_name)) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        policy_nameErr: "Please enter policy name",
      }));
      return;
    }
    if (validateEmpty(termLifeData?.annual_premium)) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        annual_premiumErr: "Please enter annual premium",
      }));
      return;
    }
    if (validateEmpty(termLifeData?.premium_ends_id)) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        premium_ends_idErr: "Please select premium ends",
      }));
      return;
    }
    if (validateEmpty(termLifeData?.premium_year)) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        premium_yearErr: "Please enter premium ends year",
      }));
      return;
    }
    if (validateEmpty(termLifeData?.death_benefit)) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        death_benefitErr: "Please enter death benefit",
      }));
      return;
    }
    if (validateEmpty(termLifeData?.policy_ends_period_id)) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        policy_ends_period_idErr: "Please select policy ends",
      }));
      return;
    }
    if (validateEmpty(termLifeData?.policy_end_year)) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        policy_end_yearErr: "Please enter policy ends year",
      }));
      return;
    }
    if (termLifeData?.included_in_plan == undefined) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        included_in_planErr: "Please select included in plan",
      }));
      return;
    }
    if (validateEmpty(termLifeData?.beneficiary_percentage)) {
      setTermLifeData((termLifeData) => ({
        ...termLifeData,
        beneficiary_percentageErr: "Please enter beneficiary percentage ",
      }));
      return;
    }
    setIsloading(true);
    let request = {
      user_id: id,
      policy_name: termLifeData?.policy_name,
      owner: profileDetailData?.first_name,
      annual_premium: termLifeData?.annual_premium,
      type_id: INSURANCET_TYPES?.TERM_LIFE,
      premium_ends_id: termLifeData?.premium_ends_id,
      premium_year: termLifeData?.premium_year,
      death_benefit: termLifeData?.death_benefit,
      included_in_plan: termLifeData?.included_in_plan,
      beneficiary_percentage: termLifeData?.beneficiary_percentage,
      policy_ends_period_id: termLifeData?.policy_ends_period_id,
      policy_end_year: termLifeData?.policy_end_year,
    };
    if (insuranceId) {
      dispatch(
        updateInsurance(request, insuranceId, id, navigate, setIsloading)
      );
    } else {
      dispatch(createInsurance(request, id, navigate, setIsloading));
    }
  };
  return (
    <div>
      <Spinner open={isLoading} />
      <div>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Insurance"} />

        <Grid container mt={4} sx={{ px: isMobile ? 2 : "" }}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} to="/users">
                Users
              </Link>
              <Link style={{ color: "white" }} to={`/profile/${id}`}>
                User Profile
              </Link>
              <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
                Assets-Liabilities Details
              </Link>
              <Link
                style={{ color: "white" }}
                to={`/assetsDetail/insurance/${id}`}
              >
                Insurance
              </Link>
              <Typography sx={{ color: "white" }}>Term-Life</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box
          sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 2 }}
        >
          <h2 className="textLinearBackground mt-3 ">Term-Life</h2>
          <Grid container columnSpacing={3}>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Policy name"
                maxLength={30}
                onChange={(event) => {
                  setTermLifeData((termLifeData) => ({
                    ...termLifeData,
                    policy_name: event.target.value,
                    policy_nameErr: "",
                  }));
                }}
                value={termLifeData?.policy_name || ""}
                formError={termLifeData?.policy_nameErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                name="occupation"
                title="Name"
                value={profileDetailData?.first_name || ""}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Annual premium"
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setTermLifeData((termLifeData) => ({
                      ...termLifeData,
                      annual_premium: event.target.value * 1,
                      annual_premiumErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setTermLifeData((termLifeData) => ({
                        ...termLifeData,
                        annual_premium: "",
                      }));
                    }
                  }
                }}
                value={termLifeData?.annual_premium || ""}
                formError={termLifeData?.annual_premiumErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Grid container columnSpacing={3}>
                <Grid item xs={6}>
                  <InsuranceSelect
                    title={" Premium ends"}
                    onChange={(event) => {
                      setTermLifeData((termLifeData) => ({
                        ...termLifeData,
                        premium_ends_id: event.target.value,
                        premium_ends_idErr: "",
                      }));
                    }}
                    value={termLifeData?.premium_ends_id || ""}
                    error={termLifeData?.premium_ends_idErr}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdminInput
                    color="white"
                    type="text"
                    maxLength={4}
                    title="Year's"
                    onChange={(event) => {
                      const numeric = /^[0-9\b]+$/;
                      if (
                        event.target.value &&
                        numeric.test(event.target.value)
                      ) {
                        setTermLifeData((termLifeData) => ({
                          ...termLifeData,
                          premium_year: event.target.value * 1,
                          premium_yearErr: "",
                        }));
                      } else {
                        setTermLifeData((termLifeData) => ({
                          ...termLifeData,
                          premium_year: "",
                        }));
                      }
                    }}
                    value={termLifeData?.premium_year || ""}
                    formError={termLifeData?.premium_yearErr}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                maxLength={13}
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Death benefit "
                value={termLifeData?.death_benefit || ""}
                formError={termLifeData?.death_benefitErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setTermLifeData((termLifeData) => ({
                      ...termLifeData,
                      death_benefit: event.target.value * 1,
                      death_benefitErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setTermLifeData((termLifeData) => ({
                        ...termLifeData,
                        death_benefit: "",
                      }));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Grid container columnSpacing={3}>
                <Grid item xs={6}>
                  <InsuranceSelect
                    title={" Policy ends"}
                    onChange={(event) => {
                      setTermLifeData((termLifeData) => ({
                        ...termLifeData,
                        policy_ends_period_id: event.target.value,
                        policy_ends_period_idErr: "",
                      }));
                    }}
                    value={termLifeData?.policy_ends_period_id || ""}
                    error={termLifeData?.policy_ends_period_idErr}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdminInput
                    color="white"
                    type="text"
                    maxLength={4}
                    title="Year's"
                    onChange={(event) => {
                      const numeric = /^[0-9\b]+$/;
                      if (
                        event.target.value &&
                        numeric.test(event.target.value)
                      ) {
                        setTermLifeData((termLifeData) => ({
                          ...termLifeData,
                          policy_end_year: event.target.value * 1,
                          policy_end_yearErr: "",
                        }));
                      } else {
                        if (!event.target.value) {
                          setTermLifeData((termLifeData) => ({
                            ...termLifeData,
                            policy_end_year: "",
                          }));
                        }
                      }
                    }}
                    value={termLifeData?.policy_end_year || ""}
                    formError={termLifeData?.policy_end_yearErr}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                onChange={(event) => {
                  setTermLifeData((termLifeData) => ({
                    ...termLifeData,
                    included_in_plan: event.target.value,
                    included_in_planErr: "",
                  }));
                }}
                value={
                  termLifeData?.included_in_plan == true ||
                  termLifeData?.included_in_plan == false
                    ? termLifeData?.included_in_plan
                    : ""
                }
                error={termLifeData?.included_in_planErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Beneficiary"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={termLifeData?.beneficiary_percentage || ""}
                formError={termLifeData?.beneficiary_percentageErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setTermLifeData((termLifeData) => ({
                        ...termLifeData,
                        beneficiary_percentage: 100,
                        beneficiary_percentageErr: "",
                      }));
                    } else {
                      setTermLifeData((termLifeData) => ({
                        ...termLifeData,
                        beneficiary_percentage: event.target.value * 1,
                        beneficiary_percentageErr: "",
                      }));
                    }
                  } else {
                    setTermLifeData((termLifeData) => ({
                      ...termLifeData,
                      beneficiary_percentage: "",
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3} mt={5}>
              <SVButton fullWidth text={"Submit"} onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
