import { Grid, Typography } from "@mui/material";
import React from "react";
import { color } from "theme/color";

const DataNotFound = () => {
  return (
    <Grid
      container
      sx={{
        borderRadius: 2,
        background: color.topbar,
        height: 100,
        alignItems: "center",
      }}
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item>
        <Typography variant="h5" className="textLinearBackground">
          Data Not Available
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DataNotFound;
