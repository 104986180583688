import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
import { api } from "../../services";
import { SILVERBACK, STATUS_CODES } from "../../services/apiConstant";
import { STORAGE_KEY } from "utils/appConstant";
import { errorToast, successToast } from "components/showToast";
import { toast } from "react-toastify";

const slice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    resetSuccess: false,
    genrateCodeData: null,
    verifyResetTokenData: null,
    captchaData: null,
  },
  reducers: {
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },
    resetPasswordSuccess: (state) => {
      state.resetSuccess = true;
    },
    verifyResetTokenSuccess: (state, action) => {
      state.verifyResetTokenData = action.payload;
    },

    verifyCaptchSuccess: (state, action) => {
      state.captchaData = action.payload;
    },
    codeGenrateSuccess: (state) => {
      state.genrateCodeData = true;
    },
  },
});

export default slice.reducer;

/**********************ACTIONS************************************************ */
export const login = (requestParams, navigate, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  api
    .post(`${SILVERBACK.login}`, requestParams, {
      headers:{
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
    .then((response) => {
      let result = response?.data?.data;
      console.log("result", result);
      if (response.status == STATUS_CODES.SUCCESS) {
        let mfaEnableMethods = result?.enableMethods;
        console.log("mfaEnableMethods", mfaEnableMethods);
        if (
          mfaEnableMethods?.mfa_enabled ||
          mfaEnableMethods?.emailAuthentication ||
          mfaEnableMethods?.textAuthentication
        ) {
          secureLocalStorage.setItem(
            STORAGE_KEY.EMAIL_OTP,
            JSON.stringify(result)
          );
          successToast(response.data.message);
          navigate("/verify-account", true);
        }
        if (result?.access_token) {
          // successToast(response.data.message);
          navigate("/dashboard", true);
          secureLocalStorage.setItem(STORAGE_KEY.USER_AUTH, result);
        }
      } else if (response.status == STATUS_CODES.FAILURE) {
        errorToast(response?.data?.detail, "support");
        dispatch(loadingSuccess(false));
      } else if (response.status == STATUS_CODES.NOT_FOUND) {
        dispatch(loadingSuccess(false));
        errorToast(response?.data?.detail, "support");
      } else if (response.status == STATUS_CODES.SERVER_ERROR) {
        errorToast(response?.data?.detail, "support");
        dispatch(loadingSuccess(false));
      } else {
        errorToast(response?.data?.detail);
        dispatch(loadingSuccess(false));
      }
      dispatch(loadingSuccess(false));
    })
    .catch((e) => {
      dispatch(loadingSuccess(false));
      return errorToast(e?.response?.data?.detail, "support");
    });
};


//Reset Password
export const resetPassword =
  (requestParams, navigate, status) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    api
      .post(`${SILVERBACK.resetPassword}`, requestParams)
      .then((response) => {
        if (response.status == STATUS_CODES.SUCCESS) {
          dispatch(resetPasswordSuccess(false));
        } else {
          toast.error(response.detail);
        }
      })
      .catch(() => {
        dispatch(loadingSuccess(false));
      });
  };

//Reset verify tocken
export const verifyResetToken = (requestParams,setIsloading) => async (dispatch) => {
  dispatch(loadingSuccess(true));
  api
    .post(`${SILVERBACK.verifyResetToken}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(verifyResetTokenSuccess(response.status));
      } else if (response.status == STATUS_CODES.FAILURE) {
        // toast.error(result.detail);
      }
      setIsloading(false)
      dispatch(loadingSuccess(false));
    })
    .catch(() => {
      setIsloading(false)
      dispatch(loadingSuccess(false));
    });
};

export const verifyCaptcha = (requestParams) => async (dispatch) => {
  api
    .post(`${SILVERBACK.verifyCaptch}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(verifyCaptchSuccess(response.status));
      } else if (response.status == STATUS_CODES.FAILURE) {
        // toast.error(result.detail);
      }
      dispatch(loadingSuccess(false));
    })
    .catch(() => {
      dispatch(loadingSuccess(false));
    });
};

export const changePassword = (requestParams, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  api
    .post(`${SILVERBACK.changePassword}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(loadingSuccess(false));
        successToast(response.data.message);
      } else if (response.status == STATUS_CODES.FAILURE) {
        dispatch(loadingSuccess(false));  
        errorToast(response.detail, "support");
      } else if (response.status == STATUS_CODES.SERVER_ERROR) {
        dispatch(loadingSuccess(false));
        errorToast(response.detail, "support");
      } else {
        dispatch(loadingSuccess(false));
        errorToast(response.detail);
      }

      dispatch(loadingSuccess(false));
    })
    .catch((e) => {
      dispatch(loadingSuccess(false));
      return errorToast(e?.response?.data?.detail, "support");
    });
};

export const varifyOtp =
  (requestParams, navigate, status) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    api
      .post(`${SILVERBACK.verifyOtp}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (response.status == STATUS_CODES.SUCCESS) {
          toast.success(result.message);
          navigate("/dashboard", true);
          secureLocalStorage.setItem(STORAGE_KEY.USER_AUTH, result);
        } else {
          toast.error(result.detail);
        }
        dispatch(loadingSuccess(false));
      })
      .catch(() => {
        dispatch(loadingSuccess(false));
      });
  };

export const forgetPassword =
  (requestParams, navigate, status) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    api
      .post(`${SILVERBACK.forgetPassword}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (response?.status == STATUS_CODES.SUCCESS) {
          successToast("Link has been successfully sent to your email");
        } else {
          errorToast(result.detail);
        }
        dispatch(loadingSuccess(false));
      })
      .catch(() => {
        dispatch(loadingSuccess(false));
      });
  };

export const mfaCodeGenrator = (requestParams, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  api
    .post(`${SILVERBACK.mfaCodeGenrator}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (response.status == STATUS_CODES.SUCCESS) {
        toast.success("");
      } else {
        toast.error(result.detail);
      }
      dispatch(codeGenrateSuccess(false));
    })
    .catch(() => {
      dispatch(loadingSuccess(false));
    });
};

export const loading = (status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
};

const {
  loadingSuccess,
  resetPasswordSuccess,
  codeGenrateSuccess,
  verifyResetTokenSuccess,
  verifyCaptchSuccess,
} = slice.actions;
