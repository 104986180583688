import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import SVButton from "components/SVButton";
import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function EstateHeading({ onClick, heading, isAssets,title }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { profileDetailData } = useSelector((state) => state.profile);
  return (
    <div style={{ marginTop: "2rem" }}>
      {isMobile ? (
        <>
          <Typography sx={{ fontSize: 25 }} className="textLinearBackground">
            {heading}
          </Typography>
          <Stack
            direction={"row"}
            sx={{ display: "flex", justifyContent: "end", mt: 1 }}
          >
            <SVButton
              text={isAssets ? title  : "Add"}
              onClick={() => {
                if (isAssets) {
                  onClick();
                } else {
                  if (
                    profileDetailData?.signatureData &&
                    profileDetailData?.signatureData?.length > 0
                  ) {
                    onClick();
                  } else {
                    toast.error("The User has not added his signature");
                  }
                }
              }}
            />
          </Stack>
        </>
      ) : (
        <Stack
          direction={"row"}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography sx={{ fontSize: 32 }} className="textLinearBackground">
            {heading}
          </Typography>
          <SVButton
            text={isAssets ? title  : "Add"}
            onClick={() => {
              if (isAssets) {
                onClick();
              } else {
                if (
                  profileDetailData?.signatureData &&
                  profileDetailData?.signatureData?.length > 0
                ) {
                  onClick();
                } else {
                  toast.error("The User has not added his signature");
                }
              }
            }}
          />
        </Stack>
      )}
    </div>
  );
}
