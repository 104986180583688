import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
// import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import {
  getDetailOfNetWorth,
  getNetWorthList,
} from "../../../store/slice/profile";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
import "./index.css";
// import moment from "moment";
// import AdminInput from "../../../components/adminInput";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import SVButton from "components/SVButton";
import TopHeading from "components/topHeading";
import { currencyFormat } from "utils/currencyFormatter";
import { PAGINATION } from "utils/appConstant";

export default function index() {
  let page = PAGINATION.page;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [pageCheck, setPageCheck] = useState(1);
  // const [searchValue, setSearchValue] = useState("");
  // const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const {
    netWorthListData,
    totalCount,
    isLoading,
    // netWorthDetailData
  } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // var searchInputRef = useRef();

  const netWorthList = (page, numberOfRows) => {
    var data = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: "",
    };
    dispatch(getNetWorthList(data, true));
  };

  // const handleSearchChange = (e) => {
  //   setSearchValue(e.target.value);
  //   if (e.target.value.length > 2) {
  //     netWorthList(page);
  //   } else if (e.target.value.length === 0) {
  //     netWorthList(page);
  //   }
  // };

  const handleClickOpen = (value) => {
    // setSelectedRowIndex(tableMeta);
    let id = value;
    var data = {
      id: id,
    };

    dispatch(getDetailOfNetWorth(data, true, navigate));
    // navigate("/netWorthDetails", { state: { id: id } });
  };

  useEffect(() => {
    netWorthList();
  }, []);

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },

    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        netWorthList(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage - 1;
        }

        setPageCheck(currentPage);
        netWorthList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      netWorthList(page, numberOfRows, "", "");
    },
  };

  // const clearSearch = () => {
  //   setSearchValue("");
  //   var data = {
  //     page: 1,
  //     perPage: 10,
  //     search: "",
  //     startDate: "",
  //     endDate: "",
  //     status: "",
  //   };
  //   let isSearch = false;
  //   dispatch(getNetWorthList(data, isSearch, true));
  // };

  const columns = [
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (params) => {
          const email = params;
          return (
            <Tooltip title={email} arrow>
              <div
                style={{
                  width: 180,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {email}
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "financialInformation",
      label: "Annual Earn",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{currencyFormat(value ? value : 0)}</Typography>;
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <SVButton
                onClick={() => handleClickOpen(value)}
                text={"View Details"}
              />
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <>
        {" "}
        <Helmet>
          <title> Financial-Request </title>
        </Helmet>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Financial Request"} />
        <br />
        <div className="App wrapper">
          <>
            {netWorthListData && netWorthListData.length > 0 ? (
              <Grid container>
                <Grid xs={12}>
                  <MUIDataTable
                    // title={"Events List"}
                    data={netWorthListData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid #202020",
                    mt: 2,
                    height: 300,
                    backgroundColor: "#202020",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: 500,
                        mt: "7.5rem",
                        color: "white",
                      }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </div>
      </>

      <Spinner open={isLoading} />
    </>
  );
}
