import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getInsurancePeriod } from "store/slice/assets";
import { profileDetail } from "store/slice/profile";
import theme from "theme";
import { INCLUDE_IN_PLAN } from "utils/appConstant";

export const InsuranceSelect = ({ title, onChange, value, error }) => {
  const dispatch = useDispatch();
  const { insurancePeriodData } = useSelector((state) => state.assets);
  const { profileDetailData } = useSelector((state) => state.profile);
  const { id } = useParams();
  useEffect(() => {
    var requestParams = {
      id: id,
    };
    dispatch(profileDetail(requestParams, true));
    dispatch(getInsurancePeriod());
  }, []);
  return (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          label={`${title}ss`}
          onChange={onChange}
          value={value}
          sx={{
            color: "white",
            width: "100%",
            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {insurancePeriodData?.map((data, index) => {
            if (data?.is_active) {
              return (
                <MenuItem key={index} value={data?.id}>
                  {data?.name?.replace("your", profileDetailData?.first_name)}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
      {error && (
        <Typography
          sx={{ color: theme.palette.error.main, fontSize: 12, ml: 1, mt: 1 }}
        >
          {error}
        </Typography>
      )}
    </>
  );
};

export const IncludeInPlanSelect = ({
  title,
  onChange,
  value,
  error,
  customOptions,
  autoFocus,
}) => {
  console.log("value", value);
  return (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="demo-simple-select-label">
          {title ? title : "Included in plan"}
        </InputLabel>
        <Select
          autoFocus={autoFocus}
          label={title ? `${title}ss` : "Included in plans "}
          onChange={onChange}
          value={value}
          sx={{
            color: "white",
            width: "100%",
            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {customOptions
            ? customOptions
            : INCLUDE_IN_PLAN?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.id}>
                    {item?.description}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
      {error && (
        <Typography
          sx={{ color: theme.palette.error.main, fontSize: 12, ml: 1, mt: 1 }}
        >
          {error}
        </Typography>
      )}
    </>
  );
};
