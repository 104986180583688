import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdminButton from "components/adminButton";
import AdminInput from "components/adminInput";
import Spinner from "components/spinner";
import TopHeading from "components/topHeading";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addOtherTypeAssets, getOtherTypeList } from "store/slice/assets";
import { color } from "theme/color";
import { validateEmpty } from "utils/constantFun";

const OtherAssets = () => {
  const [formData, setFormData] = useState({});
  const { otherTypeList } = useSelector((state) => state.assets);
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    var data = {
      type: "asset",
    };
    dispatch(getOtherTypeList(data));
  }, []);

  const submit = () => {
    if (validateEmpty(formData.assetName)) {
      setFormData((formData) => ({
        ...formData,
        assetNameErr: "Please enter asset name",
      }));
      return;
    }
    if (!formData.assetType) {
      setFormData((formData) => ({
        ...formData,
        assetTypeErr: "Please select Asset type",
      }));
      return;
    }
    if (validateEmpty(formData.currentValue)) {
      setFormData((formData) => ({
        ...formData,
        currentValueErr: "Please enter current value",
      }));
      return;
    }

    if (validateEmpty(formData.annualIncome)) {
      setFormData((formData) => ({
        ...formData,
        annualIncomeErr: "Please enter annual income",
      }));
      return;
    }

    setIsLoading(true);
    var data = {
      userId: id,
      type: "asset",
      name: formData?.assetName,
      typeId: formData?.assetType,
      currentValue: formData?.currentValue,
      annualIncome: formData?.annualIncome,
    };
    dispatch(addOtherTypeAssets(data, id, navigate, setIsLoading, setFormData));
  };
  return (
    <div>
      <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Other Assets"} />
      {/* {screenType === "desktop" && (
        <div className="mt-3">
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb">
              <li
                style={{
                  color: color.highLightColor,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                className="breadcrumb-item"
                onClick={() => navigate("/customer/net-worth")}
              >
                Net Worth
              </li>
              <li
                style={{
                  color: color.highLightColor,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                className="breadcrumb-item"
                onClick={() => navigate("/customer/net-worth/assets")}
              >
                Asset
              </li>
              <li
                style={{ fontWeight: 600, cursor: "pointer" }}
                className="breadcrumb-item active"
                aria-current="page"
              >
                Other
              </li>
            </ol>
          </nav>
        </div>
      )} */}
      <Grid container mt={4}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/users">
              Users
            </Link>
            <Link style={{ color: "white" }} to={`/profile/${id}`}>
              User Profile
            </Link>
            <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
              Assets-Liabilities Details
            </Link>
            <Typography sx={{ color: "white" }}>Add</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Spinner show={isLoading} />

      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <Grid container columnSpacing={3}>
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              type="text"
              color="white"
              title="Asset Name"
              maxLength={30}
              onChange={(e) => {
                setFormData((formData) => ({
                  ...formData,
                  assetName: e.target.value,
                  assetNameErr: "",
                }));
              }}
              value={formData?.assetName}
              formError={formData?.assetNameErr}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Asset Type</InputLabel>
              <Select
                label="Asset Typed"
                onChange={(val) => {
                  if (val) {
                    setFormData((formData) => ({
                      ...formData,
                      assetType: val.target.value,
                      assetTypeErr: "",
                    }));
                  }
                }}
                sx={{
                  color: "white",
                  width: "100%",
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {otherTypeList?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {formData?.assetTypeErr && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 12,
                  ml: 1,
                  mt: 1,
                }}
              >
                {formData?.assetTypeErr}
              </Typography>
            )}
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              title="Current Value"
              type="text"
              color="white"
              maxLength={13}
              onChange={(event) => {
                if (event.target.value) {
                  if (event.target.value < 14) {
                    setFormData((formData) => ({
                      ...formData,
                      currentValue: event.target.value * 1,
                      currentValueErr: "",
                    }));
                  }
                } else {
                  setFormData((formData) => ({
                    ...formData,
                    currentValue: "",
                  }));
                }
              }}
              value={formData?.currentValue}
              formError={formData?.currentValueErr}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              type="text"
              maxLength={13}
              color="white"
              title="Annual Income "
              onChange={(event) => {
                if (event.target.value) {
                  if (event.target.value?.length < 14) {
                    setFormData((formData) => ({
                      ...formData,
                      annualIncome: event.target.value * 1,
                      annualIncomeErr: "",
                    }));
                  }
                } else {
                  setFormData((formData) => ({
                    ...formData,
                    annualIncome: "",
                  }));
                }
              }}
              value={formData?.annualIncome}
              formError={formData?.annualIncomeErr}
            />
          </Grid>
        </Grid>
        <AdminButton title="Submit" onClick={submit} />
      </Box>
    </div>
  );
};

export default OtherAssets;
