import {
  Box,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  clearNotesData,
  createNotesApi,
  getNotesDetail,
  listOfNotesApi,
} from "store/slice/profile";
import { color } from "theme/color";
import { PAGINATION } from "utils/appConstant";
import IconButton from "@mui/material/IconButton";
import { CiEdit } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import { useTheme } from "@emotion/react";
import SVButton from "components/SVButton";
import AdminButton from "components/adminButton";
import SVDivider from "components/SVDivider";
import AdminInput from "components/adminInput";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "components/spinner";

const Notes = () => {
  const param = useParams();
  const { notesListData, totalCount, notesDetailData, isLoading } = useSelector(
    (state) => state.profile
  );
  let page = PAGINATION.page;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [notesDialog, setNotesDialog] = useState(false);
  const [notesDetailDialog, setNotesDetailDialog] = useState(false);
  const [startDate, setStartDate] = useState("dd/mm/yyyy");
  const [endDate, setEndDate] = useState("dd/mm/yyyy");
  const [startDateForSend, setStartDateForSend] = useState("");
  const [endDateForSend, setEndDateForSend] = useState("");
  const [formError, setFormError] = useState({});
  const [notes, setNotes] = useState("");

  const openNotesDialog = () => {
    setNotesDialog(true);
    clearNotesDetailData();
    setNotes("");
    setFormError({ notes: "" }); // Reset errors when dialog is opened
  };
  const clearNotesDetailData = () => {
    dispatch(clearNotesData());
  };
  const closeNotesDialog = () => {
    setNotes("");
    setNotesDialog(false);
  };

  const closeNotesDetailDialog = () => {
    setNotes("");
    setNotesDetailDialog(false);
  };

  const handleStartDate = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD");
    setStartDate(value);
    setStartDateForSend(valDate);
  };

  const handleEndDate = (value, numberOfRows) => {
    const valDate = moment(value).format("YYYY-MM-DD");
    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(
      valDate === "dd/mm/yyyy" || valDate === "Invalid date"
        ? "dd/mm/yyyy"
        : startDate
    );
    listOfNotes(
      page,
      numberOfRows,
      valDate == "Invalid date" || valDate == "" ? "" : startDateForSend,
      valDate !== "Invalid date" ? valDate : ""
    );

    // var data = {
    //   page: page,
    //   perPage: numberOfRows ? numberOfRows : perPageRows,
    //   search: searchValue,
    //   startDate:
    //     valDate == "Invalid date" || valDate == "" ? "" : startDateForSend,
    //   endDate: valDate !== "Invalid date" ? valDate : "",
    //   status: statusFilter === "users" ? "" : statusFilter,
    //   dateOfBirth: "",
    // };
  };

  const createNotes = (id) => {
    if (notes === "") {
      setFormError({ notes: "Please enter notes" });
      return;
    }
    if (id) {
      var requestParam = {
        id: id,
        to_user_id: param?.id,
        message: notes,
      };
      dispatch(createNotesApi(requestParam, true));
    } else {
      var data = {
        to_user_id: param?.id,
        message: notes,
      };
      dispatch(createNotesApi(data, true));
    }

    closeNotesDialog();
  };
  const handleNotesDialog = (value) => {
    console.log(value);
    var data = {
      id: value,
    };
    dispatch(getNotesDetail(data));

    setNotesDialog(true);
  };

  const handleNotesDetailDailog = (value) => {
    console.log("id", value);
    console.log(value);
    var data = {
      id: value,
    };
    dispatch(getNotesDetail(data));
    setNotesDetailDialog(true);
  };
  useEffect(() => {
    listOfNotes();
    if (notesDetailData) {
      setNotes(notesDetailData?.message ?? "");
    }
  }, [notesDetailData]);

  const listOfNotes = (page, numberOfRows, startDate, endDate) => {
    endDateForSend === "Invalid date" && setEndDateForSend("dd/mm/yyyy");
    var requestParams = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      start_date: startDate,
      end_date: endDate,
      user_id: param?.id,
    };
    dispatch(listOfNotesApi(requestParams, true));
  };
  // MUI Data Table options and columns
  const columns = [
    {
      name: "message",
      label: "Message",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 1,
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "timestamp",
      label: "Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>
              {value ? moment(value).format("MMMM Do, YYYY") : "-"}
            </Typography>
          );
        },
      },
    },

    {
      name: "to_user_id",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          let id = notesListData[tableMeta?.rowIndex]?.id;

          return (
            <Grid display={"flex"}>
              <Grid item>
                <Tooltip id="button-report" title="Edit Notes">
                  <IconButton aria-label="edit">
                    <CiEdit
                      onClick={() => handleNotesDialog(id)}
                      fontSize={20}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip id="button-report" title="View Notes">
                  <IconButton aria-label="view">
                    <FaRegEye
                      onClick={() => handleNotesDetailDailog(id)}
                      color="grey"
                      fontSize={20}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          );
        },
      },
    },
    // Add more columns as needed
  ];

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      //setPageCheck(currentPage);
      listOfNotes(currentPage, "", "", "");
    },

    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      listOfNotes(page, numberOfRows, "", "");
    },
  };
  return (
    <div>
      <Grid container sx={{ p: 3, mt: -5 }}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/users">
              Users
            </Link>
            <Link style={{ color: "white" }} to={`/profile/${param?.id}`}>
              User Profile
            </Link>
            <Typography sx={{ color: "white" }}>Notes</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Box sx={{ background: color.black, borderRadius: "10px", p: 3 }}>
        <Grid
          item
          sx={{ mb: 4 }}
          display="flex"
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography
              style={{ fontSize: 20 }}
              className="textLinearBackground"
            >
              Notes
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }} display="flex">
          <Grid item xs={isMobile ? 6 : 3}>
            <AdminInput
              color="white"
              type={"date"}
              title={"Start Date"}
              // min={currnetDate}
              sx={{
                mt: -1,
                pr: 2,
                background: "#202020",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },

                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)", // Change the color of the calendar icon
                },
              }}
              value={startDate || ""}
              onChange={(e) => handleStartDate(e.target.value)}
              readOnly={isMobile}
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 3}>
            <AdminInput
              color="white"
              type={"date"}
              title={"End Date"}
              // min={currnetDate}
              sx={{
                mt: -1,
                pr: 2,
                background: "#202020",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                  filter: "invert(70%)", // Change the color of the calendar icon
                },
              }}
              value={endDate || ""}
              onChange={(e) => handleEndDate(e.target.value)}
              readOnly={isMobile}
            />
          </Grid>

          <Grid
            item
            xs={isMobile ? 12 : 6}
            display="flex"
            justifyContent={"flex-end"}
          >
            <Grid
              item
              xs={isMobile ? 12 : ""}
              sx={{ textAlign: isMobile ? "center" : "" }}
            >
              <SVButton
                onClick={openNotesDialog}
                text="Add Notes"
                sx={{
                  borderRadius: 10,
                  fontSize: isMobile && 10,
                  height: 32,
                  p: 2,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {notesListData?.length > 0 ? (
          <MUIDataTable
            data={notesListData}
            columns={columns}
            options={options}
          />
        ) : (
          <Grid
            container
            sx={{
              border: "1px solid #202020",
              mt: 2,
              height: 300,
              backgroundColor: "#202020",
            }}
          >
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 500,
                  mt: "7.5rem",
                  color: "white",
                }}
              >
                No Record Found
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>
      {/* Dialog for notes dialog for add notes */}
      <Dialog
        fullWidth
        scroll="paper"
        open={notesDialog}
        onClose={closeNotesDialog}
      >
        <DialogTitle>Add Notes</DialogTitle>
        <SVDivider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminInput
                autoFocus
                margin="dense"
                title="Notes"
                type="text"
                fullWidth
                value={notes}
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) {
                    setNotes(e.target.value);
                    setFormError((formError) => ({
                      ...formError,
                      notes: "",
                    }));
                  } else {
                    if (!val) {
                      setNotes("");
                    }
                  }
                }}
                variant="outlined"
                formError={formError.notes}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AdminButton
            onClick={closeNotesDialog}
            title={"Cancel"}
            sx={{ fontSize: 14 }}
            fullWidth={true}
          />

          <AdminButton
            onClick={() => createNotes(notesDetailData?.id)}
            title={"Save"}
            sx={{ fontSize: 14 }}
            fullWidth={true}
          />
        </DialogActions>
      </Dialog>

      {/* Dialog for notes detail */}
      <Dialog
        fullWidth
        scroll="paper"
        open={notesDetailDialog}
        onClose={closeNotesDetailDialog}
      >
        <DialogTitle>Notes</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeNotesDetailDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <SVDivider />
        <DialogContent sx={{ p: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                <b style={{ color: color.buttonColor }}> Message: </b>&nbsp;
                {notesDetailData?.message}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b style={{ color: color.buttonColor }}> Date: </b>&nbsp;
                {notesDetailData?.timestamp
                  ? moment(notesDetailData?.timestamp).format("MMMM Do, YYYY")
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Spinner open={isLoading} />
    </div>
  );
};

export default Notes;
