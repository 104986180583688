import * as React from "react";
import Typography from "@mui/material/Typography";
import "./index.css";
import AdminButton from "../../components/adminButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import OTPInput from "react-otp-input";
import { Box, Grid, Link } from "@mui/material";
import Spinner from "../../components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/slice/auth";
import AdminInput from "../../components/adminInput";
import {
  ACCOUNT_VERIFICATION_METHODS,
  APP_SETTINGS,
  STORAGE_KEY,
} from "utils/appConstant";
import secureLocalStorage from "react-secure-storage";
import { MESSAGES } from "utils/appMessages";
import Cookies from "js-cookie";
import { appColors } from "theme/color";
import SVButton from "components/SVButton";
import { sendOtpAuthDataClear, sendOtpForAuth } from "store/slice/profile";
// TODO remove, this demo shouldn't need to reset the theme.

export default function Otp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formError, setFormError] = useState();
  const [otp, setOtp] = useState("");
  const [otpType, setOtpType] = useState("");

  const [showTryAnother, setShowTryAnother] = useState(false);
  const [methods, setMethods] = useState(
    ACCOUNT_VERIFICATION_METHODS.EMAIL_OTP
  );



  const [valueAnotherMethod, setValueAnotherMethod] = useState(false);

  const { isLoading } = useSelector((state) => state.auth);

  const { sendOtpAuthData } = useSelector((state) => state.profile);


  const loginData = JSON.parse(
    secureLocalStorage.getItem(STORAGE_KEY.EMAIL_OTP)
  );

  const sendOtpAuth = (e, type) => {
    setOtpType(type);
    localStorage.clear("anotherMethod");
    var data = {
      otpType: type === "email" ? "email" : "phone",
      value: loginData?.email,
    };

    dispatch(sendOtpForAuth(data, setMethods));
  };

  const clearSendOtpAuthData = () => {
    dispatch(sendOtpAuthDataClear())
  }

  const resendOtp = () => {
    let requestParam = {
      email: loginData?.email,
      password: loginData?.password,
      mfa_code: "",
    };
    dispatch(login(requestParam, navigate, true));
  };
  const handleSubmit = () => {
    clearSendOtpAuthData()
    if (!otp) {
      return setFormError(MESSAGES.OTP.emptyEmailOTP);
    }

    let requestParam = {
      email: loginData?.email,
      password: loginData?.password,
      otp: otp,
      deviceToken: Cookies.get(process.env.REACT_APP_FCM_TOKEN_KEY) ?? "",
      deviceType: APP_SETTINGS.DEVICE_TYPE,
      type: methods,
    };
    // dispatch(loading(true));
    dispatch(login(requestParam, navigate, true));
  };

  const tryAnotherMethods = () => {
    // setAnotherMethod(true)
    localStorage.setItem("anotherMethod", true);
    if (methods === ACCOUNT_VERIFICATION_METHODS.EMAIL_OTP) {
      setMethods(ACCOUNT_VERIFICATION_METHODS.EMAIL_OTP);
    } else if (methods === ACCOUNT_VERIFICATION_METHODS.PHONE_OTP) {
      setMethods(ACCOUNT_VERIFICATION_METHODS.PHONE_OTP);
    } else {
      setMethods(ACCOUNT_VERIFICATION_METHODS.TECH_SUPPORT);
    }
  };

  React.useEffect(() => {
    setValueAnotherMethod(JSON.parse(localStorage.getItem("anotherMethod")));
    if (loginData && loginData.enableMethods) {
      let mfaEnableMethods = loginData.enableMethods;
      if (mfaEnableMethods.mfa_enabled) {
        setMethods(ACCOUNT_VERIFICATION_METHODS.AUTHENTICATOR);
      } else if (mfaEnableMethods.emailAuthentication) {
        setMethods(ACCOUNT_VERIFICATION_METHODS.EMAIL_OTP);
      } else if (mfaEnableMethods.textAuthentication) {
        setMethods(ACCOUNT_VERIFICATION_METHODS.PHONE_OTP);
      }

      //try another
      if (
        mfaEnableMethods.mfa_enabled &&
        (mfaEnableMethods.emailAuthentication ||
          mfaEnableMethods.textAuthentication)
      ) {
        setShowTryAnother(true);
      } else {
        setShowTryAnother(false);
      }

      if (otpType === "email") {
        setMethods(ACCOUNT_VERIFICATION_METHODS.EMAIL_OTP);
      }

      if (otpType === "phone") {
        setMethods(ACCOUNT_VERIFICATION_METHODS.PHONE_OTP);
      }
    }
  }, [loginData]);
  return (
    <>
      {(methods === ACCOUNT_VERIFICATION_METHODS.EMAIL_OTP ||
        methods === ACCOUNT_VERIFICATION_METHODS.PHONE_OTP ||
        sendOtpAuthData === 200) && (
        <div>
          <Typography
            variant="h5"
            component="h5"
            sx={{ textAlign: "center", p: 1 }}
          >
            Confirm that it's You
          </Typography>
          <Typography sx={{ pt: 3, fontSize: 14, textAlign: "center" }}>
            We've sent you a text message on your{" "}
            {methods === ACCOUNT_VERIFICATION_METHODS.EMAIL_OTP || otpType === "email"
              ? "Email"
              : "Phone"}{" "}
            with verification code.
          </Typography>
          <Typography
            sx={{
              my: "10px",
              textAlign: "center",
              fontSize: 14,
              color: appColors.alertColor,
            }}
          >
            Code will expire in 10 minutes.
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <AdminInput
              color="white"
              title="Enter 4-digit code here"
              type="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#C5C5C5",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              value={otp}
              onChange={(e) => {
                if (e.target.value) {
                  setOtp(
                    e.target.value?.replace(/\D/g, "")?.replace(/^.{5}$/, otp)
                  );
                  setFormError("");
                } else {
                  setOtp("")
                }
              }}
              formError={formError}
            />
            <Link
              sx={{
                color: appColors.blueColor,
                textDecoration: "none",
                fontSize: 14,
              }}
              onClick={resendOtp}
            >
              Resend Code
            </Link>
            <AdminButton
              onClick={handleSubmit}
              title={"Confirm"}
              sx={{ fontSize: 14 }}
              fullWidth={true}
            />
          </Box>
        </div>
      )}

      {methods === ACCOUNT_VERIFICATION_METHODS.AUTHENTICATOR &&
        valueAnotherMethod === null &&
        sendOtpAuthData === null && (
          <div>
            <Typography
              variant="h5"
              component="h5"
              sx={{ textAlign: "center", p: 1 }}
            >
              Verify an authenticator app code to continue
            </Typography>
            <ol type="1">
              <li>Open your Google authenticator app. </li>
              <li>Find the newest code for your SilverBack account.</li>
              <li>Enter the code before it expires</li>
            </ol>
            <AdminInput
              color="white"
              title="Enter 6-digit code here"
              type="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#C5C5C5",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              value={otp}
              onChange={(val) => {
                setOtp(
                  val.target.value?.replace(/\D/g, "")?.replace(/^.{7}$/, otp)
                );
                if (val) {
                  setFormError("");
                }
              }}
              formError={formError}
            />
            <AdminButton
              onClick={handleSubmit}
              title={"Confirm"}
              sx={{ fontSize: 14 }}
              fullWidth={true}
            />
          </div>
        )}

      {methods === ACCOUNT_VERIFICATION_METHODS.TECH_SUPPORT && (
        <div>
          <Typography
            variant="h5"
            component="h5"
            sx={{ textAlign: "center", p: 1 }}
          >
            Ask for Verification Help
          </Typography>
          <Typography sx={{ pt: 3, fontSize: 14 }}>
            We understand your problem to login into your system. If you didn't
            recieve codes on any of the method, then best to have technical team
            support. Please contact to tech support team to resolve the issue.
          </Typography>
          <AdminButton
            onClick={() => {
              secureLocalStorage.clear();
              Cookies.remove(process.env.REACT_APP_FCM_TOKEN_KEY);
              navigate("/");
            }}
            title={"Logout"}
            fullWidth={true}
          />
        </div>
      )}
      {showTryAnother && !valueAnotherMethod && sendOtpAuthData === null && (
        <Typography
          sx={{
            color: appColors.blueColor,
            textDecoration: "none",
            fontSize: 15,
            cursor: "pointer",
          }}
          onClick={tryAnotherMethods}
        >
          Try another methods
        </Typography>
      )}
      {valueAnotherMethod && sendOtpAuthData === null && (
        <>
          <Grid container spacing={2} sx={{ textAlign: "center" }}>
            {loginData?.enableMethods?.emailAuthentication && (
              <>
                {/* <Grid item xs={12} sx={{textAlign:"center"}}>
  <Typography sx={{fontSize:18}}>Try with another method</Typography>
</Grid> */}
                <Grid item xs={12}>
                  <SVButton
                    sx={{ width: 200 }}
                    text={"Email Authentication"}
                    onClick={(e) => sendOtpAuth(e, "email")}
                  />
                </Grid>
              </>
            )}

            {loginData?.enableMethods?.textAuthentication &&
              loginData?.enableMethods?.emailAuthentication && (
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  OR
                </Grid>
              )}
            {loginData?.enableMethods?.textAuthentication && (
              <Grid item xs={12}>
                <SVButton
                  sx={{ width: 200 }}
                  text={"Phone Authentication"}
                  onClick={(e) => sendOtpAuth(e, "phone")}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Spinner open={isLoading} />
    </>
  );
}
