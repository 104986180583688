import { useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  // useMediaQuery,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
import "./index.css";
// import AdminInput from "../../../components/adminInput";
import { Helmet } from "react-helmet-async";
import { appColors, color } from "../../../theme/color";
import moment from "moment";
import useStyles from "./style";
import EditUpdateDialogTicket from "./sections/editUpdateDialogTicket";
import AlertDialog from "components/alertDialog/alertDialog";
import { deleteTicket, getTicketList, updateTicket } from "store/slice/profile";
import TopHeading from "components/topHeading";
import {
  PAGINATION,
  PERMISSION_IDENTIFIER,
  TEAM_MEMBER_ROLE,
  TICKET_MANAGMENT_PRIORITY,
  TICKET_MANAGMENT_STATUS,
} from "utils/appConstant";
import AdminInput from "components/adminInput";
import TicketDetailDialog from "./sections/ticketDetail";

export default function index() {
  let page = PAGINATION.page;
  const { id } = useParams();
  const location = useLocation();
  const searchInputRef = useRef();
  const statusData = location?.state?.searchFilter;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  var classes = useStyles();
  // const [searchValue, setSearchValue] = useState("");
  const [ticketData, setTicketData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    totalTicketCount,
    isLoading,
    ticketListData,
    adminPorfileDetailData,
  } = useSelector((state) => state.profile);

  const [openEditTicket, setOpenEditTicket] = useState(false);
  const [openTicketDetail, setOpenTicketDetail] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  var [priority, setPriority] = useState("");
  var [statusType, setStatusType] = useState("");
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (tableMeta, event) => {
    setAnchorEl(event.currentTarget);
  };
  const fetchTicketList = (page, numberOfRows, search) => {
    var data = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: search ? search : searchValue,
      priority: priority,
      status: statusData ? statusData : statusType,
      userId: id ? id * 1 : null,
    };
    let isSearch = searchValue ? true : false;
    dispatch(getTicketList(data, isSearch, true));
  };

  useEffect(() => {
    fetchTicketList();
  }, []);
  const options = {
    count: totalTicketCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onChangePage(currentPage) {
      //setPageCheck(currentPage);
      fetchTicketList(currentPage, "", "", "");
    },

    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchTicketList(page, numberOfRows, "", "");
    },
  };

  const handleUpdateTicket = () => {
    let request = {
      id: ticketData?.id,
      priority: ticketData?.priority ? ticketData?.priority : "",
      status: ticketData?.status ? ticketData?.status : "",
      admin_message: ticketData?.admin_message,
    };
    dispatch(updateTicket(request, setOpenEditTicket));
  };
  const handleDeleteTicket = () => {
    dispatch(deleteTicket(ticketData?.id, setOpenAlertDialog));
  };

  const columns = [
    {
      name: "ticket_number",
      label: "Ticket ID",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "userFirstName",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },
    {
      name: "userLastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "category_name",
      label: "Category",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : ""}</Typography>;
        },
      },
    },
    {
      name: "sub_category_name",
      label: "Sub-Category",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : ""}</Typography>;
        },
      },
    },

    {
      name: "message",
      label: "Message",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value} placement="bottom-start">
              <Typography className={classes.ellipseText}>
                {value ? value : ""}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "admin_message",
      label: "Admin Message",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value} placement="bottom-start">
              <Typography className={classes.ellipseText}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "status",
      label: " Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value === "pending" && (
                  <>
                    <span
                      style={{
                        color: color.main,
                        background: "#1098DB",
                        fontSize: 11,
                        padding: 4,
                        borderRadius: "10%",
                      }}
                    >
                      <b>Pending</b>
                    </span>
                  </>
                )}
                {value === "resolved" && (
                  <>
                    <span
                      style={{
                        color: color.main,
                        background: "#4BA64F",
                        fontSize: 11,
                        padding: 4,
                        borderRadius: "10%",
                      }}
                    >
                      <b>Resolved</b>
                    </span>
                  </>
                )}
                {value === "rejected" && (
                  <span
                    style={{
                      color: color.main,
                      background: "#DD7176",

                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b>Rejected</b>
                  </span>
                )}
              </Typography>
            </Grid>
          );
        },
      },
    },

    {
      name: "priority",
      label: "Priority",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>
                {value === "low" && (
                  <>
                    <span
                      style={{
                        color: color.main,
                        background: "#1098DB",
                        fontSize: 11,
                        padding: 4,
                        borderRadius: "10%",
                      }}
                    >
                      <b>Low</b>
                    </span>
                  </>
                )}
                {value === "medium" && (
                  <>
                    <span
                      style={{
                        color: color.main,
                        background: "#4BA64F",
                        fontSize: 11,
                        padding: 4,
                        borderRadius: "10%",
                      }}
                    >
                      <b>Medium</b>
                    </span>
                  </>
                )}
                {value === "high" && (
                  <span
                    style={{
                      color: color.main,
                      background: "#DD7176",

                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b>High</b>
                  </span>
                )}
                {value === null && (
                  <>
                    <Typography className={classes.ellipseText}>
                      {"-"}
                    </Typography>
                  </>
                )}
              </Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "timestamp",
      label: "Ticket Raised On",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <Typography>{moment(value).format("MMMM Do, YYYY")}</Typography>
            </Grid>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          let index = ticketListData.findIndex((item) => item.id === value);
          if (!ticketListData[index]?.isTicketDisputed) {
            if (
              adminPorfileDetailData?.user_permissions[
                PERMISSION_IDENTIFIER.TICKET_MANAGMENT
              ] > 1 ||
              TEAM_MEMBER_ROLE.SUPERADMIN_ROLE ===
                adminPorfileDetailData?.roleId
            ) {
              return (
                <>
                  <Icon
                    onClick={(event) => {
                      setTicketData(ticketListData[index]);
                      handleClick(tableMeta, event);
                    }}
                    aria-describedby={value}
                    icon="ph:dots-three-circle-fill"
                    color={appColors.input}
                    width={isMobile ? 15 : 30}
                    height={isMobile ? 15 : 30}
                    style={{ paddingTop: 2, cursor: "pointer" }}
                  />

                  <Popover
                    id={value}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            setOpenTicketDetail(true);
                            setAnchorEl(null);
                          }}
                          style={{ paddingLeft: 18 }}
                        >
                          <ListItemIcon>
                            <Icon
                              icon="carbon:view"
                              width="18"
                              height="18"
                              color={color.buttonColor}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Ticket Detail"
                            style={{ marginLeft: "-1.5rem" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      {(adminPorfileDetailData?.roleId ===
                        TEAM_MEMBER_ROLE.SUPERADMIN_ROLE ||
                        adminPorfileDetailData?.user_permissions[
                          PERMISSION_IDENTIFIER.TICKET_MANAGMENT
                        ] >= 2) && (
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => {
                              setOpenEditTicket(true);
                              setAnchorEl(null);
                            }}
                            style={{ paddingLeft: 18 }}
                          >
                            <ListItemIcon>
                              <Icon
                                icon="mingcute:edit-line"
                                width="18"
                                height="18"
                                x
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Update Ticket"
                              style={{ marginLeft: "-1.5rem" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                      {(adminPorfileDetailData?.roleId ===
                        TEAM_MEMBER_ROLE.SUPERADMIN_ROLE ||
                        adminPorfileDetailData?.user_permissions[
                          PERMISSION_IDENTIFIER.TICKET_MANAGMENT
                        ] >= 4) && (
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => {
                              setOpenAlertDialog(true), setAnchorEl(null);
                            }}
                            style={{ paddingLeft: 18 }}
                          >
                            <ListItemIcon>
                              <Icon
                                icon="mingcute:delete-line"
                                color="red"
                                width="18"
                                height="18"
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Delete"
                              style={{ marginLeft: "-1.5rem" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </List>
                  </Popover>
                </>
              );
            }
          }
        },
      },
    },
  ];
  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: page ?? 0,
      perPage: perPageRows,
      search: "",
      status: "",
    };
    let isSearch = false;
    dispatch(getTicketList(data, isSearch, true));
  };
  return (
    <>
      <>
        {" "}
        <Helmet>
          <title> Ticket-Management </title>
        </Helmet>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Ticket Management"} />
        <Grid spacing={2} container sx={{ pt: 1, pb: 1, mt: 2 }}>
          <Grid item xs={isMobile ? 6 : 4}>
            <AdminInput
              sx={{
                mt: -1,
                background: "#202020",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
              }}
              inputRef={searchInputRef}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  fetchTicketList(page);
                }
              }}
              color="white"
              title="Search By Name"
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value) {
                  fetchTicketList(page, "", e.target.value);
                } else {
                  fetchTicketList(page, "", "");
                }
              }}
              value={searchValue}
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 4}>
            <FormControl
              fullWidth
              sx={{
                mt: -1,
                background: "#202020",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">Priority</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={priority}
                label="Filter"
                onChange={(event) => {
                  priority = event.target.value;
                  setPriority(event.target.value);
                  fetchTicketList();
                }}
                style={{ color: "white" }}
              >
                <MenuItem value={""}>All</MenuItem>
                {TICKET_MANAGMENT_PRIORITY?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isMobile ? 6 : 4}>
            <FormControl
              fullWidth
              sx={{
                mt: -1,
                background: "#202020",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#202020 !important",
                  },
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">Status Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusType || ""}
                label="Filter"
                onChange={(event) => {
                  statusType = event.target.value;
                  setStatusType(event.target.value);
                  fetchTicketList();
                }}
                style={{ color: "white" }}
              >
                <MenuItem value={""}>All</MenuItem>
                {TICKET_MANAGMENT_STATUS?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <>
          {ticketListData && ticketListData.length > 0 ? (
            <Grid container>
              <Grid xs={12}>
                <MUIDataTable
                  data={ticketListData}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid #202020",
                  mt: 2,
                  height: 300,
                  backgroundColor: "#202020",
                }}
              >
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 500,
                      mt: "7.5rem",
                      color: "white",
                    }}
                  >
                    No Record Found
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
        {openEditTicket && (
          <EditUpdateDialogTicket
            openDialog={openEditTicket}
            closeEditDialog={() => setOpenEditTicket(false)}
            ticketData={ticketData}
            setTicketData={setTicketData}
            onSave={handleUpdateTicket}
            heading={"Edit Ticket Detail"}
          />
        )}
        {ticketData && (
          <TicketDetailDialog
            openDialog={openTicketDetail}
            closeEditDialog={() => setOpenTicketDetail(false)}
            ticketData={ticketData}
          />
        )}
        {openAlertDialog && (
          <AlertDialog
            open={openAlertDialog}
            handleClose={() => setOpenAlertDialog(false)}
            title={"Delete Confirmation!"}
            text={"Are you sure you want to delete this ticket?"}
            handleAgree={handleDeleteTicket}
          />
        )}
      </>

      <Spinner open={isLoading} />
    </>
  );
}
