import React from "react";
import {
  Dialog,
  // DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import SVDivider from "components/SVDivider";
import { color } from "theme/color";
import SVOutlinedButton from "components/SVOutlinedButton";
import SVButton from "components/SVButton";
import {
  TICKET_MANAGMENT_PRIORITY,
  TICKET_MANAGMENT_STATUS,
} from "utils/appConstant";
import AdminInput from "components/adminInput";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const index = ({ ...props }) => {
  const {
    openDialog,
    closeEditDialog,
    ticketData,
    onSave,
    setTicketData,
    heading,
  } = props;
  return (
    <BootstrapDialog
      fullWidth
      scroll="paper"
      open={openDialog}
      keepMounted
      onClose={closeEditDialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">
          {heading ? heading : "Edit Profile"}
        </Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={closeEditDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider />
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                <b style={{ color: color.buttonColor }}>Issued Raised By:</b>
                &nbsp;
                {ticketData?.userFirstName}
              </Typography>
              <Typography>
                <b style={{ color: color.buttonColor }}> Message: </b>&nbsp;
                {ticketData?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1.5 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Change Status
                </InputLabel>
                <Select
                  sx={{
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "grey",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={ticketData?.status || ""}
                  label="Change Statusss"
                  onChange={(e) => {
                    setTicketData((ticketData) => ({
                      ...ticketData,
                      status: e.target.value,
                    }));
                  }}
                >
                  {TICKET_MANAGMENT_STATUS?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1.5 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Change Priority
                </InputLabel>
                <Select
                  sx={{
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "grey",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black",
                      },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={ticketData?.priority || ""}
                  label="Change Priorityyy"
                  onChange={(e) => {
                    setTicketData((ticketData) => ({
                      ...ticketData,
                      priority: e.target.value,
                    }));
                  }}
                >
                  {TICKET_MANAGMENT_PRIORITY?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1.5 }}>
              <AdminInput
                title="Notes"
                multiline
                minRows={2}
                maxRows={10}
                type="text"
                fullWidth
                onChange={(e) => {
                  setTicketData((ticketData) => ({
                    ...ticketData,
                    admin_message: e.target.value,
                  }));
                }}
                value={ticketData?.admin_message || ""}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{ mt: 2 }}
            >
              <SVButton text="Save" sx={{ height: 40 }} onClick={onSave} />

              <SVOutlinedButton
                text="Cancel"
                sx={{ height: 40, ml: 1 }}
                onClick={closeEditDialog}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default React.memo(index);
