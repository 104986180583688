import { Helmet } from "react-helmet-async";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// components
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner";
import TopHeading from "components/topHeading";
import { TotalCount } from "./totalCountCard";
import { ESTATE_STATUS } from "utils/appConstant";
import { capitalizeFirstLetter } from "utils/constantFun";
import { useEffect, useState } from "react";
import { getDashboardData, getUserChartData } from "store/slice/assets";
import { DashboardDateFilter } from "./customDateFilter";
import { TotalUserGraph, UsersGraph } from "./userGraph";
import SVSelect from "components/SVSelect";
import AdminInput from "components/adminInput";

// import { TotalCount } from "./totalCountCard";
// sections

// ----------------------------------------------------------------------

export default function Dashboard() {
  const { isLoading } = useSelector((state) => state.profile);
  var [statusType, setStatusType] = useState("all");
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { dashboardData, userChartData } = useSelector((state) => state.assets);
  const [day, setDay] = useState("");
  const [dateInput, setDateInput] = useState(false);
  var [startDate, setStartDate] = useState("dd/mm/yyyy");
  var [endDate, setEndDate] = useState("dd/mm/yyyy");

  // user graph states
  const [years, setYears] = useState([]);
  const [selectMonth, setSelectMonth] = useState("");
  const [selectYear, setSelectYear] = useState("");
  var [userGraphstartDate, setUserGraphStartDate] = useState("dd/mm/yyyy");
  var [userGraphendDate, setUserGraphEndDate] = useState("dd/mm/yyyy");

  // toatl user garph
  var [totalUsermonths, setToatlUserMonths] = useState([]);
  var [users, setUsers] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (userChartData) {
      let temp = [...totalUsermonths];
      let countTemp = [...users];
      userChartData?.map((data) => {
        let index = temp?.findIndex((item) => item == data?.month);
        if (index == -1) {
          temp.push(data?.month);
          countTemp.push(data?.count);
        }
      });
      setToatlUserMonths(temp);
      setUsers(countTemp);
    }
  }, [userChartData]);
  useEffect(() => {
    const yearsArray = [];
    for (let i = 2020; i <= currentYear; i++) {
      let obj = {
        id: i,
        description: i,
      };
      yearsArray.push(obj);
    }
    setYears(yearsArray);
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchDashboardData();
    fetchUserGraphData("", currentYear);
  }, []);
  const fetchDashboardData = (search, days, startDate, endDate) => {
    let request = {
      search: search ? search : "",
      days: days ?? 0,
      startDate: startDate !== "dd/mm/yyyy" ? startDate : "",
      endDate: endDate !== "dd/mm/yyyy" ? endDate : "",
    };
    dispatch(getDashboardData(request));
  };
  const fetchUserGraphData = (month, year, startDate, endDate) => {
    let request = {
      month: month ? month : 0,
      year: year ? year : 0,
      startDate: startDate !== "dd/mm/yyyy" ? startDate : "",
      endDate: endDate !== "dd/mm/yyyy" ? endDate : "",
    };
    dispatch(getUserChartData(request, setToatlUserMonths, setUsers));
  };
  const navigateToUser = (searchQuery = "") => {
    if (searchQuery != "subscribe") {
      navigate("/users", { state: { searchFilter: searchQuery } });
    } else {
      navigate("/subscribeUser");
    }
  };

  const navigateToTicketPage = (searchQuery = "") => {
    navigate("/ticketManagement", { state: { searchFilter: searchQuery } });
  };
  const navigateToEstatePlanning = (searchQuery = "") => {
    navigate("/estate-planning", {
      state: {
        statusData: statusType !== "all" ? statusType : "",
        searchFilter: searchQuery,
      },
    });
  };
  const handleStartDate = (value) => {
    const newStartDate = value === "" ? "dd/mm/yyyy" : value;
    setStartDate(newStartDate);
    if (newStartDate !== "dd/mm/yyyy" && endDate !== "dd/mm/yyyy") {
      fetchDashboardData("", 0, newStartDate, endDate);
    }
  };
  const handleEndDate = (value) => {
    const newEndDate = value === "" ? "dd/mm/yyyy" : value;
    setEndDate(newEndDate);
    // setStartDate(
    //   value === "dd/mm/yyyy" || value === "Invalid date" ? "" : startDate
    // );
    if (newEndDate !== "dd/mm/yyyy" && startDate !== "dd/mm/yyyy") {
      fetchDashboardData("", 0, startDate, newEndDate);
    }
  };

  const handleChange = (e) => {
    setDay(e.target.value);
    if (e.target.value != 0) {
      setDateInput(false);
      setStartDate("dd/mm/yyyy");
      setEndDate("dd/mm/yyyy");
    } else {
      setDateInput(true);
    }
    fetchDashboardData("", e.target.value, "dd/mm/yyyy", "dd/mm/yyyy");
  };
  // month array

  let months = [
    { description: "January", id: 1 },
    { description: "February", id: 2 },
    { description: "March", id: 3 },
    { description: "April", id: 4 },
    { description: "May", id: 5 },
    { description: "June", id: 6 },
    { description: "July", id: 7 },
    { description: "August", id: 8 },
    { description: "September", id: 9 },
    { description: "October", id: 10 },
    { description: "November", id: 11 },
    { description: "December", id: 12 },
  ];

  // user graph start and end date
  const handleUserGraphStartDate = (value) => {
    const newStartDate = value === "" ? "dd/mm/yyyy" : value;
    setSelectMonth("");
    setSelectYear("");
    setUserGraphStartDate(newStartDate);
    if (newStartDate !== "dd/mm/yyyy" && userGraphendDate !== "dd/mm/yyyy") {
      fetchUserGraphData(0, 0, newStartDate, userGraphendDate);
    }
  };
  const handleUserGraphEndDate = (value) => {
    const newEndDate = value === "" ? "dd/mm/yyyy" : value;
    setSelectMonth("");
    setSelectYear("");
    setUserGraphEndDate(newEndDate);

    if (newEndDate !== "dd/mm/yyyy" && userGraphstartDate !== "dd/mm/yyyy") {
      fetchUserGraphData(0, 0, userGraphstartDate, newEndDate);
    }
  };
  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Grid container spacing={3} px={3}>
        <Grid xs={12} display={"flex"} justifyContent={"space-between"}>
          <TopHeading sx={{ px: 3 }} title={"Users"} />
          <DashboardDateFilter
            day={day}
            handleChange={handleChange}
            dateInput={dateInput}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </Grid>

      {/* <Stack
        direction="row"
        sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
      >
        
      </Stack> */}
      <Grid container spacing={3} px={3} mt={0} mb={6}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <TotalCount
                title={"Total Users"}
                value={dashboardData?.allUserCount}
                click={navigateToUser}
                searchQuery={"users"}
                icon={"ph:users-three"}
                background={"#e1e7fc"}
                iconColor={"#2554fb"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TotalCount
                title={"Active Users"}
                value={dashboardData?.approveUser}
                click={navigateToUser}
                searchQuery={"approve"}
                icon={"iconoir:verified-user"}
                iconColor={"#03ad4f"}
                background={"#e0f7ea"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TotalCount
                title={"Pending Users"}
                value={dashboardData?.pendingUser}
                click={navigateToUser}
                searchQuery={"review"}
                icon={"mdi:account-pending-outline"}
                iconColor={"#E9DB24"}
                background={"#fffcd2"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TotalCount
                title={"Rejected Users"}
                Estate
                Planning
                value={dashboardData?.rejectUser}
                click={navigateToUser}
                searchQuery={"rejected"}
                icon={"la:user-times"}
                iconColor={"#ffa20e"}
                background={"#fef4e4"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TotalCount
                title={"Suspended Users"}
                value={dashboardData?.suspendUser}
                click={navigateToUser}
                searchQuery={"suspend"}
                icon={"tdesign:user-blocked"}
                iconColor={"#fb2727"}
                background={"#ffe9e9"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TotalCount
                title={"Subscribed Users"}
                value={dashboardData?.subscriber}
                click={navigateToUser}
                searchQuery={"subscribe"}
                icon="fluent:person-alert-20-regular"
                iconColor={"#03a5c3"}
                background={"#d8f4f9"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} px={3} mt={0} mb={6}>
        <Grid
          item
          xs={isMobile ? 12 : 6}
          sx={{
            // border: "2px solid white",
            p: 2,
            borderRadius: 2,
          }}
        >
          <UsersGraph />
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 6}
          sx={{ 
            // border: "2px solid white",
             p: 2, borderRadius: 2 }}
        >
          <Grid container spacing={3} mb={3}>
            <Grid item xs={isMobile ? 12 : 3}>
              <SVSelect
                color="white"
                sx={{
                  mt: -0.5,
                  background: "#202020",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#202020 !important",
                      color: "white",
                      borderRadius: 0,
                    },
                  },
                  "& .MuiSelect-select": {
                    color: "white", // Change the color of the text inside the select
                  },
                }}
                fullWidth="true"
                value={selectYear || ""}
                label="Year"
                options={years}
                emptyText="Choose Year"
                onChange={(e) => {
                  setUserGraphStartDate("dd/mm/yyyy");
                  setUserGraphEndDate("dd/mm/yyyy");
                  setSelectYear(e.target.value);
                  fetchUserGraphData(selectMonth, e.target.value, "", "");
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <SVSelect
                color="white"
                sx={{
                  mt: -0.5,
                  background: "#202020",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#202020 !important",
                      color: "white",
                      borderRadius: 0,
                    },
                  },
                  "& .MuiSelect-select": {
                    color: "white", // Change the color of the text inside the select
                  },
                }}
                fullWidth="true"
                value={selectMonth || ""}
                label="Month"
                options={months}
                emptyText="Choose Month"
                onChange={(e) => {
                  setUserGraphStartDate("dd/mm/yyyy");
                  setUserGraphEndDate("dd/mm/yyyy");

                  setSelectMonth(e.target.value);
                  fetchUserGraphData(e.target.value, selectYear, "", "");
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <AdminInput
                color="white"
                type={"date"}
                title={"Start Date"}
                max={endDate ?? ""}
                sx={{
                  mt: -0.9,
                  background: "#202020",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#202020 !important",
                    },
                  },
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter: "invert(70%)", // Change the color of the calendar icon
                  },
                }}
                value={userGraphstartDate || ""}
                onChange={(e) => handleUserGraphStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <AdminInput
                color="white"
                type={"date"}
                title={"End Date"}
                min={startDate ?? ""}
                sx={{
                  mt: -0.9,
                  background: "#202020",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#202020 !important",
                    },
                  },
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter: "invert(70%)", // Change the color of the calendar icon
                  },
                }}
                value={userGraphendDate || ""}
                onChange={(e) => handleUserGraphEndDate(e.target.value)}
              />
            </Grid>
          </Grid>
          <TotalUserGraph
            setMonths={setToatlUserMonths}
            months={totalUsermonths}
            setUsers={setUsers}
            users={users}
          />
        </Grid>
      </Grid>
      <TopHeading sx={{ px: 3 }} title={"Tickets"} />
      <Grid container spacing={3} px={3} mt={0} mb={6}>
        <Grid item xs={12} sm={6} md={3}>
          <TotalCount
            title={"Total Tickets"}
            value={dashboardData?.totalTicket}
            click={navigateToTicketPage}
            searchQuery={""}
            icon={"f7:tickets"}
            iconColor={"#03a5c3"}
            background={"#d8f4f9"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TotalCount
            title={"Active Tickets"}
            value={dashboardData?.pendingTicket}
            click={navigateToTicketPage}
            searchQuery={"pending"}
            icon={"pepicons-pencil:ticket"}
            iconColor={"#E9DB24"}
            background={"#fffcd2"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TotalCount
            title={"Resolved Tickets"}
            value={dashboardData?.resolveTicket}
            click={navigateToTicketPage}
            searchQuery={"resolved"}
            icon={"icon-park-outline:tickets-checked"}
            iconColor={"#03ad4f"}
            background={"#e0f7ea"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TotalCount
            title={"Rejected Tickets"}
            value={dashboardData?.rejectTicket}
            icon={"mynaui:ticket-slash"}
            click={navigateToTicketPage}
            searchQuery={"rejected"}
            iconColor={"#fb2727"}
            background={"#ffe9e9"}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: isMobile ? "" : "flex",
          justifyContent: "space-between",
        }}
      >
        <TopHeading sx={{ px: 3 }} title={"Estate Planning"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            mt: isMobile ? 2 : -2,
            mr: 3,
          }}
        >
          <FormControl>
            <Select
              value={statusType}
              onChange={(event) => {
                statusType = event.target.value;
                setStatusType(event.target.value);
                fetchDashboardData(
                  event.target.value == "all" ? "" : event.target.value
                );
              }}
              sx={{
                color: "white",
                width: "100%",
                "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem selected value={"all"}>
                All
              </MenuItem>
              <MenuItem value={ESTATE_STATUS?.REVIEW}>
                {capitalizeFirstLetter(ESTATE_STATUS?.REVIEW)}
              </MenuItem>
              <MenuItem value={ESTATE_STATUS?.APPROVE}>
                {capitalizeFirstLetter(ESTATE_STATUS?.APPROVE)}
              </MenuItem>
              <MenuItem value={ESTATE_STATUS?.REJECT}>
                {capitalizeFirstLetter(ESTATE_STATUS?.REJECT)}
              </MenuItem>
              <MenuItem value={ESTATE_STATUS?.SIGNATURE_PENDING}>
                {capitalizeFirstLetter(ESTATE_STATUS?.SIGNATURE_PENDING)}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Grid container spacing={3} px={3} mt={0}>
        <Grid item xs={12} sm={6} md={3}>
          <TotalCount
            title={"last will and testament"}
            value={dashboardData?.estateCounts?.last_will_and_testament}
            icon={"material-symbols:real-estate-agent-outline"}
            click={navigateToEstatePlanning}
            searchQuery={"last_will_and_testament"}
            background={"#e1e7fc"}
            iconColor={"#2554fb"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TotalCount
            title={"Power Of Attorney"}
            value={dashboardData?.estateCounts?.power_of_attorney}
            icon={"tabler:user-star"}
            click={navigateToEstatePlanning}
            searchQuery={"power_of_attorney"}
            iconColor={"#ffa20e"}
            background={"#fef4e4"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TotalCount
            title={"Hipaa Release And Authorization"}
            value={dashboardData?.estateCounts?.hippa_release_and_authorization}
            icon={"tabler:building-estate"}
            click={navigateToEstatePlanning}
            searchQuery={"hippa_release_and_authorization"}
            iconColor={"#a99512"}
            background={"#f9f4d5"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TotalCount
            title={"living will health care proxy"}
            value={dashboardData?.estateCounts?.living_will_health_care_proxy}
            icon={"fluent-mdl2:real-estate"}
            click={navigateToEstatePlanning}
            searchQuery={"living_will_health_care_proxy"}
            iconColor={"#03a5c3"}
            background={"#d8f4f9"}
          />
        </Grid>
      </Grid>

      <Spinner open={isLoading} />
    </>
  );
}
