import {
  Breadcrumbs,
  Card,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { color } from "theme/color";
import Spinner from "components/spinner";
import { Link, useNavigate } from "react-router-dom";
import DataNotFound from "components/dataNotFound/dataNotFound";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AcceptRejectEstateDialog from "../estateDetailDialog/acceptRejectEstateDialog";
import EstateDetailCard from "./estateDetailCard";
import UploadPdfDialog from "../estateDetailDialog/uploadPdfDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEstateDocument,
  getEstatePdfList,
  profileDetail,
  stateList,
  updateEstatePdfStatus,
} from "store/slice/profile";
import SVButton from "components/SVButton";
import EstateDocumentLimit from "./estateDocumentLimit";
import PreviewHipaaDocument from "./estateDocuments/hipaaDocument/previewHipaaDocument";
import {
  clearEstateDetails,
  clearManagmentData,
  getManagmentOptions,
  getManagmentRelationshipList,
} from "store/slice/estate";
import PreviewPowerOfAttorneyDocument from "./estateDocuments/powerOfAttorney/previewPowerOfAttorney";
import { ESTATE_PLANNING_KEYS } from "utils/appConstant";
import PreviewLastWillDocument from "./estateDocuments/lastWillDocument/previewLastWillDocument";
import PreviewLivingWillDocument from "./estateDocuments/livingWill/previewLivingWill";
import EstateHeading from "./estateHeading";
// import PdfViewerDialog from "../estateDetailDialog/pdfViewerDialog";

const EstateDetail = () => {
  const params = useParams();
  const [anchorPOAEl, setAnchorPOAEl] = useState(null);
  const [anchorHCDEl, setAnchorHCDEl] = useState(null);
  const [anchorTESTEl, setAnchorTESTEl] = useState(null);
  const [anchorLASTEl, setAnchorLASTEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [pdfId, setPdfId] = useState(null);
  const [pdfKey, setPdfKey] = useState("");
  const [pdfIndex, setPdfIndex] = useState(0);
  const [showHippaDetails, setShowHippaDetails] = useState(false);
  const [hipaaDetailData, setHippaDetailsData] = useState("");
  const [showPOADetails, setShowPOADetails] = useState(false);
  const [POADetailData, setPOADetailsData] = useState("");
  const [showLastWillDetails, setShowLastWillDetails] = useState(false);
  const [lastWillDetailData, setLastWillDetailsData] = useState("");
  const [showLivingWillDetails, setShowLivingWillDetails] = useState(false);
  const [livingWillData, setLivingWillData] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [acceptDialog, setAcceptDialog] = useState(false);
  const [documentLimitDialog, setDocumentLimitDialog] = useState(false);
  const [uploadPdfDialog, setUploadPdfDialog] = useState(false);
  const [reasonData, setReasonData] = useState("");
  const [uploadPdfData, setUploadPdfdata] = useState({});
  const { estatePdfListData } = useSelector((state) => state.profile);
  const [actionType, setActionType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { profileDetailData } = useSelector((state) => state.profile);
  const open = Boolean(anchorPOAEl);
  const openHCD = Boolean(anchorHCDEl);
  const openTEST = Boolean(anchorTESTEl);
  const openLAST = Boolean(anchorLASTEl);

  const handleClose = () => {
    setAnchorPOAEl(null);
    setAnchorHCDEl(null);
    setAnchorTESTEl(null);
    setAnchorLASTEl(null);
  };
  const userId = params?.id;

  // get estate pdf list

  useEffect(() => {
    if (!estatePdfListData) {
      setIsLoading(true);
    }

    dispatch(getEstatePdfList(userId, setIsLoading));
    var requestParams = {
      id: userId,
    };
    dispatch(profileDetail(requestParams, true));
    dispatch(stateList());
    dispatch(getManagmentOptions(ESTATE_PLANNING_KEYS?.POWER_OF_ATTORNEY));
    dispatch(getManagmentRelationshipList());
  }, []);

  const handleAgree = () => {
    let request = {
      userMapperId: pdfId?.userMapperId,
      status:
        actionType === "accept"
          ? "approve"
          : actionType === "reject"
            ? "reject"
            : "",
      rejectReason: actionType === "reject" ? reasonData : "",
      documentId: 0,
    };
    dispatch(updateEstatePdfStatus(request, pdfKey, closeDialog));
  };
  const handleUploadPdf = () => {
    let request = {
      userMapperId: pdfId?.userMapperId,
      status: "approve",
      rejectReason: pdfId?.reject_reason ? pdfId?.reject_reason : "",
      documentId: uploadPdfData?.documentId,
    };
    dispatch(updateEstatePdfStatus(request, pdfKey, closeDialog));
  };
  const closeDialog = () => {
    setReasonData("");
    setUploadPdfdata({});
    setAcceptDialog(false);
    setUploadPdfDialog(false);
  };

  const handleDownloadPdf = (pdf) => {
    const link = document.createElement("a");
    link.href = pdf;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDelete = () => {
    setIsLoading(true);
    dispatch(
      deleteEstateDocument(
        pdfId?.userMapperId,
        pdfKey,
        setIsLoading,
        setAcceptDialog
      )
    );
  };
  return (
    <>
      <>
        {/* document limit dialog */}
        <EstateDocumentLimit
          userId={userId}
          openDialog={documentLimitDialog}
          setOpen={setDocumentLimitDialog}
        />
        <AcceptRejectEstateDialog
          openDialog={acceptDialog}
          isLoading={isLoading}
          closeDialog={closeDialog}
          setReasonData={setReasonData}
          handleDelete={handleDelete}
          reasonData={reasonData}
          type={actionType}
          pdfKey={pdfKey}
          handleAgree={handleAgree}
          message={`Are you sure you want to accept ${pdfKey?.replace(/_/g, " ")} document ?`}
        />
        <UploadPdfDialog
          openDialog={uploadPdfDialog}
          handleUploadPdf={handleUploadPdf}
          setUploadPdfdata={setUploadPdfdata}
          uploadPdfData={uploadPdfData}
          closeDialog={closeDialog}
        />
        <Grid
          container
          sx={{
            p: 3,
            mt: -5,
          }}
        >
          <Grid item xs={isMobile ? 12 : 8}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} to="/users">
                Users
              </Link>
              <Link style={{ color: "white" }} to={`/profile/${userId}`}>
                User Profile
              </Link>
              <Typography sx={{ color: "white" }}>Estate Details</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 4}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <SVButton
              text="Change Documents Limit"
              onClick={() => setDocumentLimitDialog(true)}
              sx={{ mt: isMobile ? 2 : "" }}
            />
          </Grid>
        </Grid>
        <Box sx={{ background: color.black, borderRadius: "10px", p: 3 }}>
          {/*  Last Will And Testament*/}
          {lastWillDetailData && (
            <PreviewLastWillDocument
              childerName={lastWillDetailData?.children_name}
              lastWillData={lastWillDetailData}
              viewDetails
              open={showLastWillDetails}
              handleClose={() => {
                setLastWillDetailsData("");
                setShowLastWillDetails(false);
              }}
              profileDetailData={profileDetailData}
            />
          )}
          <EstateHeading
            heading={" Last Will And Testament"}
            onClick={() => {
              dispatch(clearEstateDetails());
              navigate(`/profile/estate/last-will-and-testament/add/${userId}`);
            }}
          />

          <Card
            sx={{
              color: color.main,
              background: "transparent",
              mt: 2,
            }}
          >
            <Grid container layout={"row"} spacing={2}>
              {estatePdfListData?.last_will_and_testament &&
              estatePdfListData?.last_will_and_testament?.length >= 1 ? (
                <>
                  {estatePdfListData?.last_will_and_testament?.map(
                    (data, index) => {
                      return (
                        <EstateDetailCard
                          onEditClick={(data) =>
                            navigate(
                              `/profile/estate/last-will-and-testament/edit/${userId}/${data?.userMapperId}`
                            )
                          }
                          onDeleteClick={(data) => {
                            setPdfId(data);
                            setPdfKey("last_will_and_testament");
                            setActionType("delete");
                            setAcceptDialog(true);
                          }}
                          data={data}
                          setShowDetails={setShowLastWillDetails}
                          setDetailsData={setLastWillDetailsData}
                          index={index}
                          popOverClick={(event) => {
                            setPdfId(data);
                            setPdfIndex(index);
                            setPdfKey("last_will_and_testament");
                            setAnchorLASTEl(event.currentTarget);
                          }}
                          key={index}
                          popover={
                            <Popover
                              id={data?.id}
                              open={openLAST}
                              anchorEl={anchorLASTEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <List>
                                {pdfKey &&
                                  estatePdfListData[pdfKey][pdfIndex]
                                    ?.status === "review" && (
                                    <>
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            setActionType("accept");
                                            setAcceptDialog(true);
                                            setAnchorLASTEl(null);
                                            handleClose();
                                          }}
                                        >
                                          <ListItemText primary="Accept" />
                                        </ListItemButton>
                                      </ListItem>
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            setActionType("reject");
                                            setAcceptDialog(true);
                                            setAnchorLASTEl(null);
                                            handleClose();
                                          }}
                                        >
                                          <ListItemText primary="Reject" />
                                        </ListItemButton>
                                      </ListItem>
                                    </>
                                  )}

                                <ListItem disablePadding>
                                  <ListItemButton
                                    onClick={() => {
                                      handleDownloadPdf(
                                        estatePdfListData[pdfKey][pdfIndex]
                                          ?.form_pdf
                                      );
                                      handleClose();
                                    }}
                                  >
                                    <ListItemText primary="Download" />
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </Popover>
                          }
                        />
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  <Grid xs={12} sx={{ m: 2 }}>
                    <DataNotFound />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
          {/* POWER OF ATTORNEY */}
          {POADetailData && (
            <PreviewPowerOfAttorneyDocument
              viewDetails
              open={showPOADetails}
              handleClose={() => {
                setPOADetailsData("");
                setShowPOADetails(false);
              }}
              profileDetailData={profileDetailData}
              POAData={POADetailData}
            />
          )}
          <EstateHeading
            heading={"  Power Of Attorney"}
            onClick={() => {
              dispatch(clearEstateDetails());
              navigate(`/profile/estate/power-of-attorney/add/${userId}`);
            }}
          />

          <Card
            sx={{
              color: color.main,
              background: "transparent",
              mt: 2,
            }}
          >
            <Grid container spacing={2}>
              {estatePdfListData?.power_of_attorney &&
              estatePdfListData?.power_of_attorney?.length >= 1 ? (
                <>
                  {estatePdfListData?.power_of_attorney?.map((data, index) => {
                    return (
                      <EstateDetailCard
                        data={data}
                        index={index}
                        onDeleteClick={(data) => {
                          setPdfId(data);
                          setPdfKey("power_of_attorney");
                          setActionType("delete");
                          setAcceptDialog(true);
                        }}
                        onEditClick={(data) =>
                          navigate(
                            `/profile/estate/power-of-attorney/edit/${userId}/${data?.userMapperId}`
                          )
                        }
                        setShowDetails={setShowPOADetails}
                        setDetailsData={setPOADetailsData}
                        popOverClick={(event) => {
                          setPdfId(data);
                          setPdfIndex(index);
                          setPdfKey("power_of_attorney");
                          setAnchorPOAEl(event.currentTarget);
                        }}
                        key={index}
                        popover={
                          <Popover
                            id={data?.id}
                            open={open}
                            anchorEl={anchorPOAEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <List>
                              {pdfKey &&
                                estatePdfListData[pdfKey][pdfIndex]?.status ===
                                  "review" && (
                                  <>
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setActionType("accept");
                                          setAcceptDialog(true);
                                          setAnchorHCDEl(null);
                                          handleClose();
                                        }}
                                      >
                                        <ListItemText primary="Accept" />
                                      </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setActionType("reject");
                                          setAcceptDialog(true);
                                          setAnchorHCDEl(null);
                                          handleClose();
                                        }}
                                      >
                                        <ListItemText primary="Reject" />
                                      </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          setAnchorPOAEl(null);
                                          setUploadPdfDialog(true);
                                          handleClose();
                                        }}
                                      >
                                        <ListItemText primary="Upload Pdf" />
                                      </ListItemButton>
                                    </ListItem>
                                  </>
                                )}

                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => {
                                    handleDownloadPdf(
                                      estatePdfListData[pdfKey][pdfIndex]
                                        ?.form_pdf
                                    );
                                    handleClose();
                                  }}
                                >
                                  <ListItemText primary="Download" />
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </Popover>
                        }
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <Grid xs={12} sx={{ m: 2 }}>
                    <DataNotFound />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>

          {/* Hippa Release And Authorization */}
          {hipaaDetailData && (
            <PreviewHipaaDocument
              viewDetails
              HipaaData={hipaaDetailData}
              open={showHippaDetails}
              handleClose={() => {
                setHippaDetailsData("");
                setShowHippaDetails(false);
              }}
              profileDetailData={profileDetailData}
            />
          )}
          <EstateHeading
            heading={"   Hipaa Release And Authorization"}
            onClick={() => {
              dispatch(clearEstateDetails());
              navigate(
                `/profile/estate/hippa-release-and-authorization/add/${userId}`
              );
            }}
          />

          <Card
            sx={{
              color: color.main,
              background: "transparent",
              mt: 2,
            }}
          >
            <Grid container spacing={2}>
              {estatePdfListData?.hippa_release_and_authorization &&
              estatePdfListData?.hippa_release_and_authorization?.length >=
                1 ? (
                <>
                  {estatePdfListData?.hippa_release_and_authorization?.map(
                    (data, index) => {
                      return (
                        <EstateDetailCard
                          data={data}
                          index={index}
                          setShowDetails={setShowHippaDetails}
                          setDetailsData={setHippaDetailsData}
                          onDeleteClick={(data) => {
                            setPdfId(data);
                            setPdfKey("hippa_release_and_authorization");
                            setActionType("delete");
                            setAcceptDialog(true);
                          }}
                          key={index}
                          onEditClick={(data) =>
                            navigate(
                              `/profile/estate/hippa-release-and-authorization/edit/${userId}/${data?.userMapperId}`
                            )
                          }
                          popOverClick={(event) => {
                            setPdfIndex(index);
                            setPdfKey("hippa_release_and_authorization");
                            setAnchorHCDEl(event.currentTarget);
                            setPdfId(data);
                          }}
                          setAnchorEl={setAnchorHCDEl}
                          popover={
                            <Popover
                              id={data?.id}
                              open={openHCD}
                              anchorEl={anchorHCDEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <List>
                                {pdfKey &&
                                  estatePdfListData[pdfKey][pdfIndex]
                                    ?.status === "review" && (
                                    <>
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            setActionType("accept");
                                            setAcceptDialog(true);
                                            setAnchorHCDEl(null);
                                            handleClose();
                                          }}
                                        >
                                          <ListItemText primary="Accept" />
                                        </ListItemButton>
                                      </ListItem>
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            setActionType("reject");
                                            setAcceptDialog(true);
                                            setAnchorHCDEl(null);
                                            handleClose();
                                          }}
                                        >
                                          <ListItemText primary="Reject" />
                                        </ListItemButton>
                                      </ListItem>
                                    </>
                                  )}
                                <ListItem disablePadding>
                                  <ListItemButton
                                    onClick={() => {
                                      handleDownloadPdf(
                                        estatePdfListData[pdfKey][pdfIndex]
                                          ?.form_pdf
                                      );
                                      handleClose();
                                    }}
                                  >
                                    <ListItemText primary="Download" />
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </Popover>
                          }
                        />
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  <Grid xs={12} sx={{ m: 2 }}>
                    <DataNotFound />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>

          {/* Living Will/Health Care Proxy*/}

          {livingWillData && (
            <PreviewLivingWillDocument
              viewDetails
              livingWillData={livingWillData}
              open={showLivingWillDetails}
              handleClose={() => {
                setLivingWillData("");
                setShowLivingWillDetails(false);
              }}
              profileDetailData={profileDetailData}
            />
          )}
          <EstateHeading
            heading={"Living Will/Health Care Proxy"}
            onClick={() => {
              dispatch(clearManagmentData());
              dispatch(
                getManagmentOptions(
                  ESTATE_PLANNING_KEYS?.LIVING_WILL_HEALTH_CARE_PROXY
                )
              );
              dispatch(clearEstateDetails());
              navigate(
                `/profile/estate/living-will-health-care-proxy/add/${userId}`
              );
            }}
          />

          <Card
            sx={{
              color: color.main,
              background: "transparent",
              mt: 2,
            }}
          >
            <Grid container spacing={2}>
              {estatePdfListData?.living_will_health_care_proxy &&
              estatePdfListData?.living_will_health_care_proxy?.length >= 1 ? (
                <>
                  {estatePdfListData?.living_will_health_care_proxy?.map(
                    (data, index) => {
                      return (
                        <EstateDetailCard
                          setShowDetails={setShowLivingWillDetails}
                          setDetailsData={setLivingWillData}
                          onDeleteClick={(data) => {
                            setPdfId(data);
                            setPdfKey("living_will_health_care_proxy");
                            setActionType("delete");
                            setAcceptDialog(true);
                          }}
                          onEditClick={(data) =>
                            navigate(
                              `/profile/estate/living-will-health-care-proxy/edit/${userId}/${data?.userMapperId}`
                            )
                          }
                          data={data}
                          index={index}
                          key={index}
                          popOverClick={(event) => {
                            setPdfId(data);
                            setPdfIndex(index);
                            setPdfKey("living_will_health_care_proxy");
                            setAnchorTESTEl(event.currentTarget);
                          }}
                          popover={
                            <Popover
                              id={data?.id}
                              open={openTEST}
                              anchorEl={anchorTESTEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <List>
                                {pdfKey &&
                                  estatePdfListData[pdfKey][pdfIndex]
                                    ?.status === "review" && (
                                    <>
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            setActionType("accept");
                                            setAcceptDialog(true);
                                            setAnchorHCDEl(null);
                                            handleClose();
                                          }}
                                        >
                                          <ListItemText primary="Accept" />
                                        </ListItemButton>
                                      </ListItem>
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            setActionType("reject");
                                            setAcceptDialog(true);
                                            setAnchorHCDEl(null);
                                            handleClose();
                                          }}
                                        >
                                          <ListItemText primary="Reject" />
                                        </ListItemButton>
                                      </ListItem>
                                    </>
                                  )}
                                <ListItem disablePadding>
                                  <ListItemButton
                                    onClick={() => {
                                      handleDownloadPdf(
                                        estatePdfListData[pdfKey][pdfIndex]
                                          ?.form_pdf
                                      );
                                      handleClose();
                                    }}
                                  >
                                    <ListItemText primary="Download" />
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </Popover>
                          }
                        />
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  <Grid xs={12} sx={{ m: 2 }}>
                    <DataNotFound />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
        </Box>
      </>

      <Spinner open={isLoading} />
    </>
  );
};

export default EstateDetail;
