import React, { useState } from "react";
import { Dialog, Typography, DialogTitle, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import SVDivider from "components/SVDivider";
import { useDispatch } from "react-redux";
import SVButton from "components/SVButton";
import AdminInput from "components/adminInput";
import SVOutlinedButton from "components/SVOutlinedButton";
import { addRole } from "store/slice/estate";
// import "./index.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddRoleDialog = ({ ...props }) => {
  const { openDialog, setOpenDialog } = props;
  const [roleName, setRoleName] = useState("");
  const dispatch = useDispatch();

  const onClose = () => {
    setOpenDialog(false);
    setRoleName("");
  };
  const handleAddRole = () => {
    let request = {
      name: roleName,
      permissions: [],
      type: "",
    };
    dispatch(addRole(request, onClose));
  };

  return (
    <BootstrapDialog
      fullWidth
      scroll="paper"
      open={openDialog}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">Add Role</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider />
      <Grid container spacing={1} p={3}>
        <Grid item xs={12}>
          <AdminInput
            title="Role Name"
            sx={{
              "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "black",
                },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "black",
                },
            }}
            type="text"
            value={roleName}
            onChange={(val) => {
              setRoleName(val.target.value);
            }}
          />
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          sx={{ mt: 2 }}
        >
          <SVButton text="Add" sx={{ height: 40 }} onClick={handleAddRole} />

          <SVOutlinedButton
            text="Cancel"
            sx={{ height: 40, ml: 1 }}
            onClick={onClose}
          />
        </Grid>
      </Grid>
    </BootstrapDialog>
  );
};

export default React.memo(AddRoleDialog);
