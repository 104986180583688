import { Box } from "@mui/material";
import AdminButton from "components/adminButton";
import React from "react";
import SignatureCanvas from "react-signature-canvas";

export default function SVSignaturepad({ customRef }) {
  return (
    <Box
      sx={{
        width: "fit-content",
        background: "white",
        display: "flex",
        justifyContent: "center",
        px: 3,
        pt:2,
        borderRadius: 1,
        mt: 2,
      }}
    >
      <Box display={"grid"} alignItems={"center"}>
        <SignatureCanvas
          backgroundColor="white"
          ref={customRef}
          penColor="black"
          canvasProps={{
            width: "300%",
            height: 200,
            className: "signature-canvas",
          }}
        />

        <AdminButton
          fullWidth
          title="Clear Signature"
          onClick={() => {
            customRef.current.clear();
          }}
        />
      </Box>
    </Box>
  );
}
