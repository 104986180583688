import {
  Dialog,
  // DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  DialogTitle,
  Grid,
  styled,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SVDivider from "components/SVDivider";
import "../../index.css";
import moment from "moment";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function TicketDetailDialog(props) {
  const { openDialog, closeEditDialog, ticketData } = props;
  console.log("ticketData", ticketData);
  return (
    <BootstrapDialog
      fullWidth
      scroll="paper"
      open={openDialog}
      keepMounted
      onClose={closeEditDialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">{"Ticket Detail"}</Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={closeEditDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider />
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
          <Grid item xs={12} mt={1}>
              <Typography className="ticket-detail-title">
               Ticket Number:
              </Typography>
              <Typography className="ticket-detail-info">
                {ticketData?.ticket_number}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography className="ticket-detail-title">
                Issued Raised By :
              </Typography>
              <Typography className="ticket-detail-info">
                {ticketData?.userFirstName} {ticketData?.userLastName}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography className="ticket-detail-title">
                Category Name :
              </Typography>
              <Typography className="ticket-detail-info">
                {ticketData?.category_name}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography className="ticket-detail-title">
                Sub Category Name :
              </Typography>
              <Typography className="ticket-detail-info">
                {ticketData?.sub_category_name}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography className="ticket-detail-title">
              Message :
              </Typography>
              <Typography className="ticket-detail-info">
                {ticketData?.message}
              </Typography>
            </Grid>

            <Grid item xs={12} mt={1}>
              <Typography className="ticket-detail-title">
              Ticket Raised On :
              </Typography>
              <Typography className="ticket-detail-info">
              {moment(ticketData?.timestamp).format("MMMM Do, YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </BootstrapDialog>
  );
}
