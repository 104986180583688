import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { color } from "../../theme/color";

const SVButton = ({ ...props }) => {
  const {
    fullWidth,
    textTransform,
    border,
    text,
    onClick,
    sx,
    height,
    disabled,
    textColor,
    hoverTextColor,
    hoverColor,
    backgroundColor,
    isLoading,
  } = props;
  return (
    <Button
      fullWidth={fullWidth}
      disabled={disabled}
      sx={{
        minWidth: 80,
        fontSize: 13,
        textTransform: textTransform ? textTransform : "none",
        color: textColor ? textColor : "white",
        backgroundColor: backgroundColor ? backgroundColor : color.buttonColor,
        border: border,
        height: height ? height : 45,
        ...sx,
        ":hover": {
          color: hoverTextColor ? hoverTextColor : "white",
          backgroundColor: hoverColor ? hoverColor : color.buttonHoverColor,
        },
        ":disabled": {
          color: "black",
          backgroundColor: "#bdbdbda6",
        },
      }}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress size={15} sx={{ mr: 1 }} /> : text}
    </Button>
  );
};
export default React.memo(SVButton);
