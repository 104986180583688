import { Grid, Typography } from "@mui/material";
import SVButton from "components/SVButton";
import { useEffect, useState } from "react";
import AddTeam from "./subComponent/addTeam";
import MUIDataTable from "mui-datatables";
import { clearListOfNotesApi, deleteTeamMember, getCustomerList, listOfNotesApi } from "store/slice/profile";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "components/alertDialog/alertDialog";
import { Icon } from "@iconify/react";
import Spinner from "components/spinner";
import { PAGINATION, TEAM_MEMBER_ROLE } from "utils/appConstant";
import { color } from "theme/color";
import NoteDialog from "./subComponent/noteDialog";

export default function TeamManagment() {
  const dispatch = useDispatch();
  let page = PAGINATION.page;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const [pageCheck, setPageCheck] = useState(1);
  const [openAddTeamDialog, setOpenAddTeamDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState("");
  const [showNoteDialog, setShowNoteDialog] = useState(false);
  const [noteData, setNoteData] = useState({});
  const { customerListData, totalCount, isLoading } = useSelector(
    (state) => state.profile
  );
  useEffect(() => {
    fetchTeamList();
  }, []);
  //   fetch team list
  const fetchTeamList = (page, numberOfRows) => {
    var data = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      userTypeFilter: "team",
    };
    dispatch(getCustomerList(data, false, true));
  };
  //  mui data table options
  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },

    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchTeamList(page, "");
      } else {
        if (currentPage > 0) {
          page = currentPage - 1;
        }

        setPageCheck(currentPage);
        fetchTeamList(page, "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchTeamList(page, numberOfRows);
    },
  };
  //  mui data table column

  const columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },
    {
      name: "surename",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : "-"}</Typography>;
        },
      },
    },

    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? value : ""}</Typography>;
        },
      },
    },
    {
      name: "roleId",
      label: "Role",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>
              {value === TEAM_MEMBER_ROLE?.SUPERADMIN_ROLE
                ? "Super Admin"
                : "Assistant"}
            </Typography>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          let roleId =
            customerListData[
              customerListData?.findIndex((item) => item.id == value)
            ]?.roleId;
          if (roleId != TEAM_MEMBER_ROLE?.SUPERADMIN_ROLE) {
            return (
              <>
                <Icon
                  height={28}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(clearListOfNotesApi());
                    setNoteData({})
                    setTeamMemberId(value);
                    fetchNotesList(value);
                    setShowNoteDialog(true);
                  }}
                  icon="ph:note-bold"
                  color={color.buttonColor}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Icon
                  height={30}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTeamMemberId(value);
                    setOpenAlertDialog(true);
                  }}
                  icon="ic:baseline-delete"
                  color="red"
                />
              </>
            );
          }
        },
      },
    },
  ];

  //  remove team member

  const handleRemoveUser = () => {
    dispatch(deleteTeamMember(teamMemberId, setOpenAlertDialog));
  };

  // fetch notes list
  const fetchNotesList = (id) => {
    let request = {
      user_id: id,
      page: 0,
      perPage: 25,
      start_date: null,
      end_date: null,
    };
    dispatch(listOfNotesApi(request, true,true));
  };

  return (
    <>
      <>
        {teamMemberId && (
          <NoteDialog
            open={showNoteDialog}
            noteData={noteData}
            setNoteData={setNoteData}
            userId={teamMemberId}
            handleClose={() => {
              setShowNoteDialog(false);
            }}
          />
        )}
        <AlertDialog
          isLoading={isLoading}
          open={openAlertDialog}
          handleClose={() => setOpenAlertDialog(false)}
          title={"Remove Confirmation!"}
          text={"Are you sure you want to remove this team member?"}
          handleAgree={handleRemoveUser}
        />
        <AddTeam
          openDialog={openAddTeamDialog}
          setOpenDialog={setOpenAddTeamDialog}
        />
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 500,
              color: "white",
              marginTop: "0.5rem",
            }}
          >
            {"Team"}
          </Typography>
          <SVButton
            text={"Add Team"}
            onClick={() => setOpenAddTeamDialog(true)}
          />
        </Grid>
        {customerListData && customerListData?.length > 0 ? (
          <Grid container>
            <Grid xs={12}>
              <MUIDataTable
                data={customerListData}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              sx={{
                border: "1px solid #202020",
                mt: 2,
                height: 300,
                backgroundColor: "#202020",
              }}
            >
              <Grid
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 500,
                    mt: "7.5rem",
                    color: "white",
                  }}
                >
                  No Record Found
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </>

      <Spinner open={isLoading} />
    </>
  );
}
