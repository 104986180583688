import { useEffect, useState } from "react";
import { useParams } from "react-router";
// import InsuranceSelect from './insuranceSelect';
import { useDispatch, useSelector } from "react-redux";
import { INSURANCET_TYPES } from "utils/appConstant";
import { validateEmpty } from "utils/constantFun";
import Spinner from "components/spinner";
import {
  Box,
  Breadcrumbs,
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import SVButton from "components/SVButton";
import AdminInput from "components/adminInput";
import { color } from "theme/color";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { IncludeInPlanSelect, InsuranceSelect } from "./insuranceSelect";
import { profileDetail } from "store/slice/profile";
import {
  createInsurance,
  getCashValueApproaches,
  getDeathBenefitsList,
  getDistributionsList,
  getInsuranceDetails,
  updateInsurance,
} from "store/slice/assets";

export default function WholeLifeInsurance() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { insuranceId } = useParams();
  const { profileDetailData } = useSelector((state) => state.profile);
  const {
    insuranceDetails,
    cashValueApproachesData,
    distributionsData,
    deathBenefitOptionsData,
  } = useSelector((state) => state.assets);
  console.log(
    "ddd",
    cashValueApproachesData,
    distributionsData,
    deathBenefitOptionsData
  );
  const [isLoading, setIsloading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
    var requestParams = {
      id: id,
    };
    dispatch(profileDetail(requestParams, true));
    dispatch(getCashValueApproaches());
    dispatch(getDistributionsList());
    dispatch(getDeathBenefitsList());
  }, []);
  const [wholeLifeData, setWholeLifeData] = useState({});

  //  get insurance details
  useEffect(() => {
    if (insuranceId) {
      setIsloading(true);
      dispatch(getInsuranceDetails(insuranceId, setIsloading));
    }
  }, [insuranceId]);
  useEffect(() => {
    if (insuranceDetails) {
      setWholeLifeData(insuranceDetails);
    }
  }, [insuranceDetails]);
  const handleSubmit = () => {
    if (validateEmpty(wholeLifeData?.policy_name)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        policy_nameErr: "Please enter policy name",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.annual_premium)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        annual_premiumErr: "Please enter annual premium",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.premium_ends_id)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        premium_ends_idErr: "Please select premium ends",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.death_benefit)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        death_benefitErr: "Please enter death benefit",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.cash_value)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        cash_valueErr: "Please enter cash value",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.crediting_rate)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        crediting_rateErr: "Please enter crediting rate",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.loan_rate)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        loan_rateErr: "Please enter loan rate",
      }));
      return;
    }
    if (wholeLifeData?.included_in_plan == undefined) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        included_in_planErr: "Please select included in plan",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.beneficiary_percentage)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        beneficiary_percentageErr: "Please enter beneficiary percentage ",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.cash_value_approach_id)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        cash_value_approach_idErr: "Please select cash value approach",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.annual_fee_percentage)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        annual_fee_percentageErr: "Please enter annual fee",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.distribution_id)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        distribution_idErr: "Please select distribution",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.amount)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        amountErr: "Please enter amount",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.starting_year_period_id)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        starting_year_period_idErr: "Please select starting year ",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.starting_year)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        starting_yearErr: "Please enter starting year ",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.ending_year_period_id)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        ending_year_period_idErr: "Please select ending year ",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.ending_year)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        ending_yearErr: "Please enter ending year ",
      }));
      return;
    }
    if (validateEmpty(wholeLifeData?.death_benefit_id)) {
      setWholeLifeData((wholeLifeData) => ({
        ...wholeLifeData,
        death_benefit_idErr: "Please select death benefit options ",
      }));
      return;
    }
    setIsloading(true);
    let request = {
      user_id: id,
      policy_name: wholeLifeData?.policy_name,
      owner: profileDetailData?.first_name,
      annual_premium: wholeLifeData?.annual_premium,
      type_id: INSURANCET_TYPES?.WHOLE_LIFE,
      premium_ends_id: wholeLifeData?.premium_ends_id,
      death_benefit: wholeLifeData?.death_benefit,
      included_in_plan: wholeLifeData?.included_in_plan,
      beneficiary_percentage: wholeLifeData?.beneficiary_percentage,
      cash_value: wholeLifeData?.cash_value,
      annual_fee_percentage: wholeLifeData?.annual_fee_percentage,
      crediting_rate: wholeLifeData?.crediting_rate,
      loan_rate: wholeLifeData?.loan_rate,
      cash_value_approach_id: wholeLifeData?.cash_value_approach_id,
      distribution_id: wholeLifeData?.distribution_id,
      amount: wholeLifeData?.amount,
      starting_year_period_id: wholeLifeData?.starting_year_period_id,
      starting_year: wholeLifeData?.starting_year,
      ending_year_period_id: wholeLifeData?.ending_year_period_id,
      ending_year: wholeLifeData?.ending_year,
      death_benefit_id: wholeLifeData?.death_benefit_id,
    };
    if (insuranceId) {
      dispatch(
        updateInsurance(request, insuranceId, id, navigate, setIsloading)
      );
    } else {
      dispatch(createInsurance(request, id, navigate, setIsloading));
    }
  };
  return (
    <div>
      <Spinner open={isLoading} />
      <div>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Insurance"} />

        <Grid container mt={4} sx={{ px: isMobile ? 2 : "" }}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} to="/users">
                Users
              </Link>
              <Link style={{ color: "white" }} to={`/profile/${id}`}>
                User Profile
              </Link>
              <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
                Assets-Liabilities Details
              </Link>
              <Link
                style={{ color: "white" }}
                to={`/assetsDetail/insurance/${id}`}
              >
                Insurance
              </Link>
              <Typography sx={{ color: "white" }}>Whole-Life</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box
          sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 2 }}
        >
          <h2 className="textLinearBackground mt-3 ">Whole-Life</h2>
          <Grid container columnSpacing={3}>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Policy name"
                maxLength={30}
                onChange={(event) => {
                  setWholeLifeData((wholeLifeData) => ({
                    ...wholeLifeData,
                    policy_name: event.target.value,
                    policy_nameErr: "",
                  }));
                }}
                value={wholeLifeData?.policy_name || ""}
                formError={wholeLifeData?.policy_nameErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                name="occupation"
                title="Name"
                value={profileDetailData?.first_name || ""}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Annual premium"
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setWholeLifeData((wholeLifeData) => ({
                      ...wholeLifeData,
                      annual_premium: event.target.value * 1,
                      annual_premiumErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        annual_premium: "",
                      }));
                    }
                  }
                }}
                value={wholeLifeData?.annual_premium || ""}
                formError={wholeLifeData?.annual_premiumErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <InsuranceSelect
                title={" Premium ends"}
                onChange={(event) => {
                  setWholeLifeData((wholeLifeData) => ({
                    ...wholeLifeData,
                    premium_ends_id: event.target.value,
                    premium_ends_idErr: "",
                  }));
                }}
                value={wholeLifeData?.premium_ends_id || ""}
                error={wholeLifeData?.premium_ends_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                maxLength={13}
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Death benefit "
                value={wholeLifeData?.death_benefit || ""}
                formError={wholeLifeData?.death_benefitErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setWholeLifeData((wholeLifeData) => ({
                      ...wholeLifeData,
                      death_benefit: event.target.value * 1,
                      death_benefitErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        death_benefit: "",
                      }));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                maxLength={13}
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Cash value "
                value={wholeLifeData?.cash_value || ""}
                formError={wholeLifeData?.cash_valueErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setWholeLifeData((wholeLifeData) => ({
                      ...wholeLifeData,
                      cash_value: event.target.value * 1,
                      cash_valueErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        cash_value: "",
                      }));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Crediting rate"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={wholeLifeData?.crediting_rate || ""}
                formError={wholeLifeData?.crediting_rateErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        crediting_rate: 100,
                        crediting_rateErr: "",
                      }));
                    } else {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        crediting_rate: event.target.value * 1,
                        crediting_rateErr: "",
                      }));
                    }
                  } else {
                    setWholeLifeData((wholeLifeData) => ({
                      ...wholeLifeData,
                      crediting_rate: "",
                    }));
                  }
                }}
              />
            </Grid>{" "}
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Loan rate"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={wholeLifeData?.loan_rate || ""}
                formError={wholeLifeData?.loan_rateErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        loan_rate: 100,
                        loan_rateErr: "",
                      }));
                    } else {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        loan_rate: event.target.value * 1,
                        loan_rateErr: "",
                      }));
                    }
                  } else {
                    setWholeLifeData((wholeLifeData) => ({
                      ...wholeLifeData,
                      loan_rate: "",
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                autofocus={insuranceId ? true : false}
                onChange={(event) => {
                  setWholeLifeData((wholeLifeData) => ({
                    ...wholeLifeData,
                    included_in_plan: event.target.value,
                    included_in_planErr: "",
                  }));
                }}
                value={
                  wholeLifeData?.included_in_plan == true ||
                  wholeLifeData?.included_in_plan == false
                    ? wholeLifeData?.included_in_plan
                    : ""
                }
               
                error={wholeLifeData?.included_in_planErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Beneficiary"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={wholeLifeData?.beneficiary_percentage || ""}
                formError={wholeLifeData?.beneficiary_percentageErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        beneficiary_percentage: 100,
                        beneficiary_percentageErr: "",
                      }));
                    } else {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        beneficiary_percentage: event.target.value * 1,
                        beneficiary_percentageErr: "",
                      }));
                    }
                  } else {
                    setWholeLifeData((wholeLifeData) => ({
                      ...wholeLifeData,
                      beneficiary_percentage: "",
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Cash value approach"
                customOptions={cashValueApproachesData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setWholeLifeData((wholeLifeData) => ({
                    ...wholeLifeData,
                    cash_value_approach_id: event.target.value,
                    cash_value_approach_idErr: "",
                  }));
                }}
                value={wholeLifeData?.cash_value_approach_id || ""}
                error={wholeLifeData?.cash_value_approach_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Annual fee"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={wholeLifeData?.annual_fee_percentage || ""}
                formError={wholeLifeData?.annual_fee_percentageErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        annual_fee_percentage: 100,
                        annual_fee_percentageErr: "",
                      }));
                    } else {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        annual_fee_percentage: event.target.value * 1,
                        annual_fee_percentageErr: "",
                      }));
                    }
                  } else {
                    setWholeLifeData((wholeLifeData) => ({
                      ...wholeLifeData,
                      annual_fee_percentage: "",
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Distribution"
                customOptions={distributionsData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setWholeLifeData((wholeLifeData) => ({
                    ...wholeLifeData,
                    distribution_id: event.target.value,
                    distribution_idErr: "",
                  }));
                }}
                value={wholeLifeData?.distribution_id || ""}
                error={wholeLifeData?.distribution_idErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                maxLength={13}
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Amount"
                value={wholeLifeData?.amount || ""}
                formError={wholeLifeData?.amountErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setWholeLifeData((wholeLifeData) => ({
                      ...wholeLifeData,
                      amount: event.target.value * 1,
                      amountErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        amount: "",
                      }));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Grid container columnSpacing={3}>
                <Grid item xs={6}>
                  <InsuranceSelect
                    title={" Starting Year"}
                    onChange={(event) => {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        starting_year_period_id: event.target.value,
                        starting_year_period_idErr: "",
                      }));
                    }}
                    
                    value={wholeLifeData?.starting_year_period_id || ""}
                    error={wholeLifeData?.starting_year_period_idErr}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdminInput
                    color="white"
                    type="text"
                    maxLength={4}
                    title="Year's"
                    onChange={(event) => {
                      const numeric = /^[0-9\b]+$/;
                      if (
                        event.target.value &&
                        numeric.test(event.target.value)
                      ) {
                        setWholeLifeData((wholeLifeData) => ({
                          ...wholeLifeData,
                          starting_year: event.target.value * 1,
                          starting_yearErr: "",
                        }));
                      } else {
                        if (!event.target.value) {
                          setWholeLifeData((wholeLifeData) => ({
                            ...wholeLifeData,
                            starting_year: "",
                          }));
                        }
                      }
                    }}
                    value={wholeLifeData?.starting_year || ""}
                    formError={wholeLifeData?.starting_yearErr}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Grid container columnSpacing={3}>
                <Grid item xs={6}>
                  <InsuranceSelect
                    title="Ending Year"
                    onChange={(event) => {
                      setWholeLifeData((wholeLifeData) => ({
                        ...wholeLifeData,
                        ending_year_period_id: event.target.value,
                        ending_year_period_idErr: "",
                      }));
                    }}
                    value={wholeLifeData?.ending_year_period_id || ""}
                    error={wholeLifeData?.ending_year_period_idErr}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdminInput
                    color="white"
                    type="text"
                    maxLength={4}
                    title="Year's"
                    onChange={(event) => {
                      const numeric = /^[0-9\b]+$/;
                      if (
                        event.target.value &&
                        numeric.test(event.target.value)
                      ) {
                        setWholeLifeData((wholeLifeData) => ({
                          ...wholeLifeData,
                          ending_year: event.target.value * 1,
                          ending_yearErr: "",
                        }));
                      } else {
                        if (!event.target.value) {
                          setWholeLifeData((wholeLifeData) => ({
                            ...wholeLifeData,
                            ending_year: "",
                          }));
                        }
                      }
                    }}
                    value={wholeLifeData?.ending_year || ""}
                    formError={wholeLifeData?.ending_yearErr}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                title="Death benefit option"
                customOptions={deathBenefitOptionsData?.map((item, i) => {
                  if (item?.is_active) {
                    return (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  }
                })}
                onChange={(event) => {
                  setWholeLifeData((wholeLifeData) => ({
                    ...wholeLifeData,
                    death_benefit_id: event.target.value,
                    death_benefit_idErr: "",
                  }));
                }}
                value={wholeLifeData?.death_benefit_id || ""}
                error={wholeLifeData?.death_benefit_idErr}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={isMobile ? 12 : 3} mt={5}>
              <SVButton fullWidth text={"Submit"} onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
