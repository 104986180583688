import SVOutlinedButton from "components/SVOutlinedButton";
import {
  Dialog,
  // DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  DialogTitle,
  Grid,
  Box,
} from "@mui/material";
import SVDivider from "components/SVDivider";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SVButton from "components/SVButton";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSiteSetting } from "store/slice/settings";
import { SILVERBACK, STATUS_CODES } from "services/apiConstant";
import { api } from "services";
import { errorToast } from "components/showToast";
import Spinner from "components/spinner";
export default function UploadPdfDialog(props) {
  const {
    openDialog,
    handleUploadPdf,
    closeDialog,
    setUploadPdfdata,
    uploadPdfData,
    
  } = props;
  const uploadRef = useRef();
  const dispatch = useDispatch();
  const { siteSettingData } = useSelector((state) => state.settings);
  const [pdfName, setPdfName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getSiteSetting());
  }, []);
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const onChange = (event) => {
    setIsLoading(true);
    const formData = new FormData();
    let file = event.target.files[0];
    setPdfName(file);
    formData.append("file", file);
    formData.append("entityId", siteSettingData?.ENTITY_TYPE_DOB);
    formData.append("moduleId", siteSettingData?.MODULE_TYPE_USER);
    formData.append("typeId", siteSettingData?.DOCUMENT_TYPE_DOB);
    uploadMedia(formData);
  };

  const uploadMedia = (formDataRequest) => {
    api
      .post(SILVERBACK.uploadDocuments, formDataRequest,{
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          let imageData = response.data.data;
          setUploadPdfdata(imageData);
        } else {
          errorToast.error(response.error);
        }
        setIsLoading(false);
      })
      .catch(() => {});
  };
  const handleRemove = () => {
    setUploadPdfdata({});
  };
  return (
    <BootstrapDialog
      fullWidth
      scroll="paper"
      open={openDialog}
      keepMounted
      onClose={closeDialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">Upload Pdf</Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider />
      <DialogContent>
        <DialogContentText>
          <Grid
            container
            spacing={1}
            display={"flex"}
            justifyContent={"center"}
          >
            {uploadPdfData?.documentId ? (
              <Box
                sx={{
                  border: "1px solid black",
                  p: 3,
                  position: "relative",
                  mt: 2,
                }}
              >
                <Icon icon="vscode-icons:file-type-pdf2" width={80} />
                <Typography fontWeight={600} mt={2} color={"black"}>
                  {pdfName?.name}
                </Typography>

                <Icon
                  onClick={handleRemove}
                  icon={"carbon:close-filled"}
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    cursor: "pointer",
                  }}
                  color="black"
                  width={25}
                />
              </Box>
            ) : (
              <Box
                onClick={() => uploadRef.current.click()}
                sx={{
                  mt: 3,
                  border: "2px dashed grey",
                  height: 100,
                  width: "100%",
                  borderRadius: 1,
                  mx: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon icon="ion:cloud-upload-sharp" color="grey" width={70} />
              </Box>
            )}
            <input
              ref={uploadRef}
              onChange={onChange}
              type="file"
              style={{ display: "none" }}
              accept="application/pdf"
            />
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{ mt: 2 }}
            >
              <SVButton
                text={"Upload"}
                disabled={uploadPdfData?.documentId ? false : true}
                sx={{ height: 40, fontSize: 16 }}
                onClick={handleUploadPdf}
              />

              <SVOutlinedButton
                text="Cancel"
                sx={{ height: 40, ml: 1 }}
                onClick={closeDialog}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <Spinner open={isLoading} />
    </BootstrapDialog>
    
  );
}
