import React, { useEffect, useState } from "react";
import {
  Dialog,
  // DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  DialogTitle,
  Grid,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import SVDivider from "components/SVDivider";
// import { color } from "theme/color";
import SVOutlinedButton from "components/SVOutlinedButton";
import SVButton from "components/SVButton";
import { color } from "theme/color";
import { Icon } from "@iconify/react";
import { useTheme } from "@emotion/react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const index = ({ ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { openDialog, closeEditDialog, eKycDetailData, onSave, onReject } =
    props;

  const [iconToShow, setIconToShow] = useState("");

  useEffect(() => {
    if (eKycDetailData?.key === "address") {
      setIconToShow("mdi:address-marker-outline");
    } else if (eKycDetailData?.key === "phone") {
      setIconToShow("mdi:phone");
    } else if (eKycDetailData?.key === "email") {
      setIconToShow("iconamoon:email-light");
    } else {
      // Default icon or error handling if necessary
    }
  }),
    [eKycDetailData];

  return (
    <BootstrapDialog
      fullWidth
      scroll="paper"
      open={openDialog}
      keepMounted
      onClose={closeEditDialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">e-KYC Details</Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={closeEditDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider />
      <DialogContent>
        <DialogContentText>
          <Grid
            container
            spacing={1}
            display="flex"
            justifyContent={"space-between"}
            sx={{ mb: 1 }}
          >
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  {" "}
                  <Icon
                    icon="material-symbols:person-outline"
                    width={20}
                    color={color.black}
                  />
                </Grid>
                <Grid item>
                  {" "}
                  <Typography sx={{ fontWeight: 500, color: color.black }}>
                    Customer Name
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: 500, color: color.black }}>
                {eKycDetailData?.first_name} {eKycDetailData?.surename}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            display="flex"
            justifyContent={"space-between"}
            sx={{ mb: 1 }}
          >
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  {" "}
                  <Icon
                    icon="mingcute:document-line"
                    width={20}
                    color={color.black}
                  />
                </Grid>
                <Grid item>
                  {" "}
                  <Typography sx={{ fontWeight: 500, color: color.black }}>
                    e-KYC{" "}
                  </Typography>
                </Grid>
              </Grid>{" "}
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: 500, color: color.black }}>
                {eKycDetailData?.key}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            display="flex"
            justifyContent={"space-between"}
            sx={{ mb: 1 }}
          >
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  {" "}
                  <Icon icon={iconToShow} width={20} color={color.black} />
                </Grid>
                <Grid item>
                  {" "}
                  <Typography sx={{ fontWeight: 500, color: color.black }}>
                    Old {eKycDetailData?.key === "email" && <>Email</>}
                    {eKycDetailData?.key === "address" && <>Address</>}
                    {eKycDetailData?.key === "phone" && <>Phone</>}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: 500, color: color.black }}>
                {eKycDetailData?.oldValue?.address
                  ? eKycDetailData?.oldValue?.address
                  : eKycDetailData?.oldValue}
              </Typography>
            </Grid>
          </Grid>
          {eKycDetailData?.oldValue?.address && (
            <>
              <Grid
                container
                spacing={1}
                sx={{
                  p: 2,
                  border: "1px solid #C0C0C0",
                  borderRadius: 2,
                  mt: 2,
                  mb: 2,
                }}
              >
                {eKycDetailData?.oldValue?.postalCode && (
                  <>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      justifyContent={"space-between"}
                      sx={{ mb: 1 }}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            {" "}
                            <Icon
                              icon="material-symbols-light:post-outline"
                              width={20}
                              color={color.black}
                            />
                          </Grid>
                          <Grid item>
                            {" "}
                            <Typography
                              sx={{ fontWeight: 500, color: color.black }}
                            >
                              Postal Code
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ fontWeight: 500, color: color.black }}
                        >
                          {eKycDetailData?.oldValue?.postalCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {eKycDetailData?.oldValue?.locality && (
                  <>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      justifyContent={"space-between"}
                      sx={{ mb: 1 }}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            {" "}
                            <Icon
                              icon="game-icons:modern-city"
                              width={20}
                              color={color.black}
                            />
                          </Grid>
                          <Grid item>
                            {" "}
                            <Typography
                              sx={{ fontWeight: 500, color: color.black }}
                            >
                              City
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ fontWeight: 500, color: color.black }}
                        >
                          {eKycDetailData?.oldValue?.locality}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {eKycDetailData?.oldValue?.locality && (
                  <>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      justifyContent={"space-between"}
                      sx={{ mb: 1 }}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            {" "}
                            <Icon
                              icon="map:real-estate-agency"
                              width={20}
                              color={color.black}
                            />
                          </Grid>
                          <Grid item>
                            {" "}
                            <Typography
                              sx={{ fontWeight: 500, color: color.black }}
                            >
                              State
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ fontWeight: 500, color: color.black }}
                        >
                          {eKycDetailData?.oldValue?.state}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}

          <Grid
            container
            spacing={1}
            display="flex"
            justifyContent={"space-between"}
            sx={{ mb: 1 }}
          >
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  {" "}
                  <Icon icon={iconToShow} width={20} color={color.black} />
                </Grid>
                <Grid item>
                  {" "}
                  <Typography sx={{ fontWeight: 500, color: color.black }}>
                    New {eKycDetailData?.key === "email" && <>Email</>}
                    {eKycDetailData?.key === "address" && <>Address</>}
                    {eKycDetailData?.key === "phone" && <>Phone</>}{" "}
                  </Typography>
                </Grid>
              </Grid>{" "}
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: 500, color: color.black }}>
                {eKycDetailData?.newValue?.address
                  ? eKycDetailData?.newValue?.address
                  : eKycDetailData?.newValue}
              </Typography>
            </Grid>
          </Grid>

          {eKycDetailData?.newValue?.address && (
            <>
              <Grid
                container
                spacing={1}
                sx={{
                  p: 2,
                  border: "1px solid #C0C0C0",
                  borderRadius: 2,
                  mt: 2,
                  mb: 2,
                }}
              >
                {eKycDetailData?.newValue?.postalCode && (
                  <>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      justifyContent={"space-between"}
                      sx={{ mb: 1 }}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            {" "}
                            <Icon
                              icon="material-symbols-light:post-outline"
                              width={20}
                              color={color.black}
                            />
                          </Grid>
                          <Grid item>
                            {" "}
                            <Typography
                              sx={{ fontWeight: 500, color: color.black }}
                            >
                              Postal Code
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ fontWeight: 500, color: color.black }}
                        >
                          {eKycDetailData?.newValue?.postalCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {eKycDetailData?.newValue?.locality && (
                  <>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      justifyContent={"space-between"}
                      sx={{ mb: 1 }}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            {" "}
                            <Icon
                              icon="game-icons:modern-city"
                              width={20}
                              color={color.black}
                            />
                          </Grid>
                          <Grid item>
                            {" "}
                            <Typography
                              sx={{ fontWeight: 500, color: color.black }}
                            >
                              City
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ fontWeight: 500, color: color.black }}
                        >
                          {eKycDetailData?.newValue?.locality}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {eKycDetailData?.newValue?.locality && (
                  <>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      justifyContent={"space-between"}
                      sx={{ mb: 1 }}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            {" "}
                            <Icon
                              icon="map:real-estate-agency"
                              width={20}
                              color={color.black}
                            />
                          </Grid>
                          <Grid item>
                            {" "}
                            <Typography
                              sx={{ fontWeight: 500, color: color.black }}
                            >
                              State
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ fontWeight: 500, color: color.black }}
                        >
                          {eKycDetailData?.newValue?.state}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {eKycDetailData?.newValue?.documents && (
                  <>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      justifyContent={"space-between"}
                      sx={{ mb: 1 }}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            {" "}
                            <Icon
                              icon="map:real-estate-agency"
                              width={20}
                              color={color.black}
                            />
                          </Grid>
                          <Grid item>
                            {" "}
                            <Typography
                              sx={{ fontWeight: 500, color: color.black }}
                            >
                              Documents
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {eKycDetailData?.newValue?.documents.map((item) => {
                        return (
                          <Grid item xs={12} key={item}>
                            <img
                              width={isMobile ? 265 : 525}
                              height={300}
                              style={{ objectFit: "contain" }}
                              src={item}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}

          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            sx={{ mt: 3 }}
          >
            <SVButton text="Approve" sx={{ height: 40 }} onClick={onSave} />
            &nbsp;&nbsp;
            <SVOutlinedButton
              text="Reject"
              sx={{ height: 40, ml: 1 }}
              onClick={onReject}
            />
          </Grid>
        </DialogContentText>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default React.memo(index);
