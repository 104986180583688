import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Stack,
  Typography,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInput from "components/adminInput";
import SVSignaturepad from "../../SVSignaturepad";
import "../index.css";
import { ESTATE_PLANNING_KEYS } from "utils/appConstant";
import { getSiteSetting } from "store/slice/settings";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { onlyNumber, validEmail, validateEmpty } from "utils/constantFun";
import { errorToast } from "components/showToast";
import { useDispatch, useSelector } from "react-redux";
import AdminButton from "components/adminButton";
import { api } from "services";
import { SILVERBACK, STATUS_CODES } from "services/apiConstant";
import { profileDetail, stateList } from "store/slice/profile";
import {
  addEstateDocument,
  getEstateDetail,
  getManagmentRelationshipList,
  updateEstateDocument,
} from "store/slice/estate";
import Spinner from "components/spinner";
import SignatureImage from "../../signatureImage";
import { color } from "theme/color";
import GoogleAutoComplete from "components/googleAutoComplete";
import PreviewLastWillDocument from "./previewLastWillDocument";
export default function LastWillDocument() {
  const { id } = useParams();
  const { userMapperId } = useParams();
  const { actionType } = useParams();
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const witnessSign = useRef();
  const witnessTwoSign = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { stateListData, profileDetailData } = useSelector(
    (state) => state.profile
  );
  const { siteSettingData } = useSelector((state) => state.settings);

  const { estateDetailsData, managmentRelationshipList } = useSelector(
    (state) => state.estate
  );

  useEffect(() => {
    var requestParams = {
      id: id,
    };
    dispatch(stateList());
    dispatch(profileDetail(requestParams, true));
    dispatch(getSiteSetting());
    dispatch(getManagmentRelationshipList());
    // window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (actionType && actionType == "edit") {
      setIsloading(true);
      dispatch(getEstateDetail(userMapperId, setIsloading));
    }
  }, [actionType]);
  const [childerName, setChilderName] = useState([]);

  const [lastWillData, setLastWillData] = useState({
    agent_details: [],
    witness_details: [
      {
        document_id: null,
        name: null,
        email: "",
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
      {
        document_id: null,
        name: null,
        email: "",
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
    ],
  });
  // set details data
  useEffect(() => {
    if (estateDetailsData) {
      setLastWillData(JSON.parse(JSON.stringify(estateDetailsData)));
      setChilderName(estateDetailsData?.children_name);
    }
  }, [estateDetailsData]);

  // removed extra parameter
  useEffect(() => {
    if (
      lastWillData?.agent_details?.length > 0 &&
      lastWillData?.agent_details[0]?.relationship
    ) {
      lastWillData.agent_details[0].relationship_id =
        managmentRelationshipList[
          managmentRelationshipList?.findIndex(
            (item) => item.name == lastWillData.agent_details[0]["relationship"]
          )
        ]?.id;

      lastWillData?.agent_details.forEach((object) => {
        delete object["id"];
      });
      lastWillData?.agent_details?.forEach((object) => {
        delete object["relationship"];
      });
      lastWillData?.agent_details?.forEach((object) => {
        delete object["document"];
      });
    }
    if (lastWillData?.witness_details?.length > 0) {
      lastWillData?.witness_details?.forEach((object) => {
        delete object["id"];
      });
      lastWillData?.witness_details?.forEach((object) => {
        delete object["relationship"];
      });
      lastWillData?.witness_details?.forEach((object) => {
        delete object["document"];
      });
    }
  }, [lastWillData]);

  const navigate = useNavigate();
  const date = new Date();
  const dispatch = useDispatch();
  //   handle preview
  const handlePreview = () => {
    if (
      witnessSign?.current?.isEmpty() &&
      lastWillData.witness_details?.length > 0
    ) {
      let temp = [...lastWillData.witness_details];
      temp[0].document_id = null;
      temp[0].date = null;
    }
    if (
      witnessTwoSign?.current?.isEmpty() &&
      lastWillData.witness_details?.length > 1
    ) {
      let temp = [...lastWillData.witness_details];
      temp[1].document_id = null;
      temp[1].date = null;
    }

    if (validateEmpty(lastWillData?.marital_status)) {
      errorToast("Please select marital status");
      return;
    }

    if (lastWillData?.children_count == undefined) {
      errorToast("Please select your children count");
      return;
    }
    if (lastWillData?.children_count != 0) {
      let index = childerName?.findIndex((item) => item == "");

      if (index >= 0) {
        errorToast(`Please enter child name ${index + 1}`);
        return;
      }
    }
    if (validateEmpty(lastWillData?.executor_name_first)) {
      errorToast("Please enter first executor name ");
      return;
    }
    if (validateEmpty(lastWillData?.executor_name_second)) {
      errorToast("Please enter second executor name ");
      return;
    }
    if (validateEmpty(lastWillData?.executor_name_third)) {
      errorToast("Please enter third executor name ");
      return;
    }

    if (validateEmpty(lastWillData?.witness_details[0]?.name)) {
      errorToast("Please enter first witness name");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[0]?.email)) {
      errorToast("Please enter first witness email");
      return;
    }
    if (!validEmail(lastWillData?.witness_details[0]?.email)) {
      errorToast("Please enter valid email");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[0]?.address)) {
      errorToast("Please enter first witness address");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[0]?.city)) {
      errorToast("Please enter first witness city");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[0]?.zip_code)) {
      errorToast("Please enter first witness postal code");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[0]?.state)) {
      errorToast("Please select first witness state");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[1]?.email)) {
      errorToast("Please enter second witness email");
      return;
    }
    if (!validEmail(lastWillData?.witness_details[1]?.email)) {
      errorToast("Please enter valid email");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[1]?.address)) {
      errorToast("Please enter second witness address");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[1]?.city)) {
      errorToast("Please enter second witness city");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[1]?.zip_code)) {
      errorToast("Please enter second witness postal code");
      return;
    }
    if (validateEmpty(lastWillData?.witness_details[1]?.state)) {
      errorToast("Please select second witness state");
      return;
    }
    setShowPreview(true);
  };

  //   upload signature
  const handleUploadSignature = (document, key, addRequest) => {
    let request = {
      document: document,
      type_id: siteSettingData?.DOCUMENT_TYPE_LAST_WILL_AND_TESTAMENT,
      entityId: siteSettingData?.ENTITY_TYPE_ESTATE_PLANNING,
      moduleId: siteSettingData?.MODULE_TYPE_ESTATE_PLANNING,
    };
    try {
      api.post(SILVERBACK.uploadBase64, request).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          if (key === "witnessOne") {
            let temp = lastWillData?.witness_details;
            temp[0].document_id = response?.data?.data?.documentId;
            setLastWillData((lastWillData) => ({
              ...lastWillData,
              witness_details: temp,
              agentNameErr: "",
            }));
          }
          if (key === "witnessTwo") {
            let temp = lastWillData?.witness_details;
            temp[1].document_id = response?.data?.data?.documentId;
            setLastWillData((lastWillData) => ({
              ...lastWillData,
              witness_details: temp,
              agentNameErr: "",
            }));
          }
          if (addRequest) {
            handleSave(key, response?.data?.data?.documentId);
          }
        } else {
          errorToast(response.data.detail, "account");
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
  //   add form
  const handleSubmit = () => {
    setIsloading(true);
    if (witnessTwoSign.current.isEmpty() && witnessSign.current.isEmpty()) {
      handleSave();
    }
    if (!witnessSign?.current?.isEmpty()) {
      const data = witnessSign?.current?.toDataURL();
      const myArray = data.split(",");
      handleUploadSignature(
        myArray[1],
        "witnessOne",
        witnessTwoSign?.current?.isEmpty() ? true : ""
      );
    }
    if (!witnessTwoSign?.current?.isEmpty()) {
      const data = witnessTwoSign?.current?.toDataURL();
      const myArray = data.split(",");
      handleUploadSignature(myArray[1], "witnessTwo", true);
    }
  };
  // handle save
  const handleSave = (key, documentId) => {
    let temp = lastWillData?.witness_details;
    if (key === "witnessOne") {
      temp[0].document_id = documentId;
      temp[0].date = moment(date).format();
    }
    if (key === "witnessTwo") {
      temp[1].document_id = documentId;
      temp[1].date = moment(date).format();
    }
    let request = {
      type: ESTATE_PLANNING_KEYS?.LAST_WILL_AND_TESTAMENT,
      data: {
        children_name: childerName,
        executor_name_first: lastWillData?.executor_name_first,
        executor_name_second: lastWillData?.executor_name_second,
        executor_name_third: lastWillData?.executor_name_third,
        county: profileDetailData?.address,
        state: profileDetailData?.state,
        state_name:
          stateListData[
            stateListData?.findIndex(
              (item) => item.id == profileDetailData?.state
            )
          ]?.description,
        principal_surname: profileDetailData?.principal_surname,
        children_count: lastWillData?.children_count,
        principal_name: `${profileDetailData?.first_name} ${profileDetailData?.surename}`,
        principal_address: profileDetailData?.address,
        principal_dob: moment(profileDetailData?.dateOfBirth).format(
          "MM/DD/YYYY"
        ),
        financial_decisions: lastWillData?.financial_decisions,
        attorney_limitations: lastWillData?.attorney_limitations
          ? lastWillData?.attorney_limitations
          : "",
        medical_decisions: lastWillData?.medical_decisions,
        effective_period: lastWillData?.effective_period,
        principal_signature:
          profileDetailData?.signatureData?.length > 0
            ? profileDetailData?.signatureData[0]?.id.toString()
            : "",
        principal_signature_date: moment(date).format(),
        notary_date: null,
        anatomical_gifts: null,
        anatomical_gifts_yes_option: null,
        specific_organisations: null,
        marital_status: lastWillData?.marital_status,
        witness_details:
          key === "witnessOne" || key === "witnessTwo"
            ? temp
            : lastWillData?.witness_details,
        agent_details: lastWillData?.agent_details,
      },
      userMapperId: userMapperId ? userMapperId * 1 : null,
    };
    if (userMapperId) {
      dispatch(updateEstateDocument(id, request, setIsloading, navigate));
    } else {
      dispatch(addEstateDocument(id, request, setIsloading, navigate));
    }
  };
  const childernCountData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div>
      <Box sx={{ p: isMobile ? 3 : "" }}>
        <TopHeading title={"Last Will And Testament"} />
        <Grid container mt={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{ color: "white" }}
                to={`/profile/estateDetail/${id}`}
              >
                Estate-Planning
              </Link>
              <Typography sx={{ color: "white" }}>Add</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Box>
      <PreviewLastWillDocument
        open={showPreview}
        isLoading={isLoading}
        witnessSign={witnessSign}
        witnessTwoSign={witnessTwoSign}
        handleClose={() => setShowPreview(false)}
        profileDetailData={profileDetailData}
        childerName={childerName}
        lastWillData={lastWillData}
        handleSubmit={handleSubmit}
      />
      {/* loader */}
      <Spinner open={isLoading} />
      {/* Declaration */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 1 : 5,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Declaration</Typography>
        <Typography className="inforation-text">
          I &nbsp;
          <span style={{ color: color.buttonColor }}>
            {profileDetailData?.first_name} {profileDetailData?.surename}
          </span>
          , a resident of the state of{" "}
          <span style={{ color: color.buttonColor }}>
            {stateListData?.length > 0 &&
              stateListData[
                stateListData?.findIndex(
                  (item) => item.id == profileDetailData?.state
                )
              ]?.description}{" "}
          </span>
          and county of{" "}
          <span style={{ color: color.buttonColor }}>
            {profileDetailData?.address}
          </span>{" "}
          and being of sound mind and memory, do hereby make, publish, and
          declare this to be my last will and testament, thereby revoking and
          making null and void any and all other last will and testaments and/or
          codicils to last will and testaments heretofore made by me. All
          references herein to{" "}
          <span style={{ color: color.buttonColor }}>“this Will”</span> refer
          only to this last will and testament.
        </Typography>
      </Box>

      {/* Family */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Family</Typography>
        <Typography className="inforation-text">
          At the time of executing this Will, I am{" "}
        </Typography>
        <Stack>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <input
              className="form-check-input"
              type="radio"
              onChange={() => {
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  marital_status: "Married",
                }));
              }}
              style={{ width: 24, height: 24 }}
              checked={lastWillData?.marital_status == "Married" || ""}
            />
            &nbsp;&nbsp;&nbsp;
            <label className="radio-label-text">Married</label>
          </div>
        </Stack>
        <Stack>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <input
              className="form-check-input"
              type="radio"
              onChange={() => {
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  marital_status: "Unmarried",
                }));
              }}
              style={{ width: 24, height: 24 }}
              checked={lastWillData?.marital_status == "Unmarried" || ""}
            />
            &nbsp;&nbsp;&nbsp;
            <label className="radio-label-text">Unmarried</label>
          </div>
        </Stack>
        <Typography className="inforation-text" mt={2}>
          The names of my children are listed below. Unless otherwise
          specifically indicated in this Will, any provision for my children
          includes the below-named children as well as any child of mine
          hereafter born or adopted.
        </Typography>
        <Grid container>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="label-text" sx={{ mt: 2 }}>
              Children
            </Typography>
            <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Children</InputLabel> */}
              <Select
                onChange={(event) => {
                  setChilderName(Array(event.target.value * 1).fill(""));
                  setLastWillData((lastWillData) => ({
                    ...lastWillData,
                    children_count: event.target.value,
                  }));
                }}
                value={
                  lastWillData?.children_count ||
                  lastWillData?.children_count == 0
                    ? lastWillData?.children_count
                    : ""
                }
                sx={{
                  color: "white",
                  width: "100%",
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {childernCountData?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {childerName?.map((item, i) => {
          return (
            <Grid container key={i}>
              <Grid item xs={isMobile ? 12 : 4}>
                <AdminInput
                  color="white"
                  value={item}
                  type="text"
                  title={`Child ${i + 1}`}
                  onChange={(event) => {
                    let temp = [...childerName];
                    temp[i] = event.target.value;
                    setChilderName(temp);
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
      </Box>

      {/* Distribution */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Distribution</Typography>
        <Typography className="inforation-text">
          I give, devise and bequeath all of the rest, remainder and residue of
          my Estate, whether real or personal property of whatsoever kind or
          character and wherever situated, to the Trustee(s) of{" "}
          <span style={{ color: color.buttonColor }}>
            {" "}
            {profileDetailData?.surename}
          </span>{" "}
          Family Trust, dated{" "}
          <span style={{ color: color.buttonColor }}>
            {moment(date).format("MMMM DD, YYYY")}{" "}
          </span>{" "}
          , as then written or thereafter amended, to be added to the principal
          of that Trust and to be held, administered and distributed under the
          Declaration of Trust and any amendments made to it. If at the time of
          my death the Trust is revoked or fails for any reason, I incorporate
          herein by reference the terms of the Trust that are in effect on the
          date of the Trust’s execution, and give the rest, remainder, and
          residue of my estate to the trustee named in said Trust, to be held,
          administered, and distributed in accordance with the terms of the
          Trust incorporated herein.
        </Typography>
      </Box>
      {/* Executor Nomination  */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Executor Nomination </Typography>
        <Typography className="inforation-text" sx={{ mb: -1.5 }}>
          I nominate
        </Typography>
        <Grid container>
          <Grid xs={isMobile ? 12 : 4}>
            <AdminInput
              title="Excecutor Name"
              color="white"
              onChange={(event) => {
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  executor_name_first: event.target.value,
                }));
              }}
              value={lastWillData?.executor_name_first || ""}
            />
          </Grid>
        </Grid>
        <Typography className="inforation-text" mt={-1}>
          to be the executor of this Will.
        </Typography>

        <Typography className="inforation-text" sx={{ mb: -1.5, mt: 1 }}>
          If, for any reason, my first nominee executor is unable or unwilling
          to serve or to continue to serve as executor of this Will,
        </Typography>
        <Grid container>
          <Grid xs={isMobile ? 12 : 4}>
            <AdminInput
              title="Excecutor Name"
              color="white"
              onChange={(event) => {
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  executor_name_second: event.target.value,
                }));
              }}
              value={lastWillData?.executor_name_second || ""}
            />
          </Grid>
        </Grid>
        <Typography className="inforation-text" mt={-1}>
          to be the executor of this Will.
        </Typography>

        <Typography className="inforation-text" sx={{ mb: -1.5, mt: 1 }}>
          If, for any reason, all of the nominees designated above are unable or
          unwilling to serve or to continue to serve as executor of this Will, I
          nominate
        </Typography>
        <Grid container>
          <Grid xs={isMobile ? 12 : 4}>
            <AdminInput
              title="Excecutor Name"
              color="white"
              onChange={(event) => {
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  executor_name_third: event.target.value,
                }));
              }}
              value={lastWillData?.executor_name_third || ""}
            />
          </Grid>
        </Grid>
        <Typography className="inforation-text" mt={-1}>
          to be the executor of this Will.
        </Typography>

        <Typography className="inforation-text" mt={2}>
          If none of the nominated executors are able and willing to serve or
          continue to serve, and the vacancy is not filled as set forth above,
          the majority of estate beneficiaries shall nominate a successor
          executor. If the majority of estate beneficiaries are unable to
          nominate a successor executor, the vacancy will be filled pursuant to
          a petition filed in a court of competent jurisdiction by the resigning
          executor or any person interested in the estate.
        </Typography>
      </Box>
      {/* Miscellaneous Executor Provisions */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">
          Miscellaneous Executor Provisions
        </Typography>
        <Typography className="inforation-text">
          The term <span style={{ color: color.buttonColor }}> “executor”</span>
          &nbsp; includes any executrix, personal representative, or
          administrator, if those terms are used in the statutes of any state
          that has jurisdiction over all or any portion of my estate.
        </Typography>
        <Typography className="inforation-text" my={2}>
          My executor will have broad and reasonable discretion in the
          administration of my estate to exercise all of the powers permitted to
          be exercised by an executor under state law, including the power to
          sell estate assets with or without notice, at either public or private
          sale, and to do everything he or she deems advisable and in the best
          interest of my estate and the beneficiaries thereof, all without the
          necessity of court approval or supervision. I direct that my executor
          perform all acts and exercise all such rights and privileges, although
          not specifically mentioned in this Will, with relation to any such
          property, as if the absolute owner thereof and, in connection
          therewith, to make, execute, and deliver any instruments, and to enter
          into any covenants or agreements binding my estate or any portion
          thereof.
        </Typography>
        <Typography className="inforation-text">
          If there are two co-executors serving, they shall act by unanimous
          agreement. If there are more than two co-executors serving, they shall
          act in accordance with the decision made by the majority of
          co-executors.
        </Typography>
        <Typography className="inforation-text" my={2}>
          Subject to specific provisions to the contrary, I authorize my
          executor to distribute a share of my estate given to a minor
          beneficiary, up to the whole thereof, to a custodian under the
          applicable Transfers to Minors Act or Gifts to Minors Act, if in the
          executor’s discretion, it is in the best interests of the beneficiary.
          The executor may also make distributions to a minor by making
          distributions to the guardian of the minor’s person, or the guardian
          of the minor’s estate.
        </Typography>{" "}
        <Typography className="inforation-text">
          No person named as an executor is required to post any bond.
        </Typography>{" "}
        <Typography className="inforation-text">
          I authorize my executor to make the following choices or elections in
          my executor’s absolute discretion, regardless of the resulting effect
          on any other provisions of this Will or on any person interested in my
          estate or in the amount of any of the taxes referred to:
        </Typography>
        <Typography className="inforation-text">
          (a) choose a valuation date for estate or inheritance tax purposes or
          choose the methods to pay estate or inheritance taxes;
        </Typography>{" "}
        <Typography className="inforation-text">
          (b) elect to treat or use an item, for either federal or state tax
          purposes, as either an income tax deduction or as a deduction for
          estate or inheritance tax purposes;
        </Typography>{" "}
        <Typography className="inforation-text">
          (c) determine when a particular item is to be treated as taken into
          income or used as a tax deduction, to the extent the law provides that
          choice; and
        </Typography>
        <Typography className="inforation-text"></Typography>
        <Typography className="inforation-text">
          (d) disclaim all or any portion of any interest in property passing to
          my estate at or after my death, even though any of these actions may
          subject my estate to additional tax liabilities. No person adversely
          affected by my executor’s exercise of discretion under this clause is
          entitled to any reimbursement or adjustment, and my executor is not
          required to make any adjustment between income and principal or in the
          amount of any property passing under this Will as a result of any
          election under this provision.
        </Typography>{" "}
        <Typography className="inforation-text" my={2}>
          I authorize my executor, without obtaining court approval, to employ
          professional investment counsel on such terms as my executor considers
          proper, and to pay the fees of investment counsel as an expense of
          administration of my estate. However, my executor is under no
          obligation to employ any investment counsel.
        </Typography>
        <Typography className="inforation-text">
          I authorize my executor either to continue the operation of any
          business belonging to my estate for such time and in such manner as my
          executor may consider advisable and in the best interest of my estate,
          or to sell or liquidate the business at such time and on such terms as
          my executor may consider advisable and in the best interest of my
          estate. Any such good faith operation, sale, or liquidation by my
          executor will be at the risk of my estate and without liability on the
          part of my executor for any losses that may result.
        </Typography>
      </Box>
      {/* Debt */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Debt</Typography>
        <Typography className="inforation-text">
          I direct that as soon as is practical after my death, the executor
          named pursuant to this Will review all of my just debts and
          obligations, including last illness and funeral expenses, except for
          those secured long-term debts that may be assumed by the beneficiary
          of such property, unless such assumption is prohibited by law or on
          agreement by the beneficiary. The executor is further directed to pay
          any attorneys’ fees and any other estate administration expenses. The
          executor shall pay these just debts only after a creditor provides
          timely and sufficient evidence to support its claim and in accordance
          with applicable state law.
        </Typography>
        <Typography className="inforation-text" my={2}>
          I direct that any estate, inheritance, and succession taxes, including
          any interest and penalties thereon, imposed by the federal government
          or any state, district, or territory, attributable to assets
          includible in my estate, passing either under or outside of this Will,
          be apportioned among the persons interested in my estate in accordance
          with applicable state and federal law. My executor is authorized and
          directed to seek reimbursement from the beneficiaries of my estate of
          any taxes paid by my executor to the extent allowed by law.
        </Typography>
        <Typography className="inforation-text">
          If there are two co-executors serving, they shall act by unanimous
          agreement. If there are more than two co-executors serving, they shall
          act in accordance with the decision made by the majority of
          co-executors.
        </Typography>
        <Typography className="inforation-text" my={2}>
          If my executor cannot collect from any person interested in the estate
          the amount of tax apportioned to that person, the amount not
          recoverable will be equitably apportioned among the other persons
          interested in the estate who are subject to apportionment.
        </Typography>{" "}
        <Typography className="inforation-text">
          If a person is charged with or required to pay tax in an amount
          greater than his or her prorated amount because another person does
          not pay his or her prorated amount, the person charged with or
          required to pay the greater amount has a right of reimbursement
          against the other person.
        </Typography>{" "}
        <Typography className="inforation-text">
          I further direct that if any beneficiary named in this Will is
          indebted to me at the time of my death, and evidence of such
          indebtedness is provided or made available to my executor, that share
          of my estate that I give to any and each such beneficiary be reduced
          in value by an amount equal to the proven indebtedness of such
          beneficiary unless:
        </Typography>
        <Typography className="inforation-text">
          (i) I have specifically provided in this Will for the forbearance of
          such debt, or
        </Typography>{" "}
        <Typography className="inforation-text">
          (ii) such beneficiary is the sole principal beneficiary.
        </Typography>{" "}
      </Box>

      {/* Simultaneous Death
       */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Simultaneous Death</Typography>
        <Typography className="inforation-text">
          If it cannot be established if a beneficiary of my estate survived me,
          the provisions of the applicable Uniform Simultaneous Death Act, as
          amended, or any substantially similar successor act effective on the
          date of my death, will apply.
        </Typography>
      </Box>
      {/* NonLiability Of Fiduciaries*/}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">
        Nonliability Of Fiduciaries
        </Typography>
        <Typography className="inforation-text">
          Any fiduciary, including my executor and any trustee, who in good
          faith endeavors to carry out the provisions of this Will, will not be
          liable to me, my estate, my heirs, or my beneficiaries for any damages
          or claims arising because of their actions or inaction, or the actions
          of any predecessor fiduciary acting pursuant to this Will. My estate
          will indemnify and hold them harmless.
        </Typography>
      </Box>
      {/* Saving Clause*/}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Saving Clause</Typography>
        <Typography className="inforation-text">
          If a court of competent jurisdiction at any time invalidates or finds
          unenforceable any provision of this Will, such invalidation will not
          invalidate the whole of this Will. All of the remaining provisions
          will be undisturbed as to their legal force and effect. If a court
          finds that an invalidated or unenforceable provision would become
          valid if it were limited, then such provision will be deemed to be
          written, deemed, construed, and enforced as so limited.
        </Typography>
        <Typography className="inforation-text" mt={1}>
          <span style={{ color: color.buttonColor }}>
            IN WITNESS WHEREOF ,{" "}
          </span>{" "}
          I, the undersigned testator, declare that I sign and execute this
          instrument on the date written below as my last will and testament and
          further declare that I sign it willingly, that I execute it as my free
          and voluntary act for the purposes expressed in this document, and
          that I am eighteen years of age or older, of sound mind and memory,
          and under no constraint or undue influence.
        </Typography>
      </Box>
      {/*Signature Of Principal */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Signature Of Principal</Typography>

        {profileDetailData?.signatureData?.length > 0 && (
          <SignatureImage
            url={profileDetailData?.signatureData[0]?.signature}
          />
        )}
      </Box>
      {/*Witness Declarations (Optional) */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Attestation Clause</Typography>
        <Typography className="inforation-text">
          This last will and testament, which has been separately signed by
          Bradley Richmond Everett, the testator, was on the date indicated
          below signed and declared by the above named testator as his or her
          last will and testament in the presence of each of us. We, in the
          presence of the testator and each other, at the testator’s request,
          under penalty of perjury, hereby subscribe our names as witnesses to
          the declaration and execution of the last will and testament by the
          testator, and we declare that, to the best of our knowledge, said
          testator is eighteen years of age or older, of sound mind and memory
          and under no constraint or undue influence.
        </Typography>
        <Grid container columnSpacing={2} mt={3.5}>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="label-text">First Witness</Typography>
            <AdminInput
              title="Name"
              color="white"
              onChange={(event) => {
                let temp = lastWillData?.witness_details;
                temp[0].name = event.target.value;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              value={lastWillData?.witness_details[0]?.name || ""}
            />
            <AdminInput
              title="Email"
              color="white"
              onChange={(event) => {
                let temp = lastWillData?.witness_details;
                temp[0].email = event.target.value;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              value={lastWillData?.witness_details[0]?.email || ""}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = lastWillData?.witness_details;
                temp[0].address = value;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = lastWillData?.witness_details;
                temp[0].address = "";
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              searchValue={lastWillData?.witness_details[0]?.address}
              handlePlaceSelected={(place) => {
                let temp = lastWillData?.witness_details;
                temp[0].address = place?.formatted_address;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
            />
            <AdminInput
              title="City"
              color="white"
              onChange={(event) => {
                let temp = lastWillData?.witness_details;
                temp[0].city = event.target.value;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              value={lastWillData?.witness_details[0]?.city || ""}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AdminInput
                  title="Postal Codes"
                  maxLength="5"
                  color="white"
                  onChange={(event) => {
                    if (event.target.value) {
                      if (onlyNumber(event.target.value)) {
                        let temp = lastWillData?.witness_details;
                        temp[0].zip_code = event.target.value;
                        setLastWillData((lastWillData) => ({
                          ...lastWillData,
                          witness_details: temp,
                        }));
                      }
                    } else {
                      let temp = lastWillData?.witness_details;
                      temp[0].zip_code = "";
                      setLastWillData((lastWillData) => ({
                        ...lastWillData,
                        witness_details: temp,
                      }));
                    }
                  }}
                  value={lastWillData?.witness_details[0]?.zip_code || ""}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    label="State"
                    onChange={(event) => {
                      let temp = lastWillData?.witness_details;
                      temp[0].state = event.target.value;
                      temp[0].state_name =
                        stateListData[
                          stateListData?.findIndex(
                            (item) => item.id == event.target.value
                          )
                        ]?.description;
                      setLastWillData((lastWillData) => ({
                        ...lastWillData,
                        witness_details: temp,
                      }));
                    }}
                    value={lastWillData?.witness_details[0]?.state || ""}
                    sx={{
                      color: "white",
                      width: "100%",
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "white",
                        },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {stateListData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SVSignaturepad customRef={witnessSign} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} mt={isMobile ? 5 : ""}>
            <Typography className="label-text">Second Witness</Typography>
            <AdminInput
              title="Name"
              color="white"
              onChange={(event) => {
                let temp = lastWillData?.witness_details;
                temp[1].name = event.target.value;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              value={lastWillData?.witness_details[1]?.name || ""}
            />
            <AdminInput
              title="Email"
              color="white"
              onChange={(event) => {
                let temp = lastWillData?.witness_details;
                temp[1].email = event.target.value;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              value={lastWillData?.witness_details[1]?.email || ""}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = lastWillData?.witness_details;
                temp[1].address = value;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = lastWillData?.witness_details;
                temp[1].address = "";
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              searchValue={lastWillData?.witness_details[1]?.address}
              handlePlaceSelected={(place) => {
                let temp = lastWillData?.witness_details;
                temp[1].address = place?.formatted_address;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
            />
            <AdminInput
              title="City"
              color="white"
              onChange={(event) => {
                let temp = lastWillData?.witness_details;
                temp[1].city = event.target.value;
                setLastWillData((lastWillData) => ({
                  ...lastWillData,
                  witness_details: temp,
                }));
              }}
              value={lastWillData?.witness_details[1]?.city || ""}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AdminInput
                  title="Postal Codes"
                  maxLength="5"
                  color="white"
                  onChange={(event) => {
                    if (event.target.value) {
                      if (onlyNumber(event.target.value)) {
                        let temp = lastWillData?.witness_details;
                        temp[1].zip_code = event.target.value;
                        setLastWillData((lastWillData) => ({
                          ...lastWillData,
                          witness_details: temp,
                        }));
                      }
                    } else {
                      let temp = lastWillData?.witness_details;
                      temp[1].zip_code = "";
                      setLastWillData((lastWillData) => ({
                        ...lastWillData,
                        witness_details: temp,
                      }));
                    }
                  }}
                  value={lastWillData?.witness_details[1]?.zip_code || ""}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    label="State"
                    onChange={(event) => {
                      let temp = lastWillData?.witness_details;
                      temp[1].state = event.target.value;
                      temp[1].state_name =
                        stateListData[
                          stateListData?.findIndex(
                            (item) => item.id == event.target.value
                          )
                        ]?.description;
                      setLastWillData((lastWillData) => ({
                        ...lastWillData,
                        witness_details: temp,
                      }));
                    }}
                    value={lastWillData?.witness_details[1]?.state || ""}
                    sx={{
                      color: "white",
                      width: "100%",
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "white",
                        },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {stateListData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SVSignaturepad customRef={witnessTwoSign} />
          </Grid>
        </Grid>
      </Box>

      <Grid container px={2}>
        <Grid item xs={isMobile ? 12 : 3}>
          <AdminButton fullWidth title="Preview" onClick={handlePreview} />
        </Grid>
      </Grid>
    </div>
  );
}
