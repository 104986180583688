import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { DialogTitle } from "@mui/material";
import "../index.css";
import { useDispatch, useSelector } from "react-redux";
import SVButton from "components/SVButton";
import SignatureImage from "../../signatureImage";
import Spinner from "components/spinner";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { getManagmentOptions } from "store/slice/estate";
import { ESTATE_PLANNING_KEYS } from "utils/appConstant";
export default function PreviewLivingWillDocument(props) {
  const {
    profileDetailData,
    open,
    livingWillData,
    viewDetails,
    witnessSign,
    witnessTwoSign,
    handleSubmit,
    handleClose,
    isLoading,
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getManagmentOptions(ESTATE_PLANNING_KEYS?.LIVING_WILL_HEALTH_CARE_PROXY));
  }, []);
  const { stateListData } = useSelector((state) => state.profile);

  const { managmentOptionsData, managmentRelationshipList } = useSelector(
    (state) => state.estate
  );
  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Living Will Health Care Proxy</h2>
        <Icon icon="ooui:close" height={30} onClick={handleClose} />
      </DialogTitle>
      <Spinner open={isLoading} />
      <DialogContent>
        {/* Declaration */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-inforation-text">
            I {profileDetailData?.first_name} {profileDetailData?.surename} ,
            being of sound mind and disposing mind and memory, do hereby make
            and declare this to be my health-care directive, thereby revoking
            and making null and void any and all other health care directives,
            living wills and health care powers of attorney previously made by
            me.
          </Typography>
        </Box>
        {/* Living Will */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">Living Will</Typography>
          <Typography className="preview-inforation-text">
            I willfully and voluntarily make known my desire to prolong my life
            as long as reasonably possible within the limits of generally
            accepted health-care standards.
          </Typography>
        </Box>
        {/* Anatomical Gifts */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Anatomical Gifts
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography className="field-text">Anatomical Gifts :</Typography>
            &nbsp; &nbsp; &nbsp;
            <Typography className="title-text">
              {livingWillData?.anatomical_gifts}
            </Typography>
          </Box>
          {livingWillData?.anatomical_gifts == "Yes" && (
            <>
              <Typography className="preview-inforation-text">
                I hereby authorize the making of anatomical gifts of the
                following parts of my body for the following purpose
              </Typography>
              <Typography className="title-text">
                {
                  managmentOptionsData[0]?.options[
                    managmentOptionsData[0]?.options?.findIndex(
                      (item) =>
                        item.value ===
                        livingWillData?.anatomical_gifts_yes_option
                    )
                  ]?.label
                }
              </Typography>
              {livingWillData?.anatomical_gifts_yes_option ===
                "living_will_health_care_proxy_anatomical_gifts_yes_option_a" && (
                <Typography className="title-text">
                  {livingWillData?.specific_organisations}
                </Typography>
              )}
            </>
          )}
        </Box>
        {/* Health Care Proxy */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Health Care Proxy
          </Typography>
          <Typography className="preview-inforation-text">
            I {profileDetailData?.first_name} {profileDetailData?.surename},
            hereby appoint the following individual as my health care agent
            (“agent”) to make any and all health care decisions for me, except
            to the extent I state otherwise:
          </Typography>
          <Typography className="preview-label-text" mt={2}>
            First Agent
          </Typography>
          {livingWillData?.agent_details?.length > 0 && (
            <Box mt={2}>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Name</Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details[0]?.name}
                </Typography>
              </Stack>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Relationship</Typography>
                &nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details?.length > 0 && (
                    <>
                      {viewDetails
                        ? livingWillData?.agent_details[0]?.relationship ==
                          "Other"
                          ? livingWillData?.agent_details[0]?.other_relationship
                          : livingWillData?.agent_details[0]?.relationship
                        : livingWillData?.agent_details[0]?.relationship_id == 9
                          ? `${
                              managmentRelationshipList[
                                managmentRelationshipList?.findIndex(
                                  (item) =>
                                    item.id ==
                                    livingWillData?.agent_details[0]
                                      ?.relationship_id
                                )
                              ]?.name
                            }(${livingWillData?.agent_details[0]?.other_relationship})`
                          : managmentRelationshipList[
                              managmentRelationshipList?.findIndex(
                                (item) =>
                                  item.id ==
                                  livingWillData?.agent_details[0]
                                    ?.relationship_id
                              )
                            ]?.name}
                    </>
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Address</Typography>
                &nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details[0]?.address}
                </Typography>
              </Stack>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Phone</Typography>
                &nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details[0]?.phone}
                </Typography>
              </Stack>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Email</Typography>
                &nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details[0]?.email}
                </Typography>
              </Stack>
            </Box>
          )}
          <Typography className="preview-inforation-text" mt={2}>
            This health care proxy shall take effect in the event I become
            unable to make my own health care decisions.
          </Typography>
          <Typography className="preview-inforation-text">
            I authorize my agent to direct the disposition of my remains.
          </Typography>{" "}
          <Typography className="preview-inforation-text">
            I authorize my agent to consent to an autopsy of my remainst
          </Typography>{" "}
          <Typography className="preview-inforation-text">
            I direct my agent to make health care decisions in accordance with
            my wishes and instructions as stated above or as otherwise known to
            him or her. I also direct my agent to abide by any limitations on
            his or her authority as stated above or as otherwise known to him or
            her.
          </Typography>
          <Typography className="preview-inforation-text">
            In the event the person I appoint above is unable, unwilling, or
            unavailable to act as my health care agent, I hereby appoint the
            following individual as my alternate health care agent (“agent”):
          </Typography>
          <Typography className="preview-label-text" mt={2}>
            Second Agent
          </Typography>
          {livingWillData?.agent_details?.length >= 1 && (
            <Box my={2}>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Name</Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details[1]?.name}
                </Typography>
              </Stack>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Relationship</Typography>
                &nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details?.length > 0 && (
                    <>
                      {viewDetails
                        ? livingWillData?.agent_details[1]?.relationship ==
                          "Other"
                          ? livingWillData?.agent_details[1]?.other_relationship
                          : livingWillData?.agent_details[1]?.relationship
                        : livingWillData?.agent_details[1]?.relationship_id == 9
                          ? `${
                              managmentRelationshipList[
                                managmentRelationshipList?.findIndex(
                                  (item) =>
                                    item.id ==
                                    livingWillData?.agent_details[1]
                                      ?.relationship_id
                                )
                              ]?.name
                            }(${livingWillData?.agent_details[1]?.other_relationship})`
                          : managmentRelationshipList[
                              managmentRelationshipList?.findIndex(
                                (item) =>
                                  item.id ==
                                  livingWillData?.agent_details[1]
                                    ?.relationship_id
                              )
                            ]?.name}
                    </>
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Address</Typography>
                &nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details[1]?.address}
                </Typography>
              </Stack>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Phone</Typography>
                &nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details[1]?.phone}
                </Typography>
              </Stack>
              <Stack direction={"row"} mt={1}>
                <Typography className="field-text">Email</Typography>
                &nbsp;&nbsp;
                <Typography className="field-text">:</Typography>
                &nbsp;&nbsp;
                <Typography className="title-text">
                  {livingWillData?.agent_details[1]?.email}
                </Typography>
              </Stack>
            </Box>
          )}
          <Typography className="preview-inforation-text">
            I understand that, unless I revoke it, this proxy will remain in
            effect indefinitely.
          </Typography>{" "}
        </Box>
        {/* General provisions */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            General provisions
          </Typography>
          <Typography className="preview-inforation-text">
            If any provision hereof is held to be invalid, such invalidity will
            not affect the other provisions of this document, and such other
            provisions will be given effect without the invalid provision.
          </Typography>
          <Typography className="preview-inforation-text">
            Pursuant to the Health Insurance Portability and Accountability Act
            of 1996 (“HIPAA”) and any similar state laws, and exclusively for
            the purpose of making a determination of my incapacitation or
            inability to direct my own health care decisions and obtaining a
            physician affidavit of such, I authorize any health care provider to
            disclose to the person named herein as my health care agent or
            alternate health care agent, as applicable, any pertinent
            individually identifiable health information sufficient to determine
            whether I am by reason of illness or mental or physical disability
            incapacitated or incapable of directing my own health care
            decisions. In exercising such authority, my health care agent shall
            constitute my “personal representative” as defined by HIPAA.
          </Typography>
          <Typography className="preview-inforation-text">
            On the determination of my incapacitation or incapability to direct
            my own health care decisions, I intend for the person named herein
            as my health care agent or alternate health care agent, as
            applicable, to be treated as my “personal representative” under
            HIPAA and any similar state laws, and as such to be treated
          </Typography>

          <Typography className="preview-inforation-text">
            as I would be with respect to my rights regarding the use and
            disclosure of my individually identifiable health information or
            other medical records.
          </Typography>
          <Typography className="preview-inforation-text">
            It is my intent that this document be legally binding and effective.
            If the law does not recognize the legal validity of this document,
            it is my intention that this document be taken as a formal
            declaration of my intentions concerning all of the above provisions.
            Copies of this document have the same effect as the original.
          </Typography>
          <Typography className="preview-inforation-text">
            All persons or entities that in good faith endeavor to carry out the
            provisions of this document shall not be liable to me, my estate, or
            my heirs, for any damages or claims arising because of their actions
            or inactions based on this document. My estate shall indemnify and
            hold them harmless.
          </Typography>
          <Typography className="preview-inforation-text">
            In Witness Whereof, I have executed this document on the date below:
          </Typography>
        </Box>

        {/*Signature Of Principal */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Signature Of Principal
          </Typography>

          {profileDetailData?.signatureData?.length > 0 && (
            <SignatureImage
              url={profileDetailData?.signatureData[0]?.signature}
            />
          )}
        </Box>
        {/* Attestation Clause */}

        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Attestation Clause
          </Typography>
          <Typography className="preview-inforation-text">
            This last will and testament, which has been separately signed by
            Bradley Richmond Everett, the testator, was on the date indicated
            below signed and declared by the above named testator as his or her
            last will and testament in the presence of each of us. We, in the
            presence of the testator and each other, at the testator’s request,
            under penalty of perjury, hereby subscribe our names as witnesses to
            the declaration and execution of the last will and testament by the
            testator, and we declare that, to the best of our knowledge, said
            testator is eighteen years of age or older, of sound mind and memory
            and under no constraint or undue influence.
          </Typography>
          <Box mt={2}>
            <Typography className="preview-label-text">
              First Witness
            </Typography>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Email Address</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {livingWillData?.witness_details[0]?.email}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Address</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {livingWillData?.witness_details[0]?.address}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">City</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {livingWillData?.witness_details[0]?.city}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Postal Code</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {livingWillData?.witness_details[0]?.zip_code}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">State</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {stateListData?.length > 0 &&
                  stateListData[
                    stateListData?.findIndex(
                      (item) =>
                        item?.id == livingWillData?.witness_details[0]?.state
                    )
                  ]?.description}
              </Typography>
            </Stack>
            {viewDetails
              ? livingWillData?.witness_details[0]?.document && (
                  <SignatureImage
                    url={livingWillData?.witness_details[0]?.document}
                  />
                )
              : !witnessSign?.current?.isEmpty() && (
                  <SignatureImage url={witnessSign?.current?.toDataURL()} />
                )}
          </Box>
          <Box mt={2}>
            <Typography className="preview-label-text">
              Second Witness
            </Typography>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Email Address</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {livingWillData?.witness_details[1]?.email}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Address</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {livingWillData?.witness_details[1]?.address}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">City</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {livingWillData?.witness_details[1]?.city}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">Postal Code</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {livingWillData?.witness_details[1]?.zip_code}
              </Typography>
            </Stack>
            <Stack direction={"row"} mt={1}>
              <Typography className="field-text">State</Typography>
              &nbsp;&nbsp;
              <Typography className="field-text">:</Typography>
              &nbsp;&nbsp;
              <Typography className="title-text">
                {stateListData?.length > 0 &&
                  stateListData[
                    stateListData?.findIndex(
                      (item) =>
                        item?.id == livingWillData?.witness_details[1]?.state
                    )
                  ]?.description}
              </Typography>
            </Stack>
            {viewDetails
              ? livingWillData?.witness_details[1]?.document && (
                  <SignatureImage
                    url={livingWillData?.witness_details[1]?.document}
                  />
                )
              : !witnessTwoSign?.current?.isEmpty() && (
                  <SignatureImage url={witnessTwoSign?.current?.toDataURL()} />
                )}
          </Box>
        </Box>

        {!viewDetails && (
          <Grid container mt={3} columnSpacing={5}>
            <Grid item xs={6}>
              <SVButton text="Edit" fullWidth onClick={handleClose} />
            </Grid>
            <Grid item xs={6}>
              <SVButton text="Submit" fullWidth onClick={handleSubmit} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
