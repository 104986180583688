import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInput from "components/adminInput";
import SVSignaturepad from "../../SVSignaturepad";
import "../index.css";
import GoogleAutoComplete from "components/googleAutoComplete";
import { ESTATE_PLANNING_KEYS } from "utils/appConstant";
import { getSiteSetting } from "store/slice/settings";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { onlyNumber, validEmail, validateEmpty } from "utils/constantFun";
import { errorToast } from "components/showToast";
import { useDispatch, useSelector } from "react-redux";
import AdminButton from "components/adminButton";
import { api } from "services";
import { SILVERBACK, STATUS_CODES } from "services/apiConstant";
import { profileDetail } from "store/slice/profile";
import {
  addEstateDocument,
  getEstateDetail,
  getManagmentOptions,
  getManagmentRelationshipList,
  updateEstateDocument,
} from "store/slice/estate";
import Spinner from "components/spinner";
import SignatureImage from "../../signatureImage";
import PreviewPowerOfAttorneyDocument from "./previewPowerOfAttorney";
export default function PowerOfAttorneyDocument() {
  const { id } = useParams();
  const { userMapperId } = useParams();
  const { actionType } = useParams();
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const agentSign = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const witnessSign = useRef();
  const { profileDetailData } = useSelector((state) => state.profile);
  const { siteSettingData } = useSelector((state) => state.settings);

  const { estateDetailsData, managmentRelationshipList, managmentOptionsData } =
    useSelector((state) => state.estate);

  useEffect(() => {
    var requestParams = {
      id: id,
    };
    dispatch(profileDetail(requestParams, true));
    dispatch(getManagmentOptions(ESTATE_PLANNING_KEYS?.POWER_OF_ATTORNEY));
    dispatch(getSiteSetting());
    dispatch(getManagmentRelationshipList());
    // window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (actionType && actionType == "edit") {
      console.log("actionType", actionType);
      setIsloading(true);
      dispatch(getEstateDetail(userMapperId, setIsloading));
    }
  }, [actionType]);

  const [POAData, setPOAData] = useState({
    agent_details: [
      {
        document_id: null,
        name: "",
        email: null,
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
    ],
    witness_details: [
      {
        document_id: null,
        name: null,
        email: "",
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
    ],
  });

  // set details data
  useEffect(() => {
    if (estateDetailsData && !POAData?.effective_period) {
      setPOAData(JSON.parse(JSON.stringify(estateDetailsData)));
    }
  }, [estateDetailsData]);
  // set value of effective period
  useEffect(() => {
    if (
      POAData?.effective_period !=
        "power_of_attorney_effective_period_option_b" &&
      POAData?.effective_period !=
        "power_of_attorney_effective_period_option_b" &&
      managmentOptionsData?.length > 0 &&
      estateDetailsData
    ) {
      let value =
        managmentOptionsData[
          managmentOptionsData?.findIndex(
            (item) => item?.value == "effective_period"
          )
        ]["options"][
          managmentOptionsData[
            managmentOptionsData?.findIndex(
              (item) => item.value == "effective_period"
            )
          ]["options"]?.findIndex(
            (item) => item.label == POAData?.effective_period
          )
        ]?.value;
      POAData.effective_period = value ? value : POAData.effective_period;
    }
  }, [POAData?.effective_period]);

  // set value of medicial  decision
  useEffect(() => {
    if (
      POAData?.medical_decisions !=
        "power_of_attorney_medical_decisions_option_a" &&
      POAData?.medical_decisions !=
        "power_of_attorney_medical_decisions_option_b" &&
      managmentOptionsData?.length > 0
    ) {
      POAData.medical_decisions =
        managmentOptionsData[
          managmentOptionsData?.findIndex(
            (item) => item?.value == "medical_decisions"
          )
        ]["options"][
          managmentOptionsData[
            managmentOptionsData?.findIndex(
              (item) => item.value == "medical_decisions"
            )
          ]["options"]?.findIndex(
            (item) => item.label == POAData?.medical_decisions
          )
        ]?.value;
    }
  }, [POAData?.medical_decisions]);
  // set value of financial  decision
  useEffect(() => {
    if (
      POAData?.financial_decisions !=
        "power_of_attorney_financial_decisions_option_a" &&
      POAData?.financial_decisions !=
        "power_of_attorney_financial_decisions_option_b" &&
      managmentOptionsData?.length > 0
    ) {
      POAData.financial_decisions =
        managmentOptionsData[
          managmentOptionsData?.findIndex(
            (item) => item?.value == "financial_decisions"
          )
        ]["options"][
          managmentOptionsData[
            managmentOptionsData?.findIndex(
              (item) => item.value == "financial_decisions"
            )
          ]["options"]?.findIndex(
            (item) => item.label == POAData?.financial_decisions
          )
        ]?.value;
    }
  }, [POAData?.financial_decisions]);

  // removed extra parameter
  useEffect(() => {
    if (
      POAData?.agent_details?.length > 0 &&
      POAData?.agent_details[0]?.relationship
    ) {
      POAData.agent_details[0].relationship_id =
        managmentRelationshipList[
          managmentRelationshipList?.findIndex(
            (item) => item.name == POAData.agent_details[0]["relationship"]
          )
        ]?.id;

      POAData?.agent_details.forEach((object) => {
        delete object["id"];
      });
      POAData?.agent_details?.forEach((object) => {
        delete object["relationship"];
      });
      POAData?.agent_details?.forEach((object) => {
        delete object["document"];
      });
    }
    if (POAData?.witness_details?.length > 0) {
      POAData?.witness_details?.forEach((object) => {
        delete object["id"];
      });
      POAData?.witness_details?.forEach((object) => {
        delete object["relationship"];
      });
      POAData?.witness_details?.forEach((object) => {
        delete object["document"];
      });
    }
  }, [POAData]);

  const navigate = useNavigate();
  const date = new Date();
  const dispatch = useDispatch();
  //   handle preview
  const handlePreview = () => {
    if (agentSign?.current?.isEmpty() && POAData.agent_details?.length > 0) {
      let temp = [...POAData.agent_details];
      temp[0].document_id = null;
      temp[0].date = null;
    }
    if (
      witnessSign?.current?.isEmpty() &&
      POAData.witness_details?.length > 1
    ) {
      let temp = [...POAData.witness_details];
      temp[0].document_id = null;
      temp[0].date = null;
    }
    if (validateEmpty(POAData?.agent_details[0]?.name)) {
      errorToast("Please enter  name of agent");
      return;
    }
    if (validateEmpty(POAData?.agent_details[0]?.address)) {
      errorToast("Please enter address");
      return;
    }
    if (validateEmpty(POAData?.agent_details[0]?.relationship_id)) {
      errorToast("Please select agent relationship");
      return;
    }
    if (POAData?.agent_details[0]?.relationship_id == 9) {
      if (validateEmpty(POAData?.agent_details[0]?.other_relationship)) {
        errorToast("Please enter other relation");
        return;
      }
    }
    if (validateEmpty(POAData?.financial_decisions)) {
      errorToast("Please select financial decisions");
      return;
    }
    if (
      POAData?.financial_decisions ===
        "power_of_attorney_financial_decisions_option_b" &&
      !POAData?.attorney_limitations
    ) {
      errorToast("Please enter financial decisions limitations");
      return;
    }
    if (validateEmpty(POAData?.medical_decisions)) {
      errorToast("Please select medical decisions");
      return;
    }
    if (validateEmpty(POAData?.effective_period)) {
      errorToast("Please select effective period");
      return;
    }

    if (validateEmpty(POAData?.agent_details[0]?.email)) {
      errorToast("Please enter agent email address");
      return;
    }
    if (!validEmail(POAData?.agent_details[0]?.email)) {
      errorToast("Please enter valid email");
      return;
    }
    if (validateEmpty(POAData?.witness_details[0]?.email)) {
      errorToast("Please enter witness email address");
      return;
    }
    if (!validEmail(POAData?.witness_details[0]?.email)) {
      errorToast("Please enter valid email");
      return;
    }
    setShowPreview(true);
  };

  //   upload signature
  const handleUploadSignature = (document, key, addRequest) => {
    let request = {
      document: document,
      type_id: siteSettingData?.DOCUMENT_TYPE_POWER_OF_ATTORNEY,
      entityId: siteSettingData?.ENTITY_TYPE_ESTATE_PLANNING,
      moduleId: siteSettingData?.MODULE_TYPE_ESTATE_PLANNING,
    };
    try {
      api.post(SILVERBACK.uploadBase64, request).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          if (key === "agent") {
            let temp = POAData?.agent_details;
            temp[0].document_id = response?.data?.data?.documentId;
            setPOAData((POAData) => ({
              ...POAData,
              agent_details: temp,
              agentNameErr: "",
            }));
          }
          if (key === "witness") {
            let temp = POAData?.witness_details;
            temp[0].document_id = response?.data?.data?.documentId;
            setPOAData((POAData) => ({
              ...POAData,
              witness_details: temp,
              agentNameErr: "",
            }));
          }
          if (addRequest) {
            handleSave(key, response?.data?.data?.documentId);
          }
        } else {
          errorToast(response.data.detail, "account");
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
  //   add form
  const handleSubmit = () => {
    setIsloading(true);
    if (agentSign.current.isEmpty() && witnessSign.current.isEmpty()) {
      handleSave();
    }
    if (!agentSign.current.isEmpty()) {
      const data = agentSign?.current?.toDataURL();
      const myArray = data.split(",");
      handleUploadSignature(
        myArray[1],
        "agent",
        witnessSign?.current.isEmpty() ? true : ""
      );
    }
    if (!witnessSign.current.isEmpty()) {
      const data = witnessSign?.current?.toDataURL();
      const myArray = data.split(",");
      handleUploadSignature(myArray[1], "witness", true);
    }
  };
  // handle save
  const handleSave = (key, documentId) => {
    let temp = POAData?.agent_details;
    let witnessTemp = POAData?.witness_details;
    if (key === "agent") {
      temp[0].document_id = documentId;
      temp[0].date = moment(date).format();
    }
    if (key === "witness") {
      witnessTemp[0].document_id = documentId;
      witnessTemp[0].date = moment(date).format();
    }
    let request = {
      type: ESTATE_PLANNING_KEYS?.POWER_OF_ATTORNEY,

      data: {
        principal_name: `${profileDetailData?.first_name} ${profileDetailData?.surename}`,
        principal_address: profileDetailData?.address,
        principal_dob: moment(profileDetailData?.dateOfBirth).format(
          "MM/DD/YYYY"
        ),
        financial_decisions: POAData?.financial_decisions,
        attorney_limitations: POAData?.attorney_limitations
          ? POAData?.attorney_limitations
          : "",
        medical_decisions: POAData?.medical_decisions,
        effective_period: POAData?.effective_period,
        principal_signature:
          profileDetailData?.signatureData?.length > 0
            ? profileDetailData?.signatureData[0]?.id.toString()
            : "",
        principal_signature_date: moment(date).format(),
        notary_date: null,
        county: null,
        anatomical_gifts: null,
        anatomical_gifts_yes_option: null,
        specific_organisations: null,
        witness_details:
          key === "agent" ? witnessTemp : POAData?.witness_details,
        agent_details: key === "agent" ? temp : POAData?.agent_details,
      },

      userMapperId: userMapperId ? userMapperId * 1 : null,
    };
    if (userMapperId) {
      dispatch(updateEstateDocument(id, request, setIsloading, navigate));
    } else {
      dispatch(addEstateDocument(id, request, setIsloading, navigate));
    }
  };
  return (
    <div>
      <Box sx={{ p: isMobile ? 3 : "" }}>
        <TopHeading title={"Power Of Attorney"} />

        <Grid container mt={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{ color: "white" }}
                to={`/profile/estateDetail/${id}`}
              >
                Estate-Planning
              </Link>
              <Typography sx={{ color: "white" }}>Add</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Box>
      <PreviewPowerOfAttorneyDocument
        open={showPreview}
        isLoading={isLoading}
        witnessSign={witnessSign}
        agentSign={agentSign}
        handleClose={() => setShowPreview(false)}
        profileDetailData={profileDetailData}
        POAData={POAData}
        handleSubmit={handleSubmit}
      />
      {/* loader */}
      <Spinner open={isLoading} />
      {/* Principal Information */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Principal Information</Typography>
        <Typography className="field-text" mt={1}>
          Name
        </Typography>
        <Typography className="title-text" color={"white"}>
          {" "}
          {profileDetailData?.first_name} {profileDetailData?.surename}
        </Typography>
        <Typography className="field-text" mt={1}>
          Address
        </Typography>
        <Typography className="title-text" color={"white"}>
          {" "}
          {profileDetailData?.address}
        </Typography>
        <Typography className="field-text" mt={1}>
          DOB
        </Typography>
        <Typography className="title-text" color={"white"}>
          {" "}
          {moment(profileDetailData?.dateOfBirth).format(`MM/DD/YYYY`)}
        </Typography>
      </Box>
      {/* Designation of agent */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Designation of agent</Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              color="white"
              title="Name"
              onChange={(event) => {
                const temp = [...POAData.agent_details];
                temp[0].name = event.target.value;
                setPOAData((POAData) => ({
                  ...POAData,
                  agent_details: temp,
                }));
              }}
              value={POAData?.agent_details[0]?.name}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6} sx={{ mt: 1 }}>
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = POAData?.agent_details;
                temp[0].address = value;
                setPOAData((POAData) => ({
                  ...POAData,
                  agent_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = POAData?.agent_details;
                temp[0].address = "";
                setPOAData((POAData) => ({
                  ...POAData,
                  agent_details: temp,
                }));
              }}
              searchValue={POAData?.agent_details[0]?.address}
              handlePlaceSelected={(place) => {
                let temp = POAData?.agent_details;
                temp[0].address = place?.formatted_address;
                setPOAData((POAData) => ({
                  ...POAData,
                  agent_details: temp,
                }));
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Relation</InputLabel>
              <Select
                label="Relations"
                autoFocus={
                  POAData?.agent_details[0]?.relationship_id ? true : false
                }
                onChange={(event) => {
                  let temp = POAData?.agent_details;
                  temp[0].relationship_id = event.target.value;
                  temp[0].other_relationship = "";
                  setPOAData((POAData) => ({
                    ...POAData,
                    agent_details: temp,
                    relationship_idErr: "",
                  }));
                }}
                value={POAData?.agent_details[0]?.relationship_id}
                sx={{
                  color: "white",
                  width: "100%",
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {managmentRelationshipList?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {POAData?.agent_details[0]?.relationship_id == 9 && (
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                color="white"
                title="Enter other relation type"
                onChange={(event) => {
                  const temp = [...POAData.agent_details];
                  temp[0].other_relationship = event.target.value;
                  setPOAData((POAData) => ({
                    ...POAData,
                    agent_details: temp,
                  }));
                }}
                value={POAData?.agent_details[0]?.other_relationship}
              />{" "}
            </Grid>
          )}
        </Grid>
      </Box>
      {/* Authority Granted */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Authority Granted</Typography>
        <Typography className="inforation-text">
          The Principal Grants The Following Authority To The Attorney-In-Fact.
          Select Either Option A Or B As Applicable
          <Grid container>
            {managmentOptionsData?.map((item, i) => {
              if (item.value === "financial_decisions") {
                return (
                  <Grid item xs={12} key={i}>
                    <Typography className="field-text" mt={3}>
                      {item?.name}
                    </Typography>

                    <Stack>
                      {item?.options?.map((label, index) => {
                        return (
                          <div
                            style={{ display: "flex", marginTop: "1rem" }}
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={() => {
                                setPOAData((POAData) => ({
                                  ...POAData,
                                  financial_decisions: label?.value,
                                }));
                              }}
                              style={{
                                width: 24,
                                height: 24,
                              }}
                              checked={
                                POAData?.financial_decisions === label?.value
                              }
                            />
                            &nbsp;&nbsp;&nbsp;
                            <label className="radio-label-text">
                              {label?.label}
                            </label>
                          </div>
                        );
                      })}
                    </Stack>
                  </Grid>
                );
              }
            })}

            {POAData?.financial_decisions ===
              "power_of_attorney_financial_decisions_option_b" && (
              <Grid container>
                <Grid item xs={isMobile ? 12 : 5}>
                  <AdminInput
                    type="text"
                    color="white"
                    title="Financial decisions limitation"
                    onChange={(event) => {
                      if (event.target.value) {
                        if (onlyNumber(event.target.value)) {
                          setPOAData((POAData) => ({
                            ...POAData,
                            attorney_limitations: event.target.value,
                          }));
                        }
                      } else {
                        setPOAData((POAData) => ({
                          ...POAData,
                          attorney_limitations: "",
                        }));
                      }
                    }}
                   
                    value={POAData?.attorney_limitations}
                  />
                </Grid>
              </Grid>
            )}

            {managmentOptionsData?.map((item, i) => {
              if (item.value === "medical_decisions") {
                return (
                  <Grid item xs={12} key={i}>
                    <Typography className="field-text" mt={3}>
                      {item?.name}
                    </Typography>

                    <Stack>
                      {item?.options?.map((label, index) => {
                        return (
                          <div
                            style={{ display: "flex", marginTop: "1rem" }}
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={() => {
                                setPOAData((POAData) => ({
                                  ...POAData,
                                  medical_decisions: label?.value,
                                }));
                              }}
                              style={{ width: 24, height: 24 }}
                              checked={
                                POAData?.medical_decisions === label?.value
                              }
                            />
                            &nbsp;&nbsp;&nbsp;
                            <label className="radio-label-text">
                              {label?.label}
                            </label>
                          </div>
                        );
                      })}
                    </Stack>
                  </Grid>
                );
              }
            })}
          </Grid>
        </Typography>
      </Box>
      {/* Effective Period */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Effective Period</Typography>
        <Grid container>
          {managmentOptionsData?.map((item, i) => {
            if (item.value === "effective_period") {
              return (
                <Grid item xs={12} key={i}>
                  <Stack>
                    {item?.options?.map((label, index) => {
                      return (
                        <div
                          key={index}
                          style={{ display: "flex", marginTop: "1rem" }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={() => {
                              setPOAData((POAData) => ({
                                ...POAData,
                                effective_period: label?.value,
                              }));
                            }}
                            style={{ width: 24, height: 24 }}
                            checked={POAData?.effective_period == label?.value}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <label className="radio-label-text">
                            {label?.label}
                          </label>
                        </div>
                      );
                    })}
                  </Stack>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
      {/* Third Party Reliance */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Third Party Reliance</Typography>
        <Typography className="inforation-text">
          All Third Parties May Rely Upon The Validity Of This Document And The
          Authority Granted Herein.
        </Typography>
      </Box>
      {/* Revocation */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Revocation</Typography>
        <Typography className="inforation-text">
          This Power Of Attorney Will Remain In Effect Until Revoked By The
          Principal. The Principal May Revoke This Document At Any Time By
          Providing Written Notice To The Attorney-In-Fact.
        </Typography>
      </Box>

      {/*Signature Of Principal */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Signature Of Principal</Typography>

        {profileDetailData?.signatureData?.length > 0 && (
          <SignatureImage
            url={profileDetailData?.signatureData[0]?.signature}
          />
        )}
      </Box>
      {/*Witness Declarations (Optional) */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Grid container columnSpacing={2} mt={0.5}>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="heading-text">Agent signature</Typography>
            <SVSignaturepad customRef={agentSign} />
            <Grid item xs={isMobile ? 12 :10}>
              <AdminInput
                title="Agent Email"
                color="white"
                onChange={(event) => {
                  let temp = POAData?.agent_details;
                  temp[0].email = event.target.value;
                  setPOAData((POAData) => ({
                    ...POAData,
                    agent_details: temp,
                  }));
                }}
                value={POAData?.agent_details[0]?.email || ""}
              />
            </Grid>
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="heading-text">Witness signature</Typography>
            <SVSignaturepad customRef={witnessSign} />
            <Grid item xs={isMobile ? 12 :10}>
              <AdminInput
                title="Witness Email"
                color="white"
                onChange={(event) => {
                  let temp = POAData?.witness_details;
                  temp[0].email = event.target.value;
                  setPOAData((POAData) => ({
                    ...POAData,
                    witness_details: temp,
                  }));
                }}
                value={POAData?.witness_details[0]?.email || ""}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container px={2}>
        <Grid item xs={isMobile ? 12 :3}>
          <AdminButton fullWidth title="Preview" onClick={handlePreview} />
        </Grid>
      </Grid>
    </div>
  );
}
