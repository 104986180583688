import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function TopHeading({ title, sx }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Typography
        sx={{
          ...sx,
          pl: isMobile ? 2 : "",
          fontSize: 24,
          fontWeight: 500,
          color: "white",
          marginTop: "0",
        }}
      >
        {title}
      </Typography>
    </>
  );
}
