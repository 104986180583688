import {
  Grid,
  Box,
  Typography,
  Card,
  CircularProgress,
  Stack,
} from "@mui/material";
import AdminButton from "components/adminButton";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserActivity } from "store/slice/profile";
import { color } from "theme/color";

function UserActivity() {
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { userActivityList, userActivityCount } = useSelector(
    (state) => state.profile
  );
  const [limit, setLimit] = useState(5);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  useEffect(() => {
    fetchActivityList();
  }, []);
  const fetchActivityList = (perPage) => {
    let request = {
      page: 0,
      perPage: perPage ? perPage : limit,
      userId: profileId,
    };
    dispatch(getUserActivity(request,setViewMoreLoading));
  };
  return (
    <>
      <Box sx={{ background: color.topbar, borderRadius: "10px", p: 2 }}>
        <Grid container spacing={1} sx={{ mt: -2 }}>
          <Grid item xs={12}>
            <Typography
              className="textLinearBackground"
              sx={{ fontSize: 22, pt: 3 }}
            >
              User Activity
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {userActivityList.length > 0 ? (
              <>
                {userActivityList?.map((item, index) => {
                  return (
                    <>
                      <Card
                        key={index}
                        sx={{
                          background: "black",
                          p: 3,
                          width: "100%",
                          mt: 1,
                        }}
                      >
                        <Grid container>
                          <Grid xs={11}>
                            <Stack>
                              <Typography
                                sx={{
                                  display: "inline",
                                  fontSize: 18,
                                  pb: 0.5,
                                }}
                                className="textLinearBackground"
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                sx={{
                                  display: "inline",
                                  fontSize: 16,
                                  color: "white",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.message}
                              </Typography>
                              <Typography
                                pt={1}
                                component="span"
                                variant="body2"
                                color="white"
                              >
                                {moment(item?.timestamp).format(
                                  "DD MMM, YYYY HH:MM A"
                                )}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Card>
                    </>
                  );
                })}
                {viewMoreLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 3,
                      width: "100%",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: "#1a90ff",
                      }}
                    />
                  </Box>
                ) : (
                  userActivityCount > limit && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <AdminButton
                        onClick={() => {
                          let perPage = limit + 50;
                          setLimit(perPage);
                          setViewMoreLoading(true);
                          fetchActivityList(perPage);
                        }}
                        title={"View More"}
                      />
                    </Box>
                  )
                )}
              </>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid #202020",
                    mt: 2,
                    height: 300,
                    backgroundColor: "#202020",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: 500,
                        mt: "7.5rem",
                        color: "white",
                      }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default UserActivity;
