import { Divider } from "@mui/material";
import React from "react";

const SVDivider = ({ ...props }) => {
    const { variant, style } = props;
    return (
        <Divider variant={variant} sx={{ bgcolor: (theme) => theme.palette.divider }}
            style={style ? style: {
                border: "none",
                height: 1,
                margin: 0,
            }} />
    )
}

export default React.memo(SVDivider)