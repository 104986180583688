import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
// sections

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <Box
      sx={{
        mx: "auto",
        my: "auto",
        position: "absolute",
        textAlign: "center",
        left: "50%",
        top: "45%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Iconify icon={"quill:user-sad"} width={50} height={50} />

      <Typography
        sx={{ fontWeight: "bold" }}
        component="h1"
        variant="h2"
      >
        404
      </Typography>
      <Typography
        component="h1"
        variant="h5"
      >
        Sorry, we were unable to find that page.
      </Typography>
    </Box>
  );
}
