import React from "react";
import {
  Dialog,
  // DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  DialogTitle,
  Tooltip,
  useMediaQuery,
  // InputLabel,
  // Select,
  // MenuItem,
  Card,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import AdminInput from "components/adminInput";
import SVDivider from "components/SVDivider";
import SVOutlinedButton from "components/SVOutlinedButton";
import SVButton from "components/SVButton";

import { CiEdit } from "react-icons/ci";
import PhoneInput from "react-phone-input-2";
import { SAVE_PROFILE_KEYS } from "utils/appConstant";
import "../../profileDetail/index.css";
import { Icon } from "@iconify/react";
import { errorToast } from "components/showToast";
import SVDocumentView from "components/SVDocumentView";
import SVDocumentAdd from "components/SVDocumentAdd";
import theme from "theme";
import SVSelect from "components/SVSelect";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditUpdateProfile = ({ ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const {
    openDialog,
    closeEditDialog,
    setFormError,
    formError,
    handlePhoneInput,
    editProfile,
    setEditProfile,
    phoneField,
    onSave,
    stateListData,
    profileImage,
    onDocumentChange,
    fileLimit,
    ssnDocumentsList,
    dobDocumentsList,
    inputRef,
    isLoading

  } = props;


  return (
    <BootstrapDialog
      fullWidth
      scroll="paper"
      open={openDialog}
      keepMounted
      onClose={closeEditDialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">Edit Profile</Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={closeEditDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <SVDivider />
      <DialogContent>
        <DialogContentText>


          <Grid container justifyContent="center" alignItems="center">
            {/*** First Name */}
            <Grid item xs={isMobile ? 12 : 7}>
              <AdminInput
                fullWidth={true}
                disabled={!editProfile.editFirstName}
                color={theme.palette.text.primary}
                title={"First Name"}
                value={editProfile?.firstName || ""}
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) {
                    setEditProfile((editProfile) => ({
                      ...editProfile,
                      firstName: e.target.value,
                    }));
                    setFormError((formError) => ({
                      ...formError,
                      firstName: "",
                    }));
                  } else {
                    setEditProfile((editProfile) => ({
                      ...editProfile,
                      firstName: "",
                    }));
                  }
                }}
                formError={formError?.firstName}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 5}>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                {!editProfile.editFirstName && (
                  <Tooltip id="button-report" title="Edit Name">
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          editFirstName: true,
                        }));
                      }}
                    >
                      <CiEdit fontSize={32} />
                    </IconButton>
                  </Tooltip>
                )}
                {editProfile.editFirstName && (
                  <>
                    <SVButton
                      text="Save"
                      sx={{ height: 40 }}
                      onClick={() => {
                        onSave(SAVE_PROFILE_KEYS.firstName);
                      }}
                    />

                    <SVOutlinedButton
                      text="Cancel"
                      sx={{ height: 40, ml: 1 }}
                      onClick={() => {
                        setEditProfile((editProfile) => ({
                          ...editProfile,
                          editFirstName: false,
                        }));
                      }}
                    />
                  </>
                )}
              </Grid>
            </Grid>

            {/*** Last Name */}
            <>
              <Grid item xs={isMobile ? 12 : 7}>
                <AdminInput
                  fullWidth={true}
                  disabled={!editProfile.editLastName}
                  color={theme.palette.text.primary}
                  title={"Last Name"}
                  value={editProfile?.lastName || ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        lastName: e.target.value,
                      }));
                      setFormError((formError) => ({
                        ...formError,
                        lastName: "",
                      }));

                    } else {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        lastName: "",
                      }));
                    }
                  }}
                  formError={formError?.lastName}
                />{" "}
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editLastName && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editLastName: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editProfile.editLastName && (
                    <>
                      <SVButton
                        text="Save"
                        sx={{ height: 40 }}
                        onClick={() => {
                          onSave(SAVE_PROFILE_KEYS.lastName);
                        }}
                      />

                      <SVOutlinedButton
                        text="Cancel"
                        sx={{ height: 40, ml: 1 }}
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editLastName: false,
                          }));
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </>

            {/*** Edit Email */}
            <>
              <Grid item xs={isMobile ? 12 : 7}>
                <AdminInput
                  fullWidth={true}
                  disabled={!editProfile.editEmail}
                  type="text"
                  color={theme.palette.text.primary}
                  title={"Email Address"}
                  value={editProfile?.email || ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        email: e.target.value.trim(),
                      }));
                      if (e) {
                        setFormError((formError) => ({
                          ...formError,
                          email: "",
                        }));
                      }
                    }
                  }}
                  formError={formError?.email}
                />{" "}
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editEmail && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editEmail: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editProfile.editEmail && (
                    <>
                      <SVButton
                        text="Save"
                        sx={{ height: 40 }}
                        onClick={() => {
                          onSave(SAVE_PROFILE_KEYS.email);
                        }}
                      />

                      <SVOutlinedButton
                        text="Cancel"
                        sx={{ height: 40, ml: 1 }}
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editEmail: false,
                          }));
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </>

            {/*** SSN */}
            <>
              <Grid item xs={isMobile ? 12 : 7} sx={{ mb: 2 }}>
                <AdminInput
                  fullWidth={true}
                  disabled={!editProfile.editSSN}
                  type="number"
                  color={theme.palette.text.primary}
                  title={"SSN"}
                  value={editProfile?.ssn || ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        ssn: e.target.value,
                      }));
                      setFormError((formError) => ({
                        ...formError,
                        ssn: "",
                      }));

                    } else {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        ssn: ""
                      }));
                    }
                  }}
                  formError={formError?.ssn}
                />{" "}
                {editProfile?.editSSN && (
                  <>
                    <Grid container>
                      <Grid item sx={{ mb: 1 }}>
                        <Typography>Upload Your SSN Document(Max 2 allow)</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} display={"flex"}>

                      <Grid item xs={4}>
                        <SVDocumentAdd onClick={() => {
                          if (ssnDocumentsList.length == 2) {
                            errorToast("You already uploaded 2 documents.")
                          } else {
                            inputRef.current['ssnFile'].click();
                          }

                        }} />
                      </Grid>
                      {ssnDocumentsList?.map((data, index) => {
                        return <Grid item xs={4} key={index}>
                          <SVDocumentView media={data?.fullPath} onClick={() => {
                          }} />
                        </Grid>
                      })}


                    </Grid>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item>
                        <input
                          id="ssnFile"
                          ref={(el) => (inputRef.current['ssnFile'] = el)}
                          type="file"
                          style={{ display: "none" }}
                          className="imageUpload"
                          accept="image/jpeg,image/jpg,image/png,application/pdf"
                          onChange={(event) => onDocumentChange(event, SAVE_PROFILE_KEYS.ssn)
                          }
                          disabled={fileLimit}
                        />
                        <SVButton
                          text="Save"
                          sx={{ height: 40 }}
                          onClick={() => {
                            if (ssnDocumentsList?.length > 0) {
                              onSave(SAVE_PROFILE_KEYS.ssn);
                            } else {
                              errorToast("Please upload SSN document proof.");
                            }

                          }}
                        />
                        <SVOutlinedButton
                          text="Cancel"
                          sx={{ height: 40, ml: 1 }}
                          onClick={() => {
                            setEditProfile((editProfile) => ({
                              ...editProfile,
                              editSSN: false,
                            }));
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editSSN && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editSSN: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </>

            {/*** Phone */}
            <>
              <Grid item xs={isMobile ? 12 : 7}>
                <PhoneInput
                  disabled={!editProfile.editPhone}
                  country={"us"}
                  inputStyle={{
                    width: "100%",
                    border: "1px solid #dcdcdc",
                    borderRadius: 8,
                    color: !editProfile.editPhone ? "#AEAEAE" : "black",
                    background: "#fff",
                    height: 55,
                  }}
                  value={phoneField?.value || ""}
                  onChange={(phone, dial) => {
                    handlePhoneInput({ phone, dial });
                    if (phoneField?.value !== "") {
                      setFormError((formError) => ({
                        ...formError,
                        value: "",
                      }));
                    }
                  }}
                />
                <Typography sx={{ color: "red", p: 1 }}>
                  {formError?.value}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editPhone && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editPhone: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editProfile.editPhone && (
                    <>
                      <SVButton
                        text="Save"
                        sx={{ height: 40 }}
                        isLoading={isLoading}
                        onClick={() => {
                          onSave(SAVE_PROFILE_KEYS.phoneNumber);
                        }}
                      />

                      <SVOutlinedButton
                        text="Cancel"
                        sx={{ height: 40, ml: 1 }}
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editPhone: false,
                          }));
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </>

            {/*** DOB */}
            <>
              <Grid item xs={isMobile ? 12 : 7}>
                <AdminInput
                  fullWidth={true}
                  disabled={!editProfile.editDOB}
                  color={theme.palette.text.primary}
                  type={"date"}
                  title="DOB"
                  value={editProfile?.dateOfBirth || ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        dateOfBirth: e.target.value,
                      }));

                      setFormError((formError) => ({
                        ...formError,
                        dateOfBirth: "",
                      }));

                    } else {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        dateOfBirth: "",
                      }));
                    }
                  }}
                  formError={formError?.dateOfBirth}
                />{" "}
                {editProfile?.editDOB && (
                  <>
                    <Grid container  >
                      <Grid item sx={{ mb: 1 }}>
                        <Typography>Upload ID Proof</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Card
                          sx={{
                            width: 100,
                            height: 100,
                            background: "transparent",
                            border: "1px solid black",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() => profileImage.current.click()}
                        >
                          <Icon
                            icon="ei:plus"
                            color=" #A9A9A9"
                            height={60}
                            style={{ margin: 20 }}
                          />
                        </Card>
                      </Grid>

                      {dobDocumentsList?.map((item, index) => {
                        return <Grid item xs={4} key={index}>
                          <Card
                            sx={{
                              width: 100,
                              height: 100,
                              background: "transparent",
                              border: "1px solid black",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img src={item.fullPath} />
                          </Card>
                        </Grid>
                      })}


                    </Grid>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item>
                        <input
                          ref={profileImage}
                          type="file"
                          style={{ display: "none" }}
                          className="imageUpload"
                          accept="image/*"
                          multiple
                          onChange={(event) =>
                            onDocumentChange(event, SAVE_PROFILE_KEYS.dateOfBirth)
                          }
                          disabled={fileLimit}
                        />
                        <SVButton
                          text="Save"
                          sx={{ height: 40 }}
                          onClick={(event) => {
                            onSave(event, SAVE_PROFILE_KEYS.dateOfBirth);
                          }}
                        />
                        <SVOutlinedButton
                          text="Cancel"
                          sx={{ height: 40, ml: 1 }}
                          onClick={() => {
                            setEditProfile((editProfile) => ({
                              ...editProfile,
                              editDOB: false,
                            }));
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={isMobile ? 12 : 5} sx={{ mt: 2 }}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editDOB && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editDOB: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}

                </Grid>
              </Grid>
            </>

            {/*** Address */}
            <>
              <Grid item xs={isMobile ? 12 : 7}>
                <AdminInput
                  fullWidth={true}
                  disabled={!editProfile.editAddress}
                  color={theme.palette.text.primary}
                  title={"Address"}

                  value={editProfile?.address || ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        address: e.target.value,
                      }));

                      setFormError((formError) => ({
                        ...formError,
                        address: "",
                      }));

                    } else {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        address: ""
                      }))
                    }
                  }}
                  formError={formError?.address}
                />{" "}
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editAddress && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editAddress: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editProfile.editAddress && (
                    <>
                      <SVButton
                        text="Save"
                        sx={{ height: 40 }}
                        onClick={() => {
                          onSave(SAVE_PROFILE_KEYS.streetAddress);
                        }}
                      />

                      <SVOutlinedButton
                        text="Cancel"
                        sx={{ height: 40, ml: 1 }}
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editAddress: false,
                          }));
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </>

            {/*** City */}
            <>
              <Grid item xs={isMobile ? 12 : 7} sx={{ mb: 2 }}>
                <AdminInput
                  fullWidth={true}
                  disabled={!editProfile.editCity}
                  color={theme.palette.text.primary}
                  title={"City"}
                  value={editProfile?.city || ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        city: e.target.value,
                      }));

                      setFormError((formError) => ({
                        ...formError,
                        city: "",
                      }));
                    } else {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        city: ""
                      }))
                    }

                  }}
                  formError={formError?.city}
                />{" "}
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editCity && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editCity: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editProfile.editCity && (
                    <>
                      <SVButton
                        text="Save"
                        sx={{ height: 40 }}
                        onClick={() => {
                          onSave(SAVE_PROFILE_KEYS.city);
                        }}
                      />

                      <SVOutlinedButton
                        text="Cancel"
                        sx={{ height: 40, ml: 1 }}
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editCity: false,
                          }));
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </>

            {/*** State */}
            <>
              <Grid item xs={isMobile ? 12 : 7}>
                <SVSelect
                fullWidth="true"
                  value={editProfile?.state || ""}
                  label="State"
                  options={stateListData}
                  emptyText="Choose State"
                  disabled={!editProfile.editState}
                  onChange={(e) => {
                    if (e.target.value) {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        state: e.target.value,
                      }));
                      setFormError((formError) => ({
                        ...formError,
                        state: "",
                      }));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editState && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editState: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editProfile.editState && (
                    <>
                      <SVButton
                        text="Save"
                        sx={{ height: 40 }}
                        onClick={() => {
                          onSave(SAVE_PROFILE_KEYS.state);
                        }}
                      />

                      <SVOutlinedButton
                        text="Cancel"
                        sx={{ height: 40, ml: 1 }}
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editState: false,
                          }));
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </>

            {/*** Postal Code */}
            <>
              <Grid item xs={isMobile ? 12 : 7}>
                <AdminInput
                  fullWidth={true}
                  disabled={!editProfile.editPostalCode}
                  color={theme.palette.text.primary}
                  title={"Postal Code"}
                  maxLength={5}
                  value={editProfile?.postalCode || ""}
                  onChange={(e) => {
                    if (e.target.value) {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        postalCode: e.target.value,
                      }));

                      setFormError((formError) => ({
                        ...formError,
                        postalCode: "",
                      }));

                    } else {
                      setEditProfile((editProfile) => ({
                        ...editProfile,
                        postalCode: ""
                      }))
                    }
                  }}
                  formError={formError?.postalCode}
                />{" "}
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {!editProfile.editPostalCode && (
                    <Tooltip id="button-report" title="Edit Name">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editPostalCode: true,
                          }));
                        }}
                      >
                        <CiEdit fontSize={32} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editProfile.editPostalCode && (
                    <>
                      <SVButton
                        text="Save"
                        sx={{ height: 40 }}
                        onClick={() => {
                          onSave(SAVE_PROFILE_KEYS.postalCode);
                        }}
                      />

                      <SVOutlinedButton
                        text="Cancel"
                        sx={{ height: 40, ml: 1 }}
                        onClick={() => {
                          setEditProfile((editProfile) => ({
                            ...editProfile,
                            editPostalCode: false,
                          }));
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default React.memo(EditUpdateProfile);
