import {
  Breadcrumbs,
  Card,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { color } from "theme/color";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/spinner";
import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataNotFound from "components/dataNotFound/dataNotFound";
import {
  assetsSummary,
  clearAssetsLiabilitiesDetailData,
  liabilitiesSummary,
} from "store/slice/profile";
import { currencyFormat } from "utils/currencyFormatter";
import investmentBg from "../../../../assets/img/investmentBg.svg";
import realEstateBg from "../../../../assets/img/assetsImage.svg";
import insuranceBg from "../../../../assets/img/insuranceBg.svg";
import bankBg from "../../../../assets/img/bankBg.svg";
import otherImage from "../../../../assets/img/otherImage.svg";
import incomeBg from "../../../../assets/img/incomeBg.svg";
import creditCard from "../../../../assets/img/creditCard.svg";
import loanBg from "../../../../assets/img/loanBg.svg";
import EstateHeading from "./estate/estateHeading";
import AddAssetsDialog from "./assets/addAssetsDialog";
import AddLiabilitiesDialog from "./liabilities/addLiabilitiesDialog";

const AssetsDetail = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsloading] = useState(false);
  const [showAddAssetsDialog, SetShowAddAssetsDialog] = useState(false);
  const [showLiabilitiesDialog, SetShowLiabilitiesDialog] = useState(false);
  const { assetsSummaryData, liabilitiesSummaryData } = useSelector(
    (state) => state.profile
  );

  const userId = params?.id;

  const totalAmount = assetsSummaryData?.data?.reduce((accumulator, item) => {
    return accumulator + (item?.amount || 0);
  }, 0);

  const totalAmountLiabilities = liabilitiesSummaryData?.data?.reduce(
    (accumulator, item) => {
      return accumulator + (item?.amount || 0);
    },
    0
  );

  useEffect(() => {
    if (!assetsSummaryData?.data?.length > 0) {
      setIsloading(true);
    }

    var data = {
      userId: userId,
    };
    dispatch(assetsSummary(data, setIsloading));
  }, []);
  useEffect(() => {
    var data = {
      userId: userId,
    };
    dispatch(liabilitiesSummary(data, setIsloading));
  }, []);

  return (
    <>
      <>
        <AddAssetsDialog
          open={showAddAssetsDialog}
          userId={userId}
          isLoading={isLoading}
          setOpen={SetShowAddAssetsDialog}
          setIsloading={setIsloading}
        />
        <AddLiabilitiesDialog
          open={showLiabilitiesDialog}
          userId={userId}
          isLoading={isLoading}
          setOpen={SetShowLiabilitiesDialog}
          setIsloading={setIsloading}
        />
        <Grid container sx={{ p: 3, mt: -5 }}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} to="/users">
                Users
              </Link>
              <Link style={{ color: "white" }} to={`/profile/${userId}`}>
                User Profile
              </Link>
              <Typography sx={{ color: "white" }}>
                Assets-Liabilities Details
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box sx={{ background: color.black, borderRadius: "10px", p: 3 }}>
          <EstateHeading
            title={"Add Assets"}
            isAssets
            heading={"Assets"}
            onClick={() => {
              SetShowAddAssetsDialog(true);
            }}
          />
          <Card
            sx={{
              color: color.main,
              background: "#0E0E0E",
              p: 2,
              mt: 3,
            }}
          >
            <Grid container sx={{ display: "flex", mb: 2 }}>
              <Grid item>
                <Typography variant="h6">Total Assets : </Typography>
              </Grid>{" "}
              &nbsp;
              <Grid item>
                <Typography variant="h6" sx={{ color: color.active }}>
                  {" "}
                  {currencyFormat(totalAmount ? totalAmount : 0)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {assetsSummaryData?.data?.length > 0 ? (
                <>
                  {assetsSummaryData?.data?.map((data, index) => {
                    return (
                      <Grid key={index} item xs={isMobile ? 12 : 3}>
                        <Card
                          onClick={() => {
                            navigate(`/bankDetails/${userId}`, {
                              state: {
                                type:
                                  data?.type === "other" ? "asset" : data?.type,
                                subtype: data?.subtype,
                              },
                            });
                            dispatch(clearAssetsLiabilitiesDetailData());
                          }}
                          sx={{
                            backgroundColor: color.topbar,
                            borderRadius: 2,
                            backgroundImage:
                              (data?.type === "investment" &&
                                `url(${investmentBg})`) ||
                              (data?.type === "real_estate" &&
                                `url(${realEstateBg})`) ||
                              (data?.type === "insurance" &&
                                `url(${insuranceBg})`) ||
                              (data?.type === "income" && `url(${incomeBg})`) ||
                              (data?.type === "depository" &&
                                `url(${bankBg})`) ||
                              (data?.type === "other" && `url(${otherImage})`),
                            // backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: 80,
                            backgroundPosition: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Grid
                            container
                            display={"flex"}
                            sx={{ p: 1.5 }}
                            justifyContent={"space-between"}
                          >
                            <Grid item display={"flex"}>
                              <Typography
                                sx={{
                                  color: color.main,
                                  fontSize: 15,
                                  fontWeight:400,
                                  textTransform: "uppercase",
                                }}
                              >
                                {data?.type === "other"
                                  ? data?.subtype
                                  : data?.type === "depository"
                                    ? "Bank"
                                    : data?.type === "real_estate"
                                      ? "Real Estate"
                                      : data?.type}
                              </Typography>
                            </Grid>
                            <Grid item sx={{ mt: -1 }}>
                              <Icon
                                icon="iconamoon:arrow-right-6-circle-thin"
                                style={{
                                  color: color.main,
                                  cursor: "pointer",
                                }}
                                width={32}
                              />
                            </Grid>
                          </Grid>
                          <Grid container sx={{ p: 1.5 }}>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: 22,
                                  color: color.main,
                                }}
                              >
                                {currencyFormat(
                                  data?.amount ? data?.amount : 0
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <>
                  <Grid xs={12} sx={{ m: 2 }}>
                    <DataNotFound />
                  </Grid>{" "}
                </>
              )}
            </Grid>
          </Card>

          <EstateHeading
            isAssets
            title={"Add Liabilities"}
            heading={"Liabilities"}
            onClick={() => {
              SetShowLiabilitiesDialog(true);
            }}
          />
          <Card
            sx={{
              color: color.main,
              background: "#0E0E0E",
              p: 2,
              mt: 3,
            }}
          >
            <Grid container sx={{ display: "flex", mb: 2 }}>
              <Grid item>
                <Typography variant="h6">Total Liabilities : </Typography>
              </Grid>{" "}
              &nbsp;
              <Grid item>
                <Typography variant="h6" sx={{ color: color.active }}>
                  {" "}
                  {currencyFormat(
                    totalAmountLiabilities ? totalAmountLiabilities : 0
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {liabilitiesSummaryData?.data?.length ? (
                <>
                  {liabilitiesSummaryData?.data.map((data, index) => {
                    console.log("data?.type",data?.type)
                    return (
                      <Grid key={index} item xs={isMobile ? 12 : 3}>
                        <Card
                          onClick={() => {
                            navigate(`/bankDetails/${userId}`, {
                              state: {
                                type:
                                  data?.type === "other"
                                    ? "liability"
                                    : data?.type,
                                subtype: data?.subtype,
                              },
                            });
                            dispatch(clearAssetsLiabilitiesDetailData());
                          }}
                          sx={{
                            backgroundColor: color.topbar,
                            borderRadius: 2,
                            backgroundImage:
                              (data?.type === "credit" &&
                                `url(${creditCard})`) ||
                              (data?.type === "loan" && `url(${loanBg})`) ||
                              (data?.type === "other" && `url(${otherImage})`),
                            // backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: 80,
                            backgroundPosition: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Grid
                            container
                            display={"flex"}
                            sx={{ p: 1.5 }}
                            justifyContent={"space-between"}
                          >
                            <Grid item display={"flex"}>
                              <Typography
                                sx={{
                                  color: color.main,
                                  fontSize: 15,
                                  fontWeight:400,
                                  textTransform: "uppercase",
                                }}
                              >
                                {data?.type === "other"
                                  ? data?.subtype
                                  : data?.type}
                              </Typography>
                            </Grid>
                            <Grid item sx={{ mt: -1 }}>
                              <Icon
                                icon="iconamoon:arrow-right-6-circle-thin"
                                style={{
                                  color: color.main,
                                  cursor: "pointer",
                                }}
                                width={35}
                              />
                            </Grid>
                          </Grid>
                          <Grid container sx={{ p: 1.5 }}>
                            <Grid item>
                              <Typography
                                sx={{ fontSize: 22, color: color.main }}
                              >
                                {currencyFormat(
                                  data?.amount ? data?.amount : 0
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <>
                  <Grid xs={12} sx={{ m: 2 }}>
                    <DataNotFound />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
        </Box>
      </>

      <Spinner open={isLoading} />
    </>
  );
};

export default AssetsDetail;
