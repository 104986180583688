import React from "react";
import { Breadcrumbs, Grid, Typography, useMediaQuery } from "@mui/material";
import BasicInfo from "../sections/basicInfo/index";
// import FinancialInfo from "../sections/financialInfo/index";
// import BeneficiariesDetail from "../sections/beneficiariesDetail/index";
import OtherInfo from "../sections/otherInfo/index";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

export default function index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { netWorthDetailData } = useSelector((state) => state.profile);
  const detail = netWorthDetailData?.data?.financialAdviceRequestData;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ color: "white" }} to="/netWorth">
            Financial Request List
          </Link>
          <Typography sx={{ color: "white" }}>
            Financial Request Details
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <BasicInfo detail={detail} />
      </Grid>
      {/* <Grid item xs={6}>
        <FinancialInfo 
                detail={detail}
                />
      </Grid> */}

      {/* <Grid item xs={6}>
        <BeneficiariesDetail
                detail={detail}
                />
      </Grid> */}
      <Grid item xs={isMobile ? 12 : 6}>
        <OtherInfo detail={detail} />
      </Grid>
    </Grid>
  );
}
