// import { useTheme } from "@emotion/react";
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Card,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import DataNotFound from "components/dataNotFound/dataNotFound";
import Spinner from "components/spinner";

import SVDivider from "components/SVDivider";
import moment from "moment";
// import SVButton from "components/SVButton";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { goalsDetailApi } from "store/slice/profile";
import { color } from "theme/color";
import Chart from "react-apexcharts";
import { useTheme } from "@emotion/react";

const SetGoalDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const param = useParams();
  const { isLoading, goalsDetailData } = useSelector((state) => state.profile);

  const chartData = {
    series: [
      goalsDetailData?.totalInfo?.nearly_completed_goals_percentage
        ? goalsDetailData?.totalInfo?.nearly_completed_goals_percentage
        : 0,
      goalsDetailData?.totalInfo?.inprogress_goals_percentage
        ? goalsDetailData?.totalInfo?.inprogress_goals_percentage
        : 0,
      goalsDetailData?.totalInfo?.new_goals_percentage
        ? goalsDetailData?.totalInfo?.new_goals_percentage
        : 0,
        goalsDetailData?.totalInfo?.completed_goals_percentage
        ? goalsDetailData?.totalInfo?.completed_goals_percentage
        : 0,
      goalsDetailData?.totalInfo?.expired_goals_percentage
        ? goalsDetailData?.totalInfo?.expired_goals_percentage
        : 0,
    ],

    options: {
      chart: {
        type: "donut",
      },

      labels: [
        "Nearly Completed",
        "In Progress",
        "Not Funded",
        "Completed",
        "In Complete",
      ],
      colors: ["#068836", "#CBA500", "#949494", "#005A84", "#EC1C24"],
      stroke: {
        show: false,
        width: 1,
      },
    },

    datasets: [
      {
        borderColor: ["#FFFFFF"],
      },
    ],
    responsive: [
      {
        breakpoint: isMobile ? 768 : 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  // const clearState = () => {
  //   dispatch(clearGoalDetailData());
  // };

  useEffect(() => {
    var data = {
      userId: param?.id,
    };
    dispatch(goalsDetailApi(data, true));
  }, []);
  console.log("goalsDetailData", goalsDetailData);
  return (
    <>
      <>
        <Grid container sx={{ p: 3, mt: -5 }}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} to="/users">
                Users
              </Link>
              <Link style={{ color: "white" }} to={`/profile/${param?.id}`}>
                User Profile
              </Link>
              <Typography sx={{ color: "white" }}>Set Goal Details</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Card
          sx={{
            maxWidth: "100vw",
            color: color.main,
            background: color.black,
            p: 2,
          }}
        >
          <Grid container>
            <Grid item xs={isMobile || !goalsDetailData ? 12 : 6}>
              {" "}
              <Typography
                style={{ fontSize: 20 }}
                className="textLinearBackground"
              >
                Set Goals Details
              </Typography>
              <Card
                sx={{
                  maxWidth: "100vw",
                  color: color.grey,
                  background: color.topbar,
                  p: 2,
                  mt: 1,
                  mb: 2,
                }}
              >
                <Typography
                  style={{ fontSize: 20, textAlign: "center" }}
                  className="textLinearBackground"
                >
                  {goalsDetailData?.totalInfo?.total_goals
                    ? goalsDetailData?.totalInfo?.total_goals
                    : 0}{" "}
                  Goals
                </Typography>

                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "center", mt: 0 }}
                >
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <Typography sx={{ color: color.main }}>
                      Completed
                    </Typography>
                    <Typography sx={{ color: color.active }}>
                      {goalsDetailData?.totalInfo?.completed_goals
                        ? goalsDetailData?.totalInfo?.completed_goals
                        : 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <Typography sx={{ color: color.main }}>
                      Nearly Completed
                    </Typography>
                    <Typography sx={{ color: color.active }}>
                      {goalsDetailData?.totalInfo?.nearly_completed_goals
                        ? goalsDetailData?.totalInfo?.nearly_completed_goals
                        : 0}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <Typography sx={{ color: color.main }}>
                      Incomplete{" "}
                    </Typography>
                    <Typography sx={{ color: color.active }}>
                      {goalsDetailData?.totalInfo?.expired_goals
                        ? goalsDetailData?.totalInfo?.expired_goals
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {goalsDetailData && (
              <Grid item xs={isMobile ? 12 : 6}>
                <Chart
                  options={chartData?.options}
                  series={chartData?.series}
                  type={
                    chartData?.options?.chart?.type
                      ? chartData?.options?.chart?.type
                      : "donut"
                  }
                  width={isMobile ? "360" : "410"}
                  stroke={"none"}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 12}>
              {goalsDetailData?.goalData?.length > 0 ? (
                <>
                  {" "}
                  <Card sx={{ background: color.topbar, p: 2 }}>
                    <Grid container>
                      {goalsDetailData?.goalData?.map((data, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={isMobile ? 12 : 6}
                            sx={{ p: 1 }}
                          >
                            <Card
                              sx={{
                                background: color.black,
                                backgorund: "red",
                              }}
                            >
                              <Grid container sx={{ p: 1 }}>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{
                                    diplay: "flex",
                                    alignItem: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Card
                                    sx={{
                                      border: "1.2px solid #222222",
                                      borderRadius: 50,
                                      background: "black",
                                      position: "absolute",
                                      height: isMobile ? 55 : 84,
                                    }}
                                  >
                                    <CircularProgress
                                      variant="determinate"
                                      value={data?.completion_percentage}
                                      size={isMobile ? 55 : 82}
                                      thickness={2}
                                      sx={{
                                        color: `#${data?.colorCode}`,
                                      }}
                                    />
                                    <img
                                      src={data?.iconImage}
                                      alt=""
                                      width={50}
                                      height={82}
                                      style={{
                                        position: "absolute",
                                        left: 16,
                                        // objectFit: "contain",
                                        // marginLeft: "-3.53rem",
                                      }}
                                    />
                                    {/* <Icon
                                      icon={
                                        (data?.iconId === 36 && "ph:car") ||
                                        (data?.iconId === 37 &&
                                          "ic:outline-home") ||
                                        (data?.iconId === 38 &&
                                          "icon-park-outline:round-trip") ||
                                        (data?.iconId === 39 &&
                                          "fa6-solid:children") ||
                                        (data?.iconId === 32 &&
                                          "medical-icon:i-health-education")
                                      }
                                      style={{
                                        position: "absolute",
                                        marginLeft: isMobile ? -42 : -56,
                                        marginTop: isMobile ? 12 : 26,
                                      }}
                                      color={`#${data?.colorCode}`}
                                      width={30}
                                    /> */}
                                  </Card>
                                </Grid>
                                <Grid item xs={10} sx={{ p: 2, mt: -2 }}>
                                  <Grid
                                    item
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography sx={{ color: color.main }}>
                                      {data?.title ? data?.title : "-"}
                                    </Typography>{" "}
                                    <Typography className="textLinearBackground">
                                      {data?.amount
                                        ? "$" + `${data?.amount}`
                                        : 0}
                                    </Typography>
                                  </Grid>

                                  <Typography
                                    sx={{ color: "#707070", fontSize: 10 }}
                                  >
                                    {data?.description
                                      ? data?.description
                                      : "-"}
                                  </Typography>
                                  <SVDivider
                                    style={{
                                      marginTop: 5,
                                      border: "0.5px solid #232426",
                                    }}
                                  />
                                  <Grid container sx={{ mt: 0.5 }}>
                                    <Grid item xs={6}>
                                      <Typography
                                        sx={{ fontSize: 12 }}
                                        className="textLinearBackground"
                                      >
                                        Goad start date
                                      </Typography>
                                      <Grid item display="flex">
                                        <Icon
                                          icon="cil:calendar"
                                          color="#707070"
                                          width="20px"
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: 12,
                                            p: 0.3,
                                            color: color.main,
                                            pl: 1,
                                          }}
                                        >
                                          {data?.start_date
                                            ? moment(data?.start_date).format(
                                                "MMMM Do, YYYY"
                                              )
                                            : "-"}
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <Typography
                                        sx={{ fontSize: 12 }}
                                        className="textLinearBackground"
                                      >
                                        Goad end date
                                      </Typography>
                                      <Grid item display="flex">
                                        <Icon
                                          icon="cil:calendar"
                                          color="#707070"
                                          width="20px"
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: 12,
                                            p: 0.3,
                                            color: color.main,
                                            pl: 1,
                                          }}
                                        >
                                          {data?.end_date
                                            ? moment(data?.end_date).format(
                                                "MMMM Do, YYYY"
                                              )
                                            : "-"}{" "}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <SVDivider
                                    style={{
                                      marginTop: 5,
                                      border: "0.5px solid #232426",
                                    }}
                                  />
                                  <Grid container sx={{ mt: 0.5 }}>
                                    <Grid item xs={6}>
                                      <Typography
                                        sx={{ fontSize: 12 }}
                                        className="textLinearBackground"
                                      >
                                        Completed percentage
                                      </Typography>
                                      <Grid item display="flex">
                                        <Icon
                                          icon="material-symbols-light:circle"
                                          color={`#${data?.colorCode}`}
                                          width="20px"
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: 12,
                                            p: 0.3,
                                            color: color.main,
                                            pl: 1,
                                          }}
                                        >
                                          {data?.completion_percentage
                                            ? `${data?.completion_percentage}%`
                                            : 0}
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <Typography
                                        sx={{ fontSize: 12 }}
                                        className="textLinearBackground"
                                      >
                                        Remaining percentage
                                      </Typography>
                                      <Grid item display="flex">
                                        <Icon
                                          icon="material-symbols-light:circle"
                                          color="#707070"
                                          width="20px"
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: 12,
                                            p: 0.3,
                                            color: color.main,
                                            pl: 1,
                                          }}
                                        >
                                          {data?.left_percentage
                                            ? `${data?.left_percentage}%`
                                            : 0}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Card>
                </>
              ) : (
                <>
                  <DataNotFound />
                </>
              )}
            </Grid>
          </Grid>
        </Card>{" "}
      </>
      <Spinner open={isLoading} />
    </>
  );
};

export default SetGoalDetails;
