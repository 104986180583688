import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import image from "../../../assets/img/Logo.png";
import navConfig from "./config";
import { color } from "../../../theme/color";
import AccountPopover from "./AccountPopover";
import SideItem from "./sideItem";
import { useLocation, useNavigate } from "react-router-dom";
import { List, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminProfileDetail } from "store/slice/profile";
import { adminDetailData } from "utils/constantFun";
import { TEAM_MEMBER_ROLE } from "utils/appConstant";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,

    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Dashboard() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const { adminPorfileDetailData } = useSelector((state) => state.profile);

  let location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    dispatch(adminProfileDetail(adminDetailData ? "" : navigate));
  }, []);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: isMobile ? 0 : "auto",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: color.topbar }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              float: "left",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <AccountPopover />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: color.topbar,
            display: { xs: open ? "block" : "none", sm: "block" },
          },
        }}
      >
        <DrawerHeader style={{ height: 50 }}>
          <Box sx={{ mx: "auto", width: 100, mt: 2 }}>
            <img src={image} alt="logo" style={{ width: "100%" }} />
          </Box>{" "}
          <IconButton onClick={handleDrawerClose} style={{ color: color.main }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <List>
          {navConfig.map((item) => {
            let pathArray = location.pathname.split("/");
            const active = item.path
              ? location.pathname === item.path
                ? true
                : `/${pathArray[1]}` == item.subPath
                  ? true
                  : false
              : false;
            if (
              adminPorfileDetailData?.roleId ===
              TEAM_MEMBER_ROLE.SUPERADMIN_ROLE
            ) {
              return (
                <SideItem
                  active={active}
                  disabled={item.disabled}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                  open={open}
                />
              );
            } else {
              if (item?.id) {
                if (adminPorfileDetailData?.user_permissions[item.id]) {
                  return (
                    <>
                      <SideItem
                        active={active}
                        disabled={item.disabled}
                        icon={item.icon}
                        key={item.title}
                        path={item.path}
                        title={item.title}
                        open={open}
                      />
                    </>
                  );
                }
              } else {
                return (
                  <>
                    {adminPorfileDetailData && (
                      <SideItem
                        active={active}
                        disabled={item.disabled}
                        icon={item.icon}
                        key={item.title}
                        path={item.path}
                        title={item.title}
                        open={open}
                      />
                    )}
                  </>
                );
              }
            }
          })}
        </List>
      </Drawer>
    </Box>
  );
}
