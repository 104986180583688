import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  Button,
  Card,
  Stack,
} from "@mui/material";
import { color } from "../../../../theme/color";
import SVButton from "components/SVButton";
import CardImg from "../../../../assets/img/cardBG.png";
import Lg from "../../../../assets/img/lg.png";
// import Lg2 from "../../../../assets/img/lg2.png";
import chipLogo from "assets/img/chip.png";
import masterCardLogo from "assets/img/mastercardLogo.png";
import { useTheme } from "@emotion/react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { changeCardStatus, getCardsTransactionList } from "store/slice/profile";
import StatusCardDialog from "./statusCardDialog/statusCardDialog";
import { useParams } from "react-router-dom";
import moment from "moment";

function RecentHistory({ ...props }) {
  const { profileDetailData, cardDetailData } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [indexView, setIndexView] = useState(0);
  const [showConfirmDialog, setShowConfirmProfileDialog] = useState(false);
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const { profileId } = useParams();
  const cardData = cardDetailData && cardDetailData[indexView];
  const { cardsTransactionList, cardsTranscationCount } = useSelector(
    (state) => state.profile
  );
  const activateCard = () => {
    var data = {
      card_id: cardData?.id,
      status: cardData?.status === "SUSPENDED" ? true : false,
      notes: formData?.rejectingReason,
      last4: cardData?.last4,
      user_id: profileDetailData?.id,
    };
    dispatch(changeCardStatus(data, setShowConfirmProfileDialog, setFormData));
  };
  useEffect(() => {
    if (cardData) {
      fetchCardsTransactions();
    }
  }, [cardData]);
  const fetchCardsTransactions = () => {
    let request = {
      dateFrom: null,
      dateTo: null,
      paymentCardId: cardData?.id,
      page: 0,
      perPage: 10,
      userId: profileId,
    };
    dispatch(getCardsTransactionList(request));
  };
  const nextClick = () => {
    if (indexView >= 0) {
      setIndexView(indexView + 1);
    }
  };
  const prevClick = () => {
    if (indexView) {
      setIndexView(indexView - 1);
    }
  };

  const closeReasonDialog = () => {
    setFormData({});
    setShowConfirmProfileDialog(false);
  };
  return (
    <>
      {cardData ? (
        <Box sx={{ background: color.topbar, borderRadius: "10px", p: 3 }}>
          <Grid container spacing={1} sx={{ mt: -2 }}>
            <Grid item xs={12}>
              <Grid container display={"flex"} justifyContent={"space-between"}>
                <Grid item>
                  <Typography
                    className="textLinearBackground"
                    sx={{ fontSize: 22 }}
                  >
                    Card
                  </Typography>
                </Grid>
                <Grid item>
                  <SVButton
                    sx={{ background: cardData?.status === "ACTIVE" && "red" }}
                    text={cardData?.status === "ACTIVE" ? "Block" : "Activate"}
                    onClick={() => setShowConfirmProfileDialog(true)}
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} spacing={2} sx={{ mt: 1 }}>
                <Grid
                  item
                  xs={isMobile ? 12 : 6}
                  display={"grid"}
                  justifyContent={"center"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card
                      sx={{
                        backgroundColor: "transparent",
                        backgroundImage: `url(${CardImg})`,
                        borderRadius: 2,
                        backgroundRepeat: "no-repeat",
                        p: { xs: 1, sm: 2, md: 3 }, // adjusted padding for different screen sizes
                        width: "auto",
                      }}
                    >
                      <Grid>
                        <Grid item xs={12}>
                          <img src={Lg} width={"40%"} />
                        </Grid>
                        <Grid item xs={12} my={2}>
                          <img src={chipLogo} alt="" width={"10%"} />
                        </Grid>
                        <Grid item xs={12} mt={-1}>
                          <Typography
                            sx={{
                              fontSize: { xs: 12, sm: "1.8rem" },
                              fontWeight: 500,
                              color: "#7f7f7f96",
                            }}
                          >
                            XXXX XXXX XXXX {cardData?.last4}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          mt={0.4}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack>
                            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                              VALID THRU
                            </Typography>
                            <span
                              style={{
                                fontSize: { xs: 12, sm: "1.5rem" },
                                
                                fontWeight: 500,
                                marginTop: "0.1rem",
                                color: "#7f7f7f96",
                              }}
                            >
                              {cardData?.expirationMonth}/
                              {cardData?.expirationYear}
                            </span>
                            <Typography
                              sx={{
                                fontSize: { xs: 12, sm: "1.5rem" },

                                fontWeight: 500,
                                color: "#7f7f7f96",
                              }}
                            >
                              {cardData?.financialAccounts[0]?.name}
                            </Typography>
                          </Stack>
                          <img
                            src={masterCardLogo}
                            alt=""
                            height={"30%"}
                            width={"30%"}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>

                  <Grid
                    container
                    gap={1}
                    sx={{ mt: 1 }}
                    displat="flex"
                    justifyContent={"center"}
                  >
                    <Grid item>
                      <Button
                        onClick={prevClick}
                        disabled={indexView === 0 ? true : false}
                      >
                        <Icon
                          icon="ei:arrow-left"
                          color={indexView === 0 ? "grey" : color.main}
                          width={isMobile ? 30 : 50}
                        />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={nextClick}
                        disabled={
                          indexView === cardDetailData?.length - 1
                            ? true
                            : false
                        }
                      >
                        <Icon
                          icon="ei:arrow-right"
                          color={
                            indexView === cardDetailData?.length - 1
                              ? "grey"
                              : color.main
                          }
                          width={isMobile ? 30 : 50}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <Grid container>
                    <Grid item>
                      <Typography sx={{ color: "grey" }}>
                        Card Holder
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        sx={{ fontSize: isMobile && 20 }}
                        className="textLinearBackground"
                      >
                        {cardData?.financialAccounts[0]?.name}
                      </Typography>
                    </Grid>
                    {cardData?.status === "ACTIVE" ? (
                      <Grid item sx={{ mt: isMobile ? -1.8 : -1 }}>
                        <Typography
                          sx={{
                            backgroundColor: "#0D1A10",
                            p: 1,
                            borderRadius: 1,
                            color: "#05841E",
                            fontSize: isMobile && 12,
                          }}
                        >
                          Activated
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item sx={{ mt: isMobile ? -1.8 : -1 }}>
                        <Typography
                          sx={{
                            backgroundColor: "#6B0004",
                            p: 1,
                            borderRadius: 1,
                            color: "#FF0000",
                            fontSize: isMobile && 12,
                          }}
                        >
                          Blocked
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Divider sx={{ borderColor: "#3c3e3c" }} />
                  {cardsTransactionList?.length > 0 ? (
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      mt={3}
                      sx={{ mb: 2 }}
                    >
                      {cardsTransactionList?.map((item, i) => {
                        if (i > 3) {
                          return (
                            <Grid item key={item?.id} xs={isMobile ? 12 : 6}>
                              <Card
                                sx={{
                                  background: color.black,
                                  p: 2.1,

                                  display: "flex",
                                  flexDirection: "column",
                                  borderRadius: "8px",
                                  alignContent: "center",
                                }}
                              >
                                <Grid>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      WebkitLineClamp: 1,
                                      color: "white",
                                    }}
                                  >
                                    {item?.type} - ${item?.amount?.value}{" "}
                                    {item?.amount?.currencyCode}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {moment(item?.date).format(
                                      "MMMM Do, YYYY HH:MM A"
                                    )}{" "}
                                    - Status: {item?.status}
                                  </Typography>
                                </Grid>
                              </Card>
                            </Grid>
                          );
                        }
                      })}
                      {cardsTranscationCount > 2 && (
                        <Box display={"flex"} justifyContent={"center"} mt={5}>
                          <SVButton
                            // onClick={() =>
                            //   navigate(`/profile/transaction/${profileId}`)
                            // }
                            text={"View All"}
                            sx={{
                              borderRadius: 10,
                              fontSize: isMobile && 10,
                              height: 32,
                              p: 2,
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="28vh" // Adjust the height as needed
                      textAlign="center"
                    >
                      <Typography variant="h6" color="textSecondary">
                        No Transactions Yet
                      </Typography>
                    </Box>
                  )}
                  {/* <Grid container sx={{ color: color.main, mt: 2 }}>
                    <Grid item xs={5}>
                      <Typography>Total Limit</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {currencyFormat(0)}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ color: color.main, mt: 1 }}>
                    <Grid item xs={5}>
                      <Typography>Total Debt</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {currencyFormat(0)}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ color: color.main, mt: 1 }}>
                    <Grid item xs={5}>
                      <Typography>Transaction History</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {currencyFormat(0)}
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showConfirmDialog && (
            <>
              <StatusCardDialog
                open={showConfirmDialog}
                title={"Confirm!"}
                text={
                  cardData?.status === "SUSPENDED"
                    ? `Do you want to acitvate this card XXXX XXXX XXXX ${cardData?.last4}?`
                    : `Do you want to block this card XXXX XXXX XXXX ${cardData?.last4}?`
                }
                // onClose={() => setShowConfirmProfileDialog(false)}
                handleAgree={() => {
                  activateCard();
                }}
                handleClose={closeReasonDialog}
                cancelButtonText="Close"
                agreeButtonText={
                  cardData?.status === "ACTIVE" ? "Block" : "Activate"
                }
                formData={formData}
                setFormData={setFormData}
                formError={formError}
                setFormError={setFormError}
              />
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

export default RecentHistory;
