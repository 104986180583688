import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { DialogTitle } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import profilePic from "../../assets/img/default.jpg";
import AdminButton from "../adminButton";

export default function ViewdetailsDialog(props) {
  const { open, handleClose, data, cameFrom, forReject, handleAgree } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
    >
      {cameFrom === "delivery" ? (
        <>
          <Grid container justifyContent={"space-between"}>
            <Grid>
              <DialogTitle
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" User Details"}
              </DialogTitle>
            </Grid>
            <Grid
              sx={{
                p: 1,
              }}
            >
              <Button
                variant="Yes"
                sx={{
                  mt: 0.5,
                  color: "white",
                  backgroundColor: "#299AD5",
                  height: 25,
                  ":hover": {
                    color: "white",
                    backgroundColor: "#5856d6",
                  },
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          {/* {" User Details"} */}
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <img src={profilePic} width={50} height={50} />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <b>
                  {data?.userData?.first_name
                    ? data?.userData?.first_name +
                      " " +
                      data?.userData?.surename
                    : "N/A"}
                </b>
              </Typography>
              <Grid>
                <Typography style={{ fontSize: 12, color: "#3762ea" }}>
                  {data?.userData?.email
                    ? "@" + data?.userData?.email.split("@")[1]
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <span
                style={{
                  color: "#78CC37",
                  background: "#e0f7ea",
                  fontSize: 11,
                  padding: 4,
                  borderRadius: "10%",
                }}
              >
                <b>Active</b>
              </span>{" "}
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ margin: 0 }}
        >
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {/* <Avatar
              alt="Remy Sharp"
              src={
                data.profileImageUrl
                  ? data.profileImageUrl
                  : "/assets/avatars/avatar-jie-yan-song.png"
              }
              sx={{ width: 56, height: 56, textAlign: "center" }}
            /> */}
          </Typography>
          <br />

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black", fontSize: 12 }}>
                New Account Request
              </Typography>
            </Grid>
            <Grid sx={{ mt: -4 }}>
              <Typography sx={{ mb: 1.5 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <AdminButton sx={{ fontSize: 12 }} title={"Approved"} />
                  </Grid>
                  <Grid item>
                    <AdminButton
                      title={"Reject"}
                      sx={{ background: "red", fontSize: 12 }}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b>Email</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.email ? data?.userData?.email : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b>Date Of Birth</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.dateOfBirth
                  ? data?.userData?.dateOfBirth
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b>locality</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.locality ? data?.userData?.locality : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b>Email Verified</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.isEmailVerified ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b>Contact</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.phone
                  ? data?.userData?.countryCode + data?.userData?.phone
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b>SSN</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.ssn ? data?.userData?.ssn : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b>Address</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.address ? data?.userData?.address : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b>Joining Date</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.createdDate
                  ? data?.userData?.createdDate
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5, color: "black" }}>
                <b> Status</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userData?.status && (
                  <span
                    style={{
                      color: "#78CC37",
                      background: "#e0f7ea",
                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b>Active</b>
                  </span>
                )}
                {data?.userData?.status === "suspended" && (
                  <span
                    style={{
                      color: "black",
                      background: "#ddddde",
                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b> Unactive</b>
                  </span>
                )}
                {data?.userData?.status === "rejected" && (
                  <span
                    style={{
                      color: "red",
                      background: "#ffe9e9",
                      fontSize: 11,
                      padding: 4,
                      borderRadius: "10%",
                    }}
                  >
                    <b>Block</b>
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>

          {cameFrom === "delivery" && (
            <>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>Document </Typography>
                </Grid>
                <Grid>
                  <Link
                    to={data?.docFileFullUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Doc
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>No Of Booking </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    {data?.noOfBooking ? data.noOfBooking : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>Available To Start </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    {data?.availableToStart ? data.availableToStart : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    Experience To Handling{" "}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    {data?.haveExperienceToHandling
                      ? data.haveExperienceToHandling
                      : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>Airline Worked For </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ mb: 1.5 }}>
                    {data?.airlineWorkedFor ? data.airlineWorkedFor : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {cameFrom === "customer" ? (
          <>
            <Button
              variant="Yes"
              sx={{
                mt: 3,
                mb: 2,
                color: "white",
                fontSize: 12,
                backgroundColor: "grey",
                height: 40,
                ":hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        ) : (
          <>
            <Grid container justifyContent={"space-between"}>
              {data?.isAdminverified === 2 && (
                <>
                  {" "}
                  <Button
                    variant="Yes"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color: "white",
                      backgroundColor: "#299AD5",
                      height: 40,
                      ":hover": {
                        color: "white",
                        backgroundColor: "#5856d6",
                      },
                    }}
                    onClick={handleAgree}
                  >
                    Approved
                  </Button>
                  <Button
                    variant="Yes"
                    disabled
                    sx={{
                      mt: 3,
                      mb: 2,
                      color: "white",
                      backgroundColor: "#299AD5",
                      height: 40,
                      ":hover": {
                        color: "white",
                        backgroundColor: "#5856d6",
                      },
                      "&:disabled": {
                        color: "white",
                        backgroundColor: "grey",
                      },
                    }}
                    onClick={forReject}
                  >
                    Rejected
                  </Button>
                </>
              )}
              {data?.isAdminverified === 0 && (
                <>
                  <Button
                    variant="Yes"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color: "white",
                      backgroundColor: "#299AD5",
                      height: 40,
                      ":hover": {
                        color: "white",
                        backgroundColor: "#5856d6",
                      },
                    }}
                    onClick={handleAgree}
                  >
                    Approved
                  </Button>
                  <Button
                    variant="Yes"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color: "white",
                      backgroundColor: "#299AD5",
                      height: 40,
                      ":hover": {
                        color: "white",
                        backgroundColor: "#5856d6",
                      },
                    }}
                    onClick={forReject}
                  >
                    Rejected
                  </Button>
                </>
              )}

              {data?.isAdminverified === 1 && (
                <>
                  <Button
                    variant="Yes"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color: "white",
                      backgroundColor: "#299AD5",
                      height: 40,
                      ":hover": {
                        color: "white",
                        backgroundColor: "#5856d6",
                      },
                      "&:disabled": {
                        color: "white",
                        backgroundColor: "grey",
                      },
                    }}
                    onClick={forReject}
                  >
                    Rejected
                  </Button>

                  <Button
                    variant="Yes"
                    disabled
                    sx={{
                      mt: 3,
                      mb: 2,

                      color: "white",
                      backgroundColor: "#299AD5",
                      height: 40,
                      ":hover": {
                        color: "white",
                        backgroundColor: "#5856d6",
                      },
                    }}
                    onClick={handleAgree}
                  >
                    Approved
                  </Button>
                </>
              )}
            </Grid>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
