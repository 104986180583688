import { Icon } from '@iconify/react';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { REACT_KEY } from 'utils/appConstant';

export default function GoogleAutoComplete(props) {
  const { searchValue, handlePlaceSelected, onChangeValue, handleClearSearch } = props;
  const handleInputChange = (value) => {
    onChangeValue(value);
    // Filter out non-US addresses manually
    if (value && value?.trim() !== '') {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input: value,
        componentRestrictions: { country: 'us' }
      });
    }
  };

  return (
    <div className="mt-2" style={{ position: 'relative',display:'flex',alignItems:'center' }}>
      <Autocomplete
        apiKey={REACT_KEY.GOOGLE_MAPS_API_KEY}
        style={{
          width: '100%',
          borderRadius: 8,
          border: '2px solid grey',
          color: searchValue ? 'white' : 'grey',
          padding: 17,
          // fontFamily: 'Poppins',
          fontWeight: 600,
          fontSize: 15,
          marginTop: '0.5rem',
          backgroundColor:'transparent'
        }}
        options={{
          types: ['address'],
          componentRestrictions: { country: 'us' }
        }}
        onPlaceSelected={handlePlaceSelected}
        onChange={(e) => handleInputChange(e.target.value)}
        placeholder="Address"
        value={searchValue}
      />

      {searchValue && (
        
        <Icon
          onClick={handleClearSearch}
          icon="material-symbols:close"
          width={30}
          color="grey"
          style={{ marginLeft: -40 }}
        />
      )}
    </div>
  );
}
