// import React from "react";
// import { Card, Grid, Typography, useMediaQuery, Stack } from "@mui/material";
// import { color } from "theme/color";
// import { Icon } from "@iconify/react";
// import { useTheme } from "@emotion/react";
// import moment from "moment";

// export default function EstateDetailCard({
//   data,
//   index,
//   popOverClick,
//   popover,
// }) {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("md"));
//   return (
//     <Grid key={index} item xs={isMobile ? 12 : 3}>
//       <Card
//         sx={{
//           backgroundColor: color.topbar,
//           borderRadius: 2,
//           backgroundSize: 80,
//         }}
//       >
//         <Grid
//           container
//           display={"flex"}
//           sx={{ p: 1.5 }}
//           justifyContent={"space-between"}
//         >
//           <Grid item display={"flex"}>
//             <Typography
//               sx={{
//                 color: color.main,
//                 fontSize: 12,
//                 textTransform: "uppercase",
//               }}
//             >
//               {/* {data?.principal_name} */}
//             </Typography>
//           </Grid>
//           <Grid item sx={{ mt: -1 }}>
//             <Icon
//               icon="ph:dots-three-circle"
//               onClick={popOverClick}
//               style={{
//                 color: color.main,
//                 cursor: "pointer",
//               }}
//               width={35}
//             />
//             {popover}
//             {/* menu items */}
//           </Grid>
//         </Grid>
//         <Grid
//           container
//           sx={{ px: 1.5, mt: -2 }}
//           display={"flex"}
//           justifyContent={"space-between"}
//         >
//           <Grid item  sx={{mt:-2}}>
//             <Icon icon="vscode-icons:file-type-pdf2" width={80}  />
//           </Grid>
//           <Grid item display={"flex"} alignItems={"end"}>
//             <Stack>
//               {data?.status === "approve" && (
//                 <>
//                   <span
//                     style={{
//                       color: color.main,
//                       background: "#4BA64F",
//                       fontSize: 11,
//                       padding: 4,
//                       // paddingLeft: "0.7rem",
//                       textAlign:'center',
//                       borderRadius: "10%",
//                       marginBottom: "0.5rem",
//                     }}
//                   >
//                     <b>Approved</b>
//                   </span>
//                 </>
//               )}
//               {data?.status === "reject" && (
//                 <>
//                   <span
//                     style={{
//                       color: color.main,
//                       background: "#E63C39",
//                       fontSize: 11,
//                       padding: 4,
//                       textAlign:'center',
//                       borderRadius: "10%",
//                       marginBottom: "0.5rem",
//                     }}
//                   >
//                     <b>Rejected</b>
//                   </span>
//                 </>
//               )}
//               <Typography
//                 sx={{
//                   color: color.main,
//                   fontSize: 12,
//                   textTransform: "uppercase",
//                   mb: 1,
//                   fontWeight: 600,
//                 }}
//               >
//                 {moment(data?.timestamp).format("MMM Do, YYYY")}
//               </Typography>
//             </Stack>
//           </Grid>
//         </Grid>
//       </Card>
//     </Grid>
//   );
// }

import { Icon } from "@iconify/react";
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SVButton from "components/SVButton";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { reshareSignatureLink } from "store/slice/estate";
import { color } from "theme/color";
import { ESTATE_PLANNING_KEYS, ESTATE_STATUS } from "utils/appConstant";
import { capitalizeFirstLetter } from "utils/constantFun";
// import { useDispatch } from 'react-redux';

export default function EStateCard(props) {
  const {
    data,
    setShowDetails,
    setDetailsData,
    onEditClick,
    popover,
    popOverClick,
    onDeleteClick,
  } = props;
  const [isLoading, setIsloading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const handleReshareLink = (userMapperId) => {
    dispatch(reshareSignatureLink(userMapperId, setIsloading));
  };

  const handleDownloadPdf = (pdf) => {
    const link = document.createElement("a");
    link.href = pdf;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Grid item xs={isMobile ? 12 : 4}>
        <Card
          sx={{
            backgroundColor: color.topbar,
            borderRadius: 2,
            p: 2,
          }}
          style={{
            minHeight:
              data?.formType == ESTATE_PLANNING_KEYS?.POWER_OF_ATTORNEY
                ? 495
                : data?.formType ==
                    ESTATE_PLANNING_KEYS?.LAST_WILL_AND_TESTAMENT
                  ? 516
                  : data?.formType ==
                      ESTATE_PLANNING_KEYS?.LIVING_WILL_HEALTH_CARE_PROXY
                    ? 685
                    : "",
          }}
        >
          <Stack
            direction={"row"}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Stack>
              <Typography variant="body" color={"white"}>
                Principal Name
              </Typography>
              <Typography fontSize={15} color={"white"}>
                {data?.principal_name}{" "}
              </Typography>
            </Stack>

            <Grid item sx={{ mt: -1, display: "flex", alignItems: "center" }}>
              {data?.status === ESTATE_STATUS?.REJECT && (
                <Icon
                  icon="uil:edit"
                  color="white"
                  width={30}
                  style={{ cursor: "pointer", marginRight: "1rem" }}
                  onClick={() => {
                    onEditClick(data);
                  }}
                />
              )}
              <Icon
                icon="mingcute:delete-2-fill"
                color="red"
                width={30}
                style={{ cursor: "pointer", marginRight: "1rem" }}
                onClick={() => {
                  onDeleteClick(data);
                }}
              />
              {data?.status == ESTATE_STATUS?.REVIEW && (
                <Icon
                  icon="ph:dots-three-circle"
                  onClick={popOverClick}
                  style={{
                    color: color.main,
                    cursor: "pointer",
                  }}
                  width={35}
                />
              )}
              {popover}
              {/* menu items */}
            </Grid>
          </Stack>

          <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <Stack
            direction={"row"}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Stack>
              <Typography variant="body" color={"white"}>
                Created
              </Typography>
              <Typography fontSize={15} color={"white"}>
                {moment(data?.timestamp).format("MMMM DD, YYYY")}
              </Typography>
            </Stack>

            <Typography
              variant="h6"
              style={{
                color:
                  data?.status === ESTATE_STATUS?.REVIEW
                    ? "#1098DB"
                    : data?.status === ESTATE_STATUS?.SIGNATURE_PENDING
                      ? "yellow"
                      : data?.status === ESTATE_STATUS?.APPROVE
                        ? "#00fc00"
                        : "red",
              }}
            >
              {capitalizeFirstLetter(data?.status)}
            </Typography>
          </Stack>
          {data?.agent_details?.length > 0 && (
            <Box my={3}>
              <Typography fontSize={15} color={"white"}>
                Agent Information
              </Typography>
              {data?.agent_details?.map((item, i) => {
                return (
                  <>
                    {item?.name && (
                      <Grid container key={i} my={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={15} color={"white"}>
                            {i + 1}. Agent Name &nbsp;<span> : </span>&nbsp;{" "}
                            {item?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {item?.email && (
                      <Grid container key={i} my={2} pl={1.5}>
                        <Grid item xs={12}>
                          <Typography fontSize={15} color={"white"}>
                            Agent Email &nbsp;&nbsp;<span> : </span>&nbsp;&nbsp;
                            {item?.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                );
              })}
            </Box>
          )}
          {data?.witness_details?.length > 0 && (
            <Box my={3}>
              <Typography fontSize={15} color={"white"}>
                Witness Information
              </Typography>
              {data?.witness_details?.map((item, i) => {
                return (
                  <>
                    {item?.name && (
                      <Grid container key={i} my={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={15} color={"white"}>
                            {i + 1}. Witness Name &nbsp;&nbsp;<span> : </span>
                            &nbsp;&nbsp;{item?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {item?.email && (
                      <Grid container key={i} my={2}>
                        <Grid item xs={12}>
                          <Typography fontSize={15} color={"white"}>
                            Witness Email &nbsp;&nbsp;<span> : </span>
                            &nbsp;&nbsp;{item?.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                );
              })}
            </Box>
          )}
          <Stack direction={"row"} display={"flex"} justifyContent={"end"}>
            {data?.status === ESTATE_STATUS?.APPROVE ? (
              <SVButton
                text="Download Pdf"
                onClick={() => {
                  handleDownloadPdf(data?.form_pdf);
                }}
                height="38px"
                background={"transparent"}
                style={{ border: "2px solid white" }}
              />
            ) : (
              <SVButton
                text="View Details"
                onClick={() => {
                  setDetailsData(data);
                  setShowDetails(true);
                }}
                height="38px"
                backgroundColor={"transparent"}
                sx={{ border: "2px solid white" }}
              />
            )}
          </Stack>
          {data?.status === ESTATE_STATUS?.REJECT && (
            <Stack direction={"row"} mt={4}>
              <Typography variant="h6" style={{ color: "red" }}>
                Reason :
              </Typography>
              &nbsp;&nbsp;
              <Typography color={"white"} variant="h6">
                {data?.reject_reason}{" "}
              </Typography>
            </Stack>
          )}
          {data?.status === ESTATE_STATUS?.SIGNATURE_PENDING && (
            <>
              <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
              <div className="px-5 py-2">
                <SVButton
                  fullWidth
                  isLoading={isLoading}
                  onClick={() => {
                    setIsloading(true);
                    handleReshareLink(data?.userMapperId);
                  }}
                  text="Resend Signature Link"
                />
              </div>
            </>
          )}
        </Card>
      </Grid>
    </>
  );
}
