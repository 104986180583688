import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdminButton from "components/adminButton";
import AdminInput from "components/adminInput";
import Spinner from "components/spinner";
import TopHeading from "components/topHeading";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addManualAssetsLiabilities,
  addManualIncomeAssetsLiabilities,
} from "store/slice/assets";
import { color } from "theme/color";
import {
  INVESTMENT_TYPES,
  LOANS_TYPES,
  MANUAL_ASSETS_TYPE,
  RATE_OF_PAY_RATE,
} from "utils/appConstant";
import { validateEmpty } from "utils/constantFun";

const ManualAssets = () => {
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const submit = () => {
    if (!formData.type) {
      setFormData((formData) => ({
        ...formData,
        typeErr: "Please select Asset type",
      }));
      return;
    }
    if (formData?.type == "income") {
      if (validateEmpty(formData.institution_name)) {
        setFormData((formData) => ({
          ...formData,
          institution_nameErr: "Please enter bank name",
        }));
        return;
      }
      if (validateEmpty(formData.current_amount)) {
        setFormData((formData) => ({
          ...formData,
          current_amountErr: "Please enter current balance",
        }));
        return;
      }
      if (validateEmpty(formData.rate_of_pay_amount)) {
        setFormData((formData) => ({
          ...formData,
          rate_of_pay_amountErr: "Please enter rate of pay amount",
        }));
        return;
      }
      if (validateEmpty(formData.rate_of_pay_rate)) {
        setFormData((formData) => ({
          ...formData,
          rate_of_pay_rateErr: "Please enter rate of pay rate",
        }));
        return;
      }
      setIsLoading(true);
      var request = {
        userId: id,
        institution_name: formData?.institution_name,
        rate_of_pay_amount: formData?.rate_of_pay_amount,
        pay_frequency: "BIWEEKLY",
        rate_of_pay_rate: formData?.rate_of_pay_rate,
        iso_currency_code: "USD",
        current_amount: formData?.current_amount,
        description: formData?.description,
      };
      dispatch(
        addManualIncomeAssetsLiabilities(
          request,
          id,
          navigate,
          setIsLoading,
          setFormData
        )
      );
    } else {
      if (validateEmpty(formData.name)) {
        setFormData((formData) => ({
          ...formData,
          nameErr: "Please enter bank name",
        }));
        return;
      }
      if (formData.type == "investment") {
        if (!formData.subtype) {
          setFormData((formData) => ({
            ...formData,
            subtypeErr: "Please select investment type",
          }));
          return;
        }
      }
      if (formData.type == "loan") {
        if (!formData.subtype) {
          setFormData((formData) => ({
            ...formData,
            subtypeErr: "Please select loan type",
          }));
          return;
        }
      }
      if (validateEmpty(formData.balance_available)) {
        setFormData((formData) => ({
          ...formData,
          balance_availableErr: "Please enter available balance",
        }));
        return;
      }

      if (validateEmpty(formData.balance_current)) {
        setFormData((formData) => ({
          ...formData,
          balance_currentErr: "Please enter current balance",
        }));
        return;
      }
      if (validateEmpty(formData.balance_limit)) {
        setFormData((formData) => ({
          ...formData,
          balance_limitErr: "Please enter balance limit",
        }));
        return;
      }
      setIsLoading(true);
      var data = {
        userId: id,
        type: formData?.type,
        name: formData?.name,
        subtype: formData?.subtype,
        balance_available: formData?.balance_available,
        balance_current: formData?.balance_current,
        balance_iso_currency_code: "USD",
        balance_limit: formData?.balance_limit,
      };
      dispatch(
        addManualAssetsLiabilities(
          data,
          id,
          navigate,
          setIsLoading,
          setFormData
        )
      );
    }
  };
  return (
    <div>
      <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Manual"} />

      <Grid container mt={4}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/users">
              Users
            </Link>
            <Link style={{ color: "white" }} to={`/profile/${id}`}>
              User Profile
            </Link>
            <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
              Assets-Liabilities Details
            </Link>
            <Typography sx={{ color: "white" }}>Add</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Spinner open={isLoading} />

      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              label="Typed"
              onChange={(val) => {
                if (val) {
                  setFormData((formData) => ({
                    ...formData,
                    type: val.target.value,
                    typeErr: "",
                    subtype: "",
                  }));
                }
              }}
              sx={{
                color: "white",
                width: "100%",
                "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {MANUAL_ASSETS_TYPE?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.id}>
                    {item?.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {formData?.typeErr && (
            <Typography
              sx={{
                color: theme.palette.error.main,
                fontSize: 12,
                ml: 1,
                mt: 1,
              }}
            >
              {formData?.typeErr}
            </Typography>
          )}
        </Grid>
        {formData?.type && (
          <>
            {formData?.type == "income" ? (
              <Grid container columnSpacing={3}>
                <Grid item xs={isMobile ? 12 : 6}>
                  <AdminInput
                    type="text"
                    color="white"
                    title="Bank Name"
                    maxLength={30}
                    onChange={(e) => {
                      setFormData((formData) => ({
                        ...formData,
                        institution_name: e.target.value,
                        institution_nameErr: "",
                      }));
                    }}
                    value={formData?.institution_name}
                    formError={formData?.institution_nameErr}
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <AdminInput
                    title="Current Balance"
                    type="number"
                    color="white"
                    maxLength={13}
                    onChange={(event) => {
                      if (event.target.value) {
                        if (event.target.value?.length < 14) {
                          setFormData((formData) => ({
                            ...formData,
                            current_amount: event.target.value * 1,
                            current_amountErr: "",
                          }));
                        }
                      } else {
                        setFormData((formData) => ({
                          ...formData,
                          current_amount: "",
                        }));
                      }
                    }}
                    value={formData?.current_amount}
                    formError={formData?.current_amountErr}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <AdminInput
                    title="Rate Of Pay Amount"
                    type="number"
                    color="white"
                    maxLength={13}
                    onChange={(event) => {
                      if (event.target.value) {
                        if (event.target.value?.length < 14) {
                          setFormData((formData) => ({
                            ...formData,
                            rate_of_pay_amount: event.target.value * 1,
                            rate_of_pay_amountErr: "",
                          }));
                        }
                      } else {
                        setFormData((formData) => ({
                          ...formData,
                          rate_of_pay_amount: "",
                        }));
                      }
                    }}
                    value={formData?.rate_of_pay_amount}
                    formError={formData?.rate_of_pay_amountErr}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Rate Of Pay Rate
                    </InputLabel>
                    <Select
                      label="  Rate Of Pay Rate ss"
                      onChange={(val) => {
                        if (val) {
                          setFormData((formData) => ({
                            ...formData,
                            rate_of_pay_rate: val.target.value,
                            rate_of_pay_rateErr: "",
                          }));
                        }
                      }}
                      sx={{
                        color: "white",
                        width: "100%",
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {RATE_OF_PAY_RATE?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {formData?.subtypeErr && (
                    <Typography
                      sx={{
                        color: theme.palette.error.main,
                        fontSize: 12,
                        ml: 1,
                        mt: 1,
                      }}
                    >
                      {formData?.subtypeErr}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <AdminInput
                    title="Description"
                    color="white"
                    type='text'
                    onChange={(e) => {
                      setFormData((formData) => ({
                        ...formData,
                        description: e.target.value,
                        descriptionErr: "",
                      }));
                    }}
                    value={formData?.description}
                    formError={formData?.descriptionErr}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container columnSpacing={3}>
                <Grid item xs={isMobile ? 12 : 6}>
                  <AdminInput
                    type="text"
                    color="white"
                    title="Bank Name"
                    maxLength={30}
                    onChange={(e) => {
                      setFormData((formData) => ({
                        ...formData,
                        name: e.target.value,
                        nameErr: "",
                      }));
                    }}
                    value={formData?.name}
                    formError={formData?.nameErr}
                  />
                </Grid>

                {formData?.type == "investment" && (
                  <Grid item xs={isMobile ? 12 : 6}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Investment Types
                      </InputLabel>
                      <Select
                        label="Investment Typed"
                        onChange={(val) => {
                          if (val) {
                            setFormData((formData) => ({
                              ...formData,
                              subtype: val.target.value,
                              subtypeErr: "",
                            }));
                          }
                        }}
                        sx={{
                          color: "white",
                          width: "100%",
                          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "white",
                            },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {INVESTMENT_TYPES?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {formData?.subtypeErr && (
                      <Typography
                        sx={{
                          color: theme.palette.error.main,
                          fontSize: 12,
                          ml: 1,
                          mt: 1,
                        }}
                      >
                        {formData?.subtypeErr}
                      </Typography>
                    )}
                  </Grid>
                )}
                {formData?.type == "loan" && (
                  <Grid item xs={isMobile ? 12 : 6}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Loan Types
                      </InputLabel>
                      <Select
                        label="Asset Typed"
                        onChange={(val) => {
                          if (val) {
                            setFormData((formData) => ({
                              ...formData,
                              subtype: val.target.value,
                              subtypeErr: "",
                            }));
                          }
                        }}
                        sx={{
                          color: "white",
                          width: "100%",
                          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "white",
                            },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {LOANS_TYPES?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {formData?.subtypeErr && (
                      <Typography
                        sx={{
                          color: theme.palette.error.main,
                          fontSize: 12,
                          ml: 1,
                          mt: 1,
                        }}
                      >
                        {formData?.subtypeErr}
                      </Typography>
                    )}
                  </Grid>
                )}
                <Grid item xs={isMobile ? 12 : 6}>
                  <AdminInput
                    title="Available Balance"
                    type="number"
                    color="white"
                    maxLength={13}
                    onChange={(event) => {
                      if (event.target.value) {
                        if (event.target.value?.length < 14) {
                          setFormData((formData) => ({
                            ...formData,
                            balance_available: event.target.value * 1,
                            balance_availableErr: "",
                          }));
                        }
                      } else {
                        setFormData((formData) => ({
                          ...formData,
                          balance_available: "",
                        }));
                      }
                    }}
                    value={formData?.balance_available}
                    formError={formData?.balance_availableErr}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <AdminInput
                    title="Current Balance"
                    type="number"
                    color="white"
                    maxLength={13}
                    onChange={(event) => {
                      if (event.target.value) {
                        if (event.target.value?.length < 14) {
                          setFormData((formData) => ({
                            ...formData,
                            balance_current: event.target.value * 1,
                            balance_currentErr: "",
                          }));
                        }
                      } else {
                        setFormData((formData) => ({
                          ...formData,
                          balance_current: "",
                        }));
                      }
                    }}
                    value={formData?.balance_current}
                    formError={formData?.balance_currentErr}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <AdminInput
                    title="Balance Limit"
                    type="number"
                    color="white"
                    maxLength={13}
                    onChange={(event) => {
                      if (event.target.value) {
                        if (event.target.value?.length < 14) {
                          setFormData((formData) => ({
                            ...formData,
                            balance_limit: event.target.value * 1,
                            balance_limitErr: "",
                          }));
                        }
                      } else {
                        setFormData((formData) => ({
                          ...formData,
                          balance_limit: "",
                        }));
                      }
                    }}
                    value={formData?.balance_limit}
                    formError={formData?.balance_limitErr}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
        <AdminButton title="Submit" onClick={submit} />
      </Box>
    </div>
  );
};

export default ManualAssets;
