import * as React from "react";
import { Icon } from "@iconify/react";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, verifyResetToken } from "../../store/slice/auth";
import SuccessPage from "../successPage";
import Spinner from "../../components/spinner";

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resetSuccess, verifyResetTokenData } = useSelector(
    (state) => state.auth
  );
  const [isLoading, setIsloading] = useState(false);
  const { id } = useParams();
  const { type } = useParams();
  const [formData, setFormData] = useState("");
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState({});

  useEffect(() => {
    if (id) {
      setFormData((formData) => ({
        ...formData,
        id: id,
      }));
    }
  }, [id]);

  useEffect(() => {
    setIsloading(true)
    const data = {
      resetToken: id,
    };
    dispatch(verifyResetToken(data,setIsloading));
  }, []);

  const handleSubmit = () => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!formData.password) {
      setFormError((formError) => ({
        ...formError,
        password: "Please enter a password",
      }));
      return;
    } else if (!re.test(formData.password)) {
      setFormError((formError) => ({
        ...formError,
        password:
          "A password must contain at least eight characters, at least one number, both lower and uppercase letters, and special characters",
      }));
      return;
    }
    if (!formData.confirmPassword) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Please enter a confirm password",
      }));
      return;
    }

    if (formData.confirmPassword != formData.password) {
      setFormError((formError) => ({
        ...formError,
        confirmPassword: "Password and confirm password does not match",
      }));
      return;
    }

    let requestParam = {
      password: formData.password,
      resetToken: formData.id,
    };
    dispatch(resetPassword(requestParam, navigate, true));
  };

  const showPassWordPage = () => {
    return (
      <>
        <Typography sx={{ textAlign: "center", my: "10px", fontSize: 18 }}>
          Change Password
        </Typography>
        <Box noValidate sx={{ mt: 1 }}>
          <AdminInput
            title="Password"
            color="white"
            required={true}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#C5C5C5",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
              },
            }}
            type={showPassword.password ? "text" : "password"}
            value={formData.password}
            onChange={(val) => {
              if (val) {
                setFormData((formData) => ({
                  ...formData,
                  password: val.target.value,
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    password: "",
                  }));
                }
              }
            }}
            formError={formError.password}
            endIcon={
              <Icon
                icon={
                  !showPassword.password ? "iconamoon:eye-off" : "tabler:eye"
                }
                width="28"
                height="28"
              />
            }
            PasswordShowClick={() => {
              setShowPassword((showPassword) => ({
                ...showPassword,
                password: !showPassword.password,
              }));
            }}
          />
          <AdminInput
            title="Confirm password"
            color="white"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#C5C5C5",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
              },
            }}
            required={true}
            type={showPassword.confirmPassword ? "text" : "password"}
            value={formData.confirmPassword}
            onChange={(val) => {
              if (val) {
                setFormData((formData) => ({
                  ...formData,
                  confirmPassword: val.target.value,
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    confirmPassword: "",
                  }));
                }
              }
            }}
            formError={formError.confirmPassword}
            endIcon={
              <Icon
                icon={
                  !showPassword.confirmPassword
                    ? "iconamoon:eye-off"
                    : "tabler:eye"
                }
                width="28"
                height="28"
              />
            }
            PasswordShowClick={() => {
              setShowPassword((showPassword) => ({
                ...showPassword,
                confirmPassword: !showPassword.confirmPassword,
              }));
            }}
          />

          <AdminButton
            onClick={handleSubmit}
            title={"Submit"}
            fullWidth
            height={40}
          />
        </Box>
        <Spinner open={isLoading} />
      </>
    );
  };
  return resetSuccess ? (
    <SuccessPage link={type == "web" ? type : type} />
  ) : (
    <>
      {!isLoading && verifyResetTokenData === null ? (
        <>
          <Typography sx={{ textAlign: "center", my: "10px", fontSize: 22 }}>
            Link Expired{" "}
            <Typography sx={{ mt: 1 }}>
              <Icon icon="lets-icons:sad-light" width={50} />
            </Typography>
          </Typography>
          <Typography sx={{ textAlign: "center", my: "10px", fontSize: 18 }}>
            To Reset your password, return to the login page and select "Reset
            Password" to send a new email.
          </Typography>
        </>
      ) : (
        <>{!isLoading && showPassWordPage()}</>
      )}
    </>
  );
}
