import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import SVButton from "components/SVButton";
import SVOutlinedButton from "components/SVOutlinedButton";
import { Box, Grid } from "@mui/material";
import AdminInput from "components/adminInput";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const OtpView = ({ ...props }) => {
  const {
    openDialog,
    onClose,
    title,
    message,
    agreeButtonText,
    isLoading,
    cancelButtonText,
    handleAgree,
    handleDisagree,
    formError,
    setFormError,
    otp,
    setOtp,
  } = props;
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>{message}</Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <AdminInput
              color="black"
              title="Enter 4-digit code here"
              type="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#C5C5C5",
                  },
                  "&:hover fieldset": {
                    borderColor: "#C5C5C5",
                  },
                },
              }}
              value={otp}
              onChange={(val) => {
                if (val) {
                  setOtp(
                    val.target.value?.replace(/\D/g, "")?.replace(/^.{5}$/, otp)
                  );
                  setFormError("");
                } else {
                  setOtp("");
                }
              }}
              formError={formError}
            />

            <Grid container display="flex" justifyContent={"right"}>
              <Grid item>
                <Link
                  sx={{ color: "grey" }}
                  // onClick={resendOtp}
                  tabIndex={0}
                  component="button"
                >
                  Resend OTP
                </Link>{" "}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <SVButton
            onClick={handleAgree}
            text={agreeButtonText}
            isLoading={isLoading}
          />
          <SVOutlinedButton onClick={handleDisagree} text={cancelButtonText} />
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default React.memo(OtpView);
