import React, { memo } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Slide,
  Stack,
} from "@mui/material";
import { color } from "theme/color";
import SVDivider from "components/SVDivider";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import "./index.css";
import moment from "moment";
import { currencyFormat } from "utils/currencyFormatter";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InsuranceDetailDialog = (props) => {
  const { data, open, handleClose } = props;
  const { insuranceData, benefitsTypesData } = useSelector(
    (state) => state.assets
  );

  //eslint
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="paper"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleClose(false)}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              sx={{ fontSize: 18, fontWeight: 600 }}
              color={color.black}
            >
              {insuranceData?.length > 0 &&
                insuranceData[
                  insuranceData?.findIndex((item) => item.id == data.type_id)
                ]?.name}{" "}
              Insurance Detail
            </Typography>
            <Icon
              icon="mingcute:close-line"
              width={30}
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Stack>
        </DialogTitle>
        <SVDivider />

        <DialogContent>
          <DialogContentText sx={{ mt: 0.5 }}>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Policy Name
              </Typography>
              <Typography className="info-detail-text">
                {data?.policy_name ? data?.policy_name : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">Created</Typography>
              <Typography className="info-detail-text">
                {data?.type?.createdDate
                  ? moment(data?.type?.createdDate).format("DD/MM/YYYY")
                  : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                {" "}
                Annual premium
              </Typography>
              <Typography className="info-detail-text">
                {data?.annual_premium
                  ? currencyFormat(data?.annual_premium)
                  : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                {" "}
                Premium inflation
              </Typography>
              <Typography className="info-detail-text">
                {data?.premium_inflation ? `${data?.premium_inflation}%` : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Bodiliy injury pp
              </Typography>
              <Typography className="info-detail-text">
                {data?.bodily_injury_pp
                  ? currencyFormat(data?.bodily_injury_pp)
                  : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Bodiliy injury pa
              </Typography>
              <Typography className="info-detail-text">
                {data?.bodily_injury_pa
                  ? currencyFormat(data?.bodily_injury_pa)
                  : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Property damange
              </Typography>
              <Typography className="info-detail-text">
                {data?.property_damage ? data?.property_damage : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Deductible
              </Typography>
              <Typography className="info-detail-text">
                {data?.deductible ? currencyFormat(data?.deductible) : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Comprehensive
              </Typography>
              <Typography className="info-detail-text">
                {data?.comprehensive
                  ? data?.comprehensive === true
                    ? "Yes"
                    : "No"
                  : "-"}{" "}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Collision coverage
              </Typography>
              <Typography className="info-detail-text">
                {data?.collision_coverage
                  ? data?.collision_coverage === true
                    ? "Yes"
                    : "No"
                  : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Policy ends
              </Typography>
              <Typography className="info-detail-text">
                {data?.policy_end_year ? data?.policy_end_year : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Included In Plan
              </Typography>
              <Typography className="info-detail-text">
                {data?.included_in_plan
                  ? data?.included_in_plan === true
                    ? "Yes"
                    : "NO"
                  : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">Insured</Typography>
              <Typography className="info-detail-text">
                {data?.insured ? data?.insured : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Beneficiary{" "}
              </Typography>
              <Typography className="info-detail-text">
                {data?.beneficiary_percentage
                  ? `${data?.beneficiary_percentage}%`
                  : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Premium end year
              </Typography>
              <Typography className="info-detail-text">
                {data?.policy_end_year === data?.policy_end_year && "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Death benefit
              </Typography>
              <Typography className="info-detail-text">
                {data?.death_benefit_amount
                  ? currencyFormat(data?.death_benefit_amount)
                  : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Policy ends
              </Typography>
              <Typography className="info-detail-text">
                {data?.policy_end_year ? data?.policy_end_year : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Premium ends
              </Typography>
              <Typography className="info-detail-text">
                {data?.premium_ends?.name ? data?.premium_ends?.name : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Cash value
              </Typography>
              <Typography className="info-detail-text">
                {data?.cash_value ? currencyFormat(data?.cash_value) : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Cash value approach
              </Typography>
              <Typography className="info-detail-text">
                {data?.cash_value_approach?.name
                  ? data?.cash_value_approach?.name
                  : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Crediting rate
              </Typography>
              <Typography className="info-detail-text">
                {data?.crediting_rate ? `${data?.crediting_rate}%` : "-"}{" "}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">Loan rate</Typography>
              <Typography className="info-detail-text">
                {data?.loan_rate ? `${data?.loan_rate}%` : "-"}{" "}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Annual fee
              </Typography>
              <Typography className="info-detail-text">
                {data?.annual_fee_percentage
                  ? `${data?.annual_fee_percentage}%`
                  : "-"}{" "}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Distribution
              </Typography>
              <Typography className="info-detail-text">
                {data?.distribution?.name ?? "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">Amount</Typography>
              <Typography className="info-detail-text">
                {data?.amount ? currencyFormat(data?.amount) : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Starting year
              </Typography>
              <Typography className="info-detail-text">
                {data?.starting_year ? data?.starting_year : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Ending year
              </Typography>
              <Typography className="info-detail-text">
                {data?.ending_year ? data?.ending_year : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Death benefit option
              </Typography>
              <Typography className="info-detail-text">
                {data?.death_benefit_option?.name ?? "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Coverage Amount
              </Typography>
              <Typography className="info-detail-text">
                {data?.coverage_amount
                  ? currencyFormat(data?.coverage_amount)
                  : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Premium tax
              </Typography>
              <Typography className="info-detail-text">
                {data?.tax?.name ? data?.tax?.name : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Daily benefit
              </Typography>
              <Typography className="info-detail-text">
                {data?.daily_benefit
                  ? currencyFormat(data?.daily_benefit)
                  : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Benefit inflation
              </Typography>
              <Typography className="info-detail-text">
                {data?.benefit_inflation ? `${data?.benefit_inflation}%` : 0}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Inflation type
              </Typography>
              <Typography className="info-detail-text">
                {data?.inflation_type?.name ? data?.inflation_type?.name : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Benefit period
              </Typography>
              <Typography className="info-detail-text">
                {data?.benefit_period_value ? data?.benefit_period_value : 0}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Elimination period
              </Typography>
              <Typography className="info-detail-text">
                {data?.elimination_period?.days
                  ? data?.elimination_period?.days
                  : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Benefit taxable
              </Typography>
              <Typography className="info-detail-text">
                {data?.benefit_taxable
                  ? data?.benefit_taxable === true
                    ? "Yes"
                    : "No"
                  : "-"}
              </Typography>
            </Stack>
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Benefit type
              </Typography>
              <Typography className="info-detail-text">
                {benefitsTypesData?.length > 0
                  ? benefitsTypesData[
                      benefitsTypesData?.findIndex(
                        (item) => item.id == data?.benefit_type_id
                      )
                    ]?.name
                  : ""}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Monthly benefit
              </Typography>
              <Typography className="info-detail-text">
                {data?.monthly_benefit ? data?.monthly_benefit : "-"}
              </Typography>
            </Stack>{" "}
            <Stack direction={"row"} className="detail-box">
              <Typography className="info-detail-heading">
                Monthly benefit cap
              </Typography>
              <Typography className="info-detail-text">
                {data?.monthly_benefit_cap
                  ? data?.monthly_benefit_cap === true
                    ? "Yes"
                    : "No"
                  : "-"}
              </Typography>
            </Stack>{" "}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(InsuranceDetailDialog);
