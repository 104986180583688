import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { color } from "../../../theme/color";
import "../style.css";

export default function SideItem(props) {
  const { active = false, disabled, icon, title, index, path, open } = props;
  return (
    <ListItem
      key={index}
      disablePadding
      sx={{
        display: "block",
        ...(active && {
          backgroundColor: color.active,
          color: color.main,
        }),
        ...(disabled && {
          backgroundColor: color.main,
        }),
      }}
    >
      <Link to={path} style={{ textDecoration: "none", color: color.black }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: color.main,
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={title}
            sx={{ opacity: open ? 1 : 0, color: color.main,pt:0.4 }}
          />
        </ListItemButton>
      </Link>
    </ListItem>
  );
}
