import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { color } from "theme/color";
import image from "assets/img/insurance.png";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { clearInsuranceDetails, getInsuranceTypes } from "store/slice/assets";
import { INSURANCET_TYPES } from "utils/appConstant";
export default function Insurance() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { insuranceData } = useSelector((state) => state.assets);
  useEffect(() => {
    dispatch(getInsuranceTypes());
  }, []);
  return (
    <div>
      <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Insurance"} />

      <Grid container mt={4}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/users">
              Users
            </Link>
            <Link style={{ color: "white" }} to={`/profile/${id}`}>
              User Profile
            </Link>
            <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
              Assets-Liabilities Details
            </Link>
            <Typography sx={{ color: "white" }}>Insurance</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <Box backgroundColor="#202020">
          <Grid container py={2}>
            <Grid
              item
              xs={isMobile ? 12 : 4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Avatar
                style={{
                  height: "120px",
                  width: "120px",
                  borderRadius: 80,
                  background: "#232323",
                  border: "1px solid white",
                }}
              >
                <img src={image} width={"60%"} />
              </Avatar>
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 8}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box textAlign={"center"}>
                <h2 style={{ color: "white" }}>Financial Safety net</h2>
                <Typography fontSize={20} color={"white"}>
                  Connect your insurance with Silverback and let our experts
                  help you to achieve security knowing you're covered for
                  potential losses.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <Grid container columnSpacing={3}>
          {insuranceData?.map((item, i) => {
            if (item.is_active) {
              return (
                <Grid item xs={isMobile ? 12 : 6} key={i} mt={2}>
                  <Card
                    onClick={() => {
                      dispatch(clearInsuranceDetails());
                      if (item?.id == INSURANCET_TYPES?.TERM_LIFE) {
                        navigate(
                          `/assetsDetail/insurance/term-life-insurance/add/${id}`
                        );
                      }
                      if (item?.id == INSURANCET_TYPES?.UMBRELLA) {
                        navigate(
                          `/assetsDetail/insurance/umbrella-insurance/add/${id}`
                        );
                      }
                      if (item?.id == INSURANCET_TYPES?.WHOLE_LIFE) {
                        navigate(
                          `/assetsDetail/insurance/whole-life-insurance/add/${id}`
                        );
                      }
                      if (item?.id == INSURANCET_TYPES?.LONG_TERM) {
                        navigate(
                          `/assetsDetail/insurance/long-term-insurance/add/${id}`
                        );
                      }
                      if (item?.id == INSURANCET_TYPES?.AUTO) {
                        navigate(
                          `/assetsDetail/insurance/auto-insurance/add/${id}`
                        );
                      }
                      if (item?.id == INSURANCET_TYPES?.INDIVIDUAL_DISABILITY) {
                        navigate(
                          `/assetsDetail/insurance/individual-disability-insurance/add/${id}`
                        );
                      }
                    }}
                    sx={{
                      color: color.main,
                      background: "#202020",
                      cursor: "pointer",
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>{item?.name} </Typography>
                    <Icon icon={"solar:alt-arrow-right-linear"} width={20} />
                  </Card>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    </div>
  );
}
