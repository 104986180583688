import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { taxBracketList, taxBracketinputForm } from "store/slice/profile";
import MUIDataTable from "mui-datatables";
import Spinner from "components/spinner";
import TopHeading from "components/topHeading";
import { currencyFormat } from "utils/currencyFormatter";
import SVButton from "components/SVButton";
import TaxRatemodal from "./taxRatemodal";

let taxRateData = [];

export default function Taxrate() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  //Tax Rate Dialog Part
  const [taxModal, setTaxModal] = useState(false);
  const [formError, setFormError] = useState({});
  const [taxRateForm, setTaxRateForm] = useState({});
  const handleCancel = () => {
    setTaxRateForm(null);
    setTaxModal(false);
  };
  const { taxBracketlistData, isLoading } = useSelector(
    (state) => state.profile
  );
  useEffect(() => {
    if (taxBracketlistData?.length > 0) {
      taxBracketlistData?.map((array) => {
        array?.value?.map((data) => {
          let index = taxRateData.findIndex(
            (item) => item.id == data.id && item.year == data?.year
          );
          if (index == -1) {
            taxRateData.push(data);
          }
        });
      });
    }
  }, [taxBracketlistData]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(taxBracketList());
  }, []);

  const columns = [
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>
              <b>{value ? currencyFormat(value ? value : 0) : "-"}</b>
            </Typography>
          );
        },
      },
    },

    {
      name: "tax_rate",
      label: "Federal tax rate",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <>{value ? `${value}%` : "-"}</>;
        },
      },
    },
    {
      name: "year",
      label: "Year",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <>{value ? value : "-"}</>;
        },
      },
    },
  ];
  const options = {
    filter: false,
    pagination: false,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
  };

  const modalClose = () => {
    setTaxModal(false);
    setTaxRateForm(null);
  };

  const handleSubmit = () => {
    if (!taxRateForm?.amount) {
      setFormError((formError) => ({
        ...formError,
        amount: "Please enter the amount",
      }));
      return;
    }
    if (!taxRateForm?.rateOfInterest) {
      setFormError((formError) => ({
        ...formError,
        rateOfInterest: "Please enter the rate of interest",
      }));
      return;
    }
    if (!taxRateForm?.year) {
      setFormError((formError) => ({
        ...formError,
        year: "Please Enter valid date",
      }));
      return;
    }
    const data = {
      amount: taxRateForm?.amount,
      year: taxRateForm?.year,
      tax_rate: taxRateForm?.rateOfInterest,
    };

    dispatch(taxBracketinputForm(data, setTaxRateForm, setTaxModal));
  };
  return (
    <>
      <>
        <Grid container display="flex" justifyContent={"space-between"}>
          <Grid item>
            <TopHeading
              sx={{ p: isMobile ? 1 : "" }}
              title={"Federal Tax Rate"}
            />
          </Grid>
          <Grid item sx={{ mt: isMobile ? -2.5 : -3.5, p: isMobile ? 1 : "" }}>
            <SVButton text="Add Tax Rate" onClick={() => setTaxModal(true)} />
          </Grid>
        </Grid>
        <br />
        <div className="App wrapper">
          <>
            {taxRateData && taxRateData?.length > 0 ? (
              <Grid container>
                <Grid xs={12}>
                  <MUIDataTable
                    // title={"Events List"}
                    data={taxRateData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid #202020",
                    mt: 2,
                    height: 300,
                    backgroundColor: "#202020",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: 500,
                        mt: "7.5rem",
                        color: "white",
                      }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </div>
        {taxModal && (
          <TaxRatemodal
            openDialog={taxModal}
            agreeButtonText=" Submit"
            cancelButtonText="Cancel"
            onClose={modalClose}
            setTaxRateForm={setTaxRateForm}
            handleCancel={handleCancel}
            taxRateForm={taxRateForm}
            handleSubmit={handleSubmit}
            formError={formError}
            setFormError={setFormError}
            title="Federal tax rate"
          />
        )}
      </>

      <Spinner open={isLoading} />
    </>
  );
}
