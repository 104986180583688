import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SVSelect = ({ ...props }) => {
  const {
    label,
    value,
    onChange,
    emptyText,
    options,
    fullWidth,
    sx,
    disabled,
    color,
  } = props;
  return (
    <FormControl sx={{ ...sx, minWidth: 120 }} fullWidth={fullWidth}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        sx={{
          ...sx,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          
        }}
        labelId={`label-${label}`}
        disabled={disabled}
        id={`id-${label}`}
        value={value}
        label={label}
        onChange={onChange}
        InputProps={{
          style: {
            color: color,
          },
        }}
      >
        <MenuItem value="">
          <em>{emptyText}</em>
        </MenuItem>
        {options?.map((data, index) => {
          return (
            <MenuItem
              value={data?.id}
              key={index}
            >
              {data?.description ? data?.description :data?.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default React.memo(SVSelect);
