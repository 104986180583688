import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { STORAGE_KEY } from "utils/appConstant";

// const authData = secureLocalStorage.getItem(STORAGE_KEY.USER_AUTH);
// const token = authData?.access_token;

// axios api
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  validateStatus: (status) => {
    if (status === 401) {
      secureLocalStorage.clear();
      window.location.href = '/';
      return false;
    } else {
      return true;
    }
  },
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
  },
});



let bearerToken='';
// Add a request interceptor
// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  const authData = secureLocalStorage.getItem(STORAGE_KEY.USER_AUTH);
  const token = authData?.access_token;
    if (token) {
    bearerToken = `Bearer ${token}`;
  }
  request.headers.Authorization = bearerToken;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {

  if (error.message === 'Network Error') {
    return;
  } else if (error.response.status === 401) {
    secureLocalStorage.clear();
    window.location.href = '/';
    return;
  } else if (error.response.data.status === 404) {
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);


export { api };
