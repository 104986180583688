import { useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { getSubscribeUserList } from "../../../store/slice/profile";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
import "./index.css";
import moment from "moment";
import AdminInput from "../../../components/adminInput";
import TopHeading from "components/topHeading";
import { PAGINATION } from "utils/appConstant";

export default function index() {
  var page = PAGINATION.page;
  const [perPageRows, setNumberOfRows] = useState(PAGINATION.perPage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [pageCheck, setPageCheck] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { subscribeUserListData, totalCount, isLoading } = useSelector(
    (state) => state.profile
  );

  var searchInputRef = useRef();
  const dispatch = useDispatch();

  const subscribeUserList = (page, numberOfRows) => {
    var data = {
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: searchValue,
    };
    let isSearch = searchValue ? true : false;
    dispatch(getSubscribeUserList(data, isSearch, true));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 2) {
      subscribeUserList(page, "", e.target.vaue);
    } else if (e.target.value.length === 0) {
      subscribeUserList(page, "", e.target.vaue);
    }
  };

  useEffect(() => {
    subscribeUserList();
  }, []);

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows Per Page",
        displayRows: "of",
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },

    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 1) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        subscribeUserList(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage - 1;
        }

        setPageCheck(currentPage);
        subscribeUserList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      console.log("numberOfRows", numberOfRows);
      setNumberOfRows(numberOfRows);
      subscribeUserList(page, numberOfRows, "", "");
    },
  };

  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: page ?? 0,
      perPage: perPageRows,
      search: "",
      startDate: "",
      endDate: "",
      status: "",
    };
    let isSearch = false;
    dispatch(getSubscribeUserList(data, isSearch, true));
  };

  const columns = [
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>
              <b>{value ? value : "-"}</b>
            </Typography>
          );
        },
      },
    },

    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>
              <b>{value ? value : "-"}</b>
            </Typography>
          );
        },
      },
    },

    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (params) => {
          const email = params;
          return (
            // <Tooltip title={email} arrow>
            <div>{email}</div>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Subscribe Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography>{moment(value).format("MMMM Do, YYYY")}</Typography>
          );
        },
      },
    },
  ];

  return (
    <>
      <>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Subscribe Users"} />
        <Grid
          spacing={2}
          container
          sx={{ pt: 1, pb: 1, mt: 2, p: isMobile ? 2 : 0 }}
        >
          <Grid item xs={isMobile ? 12 : 6}>
            <AdminInput
              sx={{ mt: -1, background: "#202020" }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  subscribeUserList(page);
                }
              }}
              color="white"
              inputRef={searchInputRef}
              title="Search Subscriber"
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>
        </Grid>
        <br />
        <div className="App wrapper">
          <>
            {subscribeUserListData && subscribeUserListData.length > 0 ? (
              <Grid container>
                <Grid xs={12}>
                  <MUIDataTable
                    // title={"Events List"}
                    data={subscribeUserListData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid #202020",
                    mt: 2,
                    height: 300,
                    backgroundColor: "#202020",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: 500,
                        mt: "7.5rem",
                        color: "white",
                      }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </div>
      </>

      <Spinner open={isLoading} />
    </>
  );
}
