import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import { Link, useParams } from "react-router-dom";
import { color } from "theme/color";
import image from "assets/img/incomeIcon.svg";
import { Icon } from "@iconify/react";
import { PlaidLink } from "react-plaid-link";
import { getinit, plaidExchange } from "store/slice/assets";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Spinner from "components/spinner";
import { INVESTMENT_TYPES } from "utils/appConstant";
export default function Investment() {
  const theme = useTheme();
  const plaidLinkRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsloading] = useState(false);
  const { id } = useParams();
  const { getToken } = useSelector((state) => state.assets);
  const dispatch = useDispatch();


  const config = {
    onSuccess: (public_token, metadata) => {
      console.log("public_token", public_token);
      console.log("metadata", metadata);
      // Handle success
    },
    onExit: (err, metadata) => {
      // Handle exit
      console.log("err", err);
      console.log("metadata", metadata);
    },
    onEvent: (eventName, metadata) => {
      console.log("eventName", eventName);
      console.log("metaData", metadata);

      // Handle event
    },
    token: getToken?.link_token, // Your generated Link token
  };
  useEffect(() => {
    dispatch(getinit(id));
  }, []);
  const handleLinkCallback = (public_token, metadata) => {
    var accounts = [];

    metadata?.accounts?.forEach((item) => {
      accounts.push({
        id: item?.id,
        name: item?.name,
        mask: item?.mask,
        subtype: item?.subtype,
        type: item?.type,
      });
    });
    setIsloading(true);
    var data = {
      institution: {
        id: metadata?.institution?.institution_id,
        name: metadata?.institution?.name,
      },
      publicToken: metadata?.public_token,

      accounts: accounts,
    };
    dispatch(plaidExchange(id, data, setIsloading))
    // Handle PlaidLink callback here
  };

  return (
    <div>
      <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Insurance"} />

      <Grid container mt={4}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: "white" }} to="/users">
              Users
            </Link>
            <Link style={{ color: "white" }} to={`/profile/${id}`}>
              User Profile
            </Link>
            <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
              Assets-Liabilities Details
            </Link>
            <Typography sx={{ color: "white" }}>Investments</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <Box backgroundColor="#202020">
          <Grid container py={2}>
            <Grid
              item
              xs={isMobile ? 12 : 4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Avatar
                style={{
                  height: "120px",
                  width: "120px",
                  borderRadius: 80,
                  background: "#232323",
                  border: "1px solid white",
                }}
              >
                <img src={image} width={"60%"} />
              </Avatar>
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 8}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box textAlign={"center"}>
                <h2 style={{ color: "white" }}>Strategic Investing</h2>
                <Typography fontSize={20} color={"white"}>
                  Connect your investments with Silverback and our expert do
                  planning and managing investments to maximize returns and
                  minimize risk.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 5 }}>
        <Grid container columnSpacing={3}>
          {INVESTMENT_TYPES?.map((item, i) => {
            return (
              <Grid item xs={isMobile ? 12 : 6} key={i} mt={2}>
                <PlaidLink
                  token={config.token}
                  onSuccess={handleLinkCallback}
                  onExit={config.onExit}
                  onEvent={config.onEvent}
                  ref={plaidLinkRef}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <Card
                    sx={{
                      color: color.main,
                      background: "#202020",
                      cursor: "pointer",
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography fontSize={16}>{item} </Typography>
                    <Icon icon={"solar:alt-arrow-right-linear"} width={25} />
                  </Card>
                </PlaidLink>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Spinner open={isLoading} />
    </div>
  );
}
