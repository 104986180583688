import React from "react";
import { Card, Typography, Grid, Divider } from "@mui/material";
import { color } from "../../../../../theme/color";
import { currencyFormat } from "utils/currencyFormatter";

export default function index(props) {
  const { detail } = props;
  return (
    <Card sx={{ background: color.topbar, borderRadius: 0, p: 2 }}>
      <Typography sx={{ color: color.buttonColor, fontSize: 18 }}>
        <strong>Basic Info</strong>
      </Typography>
      <Divider sx={{ mt: 1 }} />

      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 1 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>First Name </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.firstName}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>Middle Name </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.middleName}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>Last Name </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.lastName}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>Email </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.email}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>Phone Number </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.phone}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>Marital Status </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.maritalStatus}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>Occupation Details</Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.occupationDetails}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>DOB </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.dateOfBirth}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>Address </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {detail?.address}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>Annual Earned </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: color.main, fontWeight: 600 }}>
            {currencyFormat(
              detail?.financialInformation ? detail?.financialInformation : 0
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
