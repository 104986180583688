import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { DialogTitle } from "@mui/material";
import "../index.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SVButton from "components/SVButton";
import SignatureImage from "../../signatureImage";
import Spinner from "components/spinner";
import { Icon } from "@iconify/react";
import { getManagmentOptions } from "store/slice/estate";
import { useEffect } from "react";
import { ESTATE_PLANNING_KEYS } from "utils/appConstant";
export default function PreviewPowerOfAttorneyDocument(props) {
  const {
    profileDetailData,
    open,
    POAData,
    viewDetails,
    witnessSign,
    agentSign,
    handleSubmit,
    handleClose,
    isLoading,
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getManagmentOptions(ESTATE_PLANNING_KEYS?.POWER_OF_ATTORNEY));
  }, []);
  const { managmentRelationshipList, managmentOptionsData } = useSelector(
    (state) => state.estate
  );
  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Power Of Attorney</h2>
        <Icon icon="ooui:close" height={30} onClick={handleClose} />
      </DialogTitle>
      <Spinner open={isLoading} />
      <DialogContent>
        {/* Principal Information */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black" }}>
          <Typography className="preview-heading-text">
            Principal Information
          </Typography>
          <Typography className="field-text" mt={1}>
            Name
          </Typography>
          <Typography className="title-text">
            {profileDetailData?.first_name} {profileDetailData?.surename}
          </Typography>
          <Typography className="field-text" mt={2}>
            Address
          </Typography>
          <Typography className="title-text">
            {" "}
            {profileDetailData?.address}
          </Typography>
          <Typography className="field-text" mt={2}>
            DOB
          </Typography>
          <Typography className="title-text">
            {" "}
            {moment(profileDetailData?.dateOfBirth).format(`MM/DD/YYYY`)}
          </Typography>
        </Box>

        {/* Designation of agent */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Designation of Agent
          </Typography>
          <Typography className="field-text" mt={1}>
            Name Of Agent
          </Typography>
          <Typography className="title-text">
            {POAData?.agent_details?.length > 0 &&
              POAData?.agent_details[0]?.name}
          </Typography>
          <Typography className="field-text" mt={2}>
            Address
          </Typography>
          <Typography className="title-text">
            {" "}
            {POAData?.agent_details?.length > 0 &&
              POAData?.agent_details[0]?.address}
          </Typography>
          <Typography className="field-text" mt={2}>
            Relationship To Principal
          </Typography>
          <Typography className="title-text">
            {POAData?.agent_details?.length > 0 && (
              <>
                {viewDetails
                  ? POAData?.agent_details[0]?.relationship == "Other"
                    ? POAData?.agent_details[0]?.other_relationship
                    : POAData?.agent_details[0]?.relationship
                  : POAData?.agent_details[0]?.relationship_id == 9
                    ? `${
                        managmentRelationshipList[
                          managmentRelationshipList?.findIndex(
                            (item) =>
                              item.id ==
                              POAData?.agent_details[0]?.relationship_id
                          )
                        ]?.name
                      }(${POAData?.agent_details[0]?.other_relationship})`
                    : managmentRelationshipList[
                        managmentRelationshipList?.findIndex(
                          (item) =>
                            item.id ==
                            POAData?.agent_details[0]?.relationship_id
                        )
                      ]?.name}
              </>
            )}
          </Typography>
          {viewDetails && POAData?.agent_details[0]?.document && (
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="mt-3">
                <SignatureImage url={POAData?.agent_details[0]?.document} />
              </div>
            </div>
          )}
        </Box>
        {/* Authority Granted */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Authority Granted
          </Typography>
          <Typography className="preview-inforation-text">
            The Principal Grants The Following Authority To The
            Attorney-In-Fact. Select Either Option A Or B As Applicable
          </Typography>
          <Typography className="field-text" mt={1}>
            Financial Decisions :
          </Typography>
          <Typography className="title-text">
            {viewDetails
              ? POAData?.financial_decisions
              : managmentOptionsData?.length > 0 &&
                managmentOptionsData[
                  managmentOptionsData?.findIndex(
                    (item) => item?.value == "financial_decisions"
                  )
                ]?.options[
                  managmentOptionsData[
                    managmentOptionsData?.findIndex(
                      (item) => item.value == "financial_decisions"
                    )
                  ]?.options?.findIndex(
                    (item) => item?.value == POAData?.financial_decisions
                  )
                ]?.label}
          </Typography>
          {(POAData?.financial_decisions ===
            "power_of_attorney_financial_decisions_option_b" ||
            POAData?.attorney_limitations) && (
            <>
              <Typography className="field-text" mt={2}>
                Financial Decisions Limitation :
              </Typography>
              <Typography className="title-text">
                {POAData?.attorney_limitations}
              </Typography>
            </>
          )}
          <Typography className="field-text" mt={2}>
            Medical Decisions :
          </Typography>
          <Typography className="title-text">
            {viewDetails
              ? POAData?.medical_decisions
              : managmentOptionsData?.length > 0 &&
                managmentOptionsData[
                  managmentOptionsData?.findIndex(
                    (item) => item.value == "medical_decisions"
                  )
                ]?.options[
                  managmentOptionsData[
                    managmentOptionsData?.findIndex(
                      (item) => item.value == "medical_decisions"
                    )
                  ]?.options?.findIndex(
                    (item) => item?.value == POAData?.medical_decisions
                  )
                ]?.label}
          </Typography>
        </Box>
        {/*   Effective Period */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Effective Period
          </Typography>

          <Typography className="title-text">
            {viewDetails
              ? POAData?.effective_period
              : managmentOptionsData?.length > 0 &&
                managmentOptionsData[
                  managmentOptionsData?.findIndex(
                    (item) => item.value == "effective_period"
                  )
                ]?.options[
                  managmentOptionsData[
                    managmentOptionsData?.findIndex(
                      (item) => item.value == "effective_period"
                    )
                  ]?.options?.findIndex(
                    (item) => item?.value == POAData?.effective_period
                  )
                ]?.label}{" "}
          </Typography>
        </Box>
        {/* Third Party Reliance         */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Third Party Reliance
          </Typography>
          <Typography className="preview-inforation-text">
            All Third Parties May Rely Upon The Validity Of This Document And
            The Authority Granted Herein.
          </Typography>
        </Box>

        {/*Signature Of Principal */}
        <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
          <Typography className="preview-heading-text">
            Signature Of Principal
          </Typography>

          {profileDetailData?.signatureData?.length > 0 && (
            <SignatureImage
              url={profileDetailData?.signatureData[0]?.signature}
            />
          )}
        </Box>
        {/*Agent Signature */}
        {!viewDetails && (
          <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
            <Typography className="preview-heading-text">
              Signature Of Agent
            </Typography>
            {!agentSign?.current?.isEmpty() && (
              <SignatureImage url={agentSign?.current?.toDataURL()} />
            )}
          </Box>
        )}
        {/*witnessSign Signature */}

        {witnessSign && !witnessSign?.current?.isEmpty() && (
          <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
            <Typography className="preview-heading-text">
              Signature Of Witness
            </Typography>
            <SignatureImage url={witnessSign?.current?.toDataURL()} />
          </Box>
        )}
        {viewDetails && POAData?.witness_details[0]?.document && (
          <Box sx={{ borderRadius: 1, p: 3, border: "1px solid black", mt: 2 }}>
            <Typography className="preview-heading-text">
              Signature Of Witness
            </Typography>
            <SignatureImage url={POAData?.witness_details[0]?.document} />
          </Box>
        )}

        {!viewDetails && (
          <Grid container mt={3} columnSpacing={5}>
            <Grid item xs={6}>
              <SVButton text="Edit" fullWidth onClick={handleClose} />
            </Grid>
            <Grid item xs={6}>
              <SVButton text="Submit" fullWidth onClick={handleSubmit} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
