import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Icon } from "@iconify/react";
import CountUp from 'react-countup';

export function TotalCount({ sx, value, iconColor, icon, background, title, click, searchQuery }) {

  return (
    <Card sx={sx}>
      <CardContent sx={{ cursor: 'pointer' }} onClick={click ? () => click(searchQuery) : undefined}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">
                <CountUp end={value ? value : 0}  duration={6}/>
              </Typography>
            </Stack>
            <Avatar
              sx={{
                backgroundColor: { background },
                height: "56px",
                width: "56px",
              }}
            >
              <Icon icon={icon} color={iconColor} width={35} />
            </Avatar>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
