import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import SVButton from "components/SVButton";
import SVOutlinedButton from "components/SVOutlinedButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SVConfirmDialog = ({ ...props }) => {
  const {
    openDialog,
    onClose,
    title,
    message,
    agreeButtonText,
    isLoading,
    cancelButtonText,
    handleAgree,
    handleDisagree,
  } = props;
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth={"sm"}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <SVButton
            onClick={handleAgree}
            text={agreeButtonText}
            isLoading={isLoading}
          />
          <SVOutlinedButton onClick={handleDisagree} text={cancelButtonText} />
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default React.memo(SVConfirmDialog);
