import { Icon } from "@iconify/react";
import { Card, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import SVButton from "components/SVButton";
import React, { useState } from "react";
import { color } from "theme/color";
import houseImg from "../../../../assets/img/house.png";
import graph from "../../../../assets/img/graph.svg";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import EstateDetailDialog from "./estateDetailDialog";
import Spinner from "components/spinner";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { currencyFormat } from "utils/currencyFormatter";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const SetGoals = ({ ...props }) => {
  const {
    estateDetailData,
    goalsDetailData,
    netWorthSummaryData,
    profileDetailData,
  } = props;
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showEstateDetailDialog, setShowEstateDetailDialog] = useState(false);
  const { isLoading } = useSelector((state) => state.profile);
  const chartData = {
    series: [
      goalsDetailData?.totalInfo?.nearly_completed_goals_percentage
        ? goalsDetailData?.totalInfo?.nearly_completed_goals_percentage
        : 0,
      goalsDetailData?.totalInfo?.inprogress_goals_percentage
        ? goalsDetailData?.totalInfo?.inprogress_goals_percentage
        : 0,
      goalsDetailData?.totalInfo?.new_goals_percentage
        ? goalsDetailData?.totalInfo?.new_goals_percentage
        : 0,
      goalsDetailData?.totalInfo?.completed_goals_percentage
        ? goalsDetailData?.totalInfo?.completed_goals_percentage
        : 0,
      goalsDetailData?.totalInfo?.expired_goals_percentage
        ? goalsDetailData?.totalInfo?.expired_goals_percentage
        : 0,
    ],
    options: {
      chart: {
        type: "donut",
      },
      labels: [
        "Nearly Completed",
        "In Progress",
        "Not Funded",
        "Completed",
        "In Complete",
      ],
      colors: ["#068836", "#CBA500", "#949494", "#005A84", "#EC1C24"],
      stroke: {
        show: false,
        width: 1,
      },
    },

    datasets: [
      {
        borderColor: ["#FFFFFF"],
      },
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <Box sx={{ background: color.topbar, borderRadius: "10px", p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 12 : 6}>
          <Card
            sx={{
              maxWidth: "100vw",
              color: color.main,
              background: color.black,
              p: 2,
            }}
          >
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid item>
                <Typography
                  sx={{ fontSize: 22 }}
                  className="textLinearBackground"
                >
                  Total Net Worth
                </Typography>
                <Typography
                  className="textLinearBackground"
                  sx={{ fontSize: isMobile && 28 }}
                  variant="h2"
                >
                  {currencyFormat(
                    netWorthSummaryData?.data?.net_worth
                      ? netWorthSummaryData?.data?.net_worth
                      : 0
                  )}
                </Typography>
              </Grid>
              <Grid item sx={{ mt: isMobile ? 2 : "" }}>
                <Card
                  sx={{
                    backgroundColor: color.topbar,
                    p: 1,
                    borderRadius: 10,
                  }}
                >
                  <Grid container spacing={2} display={"flex"}>
                    <Grid item>
                      <Typography sx={{ color: color.main }}>
                        Total tax
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography sx={{ color: color.green }}>
                        {" "}
                        {netWorthSummaryData?.data?.tax_rate
                          ? netWorthSummaryData?.data?.tax_rate
                          : 0}
                        %
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>

                <Grid item sx={{ mt: 4 }}>
                  <SVButton
                    sx={{ borderRadius: 20, p: 2, height: 32 }}
                    text="View Details"
                    onClick={() =>
                      navigate(`/assetsDetail/${profileDetailData?.id}`)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Card
            sx={{
              maxWidth: "100vw",
              color: color.main,
              background: color.black,
              p: 2,
              mt: 2,
            }}
          >
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid item>
                <Typography
                  sx={{ fontSize: 22 }}
                  className="textLinearBackground"
                >
                  Estate Planning
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mt: 1 }}
            >
              <Grid item xs={isMobile ? 12 : 6}>
                <img src={houseImg} width={200} />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Typography
                  sx={{ fontSize: 18 }}
                  className="textLinearBackground"
                >
                  Take a dive into your Estate Planning
                </Typography>

                <Typography sx={{ fontSize: 10, color: "grey" }}>
                  Financial planning helps you understand where you are today
                  and create a roadmap to get you where you want to be.
                </Typography>
                <Grid
                  container
                  sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                >
                  <SVButton
                    text={"More Details"}
                    onClick={() =>
                      navigate(`/profile/estateDetail/${profileDetailData?.id}`)
                    }
                    // onClick={() => setShowEstateDetailDialog(true)}
                    sx={{ borderRadius: 20, p: 2, height: 32 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Card
            sx={{
              maxWidth: "100vw",
              color: color.main,
              background: color.black,
              p: 2,
              mt: 2,
            }}
          >
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid item>
                <Typography
                  sx={{ fontSize: 22 }}
                  className="textLinearBackground"
                >
                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{ mt: 2 }}
                  >
                    <Grid item>
                      <Typography sx={{ fontSize: 22, color: color.main }}>
                        Debt-Asset Ratio
                      </Typography>
                    </Grid>
                  </Grid>{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} display={"flex"} sx={{ mt: 2 }}>
              <Grid item>
                <Typography sx={{ color: color.main }}>
                  Debt-Asset Ratio
                </Typography>
              </Grid>
              <Grid item>
                <Icon icon="lets-icons:info-duotone" width={20} />
              </Grid>
            </Grid>

            <Grid container spacing={1} display={"flex"} sx={{ mt: 2 }}>
              <Grid item>
                <Typography sx={{ color: color.main }}>Asset</Typography>
              </Grid>

              <Grid item xs={2} sx={{ mt: 0.7 }}>
                <BorderLinearProgress variant="determinate" value={0} />
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", justifyContent: "right", mt: -15 }}
              >
                <img src={graph} width={100} />
              </Grid>
            </Grid>
            <Grid container spacing={1} display={"flex"} sx={{ mt: 2 }}>
              <Grid item>
                <Typography sx={{ color: color.main }}>Debt</Typography>
              </Grid>
              <Grid item xs={2} sx={{ mt: 0.7 }}>
                <BorderLinearProgress variant="determinate" value={100} />
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                {" "}
                <Typography sx={{ color: color.main }}>
                  {" "}
                  {netWorthSummaryData?.data?.debt_ratio?.deptRatio[0].toFixed(
                    2
                  ) +
                    ":" +
                    netWorthSummaryData?.data?.debt_ratio?.deptRatio[1].toFixed(
                      2
                    )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} display={"flex"} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <BorderLinearProgress
                  variant="determinate"
                  value={netWorthSummaryData?.data?.pie_chart_data[1]?.percent}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <Card
            sx={{
              maxWidth: "100vw",
              color: color.main,
              background: color.black,
              p: 2,
            }}
          >
            {" "}
            <Typography
              style={{ fontSize: 20 }}
              className="textLinearBackground"
            >
              Set Goals
            </Typography>
            <Card
              sx={{
                maxWidth: "100vw",
                color: color.grey,
                background: color.topbar,
                p: 2,
                mt: 1,
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Grid item>
                  <SVButton
                    sx={{ borderRadius: 10, p: 2, height: 10 }}
                    onClick={() =>
                      navigate(`/setGoalDetails/${profileDetailData?.id}`)
                    }
                    text={"See More"}
                  />
                </Grid>
              </Grid>
              <Typography
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  marginTop: isMobile ? 10 : "",
                }}
                className="textLinearBackground"
              >
                {goalsDetailData?.totalInfo?.total_goals
                  ? goalsDetailData?.totalInfo?.total_goals
                  : 0}{" "}
                Goals
              </Typography>

              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center", mt: 0 }}
              >
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: 10, color: color.main }}>
                    Completed
                  </Typography>
                  <Typography sx={{ color: color.active }}>
                    {goalsDetailData?.totalInfo?.completed_goals
                      ? goalsDetailData?.totalInfo?.completed_goals
                      : 0}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: 10, color: color.main }}>
                    Nearly Completed
                  </Typography>
                  <Typography sx={{ color: color.active }}>
                    {goalsDetailData?.totalInfo?.nearly_completed_goals
                      ? goalsDetailData?.totalInfo?.nearly_completed_goals
                      : 0}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: 10, color: color.main }}>
                    Incomplete{" "}
                  </Typography>
                  <Typography sx={{ color: color.active }}>
                    {goalsDetailData?.totalInfo?.expired_goals
                      ? goalsDetailData?.totalInfo?.expired_goals
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            {goalsDetailData && (
              <Grid
                item
                display={"flex"}
                justifyContent={"center"}
                sx={{ mt: 2 }}
              >
                <Chart
                  options={chartData?.options}
                  series={chartData?.series}
                  type={chartData?.options?.chart?.type}
                  width={isMobile ? "294" : "510"}
                  stroke={"none"}
                />
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
      <Spinner open={isLoading} />

      {
        // ****** Dialogs ***** //

        // ---Estate Detail Dialog--- //
        <>
          {" "}
          {showEstateDetailDialog && (
            <EstateDetailDialog
              openDialog={showEstateDetailDialog}
              title={"Estate Planning"}
              message={""}
              onClose={() => setShowEstateDetailDialog(false)}
              handleDisagree={() => setShowEstateDetailDialog(false)}
              handleAgree={() => {
                setShowEstateDetailDialog(false);
              }}
              estateDetailData={estateDetailData}
              cancelButtonText="Close"
              agreeButtonText="Ok"
            />
          )}
        </>
      }
      <Spinner open={isLoading} />
    </Box>
  );
};

export default SetGoals;
