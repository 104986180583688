import { useEffect, useState } from "react";
import { useParams } from "react-router";
// import InsuranceSelect from './insuranceSelect';
import { useDispatch, useSelector } from "react-redux";
import { INSURANCET_TYPES } from "utils/appConstant";
import { validateEmpty } from "utils/constantFun";
import Spinner from "components/spinner";
import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import SVButton from "components/SVButton";
import AdminInput from "components/adminInput";
import { color } from "theme/color";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { IncludeInPlanSelect, InsuranceSelect } from "./insuranceSelect";
import { profileDetail } from "store/slice/profile";
import {
  createInsurance,
  getInsuranceDetails,
  updateInsurance,
} from "store/slice/assets";

export default function UmbrellaInsurance() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { insuranceId } = useParams();
  const { profileDetailData } = useSelector((state) => state.profile);
  const { insuranceDetails } = useSelector((state) => state.assets);
  const [isLoading, setIsloading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
    var requestParams = {
      id: id,
    };
    dispatch(profileDetail(requestParams, true));
  }, []);
  const [umbrellaData, setUmbrellaData] = useState({});

  //  get insurance details
  useEffect(() => {
    if (insuranceId) {
      setIsloading(true);
      dispatch(getInsuranceDetails(insuranceId, setIsloading));
    }
  }, [insuranceId]);
  useEffect(() => {
    if (insuranceDetails) {
      setUmbrellaData(insuranceDetails);
    }
  }, [insuranceDetails]);
  const handleSubmit = () => {
    if (validateEmpty(umbrellaData?.policy_name)) {
      setUmbrellaData((umbrellaData) => ({
        ...umbrellaData,
        policy_nameErr: "Please enter policy name",
      }));
      return;
    }
    if (validateEmpty(umbrellaData?.annual_premium)) {
      setUmbrellaData((umbrellaData) => ({
        ...umbrellaData,
        annual_premiumErr: "Please enter annual premium",
      }));
      return;
    }
    if (validateEmpty(umbrellaData?.coverage_amount)) {
      setUmbrellaData((umbrellaData) => ({
        ...umbrellaData,
        coverage_amountErr: "Please enter coverage amount",
      }));
      return;
    }

    if (validateEmpty(umbrellaData?.policy_ends_period_id)) {
      setUmbrellaData((umbrellaData) => ({
        ...umbrellaData,
        policy_ends_period_idErr: "Please select policy ends",
      }));
      return;
    }
    if (validateEmpty(umbrellaData?.policy_end_year)) {
      setUmbrellaData((umbrellaData) => ({
        ...umbrellaData,
        policy_end_yearErr: "Please enter policy ends year",
      }));
      return;
    }
    if (
      !(
        umbrellaData?.included_in_plan != true ||
        umbrellaData?.included_in_plan != false
      )
    ) {
      setUmbrellaData((umbrellaData) => ({
        ...umbrellaData,
        included_in_planErr: "Please select included in plan",
      }));
      return;
    }
    if (validateEmpty(umbrellaData?.premium_inflation)) {
      setUmbrellaData((umbrellaData) => ({
        ...umbrellaData,
        premium_inflationErr: "Please enter premium inflation ",
      }));
      return;
    }
    setIsloading(true);

    let request = {
      user_id: id,
      policy_name: umbrellaData?.policy_name,
      owner: profileDetailData?.first_name,
      annual_premium: umbrellaData?.annual_premium,
      coverage_amount: umbrellaData?.coverage_amount,
      premium_inflation: umbrellaData?.premium_inflation,
      type_id: INSURANCET_TYPES?.UMBRELLA,
      included_in_plan: umbrellaData?.included_in_plan,
      policy_ends_period_id: umbrellaData?.policy_ends_period_id,
      policy_end_year: umbrellaData?.policy_end_year,
    };
    if (insuranceId) {
      dispatch(
        updateInsurance(request, insuranceId, id, navigate, setIsloading)
      );
    } else {
      dispatch(createInsurance(request, id, navigate, setIsloading));
    }
  };
  return (
    <div>
      <Spinner open={isLoading} />
      <div>
        <TopHeading sx={{ p: isMobile ? 1 : "" }} title={"Insurance"} />

        <Grid container mt={4} sx={{ px: isMobile ? 2 : "" }}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ color: "white" }} to="/users">
                Users
              </Link>
              <Link style={{ color: "white" }} to={`/profile/${id}`}>
                User Profile
              </Link>
              <Link style={{ color: "white" }} to={`/assetsDetail/${id}`}>
                Assets-Liabilities Details
              </Link>
              <Link
                style={{ color: "white" }}
                to={`/assetsDetail/insurance/${id}`}
              >
                Insurance
              </Link>
              <Typography sx={{ color: "white" }}>Umbrella</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box
          sx={{ background: color.black, borderRadius: "10px", p: 3, mt: 2 }}
        >
          <h2 className="textLinearBackground mt-3 ">Umbrella</h2>
          <Grid container columnSpacing={3}>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Policy name"
                maxLength={30}
                onChange={(event) => {
                  setUmbrellaData((umbrellaData) => ({
                    ...umbrellaData,
                    policy_name: event.target.value,
                    policy_nameErr: "",
                  }));
                }}
                value={umbrellaData?.policy_name || ""}
                formError={umbrellaData?.policy_nameErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                name="occupation"
                title="Name"
                value={profileDetailData?.first_name || ""}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Annual premium"
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setUmbrellaData((umbrellaData) => ({
                      ...umbrellaData,
                      annual_premium: event.target.value * 1,
                      annual_premiumErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setUmbrellaData((umbrellaData) => ({
                        ...umbrellaData,
                        annual_premium: "",
                      }));
                    }
                  }
                }}
                value={umbrellaData?.annual_premium || ""}
                formError={umbrellaData?.annual_premiumErr}
              />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                maxLength={13}
                endIcon={<Icon icon={"mdi:dollar"} height={28} />}
                color="white"
                title="Coverage amount "
                value={umbrellaData?.coverage_amount || ""}
                formError={umbrellaData?.coverage_amountErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    setUmbrellaData((umbrellaData) => ({
                      ...umbrellaData,
                      coverage_amount: event.target.value * 1,
                      coverage_amountErr: "",
                    }));
                  } else {
                    if (!event.target.value) {
                      setUmbrellaData((umbrellaData) => ({
                        ...umbrellaData,
                        coverage_amount: "",
                      }));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Grid container columnSpacing={3}>
                <Grid item xs={6}>
                  <InsuranceSelect
                    title={" Policy ends"}
                    onChange={(event) => {
                      setUmbrellaData((umbrellaData) => ({
                        ...umbrellaData,
                        policy_ends_period_id: event.target.value,
                        policy_ends_period_idErr: "",
                      }));
                    }}
                    value={umbrellaData?.policy_ends_period_id || ""}
                    error={umbrellaData?.policy_ends_period_idErr}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdminInput
                    color="white"
                    type="text"
                    maxLength={4}
                    title="Year's"
                    onChange={(event) => {
                      const numeric = /^[0-9\b]+$/;
                      if (
                        event.target.value &&
                        numeric.test(event.target.value)
                      ) {
                        setUmbrellaData((umbrellaData) => ({
                          ...umbrellaData,
                          policy_end_year: event.target.value * 1,
                          policy_end_yearErr: "",
                        }));
                      } else {
                        if (!event.target.value) {
                          setUmbrellaData((umbrellaData) => ({
                            ...umbrellaData,
                            policy_end_year: "",
                          }));
                        }
                      }
                    }}
                    value={umbrellaData?.policy_end_year || ""}
                    formError={umbrellaData?.policy_end_yearErr}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <IncludeInPlanSelect
                onChange={(event) => {
                  setUmbrellaData((umbrellaData) => ({
                    ...umbrellaData,
                    included_in_plan: event.target.value,
                    included_in_planErr: "",
                  }));
                }}
                value={
                  umbrellaData?.included_in_plan == true ||
                  umbrellaData?.included_in_plan == false
                    ? umbrellaData?.included_in_plan
                    : ""
                }
                error={umbrellaData?.included_in_planErr}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <AdminInput
                type="text"
                color="white"
                title="Premium inflation"
                endIcon={<Icon icon={"ic:round-percent"} height={28} />}
                value={umbrellaData?.premium_inflation||''}
                formError={umbrellaData?.premium_inflationErr}
                onChange={(event) => {
                  const numeric = /^[0-9\b]+$/;
                  if (event.target.value && numeric.test(event.target.value)) {
                    if (event.target.value > 100) {
                      setUmbrellaData((umbrellaData) => ({
                        ...umbrellaData,
                        premium_inflation: 100,
                        premium_inflationErr: "",
                      }));
                    } else {
                      setUmbrellaData((umbrellaData) => ({
                        ...umbrellaData,
                        premium_inflation: event.target.value * 1,
                        premium_inflationErr: "",
                      }));
                    }
                  } else {
                    setUmbrellaData((umbrellaData) => ({
                      ...umbrellaData,
                      premium_inflation: "",
                    }));
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={isMobile ? 12 : 3} mt={5}>
              <SVButton fullWidth text={"Submit"} onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
