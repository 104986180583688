// ApexChart.js
import moment from "moment";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";

export const TotalUserGraph = (props) => {
  const { months, users } = props;

  var chartData = {
    series: [
      {
        name: "Total Users",
        data: users?.length > 0 ? users : [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e);
          },
        },
      },
      yaxis: {
        type: "text",
        labels: {
          style: {
            fontSize: 16,
            fontWeight: 600,
            colors: "black",
          },
          offsetX: 0,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: months?.length > 0 ? months : [],
        labels: {
          formatter: function (val) {
            return moment()
              .month(val - 1)
              .format("MMM");
          },
          style: {
            fontSize: "16px",
            colors: "black",
            fontWeight: 600,
          },
        },
      },
    },
  };

  return (
    <div
      className="mt-5"
      style={{
        borderRadius: 5,
        background: "white",
      }}
    >
      {months?.length > 0 && (
        <div id="chart">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type={chartData?.options?.chart?.type}
            height={350}
          />
        </div>
      )}
      <div id="html-dist"></div>
    </div>
  );
};

export const UsersGraph = () => {
  const { dashboardData } = useSelector((state) => state.assets);

  const chartData = {
    series: [
      dashboardData?.rejectUser,
      dashboardData?.pendingUser,
      dashboardData?.approveUser,
      dashboardData?.allUserCount,
    ],
    options: {
      chart: {
        type: "radialBar",
        height: 350,
      },

      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "26px",
              formatter: function (val) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return val;
              },
            },
          },
          size: "30",
          inverseOrder: true,
          hollow: {
            margin: 5,
            size: "48%",
            background: "transparent",
          },
          track: {
            show: true,
          },
          startAngle: -180,
          endAngle: 180,
        },
      },

      stroke: {
        lineCap: "round",
      },
      colors: ["#fb2727", "#E9DB24", "#03ad4f", "#2554fb"],
      labels: [
        "Rejected Users",
        "Pending Users",
        "Active Users",
        "Total Users",
      ],
      legend: {
        show: true,
        // floating: true,
        // position: "right",
        // offsetX: 70,
        // offsetY: 200,
      },
    },
  };

  return (
    <div
      className="mt-5"
      style={{
        borderRadius: 5,
        background: "white",
        paddingTop: "5rem",
      }}
    >
      {dashboardData && (
        <div id="chart">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type={chartData?.options?.chart?.type}
            height={361}
          />
        </div>
      )}
      <div id="html-dist"></div>
    </div>
  );
};
