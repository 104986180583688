import { useTheme } from "@emotion/react";
import {
  useMediaQuery,
  Card,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { color } from "theme/color";
import SVButton from "components/SVButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import SVDivider from "components/SVDivider";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getUserEstateLimit, updateEstateLimit } from "store/slice/estate";
import { capitalizeFirstLetter } from "utils/constantFun";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EstateDocumentLimit = ({ ...props }) => {
  const { openDialog, setOpen, userId } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [documentData, setDocumentData] = useState([]);
  const { userEstateLimit } = useSelector((state) => state.estate);
  useEffect(() => {
    dispatch(getUserEstateLimit(userId));
  }, []);
  useEffect(() => {
    if (userEstateLimit) {
      setDocumentData(JSON.parse(JSON.stringify(userEstateLimit)));
    }
  }, [userEstateLimit]);
  const countData = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const handleSave = () => {
    setIsLoading(true);
    let request = {
      userId: userId,
      data: documentData,
    };
    dispatch(updateEstateLimit(request, setIsLoading, closeDialog));
  };
  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        PaperProps={{
          style: {
            backgroundColor: "#202020",
          },
        }}
        fullWidth={"sm"}
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h6" style={{ color: color.main }}>
            {/* {type === "accept" ? "Accept" : "Reject "} The Estate Planning Documents */}
            Documents Limit
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <SVDivider style={{ marginTop: 10 }} />
        <DialogContent>
          <div>
            {documentData?.map((item, i) => {
              return (
                <Card
                  key={i}
                  sx={{
                    color: color.main,
                    background: "#0E0E0E",
                    p: 3,
                    my: 1,
                  }}
                >
                  <Typography variant="h6">
                    {item.type == "hippa_release_and_authorization"
                      ? "Hipaa Release And  Authorization"
                      : capitalizeFirstLetter(item?.type?.replace(/_/g, " "))}
                  </Typography>
                  <Grid container mt={4} gap={2}>
                    <Grid xs={isMobile ? 12 : 5.8}>
                      <Typography fontWeight={600} mb={0.5}>
                        Number Of Creation
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={item?.creation_limit}
                          onChange={(e) => {
                            let temp = [...documentData];
                            temp[i].creation_limit = e.target.value;
                            setDocumentData(temp);
                          }}
                          sx={{
                            color: "white",
                            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {countData?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid xs={isMobile ? 12 : 5.8}>
                      <Typography fontWeight={600} mb={0.5}>
                        Number Of Revision
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={item?.updation_limit}
                          onChange={(e) => {
                            let temp = [...documentData];
                            temp[i].updation_limit = e.target.value;
                            setDocumentData(temp);
                          }}
                          sx={{
                            color: "white",
                            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {countData?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Card>
              );
            })}

            <Grid container mt={4}>
              <Grid xs={12}>
                <SVButton
                  isLoading={isLoading}
                  onClick={handleSave}
                  text="Save"
                  sx={{ px: 3, width: "100%" }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default React.memo(EstateDocumentLimit);
