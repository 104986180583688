/** @format */

import React, { memo, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  Stack,
  DialogActions,
  CircularProgress,
  Box,
  Typography,
  Card,
} from "@mui/material";
import Spinner from "components/spinner";
import { Icon } from "@iconify/react";
import SVButton from "components/SVButton";
import TopHeading from "components/topHeading";
import SVDivider from "components/SVDivider";
import AdminButton from "components/adminButton";
import AdminInput from "components/adminInput";
import { createNotesApi, listOfNotesApi } from "store/slice/profile";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PAGINATION } from "utils/appConstant";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NoteDialog = (props) => {
  let page = PAGINATION.page;
  const { handleClose, open, userId, noteData, setNoteData, isAdminNotes } =
    props;
  const [showAddNote, setShowAddNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { subAdminNotesData, subAdminNoteCount } = useSelector(
    (state) => state.profile
  );
  const [startDate, setStartDate] = useState("dd/mm/yyyy");
  const [endDate, setEndDate] = useState("dd/mm/yyyy");
  const [limit, setLimit] = useState(25);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchNotesList(page, "", "dd/mm/yyyy", "dd/mm/yyyy");
  }, []);

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    const valDate = moment(value).format("YYYY-MM-DD");
    setEndDate(value === "" ? "dd/mm/yyyy" : value);
    setStartDate(
      valDate === "dd/mm/yyyy" || valDate === "Invalid date"
        ? "dd/mm/yyyy"
        : startDate
    );
    fetchNotesList(
      page,
      "",
      valDate == "Invalid date" || valDate == ""
        ? null
        : moment(startDate).format("YYYY-MM-DD 00:00:00"),
      valDate !== "Invalid date" ? valDate : null
    );
  };

  const fetchNotesList = (page, numberOfRows, startDate, endDate) => {
    let request = {
      user_id: userId,
      page: page ?? 0,
      perPage: numberOfRows ? numberOfRows : limit,
      start_date:
        startDate != "dd/mm/yyyy"
          ? moment(startDate).format("YYYY-MM-DD")
          : null,
      end_date:
        endDate != "dd/mm/yyyy" ? moment(endDate).format("YYYY-MM-DD") : null,
    };
    dispatch(listOfNotesApi(request, true, true, setViewMoreLoading));
  };
  const handleCloseAddDialog = () => {
    setShowAddNote(false);
  };
  const handleCreateNotes = () => {
    if (!noteData?.notes) {
      setNoteData({ ...noteData, notesErr: "Please enter notes" });
      return;
    }
    setIsLoading(true);
    var data = {
      id: noteData?.id ? noteData?.id : null,
      to_user_id: userId,
      message: noteData?.notes,
    };
    dispatch(
      createNotesApi(
        data,
        false,
        setNoteData,
        setIsLoading,
        handleCloseAddDialog,
        true
      )
    );
  };
  return (
    <>
      <Dialog
        fullScreen
        maxWidth="xs"
        scroll="paper"
        PaperProps={{
          style: {
            backgroundColor: "#202020",
          },
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>
          <Grid container display={"flex"} justifyContent={"end"}>
            <Grid item>
              <Icon
                height={35}
                style={{ cursor: "pointer" }}
                onClick={handleClose}
                icon={"gridicons:cross-circle"}
                color={"white"}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <SVDivider />

        <DialogContent>
          <Box sx={{ background: "black", borderRadius: "10px", p: 4 }}>
            <Stack
              direction={"row"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: 3,
                pb: 2,
              }}
            >
              <TopHeading title={"Notes"} />
              {!isAdminNotes && (
                <SVButton
                  text="Add Note"
                  onClick={() => {
                    setNoteData({});
                    setShowAddNote(true);
                  }}
                />
              )}
            </Stack>

            <Grid container spacing={2} sx={{ mb: 2 }} display="flex">
              <Grid item xs={3}>
                <AdminInput
                  color="white"
                  type={"date"}
                  title={"Start Date"}
                  // min={currnetDate}
                  sx={{
                    mt: -1,
                    pr: 2,
                    background: "#202020",
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#202020 !important",
                      },
                    },

                    '& input[type="date"]::-webkit-calendar-picker-indicator': {
                      filter: "invert(70%)", // Change the color of the calendar icon
                    },
                  }}
                  value={startDate || ""}
                  onChange={(e) => handleStartDate(e.target.value)}
                />
              </Grid>

              <Grid item xs={3}>
                <AdminInput
                  color="white"
                  type={"date"}
                  title={"End Date"}
                  // min={currnetDate}
                  sx={{
                    mt: -1,
                    pr: 2,
                    background: "#202020",
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#202020 !important",
                      },
                    },
                    '& input[type="date"]::-webkit-calendar-picker-indicator': {
                      filter: "invert(70%)", // Change the color of the calendar icon
                    },
                  }}
                  value={endDate || ""}
                  onChange={(e) => handleEndDate(e.target.value)}
                />
              </Grid>
            </Grid>
            {subAdminNotesData.length > 0 ? (
              <>
                {subAdminNotesData?.map((item, index) => {
                  return (
                    <>
                      <Card
                        key={index}
                        sx={{
                          background: "#202020",
                          p: 3,
                          width: "100%",
                          mt: 1,
                        }}
                      >
                        <Grid container>
                          <Grid xs={11.5}>
                            <Stack>
                              <Typography
                                sx={{
                                  display: "inline",
                                  fontSize: 18,
                                  pb: 0.5,
                                }}
                                className="textLinearBackground"
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                sx={{
                                  display: "inline",
                                  fontSize: 16,
                                  color: "white",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.message}
                              </Typography>
                              <Typography
                                pt={1}
                                component="span"
                                variant="body2"
                                color="white"
                              >
                                {moment(item?.updated_at).format(
                                  "DD MMM, YYYY HH:MM A"
                                )}
                              </Typography>
                            </Stack>
                          </Grid>
                          {!isAdminNotes && (
                            <Grid xs={0.5}>
                              <Icon
                                onClick={() => {
                                  setNoteData((noteData) => ({
                                    ...noteData,
                                    notes: item?.message,
                                    id: item?.id,
                                  }));
                                  setShowAddNote(true);
                                }}
                                cursor={"pointer"}
                                icon="carbon:edit"
                                height={30}
                                color="#1098DB"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Card>
                    </>
                  );
                })}
                {viewMoreLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 3,
                      width: "100%",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: "#1a90ff",
                      }}
                    />
                  </Box>
                ) : (
                  subAdminNoteCount > limit && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <AdminButton
                        onClick={() => {
                          let perPage = limit + 50;
                          setLimit(perPage);
                          setViewMoreLoading(true);
                          fetchNotesList(page, perPage);
                        }}
                        title={"View More"}
                      />
                    </Box>
                  )
                )}
              </>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid #202020",
                    mt: 2,
                    height: 300,
                    backgroundColor: "#202020",
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: 500,
                        mt: "7.5rem",
                        color: "white",
                      }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Dialog for notes dialog for add notes */}
      <Dialog
        fullWidth
        scroll="paper"
        open={showAddNote}
        onClose={handleCloseAddDialog}
      >
        <DialogTitle>Add Notes</DialogTitle>
        <SVDivider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminInput
                autoFocus
                margin="dense"
                title="Notes"
                type="text"
                fullWidth
                multiline
                minRows={3}
                maxRows={10}
                value={noteData?.notes || ""}
                onChange={(e) => {
                  let val = e.target.value;
                  setNoteData((noteData) => ({
                    ...noteData,
                    notes: val,
                    notesErr: "",
                  }));
                }}
                variant="outlined"
                formError={noteData?.notesErr}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AdminButton
            onClick={handleCloseAddDialog}
            title={"Cancel"}
            sx={{ fontSize: 14 }}
            fullWidth
          />

          <AdminButton
            disabled={isLoading ? true : false}
            onClick={handleCreateNotes}
            title={isLoading ? <CircularProgress color="inherit" /> : "Submit"}
            sx={{ fontSize: 14 }}
            fullWidth
          />
        </DialogActions>
      </Dialog>

      <Spinner open={isLoading} />
    </>
  );
};

export default memo(NoteDialog);
