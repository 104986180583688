import toast from "react-hot-toast";

export const successToast = (message, toastId) => {
  toast.success(message, {
    id: toastId,
    position: "top-right",
    // style:{ minWidth: '250px', textAlign:"left", border: '1px solid black',padding: '16px',
    // color: '#713200',}
  });
};

export const errorToast = (message, toastId) => {
  toast.error(message, {
    id: toastId,
    position: "top-right",
  });
};

export const customToast = (messsage, toastId, icon) => {
  toast(messsage, {
    id: toastId,
    position: "top-right",
    // Styling
    style: {},
    className: "",
    // Custom Icon
    icon: icon,
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
  });
};
