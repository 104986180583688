import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  ListItem,
  ListItemText,
} from "@mui/material";
import AdminInput from "components/adminInput";
import TopHeading from "components/topHeading";
import { useEffect, useState } from "react";
import InputEmojiWithRef from "react-input-emoji";
import "./index.css";
import SVButton from "components/SVButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerList, sendNotification } from "store/slice/profile";
import { useTheme } from "@emotion/react";
import { color } from "theme/color";
// import { styled } from "@mui/system";

export default function SendNotification() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [notificationData, setNotificationData] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [userIdArray, setUserIdArray] = useState([]);
  var page = 0;
  const resultsPerPage = 100; // Adjust as needed
  const { customerListData } = useSelector((state) => state.profile);
  const [notify, setNotify] = useState({
    forAll: true, // Initially set forAll as true
    forIndividual: false, // Assuming forIndividual should be initially false
  });
  // const [options, setOptions] = useState([]);
  const [note, setNote] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const WhiteBorderAutocomplete = styled(Autocomplete)`
  //   .MuiOutlinedInput-root {
  //     border: 0.5px solid white;
  //   }
  // `;

  // const options = [
  //   { value: "option1", label: "Option 1" },
  //   { value: "option2", label: "Option 2" },
  //   { value: "option3", label: "Option 3" },
  //   // Add more options as needed
  // ];

  const handleForAllChange = (e) => {
    if (!e.target.checked) {
      // Only update if the radio is being checked, not unchecked
      return;
    }
    setNotify(() => ({
      forAll: true,
      forIndividual: false,
    }));
  };

  const handleForIndividualChange = (e) => {
    if (!e.target.checked) {
      // Only update if the radio is being checked, not unchecked
      return;
    }
    setNotify(() => ({
      forAll: false,
      forIndividual: true,
    }));
  };

  useEffect(() => {
    var data = {
      page: page,
      perPage: resultsPerPage,
      search: "",
      startDate: "",
      endDate: "",
      status: "",
      userTypeFilter: "",
      state: "",
      dateOfBirth: "",
    };
    dispatch(getCustomerList(data, true));
  }, []);

  // useEffect(() => {
  //   if (customerListData?.length > 0) {
  //     setOptions((prevOptions) => [...prevOptions, ...customerListData]);
  //   }
  // }, [customerListData]);
  const handleChange = (option) => {
    const selectedIndex = selectedValues.findIndex(
      (item) => item.id === option.id
    );
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = [...selectedValues, option];
    } else {
      newSelected = selectedValues.filter((item) => item.id !== option.id);
    }
    let newArray = [];
    // newArray = newSelected.map((item) => item.id);
    newSelected?.map((item) => {
      newArray.push(item.id);
    });
    setUserIdArray(newArray);
    setSelectedValues(newSelected);
  };

  useEffect(() => {
    if (note) {
      setNotificationData((notificationData) => ({
        ...notificationData,
        noteErr: " ",
      }));
    }
  }, [note]);
  const handleSend = () => {
    if (!notificationData?.title) {
      setNotificationData((notificationData) => ({
        ...notificationData,
        titleErr: "Please enter title",
      }));
      return;
    }
    if (!note) {
      setNotificationData((notificationData) => ({
        ...notificationData,
        noteErr: "Please enter message ",
      }));
      return;
    }

    let request = {
      title: notificationData?.title,
      description: note,
      is_all_user: notify?.forAll,
      users: userIdArray,
    };
    dispatch(sendNotification(request, setNotificationData, setNote, navigate));
  };
  return (
    <div className="App wrapper">
      <TopHeading
        title={"Send Notifications"}
        sx={{ px: 2, p: isMobile ? 1 : "" }}
      />
      <Grid container spacing={1} sx={{ mt: 0, px: 2 }}>
        <Grid item xs={12}>
          <AdminInput
            title="Title"
            sx={{
              "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&.MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white",
                },
            }}
            type="text"
            color="white"
            value={notificationData?.title || ""}
            onChange={(val) => {
              setNotificationData((notificationData) => ({
                ...notificationData,
                title: val.target.value,
                titleErr: "",
              }));
            }}
            formError={notificationData?.titleErr}
          />
        </Grid>
        <Grid item xs={12} sx={{ px: 1, mt: 1 }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <Grid container alignItems="center">
                <Grid item>
                  <FormControlLabel
                    value={notify.forAll}
                    control={
                      <Radio
                        checked={notify.forAll}
                        onChange={handleForAllChange}
                      />
                    }
                    label={<span style={{ color: "white" }}>For all</span>}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={notify?.forIndividual}
                    control={
                      <Radio
                        checked={notify?.forIndividual}
                        onChange={handleForIndividualChange}
                      />
                    }
                    label={
                      <span style={{ color: "white" }}>For Individual</span>
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ px: 2, mt: 2 }}>
        {!notify?.forAll && (
          <div style={{ overflowY: "auto", maxHeight: "300px" }}>
            {/* <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={options}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option) => (
                <li {...props}>
                  <Checkbox
                    checked={selectedValues.some(
                      (item) => item.id === option.id
                    )}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleChange(option);
                    }}
                    style={{ marginRight: 8 }}
                  />
                  {option.title}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Favorites" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.value}
                    label={option?.first_name}
                    onDelete={() => {
                      handleChange(
                        null,
                        selectedValues.filter((v) => v !== option)
                      );
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{
                      color: "white", // Set chip text color to white
                      borderColor: "white", // Set chip border color to white
                      backgroundColor: "transparent", // Set chip background color to transparent
                    }}
                    {...getTagProps({ index })}
                  />
                ))
              }
            /> */}
            <Autocomplete
              fullWidth
              multiple
              options={customerListData ? customerListData : []}
              disableCloseOnSelect={true}
              sx={{
                ".MuiOutlinedInput-root": {
                  border: "0.1px solid #eee",
                  "&:hover": {
                    border: "0.1px solid #eee",
                  },
                },
              }}
              getOptionLabel={(option) => option?.first_name} // Specify the property to use as the label
              value={selectedValues || ""}
              onChange={(event, newValue) => {
                setSelectedValues(newValue);
              }}
              renderOption={(props, option) => (
                <>
                  <Grid container sx={{ background: "black", mt: -1, mb: -1 }}>
                    <Grid item>
                      <ListItem {...props} style={{ backgroundColor: "black" }}>
                        <Checkbox
                          checked={selectedValues.some(
                            (item) => item.id === option.id
                          )}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleChange(option);
                          }}
                        />
                        <ListItemText style={{ color: color.main }}>
                          {option?.first_name}
                        </ListItemText>
                      </ListItem>
                    </Grid>
                  </Grid>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select User/Search..."
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      color: "white", // Change text color to white
                      "& .css-1uuhbgm-MuiFormLabel-root-MuiInputLabel-root": {
                        backgroundColor: "grey",
                      },
                      "& .css-171o5pu-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink":
                        {
                          backgroundColor: color.topbar, // Set background color to grey
                        },
                    },
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.value}
                    label={option?.first_name}
                    onDelete={() => {
                      handleChange(
                        null,
                        selectedValues.filter((v) => v !== option)
                      );
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{
                      color: "white", // Set chip text color to white
                      borderColor: "white", // Set chip border color to white
                      backgroundColor: "transparent", // Set chip background color to transparent
                    }}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </div>
        )}
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{ mt: !notify?.forAll ? 1 : -2.5, px: 1 }}
      >
        <Grid item xs={12} sx={{ px: 1, mt: 2.5 }}>
          <InputEmojiWithRef
            className="react-input-emoji--wrapper"
            value={note}
            onChange={setNote}
            borderRadius={5}
            shouldReturn={note?.length > 1 ? true : false}
            theme="dark"
            placeholder="Type a message"
          />{" "}
        </Grid>
        <Typography
          sx={{ color: theme.palette.error.main, fontSize: 12, ml: 2.5, mb: 4 }}
        >
          {notificationData?.noteErr ? notificationData?.noteErr : ""}
        </Typography>
      </Grid>

      <Grid container gap={2} spacing={1} sx={{ mt: 0, px: 3.4 }}>
        <Grid xs={12} sm={12} md={2} lg={1}>
          <SVButton text="Send" sx={{ width: "100%" }} onClick={handleSend} />
        </Grid>
        <Grid xs={12} sm={12} md={2} lg={1}>
          <SVButton
            text="Close"
            sx={{ width: "100%", background: "white", color: "black" }}
            onClick={() => {
              setNotificationData({});
              setNote("");
              navigate("/notification");
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
