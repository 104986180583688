import { useState } from "react";
// @mui
import {
  Stack,
  MenuItem,
  // Avatar,
  IconButton,
  Popover,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Badge,
} from "@mui/material";
// mocks_
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Icon } from "@iconify/react";
import { color } from "../../../theme/color";
import { useDispatch, useSelector } from "react-redux";
import {
  clearListOfNotesApi,
  clearProfileState,
  listOfNotesApi,
} from "../../../store/slice/profile";
import profilePic from "../../../assets/img/default.jpg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SVDivider from "components/SVDivider";
import { NOTIFICATION_TYPE, TEAM_MEMBER_ROLE } from "utils/appConstant";
import NoteDialog from "pages/admin/settings/manageTeam/teamManagment/subComponent/noteDialog";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { adminPorfileDetailData } = useSelector((state) => state.profile);
  const [open, setOpen] = useState(null);
  const [badge, setBadge] = useState(null);
  const { notificationListData, unReadNotificationCount } = useSelector(
    (state) => state.profile
  );
  const [showNoteDialog, setShowNoteDialog] = useState(false);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleOpenbadge = (event) => {
    setBadge(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleCloseBadge = () => {
    setBadge(null);
  };
  const MENU_OPTIONS = [
    {
      label: "Settings",
      color: color.fourth,
      icon: "ant-design:setting-outlined",
      clickFun: () => Navigate("/settings"),
    },
    {
      label: "Notifications",
      color: color.fourth,
      icon: "ic:outline-message",
      clickFun: () => Navigate("/notification"),
    },
    {
      label: "Logout",
      color: color.fourth,
      icon: "ic:baseline-logout",
      clickFun: () => handleLogout(),
    },
  ];
  const fetchNotesList = () => {
    let request = {
      user_id: adminPorfileDetailData?.id,
      page: 0,
      perPage: 25,
      start_date: null,
      end_date: null,
    };
    dispatch(listOfNotesApi(request, true, true));
  };

  const handleLogout = () => {
    secureLocalStorage.clear();
    navigate("/login", { replace: true });
    dispatch(clearProfileState());
  };
  return (
    <>
      {adminPorfileDetailData && (
        <NoteDialog
          isAdminNotes
          open={showNoteDialog}
          userId={adminPorfileDetailData?.id}
          handleClose={() => {
            setShowNoteDialog(false);
          }}
        />
      )}
      <Grid container spacing={2} display={"flex"} justifyContent={"right"}>
        <Grid
          item
          style={{ margin: 10, display: "flex", alignItems: "center" }}
        >
          {adminPorfileDetailData &&
            adminPorfileDetailData?.roleId !=
              TEAM_MEMBER_ROLE.SUPERADMIN_ROLE && (
              <Icon
                height={30}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(clearListOfNotesApi());
                  setShowNoteDialog(true);
                  fetchNotesList();
                }}
                icon="ph:note-bold"
                color={color.buttonColor}
              />
            )}
          &nbsp; &nbsp;&nbsp;&nbsp;
          <Badge
            badgeContent={unReadNotificationCount}
            sx={{
              "& .MuiBadge-badge": {
                color: color.main,
                backgroundColor: color.buttonColor,
                cursor: "pointer",
                mt: 0.5,
                display: unReadNotificationCount >= 1 ? "" : "none",
              },
              cursor: "pointer",
            }}
            onClick={handleOpenbadge}
          >
            <NotificationsNoneIcon sx={{ fontSize: 35 }} color="action" />
          </Badge>
          <Popover
            open={Boolean(badge)}
            anchorEl={badge}
            onClose={handleCloseBadge}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{ mt: 1 }}
          >
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                spacing={2}
                sx={{ pl: 3, pr: 3 }}
              >
                <Grid item>
                  <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                    Notifications
                  </Typography>
                </Grid>
                {notificationListData?.length >= 5 && (
                  <Grid item>
                    <Typography
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setBadge(null);
                        navigate("/notification");
                      }}
                    >
                      View All
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {notificationListData?.length >= 1 ? (
                <>
                  {notificationListData?.map((item, index) => {
                    return (
                      index < 6 && (
                        <ListItem
                          onClick={() => {
                            handleCloseBadge();
                            if (
                              item?.notification_type ==
                              NOTIFICATION_TYPE?.USER_REGISTER_NOTIFICATION_TO_ADMIN
                            ) {
                              navigate(
                                `/profile/${JSON.parse(item?.notification_data)?.id}`
                              );
                            }
                            if (
                              item?.notification_type ==
                              NOTIFICATION_TYPE?.ADMIN_NOTIFICATION_FOR_ESTATE_FORM_SUBMITTION
                            ) {
                              navigate(
                                `/profile/estateDetail/${JSON.parse(item?.notification_data)?.id}`
                              );
                            }
                            if (
                              item?.notification_type ==
                              NOTIFICATION_TYPE?.ADMIN_RAISE_SUPPORT_TICKET_NOTIFICATION
                            ) {
                              navigate(
                                `/ticketManagement/${JSON.parse(item?.notification_data)?.id}`
                              );
                            }

                            if (
                              item?.notification_type ==
                              NOTIFICATION_TYPE?.EKYC_REQUEST
                            ) {
                              navigate(
                                `/ekycList/${JSON.parse(item?.notification_data)?.userId}`
                              );
                            }
                          }}
                          alignItems="flex-start"
                          key={index}
                          sx={{ cursor: "pointer" }}
                        >
                          <ListItemText
                            sx={{
                              fontSize: 20,
                              fontWeight: 600,
                            }}
                            primary={item?.title}
                            secondary={
                              <>
                                <Typography
                                  sx={{ display: "inline", fontSize: 12 }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {item?.message}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      )
                    );
                  })}

                  <Divider variant="inset" component="li" />
                </>
              ) : (
                <>
                  <ListItem alignItems="flex-start" sx={{ p: 3 }}>
                    <ListItemText
                      secondary={
                        <>
                          <Typography
                            sx={{ display: "inline", fontSize: 12 }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            NO MESSAGES AVAILABLE
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                </>
              )}
            </List>
          </Popover>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleOpen}
            sx={{
              mt: 0.2,
              ...(open && {
                "&:before": {
                  zIndex: 1,
                  content: "''",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                },
              }),
            }}
          >
            <img
              src={profilePic}
              width={40}
              height={40}
              style={{ borderRadius: "50%" }}
            />
          </IconButton>
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Stack sx={{ width: 200, mb: 2 }}>
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent={"center"}
                sx={{ mt: 1 }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "center", display: "flex" }}
                >
                  <img
                    width={40}
                    height={40}
                    style={{ borderRadius: "50%" }}
                    src={profilePic}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "center", display: "flex" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Super Admin
                  </Typography>{" "}
                  <br />
                </Grid>
                <Typography sx={{ fontSize: 12 }}>
                  eiatechind@gmail.com
                </Typography>
              </Grid>
              <SVDivider style={{ marginTop: 12 }} />

              {MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  sx={{
                    color: color.black,
                    justifyContent: "flex-start",
                  }}
                  onClick={() => {
                    option.clickFun();
                    handleClose();
                  }}
                >
                  <Icon
                    color={option.color}
                    icon={option.icon}
                    width="20"
                    height="20"
                  />
                  <Typography sx={{ ml: 1 }}>{option.label}</Typography>
                </MenuItem>
              ))}
            </Stack>
          </Popover>
        </Grid>
      </Grid>
    </>
  );
}
