import { Card,  useMediaQuery, useTheme } from "@mui/material";
import { color } from "../../../../theme/color";
import TeamManagment from "./teamManagment";
import Role from "./role";

export default function index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
      <>
        <Card
          sx={{
            color: color.fourth,
            background: "#202020",
            maxWidth: isMobile ? "100vw" : "92vw",
            mb:2
          }}
        >
          <TeamManagment />
        </Card>
        <Card
          sx={{
            color: color.fourth,
            background: "#202020",
            maxWidth: isMobile ? "100vw" : "92vw",
          }}
        >
          <Role />
        </Card></>
  );
}
