import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import SVButton from "components/SVButton";
import SVOutlinedButton from "components/SVOutlinedButton";
import { Grid } from "@mui/material";
import { color } from "theme/color";
import SVDivider from "components/SVDivider";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EstateDetailDialog = ({ ...props }) => {
  const {
    openDialog,
    onClose,
    title,
    message,
    agreeButtonText,
    isLoading,
    cancelButtonText,
    handleAgree,
    handleDisagree,
    estateDetailData,
  } = props;
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth={"sm"}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>{message}</Typography>

          <Typography
            sx={{
              color: color.active,
              fontSize: 15,
              fontWeight: "bold",
              mb: 1,
            }}
          >
            Will
          </Typography>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item>Name of executor</Grid>
            <Grid item>
              {estateDetailData?.will?.name_of_executor
                ? estateDetailData?.will?.name_of_executor
                : "-"}
            </Grid>
          </Grid>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item>Name of guardian</Grid>
            <Grid item>
              {estateDetailData?.will?.name_of_guardian
                ? estateDetailData?.will?.name_of_guardian
                : "-"}
            </Grid>
          </Grid>
          <SVDivider style={{ marginTop: 10 }} />

          <Typography
            sx={{
              color: color.active,
              fontSize: 15,
              fontWeight: "bold",
              mb: 1,
              mt: 1,
            }}
          >
            {estateDetailData?.living_will?.document_created
              ? "Living Will"
              : ""}
          </Typography>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item>Living Will</Grid>
            <Grid item>
              {estateDetailData?.living_will?.document_created === true
                ? "Yes"
                : "No"}
            </Grid>
          </Grid>
          <SVDivider style={{ marginTop: 10 }} />

          <Typography
            sx={{
              color: color.active,
              fontSize: 15,
              fontWeight: "bold",
              mb: 1,
              mt: 1,
            }}
          >
            {estateDetailData?.living_trust?.document_created
              ? "Living Trust"
              : ""}
          </Typography>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item>Living Trust</Grid>
            <Grid item>
              {estateDetailData?.living_trust?.document_created === true
                ? "Yes"
                : "No"}
            </Grid>
          </Grid>
          <SVDivider style={{ marginTop: 10 }} />

          <Typography
            sx={{
              color: color.active,
              fontSize: 15,
              fontWeight: "bold",
              mb: 1,
              mt: 1,
            }}
          >
            {estateDetailData?.beneficiary_designations?.created_and_reviewed
              ? "Beneficiary"
              : ""}
          </Typography>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item>Beneficiary</Grid>
            <Grid item>
              {estateDetailData?.beneficiary_designations
                ?.created_and_reviewed === true
                ? "Yes"
                : "No"}
            </Grid>
          </Grid>
          <SVDivider style={{ marginTop: 10 }} />

          <Typography
            sx={{
              color: color.active,
              fontSize: 15,
              fontWeight: "bold",
              mb: 1,
              mt: 1,
            }}
          >
            {estateDetailData?.power_of_attorney ? "Power of attorney" : ""}
          </Typography>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item>Name of power of attorney</Grid>
            <Grid item>
              {estateDetailData?.power_of_attorney?.name_of_power_of_attorney
                ? estateDetailData?.power_of_attorney?.name_of_power_of_attorney
                : "-"}
            </Grid>
          </Grid>
          <SVDivider style={{ marginTop: 10 }} />

          <Typography
            sx={{
              color: color.active,
              fontSize: 15,
              fontWeight: "bold",
              mb: 1,
              mt: 1,
            }}
          >
            {estateDetailData?.power_of_attorney ? "Health card proxy" : ""}
          </Typography>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item>Health card proxy</Grid>
            <Grid item>
              {estateDetailData?.health_care_proxy?.name_of_health_care_proxy
                ? estateDetailData?.health_care_proxy?.name_of_health_care_proxy
                : "-"}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SVButton
            onClick={handleAgree}
            text={agreeButtonText}
            isLoading={isLoading}
          />
          <SVOutlinedButton onClick={handleDisagree} text={cancelButtonText} />
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default React.memo(EstateDetailDialog);
