import { Button } from "@mui/material";
import React from "react";
import { color } from "../../theme/color";

export default function AdminButton(props) {
  const { fullWidth, title, onClick, sx, height, disabled, textTransform } =
    props;
  return (
    <Button
      fullWidth={fullWidth}
      type="submit"
      variant="contained"
      disabled={disabled}
      sx={{
        mt: 3,
        mb: 2,
        fontSize: 15,
        color: "white",
        backgroundColor: color.buttonColor,
        textTransform: textTransform ? textTransform : "none",
        height: height ? height : 45,
        ...sx,
        ":hover": {
          color: "white",
          backgroundColor: "black",
          border: "1px solid white",
        },
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
