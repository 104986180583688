const API_VERSION = {
  v1: "api/v1",
};
export const SILVERBACK = {
  login: `${API_VERSION.v1}/cms/auth/login`,
  forgetPassword: `${API_VERSION.v1}/auth/forgot-password`,
  resetPassword: `${API_VERSION.v1}/auth/reset-password`,
  changePassword: `${API_VERSION.v1}/auth/change-password`,
  mfaCodeGenrator: `${API_VERSION.v1}/auth/genrate-mfa-qr-code`,
  adminProfileDetail: `${API_VERSION.v1}/cms/user/me`,
  editProfile: `${API_VERSION.v1}/cms/user/update-profile`,
  uploadProfile: `${API_VERSION.v1}/update-picture`,
  getCustomerList: `${API_VERSION.v1}/cms/user/list`,
  profileDetail: `${API_VERSION.v1}/cms/user/user-by-id`,
  suspendAccount: `${API_VERSION.v1}/cms/user/approve-or-reject`,
  getUserDashboard: `${API_VERSION.v1}/cms/user/dashboard`,
  downloadCsv: `${API_VERSION.v1}/cms/user/export-csv`,
  subscribeUserList: `${API_VERSION.v1}/cms/user/subscriber-list`,
  verifyOtp: `${API_VERSION.v1}/cms/auth/otp-login`,
  stateList: `${API_VERSION.v1}/states`,
  updateUserDetails: `${API_VERSION.v1}/cms/user/update-user-profile-info`,
  verifyResetToken: `${API_VERSION.v1}/auth/verify-reset-token`,
  verifyOtpForProfile: `${API_VERSION.v1}/cms/user/verify-otp-to-update-profile`,
  sendOtpForProfile: `${API_VERSION.v1}/cms/user/send-otp-to-update-profile`,
  getSiteSetting: `${API_VERSION.v1}/site-setting`,
  documentType: `${API_VERSION.v1}/document/type/list`,
  uploadDocuments: `${API_VERSION.v1}/document/upload`,
  verifyCaptch: `${API_VERSION.v1}/cms/user/verify-recaptcha`,
  getNetWorthList: `${API_VERSION.v1}/financialAdviceRequest/list`,
  getDetailOfNetWorth: `${API_VERSION.v1}/financialAdviceRequest/get-by-id`,
  getNotificationList: `${API_VERSION.v1}/common/notification/list`,
  readNotification: `${API_VERSION.v1}/common/notification/read`,
  getEKycList: `${API_VERSION.v1}/ekyc/list`,
  eKycDetail: `${API_VERSION.v1}/ekyc/detail/`,
  ekycApproveReject: `${API_VERSION.v1}/ekyc/approve-reject`,
  getTicketList: `${API_VERSION.v1}/cms/ticket/list`,
  updateTicket: `${API_VERSION.v1}/cms/ticket/update`,
  deleteTicket: `${API_VERSION.v1}/cms/ticket/delete`,
  textAuthApi: `${API_VERSION.v1}/cms/user/configure-auth`,
  tOTPAuth: `${API_VERSION.v1}/cms/user/configure-totp/`,
  sendOtpForAuth: `${API_VERSION.v1}/cms/auth/otp-request`,
  createTeamMember: `${API_VERSION.v1}/cms/auth/signup`,
  deleteTeamMember: `${API_VERSION.v1}/cms/user/delete-user`,
  setPasswordByTeamMember: `${API_VERSION.v1}/auth/reset-password`,
  getPermissionList: `${API_VERSION.v1}/Permission/permissions`,
  getPermissionLevel: `${API_VERSION.v1}/Permission/permission-levels`,
  getRolePermissionDetail: `${API_VERSION.v1}/Permission/role-permissions`,
  updatePermissions: `${API_VERSION.v1}/Permission/add-update-role-permission`,
  sendNotification: `${API_VERSION.v1}/common/notification/notify-user-by-cms`,
  goalsDetailURl: `${API_VERSION.v1}/goal/cms/detail`,
  estateDetailURL: `${API_VERSION.v1}/estate/management/detail`,
  netWrothSummaryURL: `${API_VERSION.v1}/plaid/cms/net-worth/summary`,
  assetsSummaryURL: `${API_VERSION.v1}/plaid/cms/assets/accounts`,
  liablilitiesSummaryURL: `${API_VERSION.v1}/plaid/cms/liabilities/accounts`,
  bankDetailsURL: `${API_VERSION.v1}/plaid/cms/account`,
  payRollIncomeURL: `${API_VERSION.v1}/plaid/cms/payroll-income`,
  otherAccountDetailsURl: `${API_VERSION.v1}/plaid/cms/other/account`,
  taxBracketURL: `${API_VERSION.v1}/tax_brackets/cms/get-all`,
  taxBracketInputURL: `${API_VERSION.v1}/tax_brackets/cms/save`,
  activateDactivatCardURL: `${API_VERSION.v1}/cms/cards/suspend-activate-card`,
  allInsuranceDetailURL: `${API_VERSION.v1}/insurance/all`,
  realEstateDetailURL: `${API_VERSION.v1}/plaid/cms/realestate/account`,
  getEstatePdfList: `${API_VERSION.v1}/estate/cms/list`,
  updateEstatePdfStatus: `${API_VERSION.v1}/estate/cms/update-status`,
  updateSiteSetting: `${API_VERSION.v1}/cms/update-setting`,
  uploadBase64: `${API_VERSION.v1}/document/upload/base64`,
  createNotesApi: `${API_VERSION.v1}/cms/notes/create`,
  getNotesDetailApi: `${API_VERSION.v1}/cms/notes/details`,
  // estate
  getEstateList: `${API_VERSION.v1}/estate/cms/list`,
  getUserEstateLimit: `${API_VERSION.v1}/estate/cms/get-limit`,
  updateEstateLimit: `${API_VERSION.v1}/estate/cms/update-limit`,
  getEstateDetail: `${API_VERSION.v1}/estate/management/details`,
  addEstateDocument: `${API_VERSION.v1}/estate/cms/save`,
  updateEstateDocument: `${API_VERSION.v1}/estate/cms/update`,
  getManagmentRelationshipList: `${API_VERSION.v1}/estate/management/relationship`,
  getManagmentOptions: `${API_VERSION.v1}/estate/management/options`,
  deleteEstateDocument: `${API_VERSION.v1}/estate/cms`,
  reshareSignatureLink: `${API_VERSION.v1}/estate/management/reshare-signature-link`,
  listOfNotesApiURl: `${API_VERSION.v1}/cms/notes/list`,
  financialAccountDetailsApiURL: `${API_VERSION.v1}/financialAccount/financial-accounts-list`,
  transactionListApi:`${API_VERSION.v1}/fundsMovement/transfer-list`,
  ticketSubCategoryApi: `${API_VERSION.v1}/ticket/subcategories`,

  // team managment
  getRoleList: `${API_VERSION.v1}/Permission/role`,
  addRole: `${API_VERSION.v1}/Permission/role`,
  deleteRole: `${API_VERSION.v1}/Permission/role`,
  ticketCategoryApi:`${API_VERSION.v1}/ticket/categories`,
  ticketRaiseApi: `${API_VERSION.v1}/ticket/raise`,


  // assets

  getRealEstateInfo: `${API_VERSION.v1}/realestate/info`,
  addRealEstate: `${API_VERSION.v1}/realestate/add`,
  deleteRealEstate: `${API_VERSION.v1}/realestate/delete`,
  otherTypeList: `${API_VERSION.v1}/plaid/other/asset_debt_type`,
  addOtherTypeAssets: `${API_VERSION.v1}/plaid/other/save`,
  deleteOtherAssest: `${API_VERSION.v1}/plaid/other/delete`,
  getInsurancePeriod: `${API_VERSION.v1}/insurance/periods`,

  // insurance
  getInsuranceTypes: `${API_VERSION.v1}/insurance/types`,
  createInsurance: `${API_VERSION.v1}/insurance/create`,
  getInsuranceYear: `${API_VERSION.v1}/insurance/years`,
  getTaxesList: `${API_VERSION.v1}/insurance/taxes`,
  getInflationTypes: `${API_VERSION.v1}/insurance/inflation-types`,
  getEliminationPeriod: `${API_VERSION.v1}/insurance/elimination-periods`,
  getBenefitsTypes: `${API_VERSION.v1}/insurance/benefit-types`,
  getBenefitPeriods: `${API_VERSION.v1}/insurance/benefit-periods`,
  getCashValueApproaches: `${API_VERSION.v1}/insurance/cash-value-approaches`,
  getDistributionsList: `${API_VERSION.v1}/insurance/distributions`,
  getDeathBenefitsList: `${API_VERSION.v1}/insurance/death-benefits`,
  deleteInsurance: `${API_VERSION.v1}/insurance/delete`,
  getInsuranceDetails: `${API_VERSION.v1}/insurance/get`,
  updateInsurance: `${API_VERSION.v1}/insurance/update`,
  getInitIncomeURL: `${API_VERSION.v1}/plaid/init/income`,
  getInitURL: `${API_VERSION.v1}/plaid/init`,
  // manual
  addManualAssetsLiabilities: `${API_VERSION.v1}/manual/add`,
  addManualIncomeAssetsLiabilities: `${API_VERSION.v1}/manual/income-asset`,

  // plaid
  plaidExchangeURL: `${API_VERSION.v1}/plaid/exchange-token`,
  plaidExchangeIncomeURL: `${API_VERSION.v1}/plaid/income/exchange-token`,

  // dashboard
  getDashboardData: `${API_VERSION.v1}/cms/user/cms-dashboard`,
  getUserChartData: `${API_VERSION.v1}/cms/user/graph`,

  // advertise managment
  getAdvertiseList: `${API_VERSION.v1}/banner/list`,
  getBannerCategoryList: `${API_VERSION.v1}/banner/banner_category`,
  addAdvertise: `${API_VERSION.v1}/banner/add`,
  getAdvertiseDetail: `${API_VERSION.v1}/banner/detail`,
  updateAdvertise: `${API_VERSION.v1}/banner/update`,
  deleteAdvertise: `${API_VERSION.v1}/banner/delete`,

  // press -release
  getPressReleaseList: `${API_VERSION.v1}/blog/list`,
  addPressReleaseBlog: `${API_VERSION.v1}/blog/add`,
  deletePressReleaseBlog: `${API_VERSION.v1}/blog/delete`,
  getPressReleaseDetails: `${API_VERSION.v1}/blog/detail`,
  updatePressReleaseBlog: `${API_VERSION.v1}/blog/update`,
  uploadPressReleaseImage: `${API_VERSION.v1}/document/upload-image`,

  // user

  getUserActivity:`${API_VERSION.v1}/activity/list`,

  // cards transcation list
  getCardsTransactionList: `${API_VERSION.v1}/cards/card-transaction-history`, 

  
};

export const STATUS_CODES = {
  SUCCESS: 200,
  FAILURE: 400,
  NOT_AUTHORIZED: 401,
  SERVER_ERROR: 500,
  NOT_FOUND: 404,
};
