/** @format */

import React, { memo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Slide,
  Grid,
} from "@mui/material";
import SVButton from "components/SVButton";
import SVOutlinedButton from "components/SVOutlinedButton";
import { color } from "theme/color";
import SVDivider from "components/SVDivider";
import Spinner from "components/spinner";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Component = (props) => {
  const { title, open, handleClose, handleAgree, text,isLoading } = props;

  //eslint
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        scroll="paper"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleClose(false)}
      >
        
        <DialogTitle>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color={color.black}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <SVDivider />

        <DialogContent>
          <DialogContentText sx={{ mt: 0.5 }}>
            <Typography fontSize={20}>{text}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SVButton text="Yes" sx={{ height: 40 }} onClick={handleAgree} />
          <SVOutlinedButton
            text="No"
            sx={{ height: 40, ml: 1 }}
            onClick={handleClose}
          />
        </DialogActions>

        <Spinner open={isLoading} />
      </Dialog>
     

    </>
  );
};

export default memo(Component);
