import SVOutlinedButton from "components/SVOutlinedButton";
import React from "react";
import {
  Dialog,
  // DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  Grid,
} from "@mui/material";
import SVDivider from "components/SVDivider";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SVButton from "components/SVButton";
import AdminInput from "components/adminInput";
import Spinner from "components/spinner";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AcceptRejectEstateDialog = ({ ...props }) => {
  const {
    openDialog,
    message,
    type,
    handleAgree,
    closeDialog,
    pdfKey,
    setReasonData,
    isLoading,
    reasonData,
    handleDelete
  } = props;
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth={"sm"}
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <Spinner open={isLoading} />
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h6">
            {/* {type === "accept" ? "Accept" : "Reject "} The Estate Planning Documents */}
            {pdfKey?.replace(/_/g, " ")?.toUpperCase()}
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <SVDivider style={{ marginTop: 10 }} />
        <DialogContent>
          <Grid container spacing={2}>
            {type === "accept" && (
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 18 }}>{message}</Typography>
              </Grid>
            )}
            {type === "delete" && (
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 18 }}>
                  Are you sure you want to delete {pdfKey?.replace(/_/g, " ")}{" "}
                  document ?
                </Typography>
              </Grid>
            )}
            {type === "reject" && (
              <Grid item xs={12}>
                <AdminInput
                  autoFocus
                  required
                  title="Please enter reason"
                  minRows={4}
                  multiline={true}
                  maxRows={8}
                  type="text"
                  color="black"
                  value={reasonData || ""}
                  fullWidth={true}
                  onChange={(e) => {
                    if (e.target.value && e.target.value != " ") {
                      setReasonData(e.target.value);
                    } else {
                      setReasonData("");
                    }
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{ mt: 2 }}
            >
              {type === "delete" ? (
                <SVButton
                  text={"Delete"}
                  sx={{ height: 40, fontSize: 16 }}
                  onClick={handleDelete}
                />
              ) : (
                <SVButton
                  text={type === "reject" ? "Reject" : "Accept"}
                  disabled={
                    type === "reject" ? (reasonData ? false : true) : false
                  }
                  sx={{ height: 40, fontSize: 16 }}
                  onClick={handleAgree}
                />
              )}
              <SVOutlinedButton
                text="Cancel"
                sx={{ height: 40, ml: 1 }}
                onClick={closeDialog}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default React.memo(AcceptRejectEstateDialog);
