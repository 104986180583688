import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TopHeading from "components/topHeading";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInput from "components/adminInput";
import SVSignaturepad from "../../SVSignaturepad";
import "../index.css";
import GoogleAutoComplete from "components/googleAutoComplete";
import { ESTATE_PLANNING_KEYS } from "utils/appConstant";
import { getSiteSetting } from "store/slice/settings";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { areAllKeysEmpty, onlyNumber, validEmail, validateEmpty } from "utils/constantFun";
import { errorToast } from "components/showToast";
import { useDispatch, useSelector } from "react-redux";
import AdminButton from "components/adminButton";
import { api } from "services";
import { SILVERBACK, STATUS_CODES } from "services/apiConstant";
import { profileDetail, stateList } from "store/slice/profile";
import {
  addEstateDocument,
  getEstateDetail,
  updateEstateDocument,
} from "store/slice/estate";
import Spinner from "components/spinner";
import PreviewHipaaDocument from "./previewHipaaDocument";
import SignatureImage from "../../signatureImage";
export default function HippaDocument() {
  const { id } = useParams();
  const { userMapperId } = useParams();
  const { actionType } = useParams();
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const witnessTwoSign = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const witnessSign = useRef();
  const { stateListData, profileDetailData } = useSelector(
    (state) => state.profile
  );
  const { siteSettingData } = useSelector((state) => state.settings);
  useEffect(() => {
    dispatch(getSiteSetting());
  }, []);
  const { estateDetailsData } = useSelector((state) => state.estate);

  useEffect(() => {
    dispatch(stateList());
    var requestParams = {
      id: id,
    };
    dispatch(profileDetail(requestParams, true));
    // window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (actionType == "edit") {
      setIsloading(true);
      dispatch(getEstateDetail(userMapperId, setIsloading));
    }
  }, [actionType]);
  useEffect(() => {
    if (estateDetailsData) {
      setHipaaData(JSON.parse(JSON.stringify(estateDetailsData)));
    }
  }, [estateDetailsData]);
  const [HipaaData, setHipaaData] = useState({
    agent_details: [
      {
        document_id: null,
        name: "",
        email: null,
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
      {
        document_id: null,
        name: "",
        email: null,
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
      {
        document_id: null,
        name: "",
        email: null,
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
    ],
    witness_details: [
      {
        document_id: null,
        name: null,
        email: "",
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
      {
        document_id: null,
        name: null,
        email: "",
        date: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        relationship_id: null,
        phone: null,
        alternative_phone: null,
        other_relationship: null,
      },
    ],
  });

  // removed extra parameter
  useEffect(() => {
    if (HipaaData?.agent_details?.length > 0) {
      HipaaData?.agent_details.forEach((object) => {
        delete object["id"];
      });
      HipaaData?.agent_details?.forEach((object) => {
        delete object["relationship"];
      });
      HipaaData?.agent_details?.forEach((object) => {
        delete object["document"];
      });
    }
    if (HipaaData?.witness_details?.length > 0) {
      HipaaData?.witness_details?.forEach((object) => {
        delete object["id"];
      });
      HipaaData?.witness_details?.forEach((object) => {
        delete object["relationship"];
      });
      HipaaData?.witness_details?.forEach((object) => {
        delete object["document"];
      });
    }
  }, [HipaaData]);

  const navigate = useNavigate();
  const date = new Date();
  const dispatch = useDispatch();
  //   handle preview
  const handlePreview = () => {
    if (
      witnessSign?.current?.isEmpty() &&
      HipaaData.witness_details?.length > 0
    ) {
      let temp = [...HipaaData.witness_details];
      temp[0].document_id = null;
      temp[0].date = null;
    }
    if (
      witnessTwoSign?.current?.isEmpty() &&
      HipaaData.witness_details?.length > 1
    ) {
      let temp = [...HipaaData.witness_details];
      temp[1].document_id = null;
      temp[1].date = null;
    }
    if (validateEmpty(HipaaData?.agent_details[0]?.name)) {
      errorToast("Please enter first agent name");
      return;
    }
    if (validateEmpty(HipaaData?.agent_details[0]?.address)) {
      errorToast("Please enter first agent address");
      return;
    }
    if (validateEmpty(HipaaData?.agent_details[1]?.name)) {
      errorToast("Please enter second agent name");
      return;
    }
    if (validateEmpty(HipaaData?.agent_details[1]?.address)) {
      errorToast("Please enter second agent address");
      return;
    }
    if (validateEmpty(HipaaData?.agent_details[2]?.name)) {
      errorToast("Please enter third agent name");
      return;
    }
    if (validateEmpty(HipaaData?.agent_details[2]?.address)) {
      errorToast("Please enter third agent address");
      return;
    }

    if (
      !areAllKeysEmpty(HipaaData?.witness_details[0]) ||
      !witnessSign?.current?.isEmpty()
    ) {
      if (validateEmpty(HipaaData?.witness_details[0]?.email)) {
        errorToast("Please enter first witness email");
        return;
      }
      if (!validEmail(HipaaData?.witness_details[0]?.email)) {
        errorToast("Please enter valid email");
        return;
      }
      if (validateEmpty(HipaaData?.witness_details[0]?.address)) {
        errorToast("Please enter first witness address");
        return;
      }
      if (validateEmpty(HipaaData?.witness_details[0]?.city)) {
        errorToast("Please enter first witness city");
        return;
      }
      if (validateEmpty(HipaaData?.witness_details[0]?.zip_code)) {
        errorToast("Please enter first witness postal code");
        return;
      }
      if (validateEmpty(HipaaData?.witness_details[0]?.state)) {
        errorToast("Please select first witness state");
        return;
      }
    }
    if (
      !areAllKeysEmpty(HipaaData?.witness_details[1]) ||
      !witnessTwoSign?.current?.isEmpty()
    ) {
      if (validateEmpty(HipaaData?.witness_details[1]?.email)) {
        errorToast("Please enter second witness email");
        return;
      }
      if (!validEmail(HipaaData?.witness_details[1]?.email)) {
        errorToast("Please enter valid email");
        return;
      }
      if (validateEmpty(HipaaData?.witness_details[1]?.address)) {
        errorToast("Please enter second witness address");
        return;
      }
      if (validateEmpty(HipaaData?.witness_details[1]?.city)) {
        errorToast("Please enter second witness city");
        return;
      }
      if (validateEmpty(HipaaData?.witness_details[1]?.zip_code)) {
        errorToast("Please enter second witness postal code");
        return;
      }
      if (validateEmpty(HipaaData?.witness_details[1]?.state)) {
        errorToast("Please select second witness state");
        return;
      }
    }
    setShowPreview(true);
  };

  //   upload signature
  const handleUploadSignature = (document, key, addRequest) => {
    let request = {
      document: document,
      type_id: siteSettingData?.DOCUMENT_TYPE_HIPPA_RELEASE_AND_AUTHORIATION,
      entityId: siteSettingData?.ENTITY_TYPE_ESTATE_PLANNING,
      moduleId: siteSettingData?.MODULE_TYPE_ESTATE_PLANNING,
    };
    try {
      api.post(SILVERBACK.uploadBase64, request).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          if (key === "principal") {
            HipaaData.principal_signature = response?.data?.data?.documentId;
          }
          if (key === "witnessOne") {
            let temp = HipaaData?.witness_details;
            temp[0].document_id = response?.data?.data?.documentId;
            setHipaaData((HipaaData) => ({
              ...HipaaData,
              witness_details: temp,
              agentNameErr: "",
            }));
          }
          if (key === "witnessTwo") {
            let temp = HipaaData?.witness_details;
            temp[1].document_id = response?.data?.data?.documentId;
            setHipaaData((HipaaData) => ({
              ...HipaaData,
              witness_details: temp,
              agentNameErr: "",
            }));
          }
          if (addRequest) {
            handleSave(key, response?.data?.data?.documentId);
          }
        } else {
          errorToast(response.data.detail, "account");
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
  //   add form
  const handleSubmit = () => {
    setIsloading(true);
    if (witnessSign.current.isEmpty() && witnessSign.current.isEmpty()) {
      handleSave();
    }
    if (!witnessSign.current.isEmpty()) {
      const data = witnessSign.current?.toDataURL();
      const myArray = data.split(",");
      handleUploadSignature(
        myArray[1],
        "witnessOne",
        witnessTwoSign?.current.isEmpty() ? true : ""
      );
    }
    if (!witnessTwoSign.current.isEmpty()) {
      const data = witnessTwoSign.current?.toDataURL();
      const myArray = data.split(",");
      handleUploadSignature(myArray[1], "witnessTwo", true);
    }
  };
  // handle save
  const handleSave = (key, documentId) => {
    let temp = HipaaData?.witness_details;
    if (key === "witnessOne") {
      temp[0].document_id = documentId;
      temp[0].date = moment(date).format();
    }
    if (key === "witnessTwo") {
      temp[1].document_id = documentId;
      temp[1].date = moment(date).format();
    }
    let request = {
      type: ESTATE_PLANNING_KEYS?.HIPAA_RELEASE_AND_AUTHORIZATION,
      data: {
        principal_name: `${profileDetailData?.first_name} ${profileDetailData?.surename}`,
        principal_address: profileDetailData?.address,
        principal_dob: moment(profileDetailData?.dateOfBirth).format(
          "MM/DD/YYYY"
        ),
        financial_decisions: "",
        attorney_limitations: "",
        medical_decisions: "",
        effective_period: "",
        principal_signature:
          profileDetailData?.signatureData?.length > 0
            ? profileDetailData?.signatureData[0]?.id.toString()
            : "",
        principal_signature_date: moment(date).format(),
        notary_date: null,
        county: null,
        state: profileDetailData?.state,
        state_name:
          stateListData[
            stateListData?.findIndex(
              (item) => item.id == profileDetailData?.state
            )
          ]?.description,
        anatomical_gifts: null,
        anatomical_gifts_yes_option: null,
        specific_organisations: null,
        witness_details:
          key === "witnessOne" || key === "witnessTwo"
            ? temp
            : HipaaData?.witness_details,
        agent_details: HipaaData?.agent_details,
      },
      userMapperId: userMapperId ? userMapperId * 1 : null,
    };
    if (userMapperId) {
      dispatch(updateEstateDocument(id, request, setIsloading, navigate));
    } else {
      dispatch(addEstateDocument(id, request, setIsloading, navigate));
    }
  };
  return (
    <div>
      <Box sx={{ p: isMobile ? 3 : "" }}>
        <TopHeading title={"Hippa Release And Authorization"} />

        <Grid container mt={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{ color: "white" }}
                to={`/profile/estateDetail/${id}`}
              >
                Estate-Planning
              </Link>
              <Typography sx={{ color: "white" }}>Add</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Box>
      <PreviewHipaaDocument
        open={showPreview}
        isLoading={isLoading}
        witnessSign={witnessSign}
        witnessTwoSign={witnessTwoSign}
        handleClose={() => setShowPreview(false)}
        profileDetailData={profileDetailData}
        HipaaData={HipaaData}
        handleSubmit={handleSubmit}
      />
      {/* loader */}
      <Spinner open={isLoading} />
      {/* Authority Granted */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="inforation-text">
          I {profileDetailData?.first_name} {profileDetailData?.surename} ,
          hereby authorize the following persons (hereinafter collectively
          referred to as agent") to act as my agents with regard to the matters
          specified in this HIPAA Release and Authorization (“Release”)
        </Typography>
        <Typography className="heading-text">Authority Granted</Typography>
        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="label-text">First Agent</Typography>
            <AdminInput
              color="white"
              title="Name"
              onChange={(event) => {
                const temp = [...HipaaData.agent_details];
                temp[0].name = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              value={HipaaData?.agent_details[0]?.name}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = HipaaData?.agent_details;
                temp[0].address = value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = HipaaData?.agent_details;
                temp[0].address = "";
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              searchValue={HipaaData?.agent_details[0]?.address}
              handlePlaceSelected={(place) => {
                let temp = HipaaData?.agent_details;
                temp[0].address = place?.formatted_address;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="label-text">Second Agent</Typography>
            <AdminInput
              color="white"
              title="Name"
              onChange={(event) => {
                const temp = [...HipaaData.agent_details];
                temp[1].name = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              value={HipaaData?.agent_details[1]?.name}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = HipaaData?.agent_details;
                temp[1].address = value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = HipaaData?.agent_details;
                temp[1].address = "";
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              searchValue={HipaaData?.agent_details[1]?.address}
              handlePlaceSelected={(place) => {
                let temp = HipaaData?.agent_details;
                temp[1].address = place?.formatted_address;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="label-text">Third Agent</Typography>
            <AdminInput
              color="white"
              title="Name"
              onChange={(event) => {
                const temp = [...HipaaData.agent_details];
                temp[2].name = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              value={HipaaData?.agent_details[2]?.name}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = HipaaData?.agent_details;
                temp[2].address = value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = HipaaData?.agent_details;
                temp[2].address = "";
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
              searchValue={HipaaData?.agent_details[2]?.address}
              handlePlaceSelected={(place) => {
                let temp = HipaaData?.agent_details;
                temp[2].address = place?.formatted_address;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  agent_details: temp,
                }));
              }}
            />
          </Grid>
        </Grid>
        <Typography className="inforation-text" mt={2}>
          For purposes of this Release, the following persons shall also be
          treated as my agents in addition to the persons listed above :
        </Typography>
        <Typography className="inforation-text">
          (i) any person designated as a primary or successor agent in a durable
          power of attorney which I have executed, whether or not such person is
          presently serving as such;
        </Typography>{" "}
        <Typography className="inforation-text">
          (ii) any person presently serving as trustee or named as a successor
          trustee in any revocable trust created by me.
        </Typography>
        <Typography className="inforation-text" mt={1}>
          This Release and all the provisions contained herein are effective
          immediately. I intend for my agent to be treated as I would be treated
          with respect to my rights regarding the use and disclosure of my
          individually identifiable health information and other medical
          records. This Release authority applies to any information governed by
          the Health Insurance Portability and Accountability Act of 1996
          (HIPAA), 42 U.S.C. Sections 1320d to 1320d-9 and 45 C.F.R. Sections
          164.500 to 164.534, as may be amended from time to time.
        </Typography>
        <Typography className="inforation-text" mt={1}>
          I hereby revoke any and all other HIPAA releases heretofore made by
          me.
        </Typography>
      </Box>
      {/* Authorization */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Authorization</Typography>
        <Typography className="inforation-text">
          Thereby authorize any doctor, physician, medical specialist,
          psychiatrist, chiropractor, health-care professional, dentist,
          optometrist, health plan, hospital, hospice, clinic, laboratory,
          pharmacy or pharmacy benefit manager, medical facility, pathologist,
          or other provider of medical or mental health care, as well as any
          insurance company and the Medical Information Bureau Inc. or other
          health-care clearinghouse that has paid for or is seeking payment from
          me for such services (referred to herein as a "covered entity"), to
          give, disclose and release to my agent who is named herein and who is
          currently serving as such and without restriction, all of my
          individually identifiable health information and medical records
          regarding any past, present or future medical or mental health
          condition, including all information relating to the diagnosis and
          treatment of HIV/AIDS, sexually transmitted diseases, mental illness,
          and drug or alcohol abuse. Additionally, this disclosure shall include
          the ability to ask questions and discuss this protected medical
          information with the person or entity who has possession of the
          protected medical information even if Iam fully competent to ask
          questions and discuss this matter at the time. It is my intention to
          give a full authorization to access any protected medical information
          to my agent. Health information and medical records as indicated above
          shall be released at my request or at the request of my agent named
          herein as may be needed to assist in my treatment, make decisions
          about my care or for any other reason, at my discretion or at the
          discretion of my agent.
        </Typography>
        <Typography className="inforation-text">
          The authority given to my agent shall supersede any prior agreement
          that I may have made with my health-care providers to restrict access
          to or disclosure of my individually identifiable health information.
          The individually identifiable health information and other medical
          records given, disclosed, or released to my agent may be subject to
          re-disclosure by my agent and may no longer be protected by HIPAA.
        </Typography>{" "}
      </Box>

      {/* Termination */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Termination</Typography>
        <Typography className="inforation-text">
          This Release shall terminate on the first to occur of :
        </Typography>
        <Typography className="inforation-text">
          (i) two years following my death, or
        </Typography>{" "}
        <Typography className="inforation-text">
          (ii) on my written revocation actually received by the covered entity.
          Proof of receipt of my written revocation may be by certified mail,
          registered mail, facsimile, electronic mail, or any other means
          evidencing actual receipt by the covered entity. This Release shall
          not be affected by my subsequent disability or incapacity. There are
          no exceptions to my right to revoke this Release.
        </Typography>
      </Box>
      {/* Release */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Release</Typography>
        <Typography className="inforation-text">
          Each covered entity that acts in reliance on this Release shall be
          released from liability that may result from disclosing my
          individually identifiable health information and other medical
          records.
        </Typography>
      </Box>
      {/* Legal Action */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Legal Action </Typography>
        <Typography className="inforation-text">
          I authorize my agent to bring a legal action against a covered entity
          which refuses to accept and recognize this Release. No covered entity
          may condition treatment, payment, enrollment or eligibility for
          benefits on whether I sign this authorization when the prohibition on
          conditioning of authorizations in 45 C.F.R. Section 164.508(b)(4)
          applies. Further, to fulfill my intent as expressed herein, I
          authorize my agent to sign any documentation that my agent deems
          necessary or appropriate to secure the disclosure of my individually
          identifiable health information and other medical records.
        </Typography>
      </Box>
      {/* Subsequent Disclosure Of Information */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">
          Subsequent Disclosure Of Information
        </Typography>
        <Typography className="inforation-text">
          Any information disclosed to my agent under this Release may
          subsequently be disclosed to another party by my agent. My agent shall
          not be required to indemnify a covered entity or perform any act if
          information is subsequently disclosed by my agent.
        </Typography>
      </Box>
      {/* Copies And Facsimiles */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Copies And Facsimiles</Typography>
        <Typography className="inforation-text">
          Copies or facsimiles of this Release shall be as valid as the original
          Release.
        </Typography>
      </Box>
      {/*Signature Of Principal */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">Signature Of Principal</Typography>
        <Typography className="inforation-text">
          I sign my name to this HIPAA Release and Authorization on{" "}
          {moment(date).format("DD MMM, YYYY")}
        </Typography>
        {profileDetailData?.signatureData?.length > 0 && (
          <SignatureImage
            url={profileDetailData?.signatureData[0]?.signature}
          />
        )}
      </Box>
      {/*Witness Declarations (Optional) */}
      <Box
        sx={{
          background: "black",
          my: isMobile ? 2 : 3,
          borderRadius: 1,
          p: 3,
        }}
      >
        <Typography className="heading-text">
          Witness Declarations (Optional){" "}
        </Typography>
        <Typography className="inforation-text">
          By signing as a witness, I acknowledge that the principal signed this
          HIPAA Release and Authorization in my presence and in the presence of
          the other witness, or that the principal acknowledged to me that the
          principal's signature was affixed by him or her or at his or her
          direction. I also acknowledge that the principal has stated that this
          HIPAA Release and Authorization reflects his or her wishes and that he
          or she has signed it voluntarily.
        </Typography>
        <Grid container columnSpacing={2} mt={0.5}>
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography className="label-text">First Witness</Typography>
            <AdminInput
              title="Name"
              color="white"
              onChange={(event) => {
                let temp = HipaaData?.witness_details;
                temp[0].name = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              value={HipaaData?.witness_details[0]?.name || ""}
            />
            <AdminInput
              title="Email"
              color="white"
              onChange={(event) => {
                let temp = HipaaData?.witness_details;
                temp[0].email = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              value={HipaaData?.witness_details[0]?.email || ""}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = HipaaData?.witness_details;
                temp[0].address = value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = HipaaData?.witness_details;
                temp[0].address = "";
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              searchValue={HipaaData?.witness_details[0]?.address}
              handlePlaceSelected={(place) => {
                let temp = HipaaData?.witness_details;
                temp[0].address = place?.formatted_address;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
            />
            <AdminInput
              title="City"
              color="white"
              onChange={(event) => {
                let temp = HipaaData?.witness_details;
                temp[0].city = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              value={HipaaData?.witness_details[0]?.city || ""}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
              <AdminInput
                  title="Postal Codes"
                  maxLength="5"
                  color="white"
                  onChange={(event) => {
                    if (event.target.value) {
                      if (onlyNumber(event.target.value)) {
                        let temp = HipaaData?.witness_details;
                        temp[0].zip_code = event.target.value;
                        setHipaaData((HipaaData) => ({
                          ...HipaaData,
                          witness_details: temp,
                        }));
                      }
                    } else {
                      let temp = HipaaData?.witness_details;
                      temp[0].zip_code = "";
                      setHipaaData((HipaaData) => ({
                        ...HipaaData,
                        witness_details: temp,
                      }));
                    }
                  }}
                  value={HipaaData?.witness_details[0]?.zip_code || ""}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    label="State"
                    onChange={(event) => {
                      let temp = HipaaData?.witness_details;
                      temp[0].state = event.target.value;
                      temp[0].state_name =
                        stateListData[
                          stateListData?.findIndex(
                            (item) => item.id == event.target.value
                          )
                        ]?.description;
                      setHipaaData((HipaaData) => ({
                        ...HipaaData,
                        witness_details: temp,
                      }));
                    }}
                    value={HipaaData?.witness_details[0]?.state || ""}
                    sx={{
                      color: "white",
                      width: "100%",
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "white",
                        },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {stateListData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SVSignaturepad customRef={witnessSign} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} mt={isMobile ? 4 : ""}>
            <Typography className="label-text">Second Witness</Typography>
            <AdminInput
              title="Name"
              color="white"
              onChange={(event) => {
                let temp = HipaaData?.witness_details;
                temp[1].name = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              value={HipaaData?.witness_details[1]?.name || ""}
            />
            <AdminInput
              title="Email"
              color="white"
              onChange={(event) => {
                let temp = HipaaData?.witness_details;
                temp[1].email = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              value={HipaaData?.witness_details[1]?.email || ""}
            />
            <GoogleAutoComplete
              onChangeValue={(value) => {
                let temp = HipaaData?.witness_details;
                temp[1].address = value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              handleClearSearch={() => {
                let temp = HipaaData?.witness_details;
                temp[1].address = "";
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              searchValue={HipaaData?.witness_details[1]?.address}
              handlePlaceSelected={(place) => {
                let temp = HipaaData?.witness_details;
                temp[1].address = place?.formatted_address;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
            />
            <AdminInput
              title="City"
              color="white"
              onChange={(event) => {
                let temp = HipaaData?.witness_details;
                temp[1].city = event.target.value;
                setHipaaData((HipaaData) => ({
                  ...HipaaData,
                  witness_details: temp,
                }));
              }}
              value={HipaaData?.witness_details[1]?.city || ""}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
               
                  <AdminInput
                  title="Postal Codes"
                  maxLength="5"
                  color="white"
                  onChange={(event) => {
                    if (event.target.value) {
                      if (onlyNumber(event.target.value)) {
                        let temp = HipaaData?.witness_details;
                        temp[1].zip_code = event.target.value;
                        setHipaaData((HipaaData) => ({
                          ...HipaaData,
                          witness_details: temp,
                        }));
                      }
                    } else {
                      let temp = HipaaData?.witness_details;
                      temp[1].zip_code = "";
                      setHipaaData((HipaaData) => ({
                        ...HipaaData,
                        witness_details: temp,
                      }));
                    }
                  }}
                  value={HipaaData?.witness_details[1]?.zip_code || ""}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    label="State"
                    onChange={(event) => {
                      let temp = HipaaData?.witness_details;
                      temp[1].state = event.target.value;
                      temp[1].state_name =
                        stateListData[
                          stateListData?.findIndex(
                            (item) => item.id == event.target.value
                          )
                        ]?.description;
                      setHipaaData((HipaaData) => ({
                        ...HipaaData,
                        witness_details: temp,
                      }));
                    }}
                    value={HipaaData?.witness_details[1]?.state || ""}
                    sx={{
                      color: "white",
                      width: "100%",
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "white",
                        },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {stateListData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SVSignaturepad customRef={witnessTwoSign} />
          </Grid>
        </Grid>
      </Box>
      <Grid container px={2}>
        <Grid item xs={isMobile ? 12 : 3}>
          <AdminButton fullWidth title="Preview" onClick={handlePreview} />
        </Grid>
      </Grid>
    </div>
  );
}
