import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import image from "../../assets/img/silverBack.png";
import { color } from "../../theme/color";
import { useTheme } from "@emotion/react";
// @mui
// components

export default function AuthLayout() {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        sx={{
          backgroundColor: color.black,
          width: "100%",
          height: "100vh",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "90vw" : isMedium ? "50vw" : "27vw",
            position: "absolute",
            left: "50%",
            top: "45%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box sx={{ mx: "auto", width: 200, mb: 2 }}>
            <img src={image} alt="logo" style={{ width: "100%" }} />
          </Box>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
