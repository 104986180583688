import React from "react";
import { Card, Typography, Grid, Divider } from "@mui/material";
import { color } from "../../../../../theme/color";
import landingPic from "../../../../../assets/img/landing.jpg";

export default function index(props) {
  const { detail } = props;
  return (
    <Card sx={{ background: color.topbar, borderRadius: 0, p: 2 }}>
      <Typography sx={{ color: color.buttonColor, fontSize: 18 }}>
        <strong>Other Info </strong>
      </Typography>
      <Divider sx={{ mt: 1 }} />
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>
            Driving License Front
          </Typography>
        </Grid>
        <Grid item>
          <img
            style={{ objectFit: "cover", borderRadius: 15 }}
            src={
              detail?.documents?.length >= 1 ? detail?.documents[0] : landingPic
            }
            width={350}
            height={180}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 0.2 }}
      >
        <Grid item>
          <Typography sx={{ color: color.main }}>
            Driving License Back
          </Typography>
        </Grid>
        <Grid item>
          <img
            style={{ objectFit: "cover", borderRadius: 15 }}
            src={
              detail?.documents?.length >= 1 ? detail?.documents[1] : landingPic
            }
            width={350}
            height={180}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
